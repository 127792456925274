import { useEffect, useState } from 'react';
import { Drawer } from 'rsuite';
import styled from 'styled-components';
import ErrorComponent from '../../../shared/components/ErrorComponent';
import Preloader from '../../../shared/components/Preloader';
import { Loading } from '../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../store';
import { ReviewsJetPlatform } from '../../product/types';
import { selectedSiteSelector } from '../../userData/redux/userData.slice';
import { fetchWidgetSettings } from '../../widgetSettings/redux/action';
import useSaveSettings from './hooks/useSaveSettings';
import ShopifyProductWidget from './installation/shopify-product-widget';
import WebflowProductWidget from './installation/webflow/webflow-product-tutorial';
import WixProductWidget from './installation/wix-product-widget';
import Preview from './preview';
import Sidebar from './Sidebar';

/* eslint no-console: "warn" */

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const PreviewSpace = styled.div`
  flex: 1;
  background: #f6f6f9;
  min-width: 0;
  height: 100vh;
  position: sticky;
  top: 0;
  overflow-y: auto;
`;

const CenteredWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 80px);
  width: 100%;
`;

export default function ProductWidget() {
  const dispatch = useAppDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const selectedSite = useAppSelector(selectedSiteSelector);
  const { platform } = selectedSite ?? {};
  const [selectedPlatform, setSelectedPlatform] =
    useState<ReviewsJetPlatform | null>(null);

  const { loadingSiteWidgetSettings } = useAppSelector(
    (state) => state.setting
  );
  const { saveSettings } = useSaveSettings();

  useEffect(() => {
    dispatch(fetchWidgetSettings());
  }, []);

  const handleViewInstallation = () => {
    saveSettings(false);

    if (!platform) {
      return null;
    }

    setSelectedPlatform(platform);
    setOpenDrawer(true);
  };

  if (loadingSiteWidgetSettings === Loading.PENDING) {
    return <Preloader />;
  }

  if (loadingSiteWidgetSettings === Loading.ERROR) {
    return (
      <CenteredWrapper>
        <ErrorComponent onClick={() => dispatch(fetchWidgetSettings())} />
      </CenteredWrapper>
    );
  }

  return (
    <>
      <Container>
        <Sidebar onInstall={handleViewInstallation} />
        <PreviewSpace>
          <Preview onInstall={handleViewInstallation} />
        </PreviewSpace>
      </Container>

      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)} size="lg">
        <Drawer.Body>
          {selectedPlatform === ReviewsJetPlatform.WIX && <WixProductWidget />}
          {selectedPlatform === ReviewsJetPlatform.SHOPIFY && (
            <ShopifyProductWidget />
          )}
          {selectedPlatform === ReviewsJetPlatform.WEBFLOW && (
            <WebflowProductWidget />
          )}
        </Drawer.Body>
      </Drawer>
    </>
  );
}
