import dayjs from 'dayjs';
import millify from 'millify';
import { MdVerifiedUser } from 'react-icons/md';
import styled from 'styled-components';
import abbreviateLastName from '../../../../../../../../shared/utils/abbreviateLastName';
import generateAvatarData from '../../../../../../../../shared/utils/generateAvatarData';
import { Review } from '../../../../../../../manage-reviews/redux/reviews.slice';
import { SiteWidgetSettings } from '../../../../../../../widgetSettings/redux/widgetSettings.slice';
import LikeButton from '../../shared/LikeButton';
import ReviewRating from '../../shared/ReviewRating';

const Wrapper = styled.div`
  border-radius: 10px;
  padding: 16px 12px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  box-shadow: 0 1px 0 rgba(142, 142, 142, 0.1);

  .reviewer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
  }
  .location {
    font-size: 12px;
  }

  .reviewer-location {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    line-height: 20px;
  }
`;

const ReviewerDesktop = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 200px;
  width: 100%;
  gap: 10px;

  span.name {
    font-weight: bold;
    color: #000;
    display: block;
  }
  span.verified {
    font-size: 12px;
    font-weight: 600;
  }
  div.verfied-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    color: green;
  }
`;
const ReviewerMobile = styled.div`
  margin-bottom: 20px;
  align-items: center;
  gap: 10px;
  display: none;

  span.name {
    font-weight: bold;
    color: #000;
    display: block;
  }
  span.verified {
    font-size: 12px;
  }
  div.verfied-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    color: green;
  }
`;
const ReviewDetails = styled.div`
  color: #383838;
  width: 100%;

  div.review-title {
    font-size: 16px;
    margin-top: 15px;
    font-weight: bold;
  }
  div.review-text {
    margin-top: 10px;
    line-height: 22px;
    margin-bottom: 20px;

    p {
      color: inherit !important;
    }

    p span {
      color: inherit !important;
    }
  }
  div.review-reply {
    border-left: 4px solid #d0d0d0;
    padding: 10px 20px;
    margin: 0 0 20px;
    line-height: 22px;
    margin-left: 0;

    p {
      margin: 0;
      font-size: 15px;
      color: inherit !important;

      span {
        color: inherit !important;
      }
    }

    .title {
      font-size: 15px;
    }
  }
  .review-images {
    display: flex;
    gap: 15px;
    margin: 0 0 30px;
    display: flex;
    align-items: center;
  }
  .rj-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .date {
      font-size: 13px;
      color: #6c6c6c;
    }
  }
  button.like-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    background: #fff;
    padding: 5px 10px;
    border: 1px solid #d0d0d0;
    border-radius: 15px;
    color: #898787;

    &.liked {
      color: #000;
    }
  }
`;

const ReviewImage = styled.div<{ url: string }>`
  width: 90px;
  height: 90px;
  border-radius: 5px;
  background: url(${({ url }) => url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const ReviewerInitial = styled.div<{ $backgroundColor: string }>`
  color: #fff;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial;
  font-size: 14px;
  border-radius: 50%;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

const ReviewerAvatar = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 50%;
`;

type Props = {
  review: Review;
  settings: SiteWidgetSettings;
  onClickImage: (reviews: Review, index: number) => void;
};

export default function ReviewItem({ review, settings, onClickImage }: Props) {
  const {
    rating,
    reviewer: { fullName, avatar },
    content,
    title,
    reply,
    helpfulCount,
    pictures,
    verified,
    reviewDate,
    location,
  } = review;
  const {
    productWidget: {
      starColor,
      fontSize,
      textColor,
      dateColor,
      showReviewDate,
    },
    brandingSettings: {
      showFullName,
      dateFormat = 'DD/MM/YYYY',
      displayLocation,
    },
    translation: { responseFromTheOwner },
  } = settings;

  let reviewerName = '';

  if (showFullName) {
    reviewerName = abbreviateLastName(fullName);
  } else {
    const [firstName] = fullName?.split(' ') ?? [];
    reviewerName = firstName;
  }

  const avatarData = generateAvatarData(reviewerName ?? '');

  let _reviewDate = reviewDate;

  try {
    _reviewDate = dayjs(reviewDate).format(dateFormat);

    if (_reviewDate === 'Invalid Date') {
      _reviewDate = reviewDate;
    }
  } catch {
    _reviewDate = reviewDate;
  }
  return (
    <Wrapper>
      <ReviewerDesktop>
        {avatar ? (
          <ReviewerAvatar src={avatar} referrerPolicy="no-referrer" />
        ) : (
          <ReviewerInitial $backgroundColor={avatarData.backgroundColor}>
            {avatarData.avatar}
          </ReviewerInitial>
        )}

        <div className="reviewer">
          <span
            className="name assistant-500"
            style={{ color: textColor, fontSize }}
          >
            {reviewerName}
          </span>
          {displayLocation && location?.city && (
            <span
              className="location assistant-400"
              style={{
                color: textColor,
                opacity: 0.7,
                fontSize: fontSize - 3,
              }}
            >{`${location.city}, ${location.countryCode}`}</span>
          )}

          <div className="verfied-wrapper">
            <MdVerifiedUser />
            <span
              className="verified assistant-400"
              style={{ fontSize: fontSize - 3 }}
            >
              Verified
            </span>
          </div>
        </div>
      </ReviewerDesktop>
      <ReviewDetails>
        <ReviewerMobile>
          {avatar ? (
            <ReviewerAvatar src={avatar} />
          ) : (
            <ReviewerInitial $backgroundColor={avatarData.backgroundColor}>
              {avatarData.avatar}
            </ReviewerInitial>
          )}

          <div className="reviewer">
            <div className="reviewer-location assistant-500">
              <span
                className="name assistant-500"
                style={{ color: textColor, fontSize }}
              >
                {reviewerName}
              </span>
              {displayLocation && location?.city && (
                <span
                  className="location assistant-400"
                  style={{
                    color: textColor,
                    opacity: 0.7,
                    fontSize: fontSize - 3,
                  }}
                >{`${location.city}, ${location.countryCode}`}</span>
              )}
            </div>

            {verified && (
              <div className="verfied-wrapper">
                <MdVerifiedUser />
                <span
                  className="verified assistant-400"
                  style={{ fontSize: fontSize - 3 }}
                >
                  Verified
                </span>
              </div>
            )}
          </div>
        </ReviewerMobile>
        <ReviewRating color={starColor} rating={rating} />
        <div
          className="review-title assistant-500"
          style={{ color: textColor, fontSize }}
        >
          {title}
        </div>
        <div
          style={{ color: textColor, fontSize, opacity: 0.7 }}
          className="review-text review-content assistant-400"
          dangerouslySetInnerHTML={{ __html: content }}
        />

        {!!pictures.length && (
          <div className="review-images">
            {pictures.map((picture, index) => (
              <ReviewImage
                key={picture}
                url={picture}
                onClick={() => onClickImage(review, index)}
              />
            ))}
          </div>
        )}

        {reply?.content && (
          <div
            className="review-reply assistant-400"
            style={{ color: textColor, fontSize }}
          >
            <span
              className="title assistant-500"
              style={{ fontSize, color: textColor, opacity: 0.7 }}
            >
              {responseFromTheOwner}
            </span>
            <p
              dangerouslySetInnerHTML={{ __html: reply.content }}
              style={{ fontSize, opacity: 0.7 }}
            />
          </div>
        )}
        {showReviewDate && (
          <div className="rj-footer">
            <LikeButton count={millify(helpfulCount)} color={textColor} />

            <span
              className="date assistant-400"
              style={{
                color: textColor,
                fontSize: fontSize - 2,
                opacity: 0.7,
              }}
            >
              {_reviewDate}
            </span>
          </div>
        )}
      </ReviewDetails>
    </Wrapper>
  );
}
