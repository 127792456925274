import { useState } from 'react';
import styled from 'styled-components';
import { Loading } from '../../../../../shared/types/review.types';
import { useAppSelector } from '../../../../../store';
import { Review } from '../../../../manage-reviews/redux/reviews.slice';
import {
  ProductWidgetTabType,
  ReviewsJetPlatform,
} from '../../../../product/types';
import { ReviewRatingSummary } from '../../../../review/types';
import { siteWidgetSettingsSelector } from '../../../../widgetSettings/redux/widgetSettings.slice';
import { OptionBeingUpdated } from '../../redux/product-reviews-settings.slice';
import Header from './components/Header';
import PhotosVideos from './components/PhotosVideos';
import QuestionForm from './components/Questions/QuestionForm';
import Reviews from './components/Reviews';
import Modal from './components/shared/Modal';
import Tabs from './components/Tabs';
import './index.css';
import { mediaWithReviews, productReviews } from './reviews';

const Wrapper = styled.div`
  width: calc(100% - 40px);
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 15px !important;
  margin: auto;
`;

const ProductWidgetStar = styled.div`
  height: 50px;
  &:empty {
    display: block !important;
  }
`;

type Props = {
  siteId?: string;
  instanceId?: string;
  productId: string;
  platform: ReviewsJetPlatform;
};

export default function ProductWidget({
  productId,
  instanceId,
  siteId,
  platform,
}: Props) {
  const [tab, setTab] = useState<ProductWidgetTabType>('reviews');

  const [isOpen, setOpen] = useState(false);
  const totalDocs = 1200;
  const rating = 4.5;
  const ratingSummary: ReviewRatingSummary = {
    oneStars: 10,
    twoStars: 20,
    threeStars: 30,
    fourStars: 40,
    fiveStars: 50,
  };
  const settings = useAppSelector(siteWidgetSettingsSelector);
  const siteName = 'Test Site';
  // @ts-ignore
  const reviews: Review[] = productReviews;
  const hasNextPage = false;
  const loadingReviews = Loading.SUCCESS;
  const questions: any[] = [];
  const { optionBeingUpdated } = useAppSelector(
    (state) => state.productReviewsSettings
  );

  if (!settings) {
    return null;
  }

  const {
    productWidget: { showAllCustomerPhotosAndVideos },
  } = settings;

  return (
    <>
      <ProductWidgetStar id="rj-product-widget" />
      <Wrapper>
        <>
          {optionBeingUpdated !==
            OptionBeingUpdated.PRODUCT_REVIEWS_EMPTY_STATE && (
            <>
              <Header
                rating={rating}
                totalDocs={totalDocs}
                settings={settings}
                ratingSummary={ratingSummary}
                productId={productId}
                siteName={siteName}
                openQuestionForm={() => setOpen(true)}
              />
              {showAllCustomerPhotosAndVideos && (
                <PhotosVideos
                  // @ts-ignore
                  mediaWithReviews={mediaWithReviews}
                  settings={settings}
                />
              )}
            </>
          )}
          {!!questions.length && (
            <Tabs
              tab={tab}
              onChange={(tab: any) => setTab(tab)}
              settings={settings}
              totalQuestions={questions.length}
              totalReviews={totalDocs}
            />
          )}
          <Reviews
            reviews={reviews}
            settings={settings}
            totalDocs={totalDocs}
            hasNextPage={hasNextPage}
            loadingReviews={loadingReviews}
            loadMoreReviews={() => {}}
            hasQuestions={!!questions.length}
            productId={productId}
            siteName={siteName}
            // @ts-ignore
            mediaWithReviews={mediaWithReviews}
            openQuestionForm={() => setOpen(true)}
          />
        </>
      </Wrapper>

      <Modal
        isOpen={isOpen}
        size="450px"
        onClose={() => setOpen(false)}
        children={
          <QuestionForm
            productId={productId}
            settings={settings}
            onClose={() => setOpen(false)}
            instanceId={instanceId}
            siteId={siteId}
          />
        }
      />
    </>
  );
}
