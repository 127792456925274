import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { IoCopyOutline } from 'react-icons/io5';
import { TiTickOutline } from 'react-icons/ti';
import { Button, Divider } from 'rsuite';
import styled from 'styled-components';

import CopyableCode from '../../../../../shared/components/CopyableCode';
import { useAppDispatch } from '../../../../../store';
import { toggleFeedbackDrawer } from '../../../../userData/redux/userData.slice';

const StepWrapper = styled.div`
  margin-bottom: 30px;

  p {
    margin: 30px 0;
  }

  img {
    width: 100%;
    border: 1px solid rgb(222, 222, 222);
    padding: 2px;
    border-radius: 10px;
  }
`;

const Message = styled.div`
  border-left: 4px solid #5bc0de;
  background-color: #e2f2fb;
  padding: 20px;
  margin-bottom: 50px;
  font-size: 14px;
  border-radius: 5px;

  .message-title {
    color: #4a90e2;
    margin-bottom: 0px;
    font-size: 18px;
    line-height: 30px;
    font-family: 'Ubuntu', sans-serif;
  }

  p {
    margin: 0 0 20px;
  }

  .contact-us {
    background-color: #fff;
    border: 1px solid #4a90e2;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
  }

  .contact-us:hover {
    background-color: #4a90e2;
    color: #fff;
  }
`;

const Step = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background-color: #f3f3f3;
  justify-content: flex-start;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 20px;
`;
const StepNumber = styled.div`
  height: 100%;
  width: 80px;
  background-color: #cfe4ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-weight: bold;
`;

const StepDesc = styled.div`
  margin-bottom: 10px;
`;

const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
  margin: auto;
  gap: 5px;
  margin-top: 10px;
`;

type Props = {
  siteId?: string;
};

export default function ProductWidget({ siteId }: Props) {
  const [copied, setCopied] = useState(false);
  const dispatch = useAppDispatch();
  const installationCode = `<div data-widget="reviewsjet-product-widget" data-id="${siteId}"></div>
<script defer src="https://cdn.reviewsjet.com/@FlexByt/widgets/webflow-product-widget/product-widget.js"></script>`;

  const { t } = useTranslation('common');

  return (
    <>
      <StepWrapper>
        <Step>
          <StepNumber>Step 1</StepNumber>
          <div>Copy the installation code</div>
        </Step>
        <StepDesc>
          <strong>Installation code:</strong>
        </StepDesc>

        <CopyableCode code={installationCode} />

        <CopyToClipboard text={installationCode}>
          <StyledButton
            color={copied ? 'green' : 'ghost'}
            appearance="ghost"
            onClick={() => {
              setCopied(true);
              setTimeout(() => setCopied(false), 3000);
            }}
          >
            {copied ? (
              <>
                <TiTickOutline />
                {t('reviews.import-modal.copied-to-clipboard')}
              </>
            ) : (
              <>
                <IoCopyOutline /> {t('shared.copy-installation-code')}
              </>
            )}
          </StyledButton>
        </CopyToClipboard>
      </StepWrapper>

      <StepWrapper>
        <Step>
          <StepNumber>Step 2</StepNumber>
          <div>Paste the installation code</div>
        </Step>
        <StepDesc>
          In your Webflow Designer, navigate to the Products Template page.
        </StepDesc>
        <img src="/images/tutorials/webflow/product-widget-1.png" alt="" />

        <p>
          Next, add a <b>Code Embed</b> widget to the page.
        </p>
        <img src="/images/tutorials/webflow/product-widget-2.png" alt="" />

        <p>
          In the <b>Code</b> field, paste the installation code you copied in
          Step 1.
        </p>

        <img src="/images/tutorials/webflow/product-widget-3.png" alt="" />

        <p>Preview:</p>
        <img src="/images/tutorials/webflow/product-widget-4.png" alt="" />

        <p>
          Click <b>Save &amp; Close</b> to save your changes. Don't forget to
          publish your changes.
        </p>
      </StepWrapper>

      <Divider />

      <p style={{ textAlign: 'center' }}>
        Congratulations! Your Product widget should now be successfully
        integrated and visibly displayed on your website.
      </p>

      <Divider />

      <Message>
        <div className="message-title">
          Having trouble installing the Product widget?
        </div>
        <p>Click the button below to contact us now.</p>
        <button
          className="contact-us"
          onClick={() => dispatch(toggleFeedbackDrawer())}
        >
          Contact Us
        </button>
      </Message>
    </>
  );
}
