import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Loading } from '../../../../../../../shared/types/review.types';
import { useAppSelector } from '../../../../../../../store';
import { Review } from '../../../../../../manage-reviews/redux/reviews.slice';
import { MediaWithReview } from '../../../../../../review/types';
import { SiteWidgetSettings } from '../../../../../../widgetSettings/redux/widgetSettings.slice';
import { OptionBeingUpdated } from '../../../../redux/product-reviews-settings.slice';
import EmptyState from '../EmptyState';
import Modal from '../shared/Modal';
import PhotoReviewModal from '../shared/PhotoReviewModal';
import SortButton from '../shared/SortButton';
import ReviewsList from './ReviewsList';

/* eslint no-console: "warn" */

const Wrapper = styled.div``;
const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  box-shadow: 0 1px 0 rgba(142, 142, 142, 0.1);
  padding-bottom: 16px;
`;
const LayoutButtons = styled.div<{ $btnColor: string }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    background-color: #ffffff;
    outline: none;
    padding: 5px 7px;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    /* border-radius: 5px; */
    cursor: pointer;
    color: #a1a1a1;
    &.active {
      color: #000;
      background-color: #f0f0f1;
      &:hover {
        color: #000;
        background-color: #f0f0f1;
      }
    }
    &:hover {
      background-color: #f0f0f1;
    }

    &.list-btn {
      -webkit-border-top-left-radius: 5px;
      -webkit-border-bottom-left-radius: 5px;
      -moz-border-radius-topleft: 5px;
      -moz-border-radius-bottomleft: 5px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-left: 1px solid #ddd;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
    }

    &.grid-btn {
      -webkit-border-top-right-radius: 5px;
      -webkit-border-bottom-right-radius: 5px;
      -moz-border-radius-topright: 5px;
      -moz-border-radius-bottomright: 5px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border: 1px solid #ddd;
    }
  }
`;

const Loadmore = styled.button<{ $color: string; $bgColor: string }>`
  display: block;
  margin: 30px 0;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding: 12px 20px;

  border-radius: 25px;
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ $color }) => $color};
  cursor: pointer;
  border: none;
  outline: none;
`;

type Props = {
  siteName: string;
  productId: string;
  totalDocs: number;
  hasNextPage: boolean;
  hasQuestions: boolean;
  reviews: Review[];
  loadingReviews: Loading;
  settings: SiteWidgetSettings;
  openQuestionForm: () => void;
  mediaWithReviews: MediaWithReview[];
  loadMoreReviews: () => void;
};

export default function Reviews({
  reviews,
  settings,
  siteName,
  productId,
  totalDocs,
  hasQuestions,
  loadingReviews,
  mediaWithReviews,
  loadMoreReviews,
  openQuestionForm,
}: Props) {
  const [isOpen, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const {
    productWidget: { buttonBackgroundColor, buttonTextColor, fontSize },
    translation: { loadMoreReviews: loadMore, loading },
  } = settings;
  const [selectedMediaReview, setSelectedMediaReview] =
    useState<MediaWithReview | null>(null);
  const [selectedReview, setSelectedReview] = useState<Review | null>(null);
  const [reviewsWithMedia, setReviewsWithMedia] = useState(mediaWithReviews);
  const { optionBeingUpdated } = useAppSelector(
    (state) => state.productReviewsSettings
  );

  const displayReview = (review: Review, _index: number) => {
    const { pictures, type } = review;

    const newReviewsWithMedia = pictures.map((_: any, index: number) => ({
      type: type === 'text' ? 'picture' : 'video',
      media: pictures[index],
      review,
    })) as MediaWithReview[];

    setCurrentIndex(_index);
    setSelectedReview(review);
    setReviewsWithMedia(newReviewsWithMedia);
    setSelectedMediaReview(newReviewsWithMedia[0]);
    setOpen(true);
  };

  useEffect(() => {
    if (selectedReview && currentIndex !== null) {
      setSelectedMediaReview(reviewsWithMedia[currentIndex]);
    }
  }, [currentIndex, selectedReview, reviewsWithMedia]);

  return (
    <Wrapper>
      {optionBeingUpdated === OptionBeingUpdated.PRODUCT_REVIEWS_EMPTY_STATE ? (
        <EmptyState
          settings={settings}
          hasQuestions={hasQuestions}
          productId={productId}
          siteName={siteName}
          openQuestionForm={openQuestionForm}
        />
      ) : (
        <>
          {!!totalDocs && (
            <Actions>
              <SortButton fontSize={fontSize} />
            </Actions>
          )}

          <ReviewsList
            reviews={reviews}
            settings={settings}
            onClickImage={(review, index) => displayReview(review, index)}
          />

          <Loadmore
            $color={buttonTextColor}
            $bgColor={buttonBackgroundColor}
            onClick={loadMoreReviews}
            disabled={loadingReviews === Loading.PENDING}
            type="button"
            style={{ fontSize: fontSize - 1 }}
          >
            {loadingReviews === Loading.PENDING ? loading : loadMore}
          </Loadmore>

          <Modal
            size="1000px"
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            children={
              <PhotoReviewModal
                settings={settings}
                currentIndex={currentIndex}
                selectedMediaReview={selectedMediaReview}
                mediaWithReviews={reviewsWithMedia}
                onSelect={(selectedMediaReview, index) => {
                  setSelectedMediaReview(selectedMediaReview);
                  setCurrentIndex(index);
                }}
              />
            }
          />
        </>
      )}
    </Wrapper>
  );
}
