import { FaCircleInfo } from 'react-icons/fa6';
import { Divider, Input, InputGroup } from 'rsuite';
import styled from 'styled-components';
import { Label } from '../../../../shared/components/Label';
import PremiumBanner from '../../../../shared/components/PremiumBanner';
import { Title } from '../../../../shared/components/Title';
import { SubscriptionPlan } from '../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { selectedSiteSelector } from '../../../userData/redux/userData.slice';
import FormRow from '../../../widgetSettings/components/form-row';
import {
  siteWidgetSettingsSelector,
  updateSiteWidgetSettings,
} from '../../../widgetSettings/redux/widgetSettings.slice';
import {
  OptionBeingUpdated,
  setOptionBeingUpdated,
} from '../redux/product-reviews-settings.slice';

const InfoIconWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: help;

  &:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }
`;

const InfoIcon = styled(FaCircleInfo)`
  color: #666;
  font-size: 14px;
`;

const CustomTooltip = styled.div`
  position: absolute;
  left: -50px;
  bottom: calc(100% + 8px);
  transform: translateX(-50%);
  background: #333;
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  z-index: 1000;
  line-height: 15px;

  &::before {
    content: '';
    position: absolute;
    right: 20px;
    bottom: -4px;
    transform: translateX(-50%);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #333;
  }
`;

const SmallerTitle = styled(Title)`
  font-size: 14px;
`;

export default function Language() {
  const dispatch = useAppDispatch();
  const settings = useAppSelector(siteWidgetSettingsSelector);
  const { translation } = settings ?? {};
  const {
    productWidgetTitle,
    reviewForm,
    questionForm,
    customerPhotosAndVideos,
    loadMoreReviews,
    productReviewsEmptyState,
  } = translation ?? {};
  const { addReview, submitButtonText, cancel } = reviewForm ?? {};
  const {
    close,
    addQuestion,
    askQuestionPlaceholder,
    emailPlaceholder,
    namePlaceholder,
    responseFromTheOwner,
    thankYouMessage,
    thankYouTitle,
  } = questionForm ?? {};

  const selectedSite = useAppSelector(selectedSiteSelector);
  const { plan } = selectedSite ?? {};
  const disabled = plan === SubscriptionPlan.FREE;

  return (
    <div>
      {plan === SubscriptionPlan.FREE && (
        <PremiumBanner
          availableOn={SubscriptionPlan.PRO}
          description="Upgrade to update the language."
        />
      )}
      <SmallerTitle
        style={{ marginTop: plan === SubscriptionPlan.FREE ? '20px' : '0' }}
      >
        Product Reviews Translation
      </SmallerTitle>
      <FormRow>
        <Label>Based on total reviews</Label>

        <InputGroup inside>
          <Input
            placeholder="Based on total reviews"
            value={productWidgetTitle}
            readOnly={disabled}
            onChange={(value) => {
              if (disabled) return;

              dispatch(
                updateSiteWidgetSettings({
                  ...settings,
                  translation: {
                    ...settings?.translation,
                    productWidgetTitle: value,
                  },
                })
              );
            }}
            onClick={() => {
              dispatch(setOptionBeingUpdated(null));
            }}
          />
          <InputGroup.Addon>
            <InfoIconWrapper>
              <InfoIcon />
              <CustomTooltip className="tooltip">
                {'{totalReview}'} will show the <br />
                actual number of reviews
              </CustomTooltip>
            </InfoIconWrapper>
          </InputGroup.Addon>
        </InputGroup>
      </FormRow>
      <FormRow>
        <Label>Write a review</Label>
        <Input
          placeholder="Write a review"
          value={addReview}
          readOnly={disabled}
          onChange={(value) => {
            if (disabled) return;

            dispatch(
              updateSiteWidgetSettings({
                ...settings,
                translation: {
                  ...settings?.translation,
                  reviewForm: {
                    ...reviewForm,
                    addReview: value,
                  },
                },
              })
            );
          }}
          onClick={() => {
            dispatch(setOptionBeingUpdated(null));
          }}
        />
      </FormRow>

      <FormRow>
        <Label>Customer photos and videos</Label>
        <Input
          placeholder="Customer photos and videos"
          value={customerPhotosAndVideos}
          readOnly={disabled}
          onChange={(value) => {
            if (disabled) return;

            dispatch(
              updateSiteWidgetSettings({
                ...settings,
                translation: {
                  ...translation,
                  customerPhotosAndVideos: value,
                },
              })
            );
          }}
          onClick={() => {
            dispatch(setOptionBeingUpdated(null));
          }}
        />
      </FormRow>
      <FormRow>
        <Label>Load more reviews</Label>
        <Input
          placeholder="Load more reviews"
          value={loadMoreReviews}
          readOnly={disabled}
          onChange={(value) => {
            if (disabled) return;

            dispatch(
              updateSiteWidgetSettings({
                ...settings,
                translation: {
                  ...translation,
                  loadMoreReviews: value,
                },
              })
            );
          }}
          onClick={() => {
            dispatch(setOptionBeingUpdated(null));
          }}
        />
      </FormRow>

      <FormRow>
        <Label>No product reviews published yet.</Label>
        <Input
          placeholder="No product reviews published yet."
          value={productReviewsEmptyState}
          readOnly={disabled}
          onChange={(value) => {
            if (disabled) return;

            dispatch(
              updateSiteWidgetSettings({
                ...settings,
                translation: {
                  ...translation,
                  productReviewsEmptyState: value,
                },
              })
            );
          }}
          onClick={() => {
            dispatch(
              setOptionBeingUpdated(
                OptionBeingUpdated.PRODUCT_REVIEWS_EMPTY_STATE
              )
            );
          }}
        />
      </FormRow>

      <Divider />

      <SmallerTitle>Product Questions Translation</SmallerTitle>
      <FormRow>
        <Label>Ask a question</Label>
        <Input
          placeholder="Ask a question"
          value={addQuestion}
          readOnly={disabled}
          onChange={(value) => {
            if (disabled) return;

            dispatch(
              updateSiteWidgetSettings({
                ...settings,
                translation: {
                  ...settings?.translation,
                  questionForm: {
                    ...questionForm,
                    addQuestion: value,
                  },
                },
              })
            );
          }}
          onClick={() => {
            dispatch(setOptionBeingUpdated(null));
          }}
        />
      </FormRow>

      <FormRow>
        <Label>Ask a question</Label>
        <Input
          placeholder="Ask a question placeholder"
          value={askQuestionPlaceholder}
          readOnly={disabled}
          onChange={(value) => {
            if (disabled) return;

            dispatch(
              updateSiteWidgetSettings({
                ...settings,
                translation: {
                  ...settings?.translation,
                  questionForm: {
                    ...questionForm,
                    askQuestionPlaceholder: value,
                  },
                },
              })
            );
          }}
          onClick={() => {
            dispatch(setOptionBeingUpdated(null));
          }}
        />
      </FormRow>

      <FormRow>
        <Label>Name placeholder</Label>
        <Input
          placeholder="Name placeholder"
          value={namePlaceholder}
          readOnly={disabled}
          onChange={(value) => {
            if (disabled) return;

            dispatch(
              updateSiteWidgetSettings({
                ...settings,
                translation: {
                  ...settings?.translation,
                  questionForm: {
                    ...questionForm,
                    namePlaceholder: value,
                  },
                },
              })
            );
          }}
          onClick={() => {
            dispatch(setOptionBeingUpdated(null));
          }}
        />
      </FormRow>

      <FormRow>
        <Label>Email placeholder</Label>
        <Input
          placeholder="Email placeholder"
          value={emailPlaceholder}
          readOnly={disabled}
          onChange={(value) => {
            if (disabled) return;

            dispatch(
              updateSiteWidgetSettings({
                ...settings,
                translation: {
                  ...settings?.translation,
                  questionForm: {
                    ...questionForm,
                    emailPlaceholder: value,
                  },
                },
              })
            );
          }}
          onClick={() => {
            dispatch(setOptionBeingUpdated(null));
          }}
        />
      </FormRow>

      <FormRow>
        <Label>Submit button text</Label>
        <Input
          placeholder="Submit button text"
          value={submitButtonText}
          readOnly={disabled}
          onChange={(value) => {
            if (disabled) return;

            dispatch(
              updateSiteWidgetSettings({
                ...settings,
                translation: {
                  ...settings?.translation,
                  reviewForm: {
                    ...reviewForm,
                    submitButtonText: value,
                  },
                },
              })
            );
          }}
          onClick={() => {
            dispatch(setOptionBeingUpdated(null));
          }}
        />
      </FormRow>

      <FormRow>
        <Label>Cancel button text</Label>
        <Input
          placeholder="Cancel button text"
          value={cancel}
          readOnly={disabled}
          onChange={(value) => {
            if (disabled) return;

            dispatch(
              updateSiteWidgetSettings({
                ...settings,
                translation: {
                  ...settings?.translation,
                  reviewForm: {
                    ...reviewForm,
                    cancel: value,
                  },
                },
              })
            );
          }}
          onClick={() => {
            dispatch(setOptionBeingUpdated(null));
          }}
        />
      </FormRow>

      <FormRow>
        <Label>Thank you title</Label>
        <Input
          placeholder="Thank you title"
          value={thankYouTitle}
          readOnly={disabled}
          onChange={(value) => {
            if (disabled) return;

            dispatch(
              updateSiteWidgetSettings({
                ...settings,
                translation: {
                  ...settings?.translation,
                  questionForm: {
                    ...questionForm,
                    thankYouTitle: value,
                  },
                },
              })
            );
          }}
          onClick={() => {
            dispatch(setOptionBeingUpdated(null));
          }}
        />
      </FormRow>

      <FormRow>
        <Label>Thank you message</Label>
        <Input
          placeholder="Thank you message"
          value={thankYouMessage}
          readOnly={disabled}
          onChange={(value) => {
            if (disabled) return;

            dispatch(
              updateSiteWidgetSettings({
                ...settings,
                translation: {
                  ...settings?.translation,
                  questionForm: {
                    ...questionForm,
                    thankYouMessage: value,
                  },
                },
              })
            );
          }}
          onClick={() => {
            dispatch(setOptionBeingUpdated(null));
          }}
        />
      </FormRow>

      <FormRow>
        <Label>Response from the owner</Label>
        <Input
          placeholder="Response from the owner"
          value={responseFromTheOwner}
          readOnly={disabled}
          onChange={(value) => {
            if (disabled) return;

            dispatch(
              updateSiteWidgetSettings({
                ...settings,
                translation: {
                  ...settings?.translation,
                  questionForm: {
                    ...questionForm,
                    responseFromTheOwner: value,
                  },
                },
              })
            );
          }}
          onClick={() => {
            dispatch(setOptionBeingUpdated(null));
          }}
        />
      </FormRow>

      <FormRow>
        <Label>Close button text</Label>
        <Input
          placeholder="Close button text"
          value={close}
          readOnly={disabled}
          onChange={(value) => {
            if (disabled) return;

            dispatch(
              updateSiteWidgetSettings({
                ...settings,
                translation: {
                  ...settings?.translation,
                  questionForm: {
                    ...questionForm,
                    close: value,
                  },
                },
              })
            );
          }}
          onClick={() => {
            dispatch(setOptionBeingUpdated(null));
          }}
        />
      </FormRow>
    </div>
  );
}
