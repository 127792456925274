import initials from 'initials';
import { useRef } from 'react';
import { FaRegTrashCan } from 'react-icons/fa6';
import { Avatar } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../../shared/components/Button';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import useChangeLogo from '../../../../shared/hooks/useChangeLogo';
import generateColorHsl from '../../../../shared/utils/generateColorHsl';

/* eslint no-console: "warn" */

const StyledAvatar = styled(Avatar)<{ color: string }>`
  color: #fff;
  background-color: ${({ color }) => color};
  font-size: 20px;
  font-weight: bolder;
`;

const DeleteBtn = styled.div`
  display: block;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 100%;
  height: 26px;
  width: 26px;
  &:hover {
    color: #324fbe;
    background-color: #ebeef9;
  }
`;

const StyledLogo = styled.img`
  max-height: 60px;
  max-width: 150px;
  object-fit: contain;
`;

type Props = {
  logo?: string | File;
  name: string;
  showDeleteButton?: boolean;
  onLogoDeleted: () => void;
  onLogoUploaded: (value: string) => void;
};

export default function Logo(props: Props) {
  const ref = useRef(null);
  const {
    name,
    onLogoUploaded,
    onLogoDeleted,
    logo,
    showDeleteButton = true,
  } = props;

  const { logoUrl, handleChange } = useChangeLogo({ logo, onLogoUploaded });

  return (
    <FlexContainer justifyContent="flex-start" alignItems="center" gap="30px">
      <div>
        {logoUrl ? (
          <StyledLogo src={logoUrl} alt="logo" />
        ) : (
          <StyledAvatar
            size="lg"
            src={logoUrl ?? ''}
            color={
              logoUrl ? '#cdcdcd' : name ? generateColorHsl(name) : '#dddddd'
            }
          >
            {name ? initials(name).substring(0, 2).toUpperCase() : ''}
          </StyledAvatar>
        )}
      </div>
      <div>
        <p style={{ marginBottom: 10 }}>
          For optimal results, use a high-resolution image and set the height to
          60px.
        </p>

        <FlexContainer justifyContent="flex-start" gap="5px">
          <Button
            rounded
            size="small"
            type="button"
            variant="tertiary"
            onClick={() => {
              (ref.current as any)?.click();
            }}
          >
            Change
          </Button>
          {showDeleteButton && logo && (
            <DeleteBtn
              role="presentation"
              onClick={() => {
                onLogoDeleted();
              }}
            >
              <FaRegTrashCan size={16} />
            </DeleteBtn>
          )}
        </FlexContainer>
      </div>
      <input
        hidden
        value=""
        ref={ref}
        type="file"
        onChange={handleChange}
        accept=".jpeg,.jpg,.png,.webp,.avif"
      />
    </FlexContainer>
  );
}
