import _ from 'lodash';
import { Control, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Checkbox, CheckboxGroup, Message } from 'rsuite';
import styled from 'styled-components';
import { FormType } from '..';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import {
  Integration,
  IntegrationPlatform,
} from '../../../../socialReviews/redux/social-reviews.slice';
import FormRow from '../../../../widgetSettings/components/form-row';

type Props = {
  submitReviewsTo: string[];
  control: Control<FormType, any>;
  integrations: Record<IntegrationPlatform, Integration[]> | null;
};

const StyledMessage = styled(Message)`
  .rs-message-container {
    color: #000;
    background-color: #f9f9f9;
  }
`;

export default function ConnectedThirdPartyAccounts(props: Props) {
  const { control, submitReviewsTo, integrations } = props;

  if (_.isEmpty(integrations)) {
    return (
      <StyledMessage>
        <FlexContainer justifyContent="flex-start" gap="5px" flexWrap="wrap">
          <span>No third-party accounts are currently connected.</span>
          <Link to="/import-reviews">Connect an account</Link>
        </FlexContainer>
      </StyledMessage>
    );
  }

  return (
    <>
      <FormRow
        stack
        gap="0"
        alignItems="flex-start"
        marginBottom="0"
        marginTop="0"
      >
        <span>Select third-party accounts?</span>
        <Controller
          name="submitReviewsTo"
          control={control}
          render={({ field }) => (
            <CheckboxGroup {...field}>
              {!!integrations?.google_maps_reviews?.length && (
                <Checkbox
                  value="google_maps_reviews"
                  defaultChecked={submitReviewsTo.includes(
                    'google_maps_reviews'
                  )}
                >
                  Google
                </Checkbox>
              )}
              {!!integrations?.yelp_reviews?.length && (
                <Checkbox
                  value="yelp_reviews"
                  defaultChecked={submitReviewsTo.includes('yelp_reviews')}
                >
                  Yelp
                </Checkbox>
              )}
              {!!integrations?.trustpilot_reviews?.length && (
                <Checkbox
                  value="trustpilot_reviews"
                  defaultChecked={submitReviewsTo.includes(
                    'trustpilot_reviews'
                  )}
                >
                  Trustpilot
                </Checkbox>
              )}
              {!!integrations?.producthunt_reviews?.length && (
                <Checkbox
                  value="producthunt_reviews"
                  defaultChecked={submitReviewsTo.includes(
                    'producthunt_reviews'
                  )}
                >
                  Product Hunt
                </Checkbox>
              )}
              {!!integrations?.tripadvisor_reviews?.length && (
                <Checkbox
                  value="tripadvisor_reviews"
                  defaultChecked={submitReviewsTo.includes(
                    'tripadvisor_reviews'
                  )}
                >
                  Tripadvisor
                </Checkbox>
              )}
            </CheckboxGroup>
          )}
        />
      </FormRow>
      <StyledMessage>
        <FlexContainer justifyContent="flex-start" gap="5px" flexWrap="wrap">
          <span>
            To connect more third-party accounts are currently connected,
          </span>
          <Link to="/import-reviews">click here</Link>
        </FlexContainer>
      </StyledMessage>
    </>
  );
}
