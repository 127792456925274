import { useEffect, useState } from 'react';
import { FaCircleInfo } from 'react-icons/fa6';
import { IoChevronBack } from 'react-icons/io5';
import { MdLink, MdOutlineEmail, MdPeople, MdSettings } from 'react-icons/md';
import { Input, InputGroup, InputPicker, Toggle as RsuiteToggle } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import styled from 'styled-components';
import BackButton from '../../../../shared/components/BackButton';
import AppModal from '../../../../shared/components/modal';
import useModal from '../../../../shared/components/modal/useModal';
import { QuillEditor } from '../../../../shared/components/QuillEditor';
import { useNotification } from '../../../../shared/hooks/useNotification';
import {
  Loading,
  SubscriptionPlan,
} from '../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { toggleUpgradeModal } from '../../../../store/app.slice';
import { fetchReviewForms } from '../../../review-forms/redux/actions';
import { reviewFormSelector } from '../../../review-forms/redux/review-form.slice';
import {
  Recipient as ReviewRequestRecipient,
  setReviewRequestData,
} from '../../../review-request-data/redux/review-request-data.slice';
import {
  accountSelector,
  selectedSiteSelector,
} from '../../../userData/redux/userData.slice';
import { Order } from '../types';
import { formatPrice, getCustomerDetails } from '../utils/common';
import ConfirmSendEmails from './ConfirmSendEmails';
import SendTestEmail from './SendTestEmail';

/* eslint no-console: "warn" */

const InfoIconWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: help;

  &:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }
`;

const InfoIcon = styled(FaCircleInfo)`
  color: #666;
  font-size: 14px;
`;

const CustomTooltip = styled.div`
  position: absolute;
  left: -50px;
  bottom: calc(100% + 8px);
  transform: translateX(-50%);
  background: #333;
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  z-index: 1000;
  line-height: 15px;

  &::before {
    content: '';
    position: absolute;
    right: 20px;
    bottom: -4px;
    transform: translateX(-50%);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #333;
  }
`;

const ConfigSection = styled.div`
  background: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const HeaderWrapper = styled.div`
  background-color: #f9fafb;
  padding: 24px 28px;
  border-radius: 12px 12px 0 0;
  border-bottom: 1px solid #e5e7eb;
  margin: 26px 0;
`;

const StyledTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  color: #111827;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    color: #6366f1;
    font-size: 22px;
  }
`;

const Section = styled.div`
  padding: 28px;
  border-bottom: 1px solid #e5e7eb;

  &:last-child {
    border-bottom: none;
  }
`;

const SectionTitle = styled.h3`
  font-size: 13px;
  font-weight: 600;
  color: #475569;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    color: #6366f1;
    font-size: 16px;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LabelContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Label = styled.label`
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #374151;
  margin-bottom: 6px;
`;

const InputLabel = styled(Label)`
  margin-bottom: 8px;
  color: #4e4e4e;
`;

const ToggleLabel = styled(Label)`
  margin-bottom: 2px;
  font-weight: 600;
`;

const Description = styled.div`
  font-size: 13px;
  color: #6b7280;
  line-height: 1.4;
`;

const Recipients = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Recipient = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 8px 12px;
  background: #f9fafb;
  border-radius: 6px;
  font-size: 14px;

  .name {
    font-weight: 500;
    color: #111827;
  }

  .email {
    color: #6b7280;
  }
`;

const MoreRecipients = styled.div`
  color: #6b7280;
  font-size: 14px;
  padding: 8px 12px;
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 28px;
  background: #f9fafb;
  border-top: 1px solid #e5e7eb;
  border-radius: 0 0 12px 12px;
`;

const Button = styled.button<{ variant?: 'primary' | 'secondary' }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;

  ${({ variant = 'secondary' }) =>
    variant === 'primary'
      ? `
    background: #6366f1;
    color: white;
    border: 1px solid #6366f1;
    &:hover {
      background: #4f46e5;
      border-color: #4f46e5;
    }
  `
      : `
    background: white;
    color: #374151;
    border: 1px solid #d1d5db;
    &:hover {
      border-color: #9ca3af;
      background: #f9fafb;
    }
  `}
`;

const ErrorMessage = styled.div`
  color: #dc2626;
  font-size: 13px;
  margin-top: 4px;
`;

const validateForm = (data: any) => {
  const errors: Record<string, string> = {};

  if (!data?.formKey) {
    errors.formKey = 'Please select a form';
  }

  if (!data?.senderName?.trim()) {
    errors.senderName = 'Sender name is required';
  }

  if (!data?.senderEmail?.trim()) {
    errors.senderEmail = 'Sender email is required';
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.senderEmail)) {
    errors.senderEmail = 'Please enter a valid email address';
  }

  if (!data?.emailSubject?.trim()) {
    errors.emailSubject = 'Email subject is required';
  }

  if (!data?.message?.trim()) {
    errors.message = 'Message is required';
  }

  if (!data?.formButtonText?.trim()) {
    errors.formButtonText = 'Form button text is required';
  }

  return errors;
};

export default function Form({
  onClose,
  orders,
  onSuccess,
}: {
  onClose: () => void;
  orders: Order[];
  onSuccess: () => void;
}) {
  const dispatch = useAppDispatch();
  const { account } = useAppSelector(accountSelector);
  const { ownerEmail: senderEmail } = account ?? {};
  const selectedSite = useAppSelector(selectedSiteSelector);
  const { plan, siteDisplayName: senderName } = selectedSite ?? {};
  const { data: reviewRequestData } = useAppSelector(
    (state) => state.reviewRequestData
  );
  const { showWarningToast } = useNotification();
  const { message = '' } = reviewRequestData ?? {};

  const { reviewForms } = useAppSelector(reviewFormSelector);
  const { data: reviewFormsList = [], loading: reviewFormsLoading } =
    reviewForms ?? {};

  const transformedReviewForms = reviewFormsList.map((form) => ({
    label: form.name,
    value: form.trustKey,
  }));

  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  const openTestModal = () => {
    const errors = validateForm(reviewRequestData);

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      showWarningToast('Please fill in all fields');
      return;
    }

    // Clear errors if validation passes
    setFormErrors({});

    handleOpenTestModal();
  };

  const handleSendEmails = () => {
    const errors = validateForm(reviewRequestData);

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      showWarningToast('Please fill in all fields');
      return;
    }

    // Clear errors if validation passes
    setFormErrors({});
    handleOpenSendModal();
  };

  useEffect(() => {
    let recipients: ReviewRequestRecipient[] = [];

    if (orders.length > 0) {
      recipients = orders.map((order) => ({
        name: getCustomerDetails(order)?.fullName,
        email: getCustomerDetails(order)?.email,
        type: 'order',
        orderNumber: order.orderNumber,
        product: {
          name: order.productName,
          imageUrl: order.productImage,
          price: formatPrice(order.price),
        },
      }));
    }

    const message = `
    <div>
      <p>Hi {{first_name}}! 👋🏼</p>
      <p><br /></p>
      <p>Thank you for your recent purchase! We hope you're enjoying your new product.</p>
      <p><br /></p>
      <p>Your feedback is incredibly valuable to us and helps other customers make informed decisions.</p>
      <p><br /></p>
      <p>Would you take a moment to share your experience?</p>
      <p><br /></p>
      <p>Cheers,<br />${senderName}</p>
    </div>
    `;

    dispatch(
      setReviewRequestData({
        ...reviewRequestData,
        senderName,
        senderEmail,
        recipients,
        message,
        formButtonText: 'Leave a review',
        emailSubject: '{{first_name}}, how was your recent order?',
      })
    );
  }, [orders]);

  useEffect(() => {
    dispatch(fetchReviewForms());
  }, []);

  const {
    showModal: showTestModal,
    handleCloseModal: handleCloseTestModal,
    handleOpenModal: handleOpenTestModal,
  } = useModal();

  const {
    showModal: showSendModal,
    handleCloseModal: handleCloseSendModal,
    handleOpenModal: handleOpenSendModal,
  } = useModal();

  return (
    <div>
      <BackButton onClick={onClose}>
        <IoChevronBack />
        Orders
      </BackButton>
      <ConfigSection>
        <HeaderWrapper>
          <StyledTitle>
            <MdOutlineEmail />
            Request Past Reviews
          </StyledTitle>
          <Description>
            Let's invite your customers to leave you a review on the product
            they purchased by sending them a personalized email with a link to
            one of your forms.
          </Description>
        </HeaderWrapper>

        <Section>
          <SectionTitle>
            <MdLink />
            Link a form
          </SectionTitle>
          <FormGroup>
            <Label>Connect a form where users will submit their reviews</Label>
            <InputPicker
              cleanable={false}
              value={reviewRequestData?.formKey}
              data={transformedReviewForms}
              placeholder="Select a form"
              loading={reviewFormsLoading === Loading.PENDING}
              style={{ width: '100%' }}
              onChange={(value) => {
                setFormErrors((prev) => ({ ...prev, formKey: '' }));
                dispatch(
                  setReviewRequestData({
                    ...reviewRequestData,
                    formKey: value,
                  })
                );
              }}
            />
            {formErrors.formKey && (
              <ErrorMessage>{formErrors.formKey}</ErrorMessage>
            )}
          </FormGroup>
        </Section>

        <Section>
          <SectionTitle>
            <MdPeople />
            Recipients
          </SectionTitle>
          <Recipients>
            {orders.slice(0, 2).map((order) => {
              const { fullName, email } = getCustomerDetails(order);
              return (
                <Recipient key={order.id}>
                  <span className="name">
                    <b>{fullName}</b>
                  </span>
                  <span className="email">&lt;{email}&gt;</span>
                </Recipient>
              );
            })}
            {orders.length > 2 && (
              <MoreRecipients>
                and {orders.length - 2} more customer
                {orders.length - 2 > 1 ? 's' : ''}
              </MoreRecipients>
            )}
          </Recipients>
        </Section>

        <Section>
          <SectionTitle>
            <MdOutlineEmail />
            Email details
          </SectionTitle>

          <FormGroup>
            <InputLabel>Sender name</InputLabel>
            <Input
              type="text"
              placeholder="Enter sender name"
              value={reviewRequestData?.senderName ?? ''}
              onChange={(value) => {
                setFormErrors((prev) => ({ ...prev, senderName: '' }));
                dispatch(
                  setReviewRequestData({
                    ...reviewRequestData,
                    senderName: value,
                  })
                );
              }}
            />
            {formErrors.senderName && (
              <ErrorMessage>{formErrors.senderName}</ErrorMessage>
            )}
          </FormGroup>

          <FormGroup>
            <InputLabel>Sender email</InputLabel>
            <Input
              type="text"
              placeholder="Enter sender email"
              value={reviewRequestData?.senderEmail ?? ''}
              onChange={(value) => {
                setFormErrors((prev) => ({ ...prev, senderEmail: '' }));
                dispatch(
                  setReviewRequestData({
                    ...reviewRequestData,
                    senderEmail: value,
                  })
                );
              }}
            />
            {formErrors.senderEmail && (
              <ErrorMessage>{formErrors.senderEmail}</ErrorMessage>
            )}
          </FormGroup>

          <FormGroup>
            <ToggleWrapper>
              <LabelContainer>
                <ToggleLabel>Right-To-Left Content</ToggleLabel>
                <Description>
                  Use this option to switch the email content direction
                </Description>
              </LabelContainer>
              <RsuiteToggle
                checked={reviewRequestData?.rightToLeftContent}
                onChange={(value) => {
                  dispatch(
                    setReviewRequestData({
                      ...reviewRequestData,
                      rightToLeftContent: value,
                    })
                  );
                }}
              />
            </ToggleWrapper>
          </FormGroup>

          <FormGroup>
            <InputLabel>Email subject</InputLabel>
            <InputGroup inside>
              <Input
                type="text"
                placeholder="Enter email subject"
                value={reviewRequestData?.emailSubject}
                onChange={(value) => {
                  setFormErrors((prev) => ({ ...prev, emailSubject: '' }));
                  dispatch(
                    setReviewRequestData({
                      ...reviewRequestData,
                      emailSubject: value,
                    })
                  );
                }}
              />
              <InputGroup.Addon>
                <InfoIconWrapper>
                  <InfoIcon />
                  <CustomTooltip className="tooltip">
                    {'{{first_name}}'} will be replaced <br />
                    with the customer's first name
                  </CustomTooltip>
                </InfoIconWrapper>
              </InputGroup.Addon>
            </InputGroup>
            {formErrors.emailSubject && (
              <ErrorMessage>{formErrors.emailSubject}</ErrorMessage>
            )}
          </FormGroup>

          <FormGroup>
            <InputLabel>Message</InputLabel>
            <QuillEditor
              simple
              content={message}
              onChange={(message) => {
                setFormErrors((prev) => ({ ...prev, message: '' }));
                dispatch(
                  setReviewRequestData({
                    ...reviewRequestData,
                    message,
                  })
                );
              }}
            />
            {formErrors.message && (
              <ErrorMessage>{formErrors.message}</ErrorMessage>
            )}
          </FormGroup>
        </Section>

        <Section>
          <SectionTitle>
            <MdSettings />
            Options
          </SectionTitle>
          <FormGroup>
            <Label>Form button text</Label>
            <Input
              type="text"
              placeholder="Write a Review"
              value={reviewRequestData?.formButtonText}
              onChange={(value) => {
                setFormErrors((prev) => ({ ...prev, formButtonText: '' }));
                dispatch(
                  setReviewRequestData({
                    ...reviewRequestData,
                    formButtonText: value,
                  })
                );
              }}
            />
            {formErrors.formButtonText && (
              <ErrorMessage>{formErrors.formButtonText}</ErrorMessage>
            )}
          </FormGroup>

          {plan === SubscriptionPlan.FREE && (
            <FormGroup>
              <ToggleWrapper>
                <LabelContainer>
                  <ToggleLabel>Hide branding</ToggleLabel>
                  <Description>
                    Use this option to hide the branding of ReviewsJet in the
                    email.
                  </Description>
                </LabelContainer>
                <RsuiteToggle
                  checked={false}
                  onChange={() => {
                    dispatch(toggleUpgradeModal(SubscriptionPlan.ADVANCED));
                  }}
                />
              </ToggleWrapper>
            </FormGroup>
          )}
        </Section>
        <ButtonSection>
          <Button onClick={openTestModal}>Send Test Email</Button>
          <Button variant="primary" onClick={handleSendEmails}>
            Save & Send Email{orders.length > 1 ? 's' : ''}
          </Button>
        </ButtonSection>
      </ConfigSection>

      <AppModal
        size="500px"
        shouldOpenModal={showTestModal}
        onModalClose={handleCloseTestModal}
        body={<SendTestEmail onClose={handleCloseTestModal} />}
      />

      <AppModal
        size="500px"
        shouldOpenModal={showSendModal}
        onModalClose={handleCloseSendModal}
        body={
          <ConfirmSendEmails
            onClose={() => {
              handleCloseSendModal();
            }}
            recipients={orders}
            onSuccess={() => {
              onSuccess();
              onClose();
            }}
          />
        }
      />
    </div>
  );
}
