export const productReviews = [
  {
    _id: '67d4c55b2d3c7086f2cf5945',
    platform: 'productReview',
    title: 'Perfect Everyday Sneakers!',
    reviewer: {
      _id: '67d4c55b2d3c7086f2cf58fe',
      email: '114116588073861543473',
      siteId: '67d1f11bbc0d6425a894e8ae',
      avatar: '/images/reviewers/1.jpg',
      created_at: '2025-03-15T00:10:02.488Z',
      fullName: 'Jane Choi',
      updated_at: '2025-03-22T00:05:04.955Z',
    },
    siteId: '67d1f11bbc0d6425a894e8ae',
    content:
      'I absolutely love these sneakers! They are super comfortable, stylish, and perfect for everyday wear. The material feels durable, and they fit true to size. I’ve been getting so many compliments whenever I wear them!',
    created_at: '2025-03-15T00:10:03.228Z',
    fromWidget: false,
    helpfulCount: 0,
    link: 'https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnTURBbzVmS2JBEAE!2m1!1s0x0:0x651a57f6d128211c!3m1!1s2@1:CIHM0ogKEICAgMDAo5fKbA%7CCgwIgbCtvQYQyMix3wE%7C?hl=en',
    location: {
      city: 'San Francisco',
      countryCode: 'CA',
    },
    pictures: [
      '/images/review-pictures/1.jpg',
      '/images/review-pictures/2.jpg',
      '/images/review-pictures/3.jpg',
    ],
    rating: 4,
    reply: {
      date: '3 weeks ago',
      content:
        'Hi Jane 😊\nThank you so much for your amazing feedback! We’re thrilled to hear that you love your new sneakers and that they’ve become your go-to pair. Comfort and style are exactly what we aim for, and it’s great to know we hit the mark!\n\nIf you need more pairs or have any questions, feel free to reach out. Happy walking!\n\nBest,\nThe Sneaker Squad 👟',
      _id: '67d4c55b159d0971b8c78267',
    },
    reviewDate: '2025-02-15',
    status: 'published',
    type: 'text',
    updated_at: '2025-03-16T17:42:18.989Z',
    verified: true,
    video: null,
    videoPoster: null,
    platformProductId: '653d391a4d9800b8d4578a94',
    product: {
      _id: '67d70d622d3c7086f21a3e47',
      productId: '653d391a4d9800b8d4578a94',
      siteId: '67d1f11bbc0d6425a894e8ae',
      created_at: '2025-03-16T17:41:53.978Z',
      media: [],
      name: 'Drink Fig & Lime',
      platform: 'wix',
      price: '400 USD',
      slug: 'drink-fig-lime',
      updated_at: '2025-03-17T09:14:19.418Z',
    },
    id: '67d4c55b2d3c7086f2cf5945',
  },
  {
    _id: '67d4c55b2d3c7086f2cf5946',
    platform: 'productReview',
    title: 'Stylish and Comfortable Once Broken In',
    reviewer: {
      _id: '67d4c55b2d3c7086f2cf58fe',
      email: '114116588073861543473',
      siteId: '67d1f11bbc0d6425a894e8ae',
      avatar: '/images/reviewers/2.jpg',
      created_at: '2025-03-15T00:10:02.488Z',
      fullName: 'Alex Thompson',
      updated_at: '2025-03-22T00:05:04.955Z',
    },
    siteId: '67d1f11bbc0d6425a894e8ae',
    content:
      'The sneakers look awesome and feel pretty comfortable overall. I love the modern design and how they pair well with both casual and sporty outfits. My only issue is that they felt a bit snug at first, but after wearing them a few times, they loosened up and now fit perfectly. Definitely worth it for the style!',
    created_at: '2025-03-15T00:10:03.228Z',
    fromWidget: false,
    helpfulCount: 0,
    link: 'https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnTURBbzVmS2JBEAE!2m1!1s0x0:0x651a57f6d128211c!3m1!1s2@1:CIHM0ogKEICAgMDAo5fKbA%7CCgwIgbCtvQYQyMix3wE%7C?hl=en',
    location: {
      city: 'Manhattan',
      countryCode: 'NY',
    },
    pictures: [
      '/images/review-pictures/4.jpg',
      '/images/review-pictures/5.jpg',
      '/images/review-pictures/6.jpg',
    ],
    rating: 4,
    reply: {
      date: '3 weeks ago',
      content: `Hi Alex 👋
        Thank you for sharing your experience! We’re so glad you love the modern design and how versatile the sneakers are. Sometimes they can feel a bit snug at first, but we’re happy to hear they’ve broken in nicely.
        If you ever need sizing tips or more style recommendations, just give us a shout! We appreciate your feedback and are here to help whenever you need it.
        Best,
        The Sneaker Squad 👟`,
      _id: '67d4c55b159d0971b8c78267',
    },
    reviewDate: '2025-02-15',
    status: 'published',
    type: 'text',
    updated_at: '2025-03-16T17:42:18.989Z',
    verified: true,
    video: null,
    videoPoster: null,
    platformProductId: '653d391a4d9800b8d4578a94',
    product: {
      _id: '67d70d622d3c7086f21a3e47',
      productId: '653d391a4d9800b8d4578a94',
      siteId: '67d1f11bbc0d6425a894e8ae',
      created_at: '2025-03-16T17:41:53.978Z',
      media: [],
      name: 'Drink Fig & Lime',
      platform: 'wix',
      price: '400 USD',
      slug: 'drink-fig-lime',
      updated_at: '2025-03-17T09:14:19.418Z',
    },
    id: '67d4c55b2d3c7086f2cf5946',
  },
];

export const mediaWithReviews = [
  {
    type: 'picture',
    media: '/images/review-pictures/1.jpg',
    review: {
      _id: '67d4c55b2d3c7086f2cf5945',
      title: 'Perfect Everyday Sneakers!',
      platform: 'productReview',
      reviewer: {
        _id: '67d4c55b2d3c7086f2cf58fe',
        email: '114116588073861543473',
        siteId: '67d1f11bbc0d6425a894e8ae',
        avatar: '/images/reviewers/1.jpg',
        created_at: '2025-03-15T00:10:02.488Z',
        fullName: 'Jane Choi',
        updated_at: '2025-03-22T00:05:04.955Z',
      },
      siteId: '67d1f11bbc0d6425a894e8ae',
      content:
        'I absolutely love these sneakers! They are super comfortable, stylish, and perfect for everyday wear. The material feels durable, and they fit true to size. I’ve been getting so many compliments whenever I wear them!',
      created_at: '2025-03-15T00:10:03.228Z',
      fromWidget: false,
      helpfulCount: 0,
      link: 'https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnTURBbzVmS2JBEAE!2m1!1s0x0:0x651a57f6d128211c!3m1!1s2@1:CIHM0ogKEICAgMDAo5fKbA%7CCgwIgbCtvQYQyMix3wE%7C?hl=en',
      location: null,
      pictures: [
        '/images/review-pictures/1.jpg',
        '/images/review-pictures/2.jpg',
        '/images/review-pictures/3.jpg',
      ],
      rating: 4,
      reply: {
        date: '3 weeks ago',
        content:
          'Hi Jane 😊\nThank you so much for your amazing feedback! We’re thrilled to hear that you love your new sneakers and that they’ve become your go-to pair. Comfort and style are exactly what we aim for, and it’s great to know we hit the mark!\n\nIf you need more pairs or have any questions, feel free to reach out. Happy walking!\n\nBest,\nThe Sneaker Squad 👟',
        _id: '67d4c55b159d0971b8c78267',
      },
      reviewDate: '2025-02-15',
      status: 'published',
      type: 'text',
      updated_at: '2025-03-16T17:42:18.989Z',
      verified: true,
      video: null,
      videoPoster: null,
      platformProductId: '653d391a4d9800b8d4578a94',
      product: {
        _id: '67d70d622d3c7086f21a3e47',
        productId: '653d391a4d9800b8d4578a94',
        siteId: '67d1f11bbc0d6425a894e8ae',
        created_at: '2025-03-16T17:41:53.978Z',
        media: [],
        name: 'Drink Fig & Lime',
        platform: 'wix',
        price: '400 USD',
        slug: 'drink-fig-lime',
        updated_at: '2025-03-17T09:14:19.418Z',
      },
    },
  },
  {
    type: 'picture',
    media: '/images/review-pictures/2.jpg',
    review: {
      _id: '67d4c55b2d3c7086f2cf5945',
      platform: 'productReview',
      title: 'Perfect Everyday Sneakers!',
      reviewer: {
        _id: '67d4c55b2d3c7086f2cf58fe',
        email: '114116588073861543473',
        siteId: '67d1f11bbc0d6425a894e8ae',
        avatar: '/images/reviewers/1.jpg',
        created_at: '2025-03-15T00:10:02.488Z',
        fullName: 'Jane Choi',
        updated_at: '2025-03-22T00:05:04.955Z',
      },
      siteId: '67d1f11bbc0d6425a894e8ae',
      content:
        'I absolutely love these sneakers! They are super comfortable, stylish, and perfect for everyday wear. The material feels durable, and they fit true to size. I’ve been getting so many compliments whenever I wear them!',
      created_at: '2025-03-15T00:10:03.228Z',
      fromWidget: false,
      helpfulCount: 0,
      link: 'https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnTURBbzVmS2JBEAE!2m1!1s0x0:0x651a57f6d128211c!3m1!1s2@1:CIHM0ogKEICAgMDAo5fKbA%7CCgwIgbCtvQYQyMix3wE%7C?hl=en',
      location: null,
      pictures: [
        '/images/review-pictures/1.jpg',
        '/images/review-pictures/2.jpg',
        '/images/review-pictures/3.jpg',
      ],
      rating: 4,
      reply: {
        date: '3 weeks ago',
        content:
          'Hi Jane 😊\nThank you so much for your amazing feedback! We’re thrilled to hear that you love your new sneakers and that they’ve become your go-to pair. Comfort and style are exactly what we aim for, and it’s great to know we hit the mark!\n\nIf you need more pairs or have any questions, feel free to reach out. Happy walking!\n\nBest,\nThe Sneaker Squad 👟',
        _id: '67d4c55b159d0971b8c78267',
      },
      reviewDate: '2025-02-15',
      status: 'published',
      type: 'text',
      updated_at: '2025-03-16T17:42:18.989Z',
      verified: true,
      video: null,
      videoPoster: null,
      platformProductId: '653d391a4d9800b8d4578a94',
      product: {
        _id: '67d70d622d3c7086f21a3e47',
        productId: '653d391a4d9800b8d4578a94',
        siteId: '67d1f11bbc0d6425a894e8ae',
        created_at: '2025-03-16T17:41:53.978Z',
        media: [],
        name: 'Drink Fig & Lime',
        platform: 'wix',
        price: '400 USD',
        slug: 'drink-fig-lime',
        updated_at: '2025-03-17T09:14:19.418Z',
      },
    },
  },
  {
    type: 'picture',
    media: '/images/review-pictures/3.jpg',
    review: {
      _id: '67d4c55b2d3c7086f2cf5945',
      platform: 'productReview',
      title: 'Perfect Everyday Sneakers!',
      reviewer: {
        _id: '67d4c55b2d3c7086f2cf58fe',
        email: '114116588073861543473',
        siteId: '67d1f11bbc0d6425a894e8ae',
        avatar: '/images/reviewers/1.jpg',
        created_at: '2025-03-15T00:10:02.488Z',
        fullName: 'Jane Choi',
        updated_at: '2025-03-22T00:05:04.955Z',
      },
      siteId: '67d1f11bbc0d6425a894e8ae',
      content:
        'I absolutely love these sneakers! They are super comfortable, stylish, and perfect for everyday wear. The material feels durable, and they fit true to size. I’ve been getting so many compliments whenever I wear them!',
      created_at: '2025-03-15T00:10:03.228Z',
      fromWidget: false,
      helpfulCount: 0,
      link: 'https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnTURBbzVmS2JBEAE!2m1!1s0x0:0x651a57f6d128211c!3m1!1s2@1:CIHM0ogKEICAgMDAo5fKbA%7CCgwIgbCtvQYQyMix3wE%7C?hl=en',
      location: null,
      pictures: [
        '/images/review-pictures/1.jpg',
        '/images/review-pictures/2.jpg',
        '/images/review-pictures/3.jpg',
      ],
      rating: 4,
      reply: {
        date: '3 weeks ago',
        content:
          'Hi Jane 😊\nThank you so much for your amazing feedback! We’re thrilled to hear that you love your new sneakers and that they’ve become your go-to pair. Comfort and style are exactly what we aim for, and it’s great to know we hit the mark!\n\nIf you need more pairs or have any questions, feel free to reach out. Happy walking!\n\nBest,\nThe Sneaker Squad 👟',
        _id: '67d4c55b159d0971b8c78267',
      },
      reviewDate: '2025-02-15',
      status: 'published',
      type: 'text',
      updated_at: '2025-03-16T17:42:18.989Z',
      verified: true,
      video: null,
      videoPoster: null,
      platformProductId: '653d391a4d9800b8d4578a94',
      product: {
        _id: '67d70d622d3c7086f21a3e47',
        productId: '653d391a4d9800b8d4578a94',
        siteId: '67d1f11bbc0d6425a894e8ae',
        created_at: '2025-03-16T17:41:53.978Z',
        media: [],
        name: 'Drink Fig & Lime',
        platform: 'wix',
        price: '400 USD',
        slug: 'drink-fig-lime',
        updated_at: '2025-03-17T09:14:19.418Z',
      },
    },
  },
  {
    type: 'picture',
    media: '/images/review-pictures/4.jpg',
    review: {
      _id: '67d4c55b2d3c7086f2cf5945',
      platform: 'productReview',
      title: 'Stylish and Comfortable Once Broken In',
      reviewer: {
        _id: '67d4c55b2d3c7086f2cf58fe',
        email: '114116588073861543473',
        siteId: '67d1f11bbc0d6425a894e8ae',
        avatar: '/images/reviewers/2.jpg',
        created_at: '2025-03-15T00:10:02.488Z',
        fullName: 'Alex Thompson',
        updated_at: '2025-03-22T00:05:04.955Z',
      },
      siteId: '67d1f11bbc0d6425a894e8ae',
      content:
        'The sneakers look awesome and feel pretty comfortable overall. I love the modern design and how they pair well with both casual and sporty outfits. My only issue is that they felt a bit snug at first, but after wearing them a few times, they loosened up and now fit perfectly. Definitely worth it for the style!',
      created_at: '2025-03-15T00:10:03.228Z',
      fromWidget: false,
      helpfulCount: 0,
      link: 'https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnTURBbzVmS2JBEAE!2m1!1s0x0:0x651a57f6d128211c!3m1!1s2@1:CIHM0ogKEICAgMDAo5fKbA%7CCgwIgbCtvQYQyMix3wE%7C?hl=en',
      location: null,
      pictures: [
        '/images/review-pictures/1.jpg',
        '/images/review-pictures/2.jpg',
        '/images/review-pictures/3.jpg',
      ],
      rating: 4,
      reply: {
        date: '3 weeks ago',
        content: `Hi Alex 👋
        Thank you for sharing your experience! We’re so glad you love the modern design and how versatile the sneakers are. Sometimes they can feel a bit snug at first, but we’re happy to hear they’ve broken in nicely.
        If you ever need sizing tips or more style recommendations, just give us a shout! We appreciate your feedback and are here to help whenever you need it.
        Best,
        The Sneaker Squad 👟`,
        _id: '67d4c55b159d0971b8c78267',
      },
      reviewDate: '2025-02-15',
      status: 'published',
      type: 'text',
      updated_at: '2025-03-16T17:42:18.989Z',
      verified: true,
      video: null,
      videoPoster: null,
      platformProductId: '653d391a4d9800b8d4578a94',
      product: {
        _id: '67d70d622d3c7086f21a3e47',
        productId: '653d391a4d9800b8d4578a94',
        siteId: '67d1f11bbc0d6425a894e8ae',
        created_at: '2025-03-16T17:41:53.978Z',
        media: [],
        name: 'Drink Fig & Lime',
        platform: 'wix',
        price: '400 USD',
        slug: 'drink-fig-lime',
        updated_at: '2025-03-17T09:14:19.418Z',
      },
    },
  },
  {
    type: 'picture',
    media: '/images/review-pictures/5.jpg',
    review: {
      _id: '67d4c55b2d3c7086f2cf5945',
      platform: 'productReview',
      title: 'Stylish and Comfortable Once Broken In',
      reviewer: {
        _id: '67d4c55b2d3c7086f2cf58fe',
        email: '114116588073861543473',
        siteId: '67d1f11bbc0d6425a894e8ae',
        avatar: '/images/reviewers/2.jpg',
        created_at: '2025-03-15T00:10:02.488Z',
        fullName: 'Alex Thompson',
        updated_at: '2025-03-22T00:05:04.955Z',
      },
      siteId: '67d1f11bbc0d6425a894e8ae',
      content:
        'The sneakers look awesome and feel pretty comfortable overall. I love the modern design and how they pair well with both casual and sporty outfits. My only issue is that they felt a bit snug at first, but after wearing them a few times, they loosened up and now fit perfectly. Definitely worth it for the style!',
      created_at: '2025-03-15T00:10:03.228Z',
      fromWidget: false,
      helpfulCount: 0,
      link: 'https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnTURBbzVmS2JBEAE!2m1!1s0x0:0x651a57f6d128211c!3m1!1s2@1:CIHM0ogKEICAgMDAo5fKbA%7CCgwIgbCtvQYQyMix3wE%7C?hl=en',
      location: null,
      pictures: [
        '/images/review-pictures/1.jpg',
        '/images/review-pictures/2.jpg',
        '/images/review-pictures/3.jpg',
      ],
      rating: 4,
      reply: {
        date: '3 weeks ago',
        content: `Hi Alex 👋
        Thank you for sharing your experience! We’re so glad you love the modern design and how versatile the sneakers are. Sometimes they can feel a bit snug at first, but we’re happy to hear they’ve broken in nicely.
        If you ever need sizing tips or more style recommendations, just give us a shout! We appreciate your feedback and are here to help whenever you need it.
        Best,
        The Sneaker Squad 👟`,
        _id: '67d4c55b159d0971b8c78267',
      },
      reviewDate: '2025-02-15',
      status: 'published',
      type: 'text',
      updated_at: '2025-03-16T17:42:18.989Z',
      verified: true,
      video: null,
      videoPoster: null,
      platformProductId: '653d391a4d9800b8d4578a94',
      product: {
        _id: '67d70d622d3c7086f21a3e47',
        productId: '653d391a4d9800b8d4578a94',
        siteId: '67d1f11bbc0d6425a894e8ae',
        created_at: '2025-03-16T17:41:53.978Z',
        media: [],
        name: 'Drink Fig & Lime',
        platform: 'wix',
        price: '400 USD',
        slug: 'drink-fig-lime',
        updated_at: '2025-03-17T09:14:19.418Z',
      },
    },
  },
  {
    type: 'picture',
    media: '/images/review-pictures/6.jpg',
    review: {
      _id: '67d4c55b2d3c7086f2cf5945',
      platform: 'productReview',
      title: 'Stylish and Comfortable Once Broken In',
      reviewer: {
        _id: '67d4c55b2d3c7086f2cf58fe',
        email: '114116588073861543473',
        siteId: '67d1f11bbc0d6425a894e8ae',
        avatar: '/images/reviewers/2.jpg',
        created_at: '2025-03-15T00:10:02.488Z',
        fullName: 'Alex Thompson',
        updated_at: '2025-03-22T00:05:04.955Z',
      },
      siteId: '67d1f11bbc0d6425a894e8ae',
      content:
        'The sneakers look awesome and feel pretty comfortable overall. I love the modern design and how they pair well with both casual and sporty outfits. My only issue is that they felt a bit snug at first, but after wearing them a few times, they loosened up and now fit perfectly. Definitely worth it for the style!',
      created_at: '2025-03-15T00:10:03.228Z',
      fromWidget: false,
      helpfulCount: 0,
      link: 'https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnTURBbzVmS2JBEAE!2m1!1s0x0:0x651a57f6d128211c!3m1!1s2@1:CIHM0ogKEICAgMDAo5fKbA%7CCgwIgbCtvQYQyMix3wE%7C?hl=en',
      location: null,
      pictures: [
        '/images/review-pictures/1.jpg',
        '/images/review-pictures/2.jpg',
        '/images/review-pictures/3.jpg',
      ],
      rating: 4,
      reply: {
        date: '3 weeks ago',
        content: `Hi Alex 👋
        Thank you for sharing your experience! We’re so glad you love the modern design and how versatile the sneakers are. Sometimes they can feel a bit snug at first, but we’re happy to hear they’ve broken in nicely.
        If you ever need sizing tips or more style recommendations, just give us a shout! We appreciate your feedback and are here to help whenever you need it.
        Best,
        The Sneaker Squad 👟`,
        _id: '67d4c55b159d0971b8c78267',
      },
      reviewDate: '2025-02-15',
      status: 'published',
      type: 'text',
      updated_at: '2025-03-16T17:42:18.989Z',
      verified: true,
      video: null,
      videoPoster: null,
      platformProductId: '653d391a4d9800b8d4578a94',
      product: {
        _id: '67d70d622d3c7086f21a3e47',
        productId: '653d391a4d9800b8d4578a94',
        siteId: '67d1f11bbc0d6425a894e8ae',
        created_at: '2025-03-16T17:41:53.978Z',
        media: [],
        name: 'Drink Fig & Lime',
        platform: 'wix',
        price: '400 USD',
        slug: 'drink-fig-lime',
        updated_at: '2025-03-17T09:14:19.418Z',
      },
    },
  },
];
