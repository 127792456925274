import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import productReviewsSettings from '../features/ecommerce/product-widget/redux/product-reviews-settings.slice';
import emails from '../features/emails/redux/emails.slice';
import webflow from '../features/external/redux/webflow.slice';
import incentive from '../features/incentives/redux/incentive.slice';
import reviews from '../features/manage-reviews/redux/reviews.slice';
import onboarding from '../features/onboarding/redux/onboarding.slice';
import plans from '../features/plans/redux/plans.slice';
import product from '../features/product/redux/product.slice';
import questions from '../features/questions-and-answers/redux/questions.slice';
import reviewForm from '../features/review-forms/redux/review-form.slice';
import reviewRequestData from '../features/review-request-data/redux/review-request-data.slice';
import review from '../features/review/redux/review.slice';
import integrations from '../features/settings/components/Integrations/redux/integrations.slice';
import businessInfo from '../features/settings/redux/business-info.slice';
import testimonial from '../features/share-form/redux /testimonial.slice';
import socialReviews from '../features/socialReviews/redux/social-reviews.slice';
import userData from '../features/userData/redux/userData.slice';
import setting from '../features/widgetSettings/redux/widgetSettings.slice';
import app from './app.slice';
import appHistory from './appHistory';

const reducers = combineReducers({
  app,
  review,
  webflow,
  setting,
  reviews,
  userData,
  product,
  incentive,
  socialReviews,
  questions,
  businessInfo,
  testimonial,
  emails,
  plans,
  integrations,
  onboarding,
  reviewForm,
  productReviewsSettings,
  router: connectRouter(appHistory),
  reviewRequestData,
});

export default reducers;
