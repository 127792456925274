import MarkdownIt from 'markdown-it';
import { Input, Toggle } from 'rsuite';
import styled from 'styled-components';
import { Label } from '../../../../../shared/components/Label';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { selectedSiteSelector } from '../../../../userData/redux/userData.slice';
import FormRow from '../../../../widgetSettings/components/form-row';
import {
  reviewFormSelector,
  updateReviewFormSettings,
} from '../../../redux/review-form.slice';
const md = new MarkdownIt();

const Preview = styled.div`
  margin-top: 16px;
  padding: 16px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;

  p {
    margin-bottom: 16px;
  }

  ul {
    padding-left: 16px;
    list-style-type: disc;
  }

  ol {
    padding-left: 16px;
    list-style-type: decimal;
  }
`;

const VideoSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #343434;
  line-height: 1.42857143;
  width: 100%;
  background: #fff;
  border: 1px solid #e5e5ea;
  border-radius: 6px;
  padding: 7px 11px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: bold;

  &:hover {
    background: #f8f9fa;
    border-color: #d1d1d6;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  svg {
    font-size: 18px;
    color: #343434;
  }
  p {
    margin: 0;
  }
`;

export default function SuccessScreen() {
  const dispatch = useAppDispatch();
  // const [useCustomSuccessPage, setUseCustomSuccessPage] = useState(false);
  // const [callToAction, setCallToAction] = useState(false);
  // const [callToActionText, setCallToActionText] = useState('');
  // const [callToActionUrl, setCallToActionUrl] = useState('');
  // const [title, setTitle] = useState('Thank you 🙏');
  // const [thankYouMessage, setThankYouMessage] = useState(
  //   'Thank you so much for your support! We appreciate your support and we hope you enjoy using our product.'
  // );
  // const [redirectUrl, setRedirectUrl] = useState('');

  const selectedSite = useAppSelector(selectedSiteSelector);
  const { reviewFormSettings } = useAppSelector(reviewFormSelector);
  const { successScreen } = reviewFormSettings ?? {};

  const {
    useCustomSuccessScreen,
    redirectUrl,
    pageTitle,
    message,
    video,
    callToAction,
  } = successScreen ?? {};

  const { url, plan } = selectedSite ?? {};

  return (
    <>
      <FormRow gap="10px" justifyContent="flex-start">
        <Toggle
          // size="lg"
          checked={useCustomSuccessScreen}
          // @ts-ignore
          onChange={(value) => {
            dispatch(
              updateReviewFormSettings({
                successScreen: {
                  ...successScreen,
                  useCustomSuccessScreen: value,
                },
              })
            );
          }}
          checkedChildren="Yes"
          unCheckedChildren="No"
        />
        <Label margin="0">Use Custom Success Page</Label>
      </FormRow>

      {useCustomSuccessScreen ? (
        <FormRow>
          <Label>Redirect URL</Label>
          <Input
            placeholder="Ex: https://reviewsjet.com"
            value={redirectUrl}
            onChange={(value) => {
              dispatch(
                updateReviewFormSettings({
                  successScreen: {
                    ...successScreen,
                    redirectUrl: value,
                  },
                })
              );
            }}
          />
        </FormRow>
      ) : (
        <>
          <FormRow>
            <Label> Page Title</Label>
            <Input
              placeholder="Enter your title"
              value={pageTitle}
              onChange={(value) => {
                dispatch(
                  updateReviewFormSettings({
                    successScreen: { ...successScreen, pageTitle: value },
                  })
                );
              }}
            />
          </FormRow>

          <FormRow>
            <Label>Message</Label>
            <Input
              as="textarea"
              rows={5}
              placeholder="Enter your message"
              value={message}
              onChange={(value) => {
                dispatch(
                  updateReviewFormSettings({
                    successScreen: {
                      ...successScreen,
                      message: value,
                    },
                  })
                );
              }}
            />
          </FormRow>

          {/* <FormRow>
            <Label>Thank You Video Message</Label>
            {plan === SubscriptionPlan.ADVANCED ? (
              <VideoSection>
                <IoVideocamOutline />
                <p>Record a thank you video</p>
              </VideoSection>
            ) : (
              <PremiumBanner
                availableOn={SubscriptionPlan.ADVANCED}
                description="Upgrade to add a thank you video to your success screen."
              />
            )}
          </FormRow> */}

          {/* <FormRow gap="10px" justifyContent="flex-start">
            <Toggle
              // size="lg"
              checked={callToAction}
              // @ts-ignore
              onChange={setCallToAction}
              checkedChildren="Yes"
              unCheckedChildren="No"
            />
            <Label margin="0">Call to action</Label>
          </FormRow> */}

          {/* {callToAction && (
            <>
              <FormRow gap="10px">
                <Input
                  placeholder="Ex: Get started"
                  value={callToActionText}
                  onChange={(value) => setCallToActionText(value)}
                />

                <Input
                  placeholder={`Ex: ${removeTrailingSlash(url!)}/thank-you`}
                  value={callToActionUrl}
                  onChange={(value) => setCallToActionUrl(value)}
                />
              </FormRow>
            </>
          )} */}
        </>
      )}
    </>
  );
}
