import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Schema } from 'rsuite';
import styled from 'styled-components';
import { axiosPrivate } from '../../../../services/axiosPrivate';
import Button from '../../../../shared/components/Button';
import ReviewsJetCrownLogo from '../../../../shared/components/ReviewsJetCrownLogo';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import { useNotification } from '../../../../shared/hooks/useNotification';
import { Loading } from '../../../../shared/types/review.types';
import removeTrailingSlash from '../../../../shared/utils/removeTrailingSlash';
import {
  AuthForm,
  AuthFormGroup,
  AuthIconWrapper,
  AuthLabel,
  AuthTextField,
  AuthTitle,
} from '../../components';

/* eslint no-console: "warn" */

const StyledFlexContainer = styled(FlexContainer)`
  border-top: 1px solid #eeeeee;
  padding: 10px 0;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 40px;
  max-width: 500px;
  max-height: 90%;
  overflow: auto;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  @media (max-width: 530px) {
    max-width: 100%;
    height: 100%;
    border-radius: 0px;
    border: 0;
    box-shadow: none;
  }

  input {
    border-radius: 25px;
  }

  .forgot-password {
    color: #8b8e99;
    /* text-decoration: underline !important;
    text-underline-offset: 4px; */
  }
`;

const StyledButton = styled(Button)`
  background-color: #000;
  color: #fff;
  border-radius: 25px;
  width: 100%;
  border-color: #000;
  padding: 11px;
`;

const Intro = styled.p`
  margin-bottom: 30px;
  color: #737373;
  font-size: 15px;
`;

const StyledAuthTextField = styled(AuthTextField)`
  padding: 11px 15px;
  /* border-radius: 5px !important; */
`;

const StyledAuthIconWrapper = styled(AuthIconWrapper)`
  bottom: 5px !important;
`;

const { StringType } = Schema.Types;

const model = Schema.Model({
  password: StringType().isRequired('This field is required.'),
});

const CreateWebflowAccountPage = () => {
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();
  const { showSuccessToast, showErrorToast } = useNotification();
  const [visible, setVisible] = useState(false);
  const formRef = useRef<{ check: () => void }>(null);
  const [formValue, setFormValue] = useState({
    email: '',
    password: '',
  });
  const [siteId, setSiteId] = useState('');
  const [doNext, setDoNext] = useState('');
  const [platform, setPlatform] = useState('');
  const [accountId, setAccountId] = useState('');
  const [loading, setLoading] = useState(Loading.INITIAL);

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  const handleSubmit = async () => {
    if (!formRef.current?.check()) {
      return;
    }

    if (!token) {
      return;
    }

    try {
      setLoading(Loading.PENDING);

      await axiosPrivate.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/webflow/create-account`,
        {
          password: formValue.password,
          accountId,
          siteId,
          platform,
        }
      );

      setLoading(Loading.SUCCESS);

      showSuccessToast(
        'Account created successfully. Please login to continue.'
      );
      navigate('/login');
    } catch (error) {
      setLoading(Loading.ERROR);
      showErrorToast('Failed to create account');
    }
  };

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode<{
        email: string;
        doNext: string;
        siteId: string;
        platform: string;
        accountId: string;
      }>(token);

      setDoNext(decoded.doNext);
      setSiteId(decoded.siteId);
      setPlatform(decoded.platform);
      setAccountId(decoded.accountId);

      setFormValue({
        password: '',
        email: decoded.email,
      });
    }
  }, [token]);

  if (!doNext) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Sign In to ReviewsJet | Manage Your Online Reviews</title>
        <meta
          name="description"
          content="Sign in to ReviewsJet to manage all your reviews from Google, Yelp, Trustpilot, Etsy and more in one place. Monitor, respond, and grow your online reputation effortlessly."
        />
      </Helmet>

      <Wrapper>
        <a href={process.env.REACT_APP_REVIEWSJET_WEBSITE!}>
          <ReviewsJetCrownLogo />
        </a>
        <AuthTitle marginBottom="10px">Create Your Account</AuthTitle>
        <Intro>Enter your name and set a secure password to continue</Intro>

        <AuthForm
          fluid
          ref={formRef}
          onChange={setFormValue}
          formValue={formValue}
          model={model}
        >
          <AuthFormGroup controlId="email">
            <AuthLabel>Email</AuthLabel>
            <StyledAuthTextField
              name="email"
              type="text"
              autoComplete="true"
              readOnly
              placeholder="james@bond.com"
            />
          </AuthFormGroup>

          <div style={{ height: 20 }}></div>

          <AuthFormGroup controlId="password">
            <AuthLabel>Password</AuthLabel>
            <StyledAuthIconWrapper onClick={toggleVisibility}>
              {visible ? <EyeIcon /> : <EyeSlashIcon />}
            </StyledAuthIconWrapper>
            <StyledAuthTextField
              isPassword
              name="password"
              type={visible ? 'text' : 'password'}
              autoComplete="off"
              placeholder="Enter new password"
            />
          </AuthFormGroup>

          <Form.Group>
            <FlexContainer
              justifyContent="space-between"
              style={{ marginTop: 20 }}
            >
              <StyledButton
                size="large"
                type="submit"
                onClick={handleSubmit}
                disabled={loading === Loading.PENDING}
              >
                {loading === Loading.PENDING ? (
                  <b>Please wait...</b>
                ) : (
                  <FlexContainer gap="5px">
                    <b>Create account</b>
                  </FlexContainer>
                )}
              </StyledButton>
            </FlexContainer>
          </Form.Group>
        </AuthForm>
      </Wrapper>
    </>
  );
};

export default CreateWebflowAccountPage;
