import { QRCodeSVG } from 'qrcode.react';
import { useRef } from 'react';
import {
  FaFacebook,
  FaLinkedin,
  FaQrcode,
  FaSms,
  FaTwitter,
  FaWhatsapp,
} from 'react-icons/fa';
import { RiShareLine } from 'react-icons/ri';
import Dropdown from './Dropdown';

/* eslint no-console: "warn" */

type Props = {
  formUrl: string;
};

export default function Share({ formUrl }: Props) {
  const qrCodeSize = 256;
  const qrCodeRef = useRef<SVGSVGElement | null>(null);

  const handleDownloadQR = () => {
    if (qrCodeRef.current) {
      const svg = qrCodeRef.current;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = qrCodeSize;
      canvas.height = qrCodeSize;

      const svgData = new XMLSerializer().serializeToString(svg);
      const img = new Image();
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;

      img.onload = () => {
        if (ctx) {
          ctx.drawImage(img, 0, 0, qrCodeSize, qrCodeSize);
          const pngUrl = canvas.toDataURL('image/png');
          const downloadLink = document.createElement('a');
          downloadLink.href = pngUrl;
          downloadLink.download = 'QR Code.png';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      };
    }
  };

  const handleShare = (platform: string) => {
    const text = 'Leave us a review!';
    const shareUrls = {
      twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        formUrl
      )}&text=${encodeURIComponent(text)}`,
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        formUrl
      )}`,
      linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        formUrl
      )}`,
      whatsapp: `https://wa.me/?text=${encodeURIComponent(
        `${text} ${formUrl}`
      )}`,
      sms: `sms:?body=${encodeURIComponent(`${text} ${formUrl}`)}`,
    };

    if (platform === 'sms') {
      window.location.href = shareUrls[platform];
    } else {
      window.open(shareUrls[platform as keyof typeof shareUrls], '_blank');
    }
  };

  return (
    <>
      <Dropdown
        trigger={
          <>
            <RiShareLine size={18} />
            Share
          </>
        }
        items={[
          {
            icon: <FaWhatsapp />,
            label: 'WhatsApp',
            onClick: () => handleShare('whatsapp'),
          },
          {
            icon: <FaFacebook />,
            label: 'Facebook',
            onClick: () => handleShare('facebook'),
          },
          {
            icon: <FaTwitter />,
            label: 'Twitter',
            onClick: () => handleShare('twitter'),
          },
          {
            icon: <FaLinkedin />,
            label: 'LinkedIn',
            onClick: () => handleShare('linkedin'),
          },
          {
            icon: <FaSms />,
            label: 'SMS',
            onClick: () => handleShare('sms'),
            separator: true,
          },
          {
            icon: <FaQrcode />,
            label: 'Download QR Code',
            onClick: handleDownloadQR,
          },
        ]}
      />

      <div style={{ display: 'none' }}>
        <QRCodeSVG
          value={formUrl}
          ref={qrCodeRef}
          size={qrCodeSize}
          bgColor="#ffffff"
          fgColor="#000000"
        />
      </div>
    </>
  );
}
