import _ from 'lodash';
import { Control, Controller } from 'react-hook-form';
import { Radio, RadioGroup, Tag } from 'rsuite';
import styled from 'styled-components';
import { FormType } from '..';
import Button from '../../../../../shared/components/Button';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import { Loading } from '../../../../../shared/types/review.types';
import {
  Integration,
  IntegrationPlatform,
} from '../../../../socialReviews/redux/social-reviews.slice';
import ConnectedThirdPartyAccounts from './ConnectedThirdPartyAccounts';

/* eslint no-console: "warn" */

const Wrapper = styled.form`
  width: 100%;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const StyledTag = styled(Tag)`
  font-size: 11px;
  font-weight: bold;
  color: #0084ed;
  background-color: #eef8ff;
`;

const Header = styled.div`
  padding: 15px 20px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid rgb(235, 235, 235);
`;

const FormGroup = styled.div`
  width: 100%;
`;

const Content = styled.div`
  padding: 20px;
  margin-bottom: 20px;
`;

const Footer = styled(FlexContainer)`
  padding: 15px 20px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  border-top: 1px solid rgb(235, 235, 235);
`;

type Props = {
  submitting: Loading;
  control: Control<FormType, any>;
  submitReviewsTo: string[];
  _submissionType: 'reviewsjet' | 'thirdparty';
  _submitReviewsTo: IntegrationPlatform[];
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  integrations: Record<IntegrationPlatform, Integration[]> | null;
};

export default function ReviewsCollection(props: Props) {
  const {
    onSubmit,
    control,
    submitting,
    integrations,
    submitReviewsTo,
    _submissionType,
    _submitReviewsTo,
  } = props;

  return (
    <Wrapper onSubmit={onSubmit}>
      <Header>
        <span>Review collection settings</span>
      </Header>
      <Content>
        <FlexContainer
          stack
          justifyContent="flex-start"
          alignItems="flex-start"
          gap="15px"
        >
          <FormGroup>
            <span>Where should users submit reviews?</span>

            <Controller
              name="submissionType"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Radio value="reviewsjet">
                    <FlexContainer justifyContent="flex-start" gap="10px">
                      <span>
                        Submit directly via the ReviewsJet review form
                      </span>
                      <StyledTag color="blue">RECOMMENDED</StyledTag>
                    </FlexContainer>
                  </Radio>
                  <Radio value="thirdparty">
                    Submit directly to your connected accounts, such as Google,
                    Yelp, etc...
                  </Radio>
                </RadioGroup>
              )}
            />
          </FormGroup>
          {_submissionType === 'thirdparty' && (
            <FormGroup>
              <ConnectedThirdPartyAccounts
                control={control}
                integrations={integrations}
                submitReviewsTo={submitReviewsTo}
              />
            </FormGroup>
          )}
        </FlexContainer>
      </Content>
      <Footer justifyContent="flex-end">
        <Button
          rounded
          type="submit"
          disabled={
            _submissionType === 'thirdparty' &&
            (_.isEmpty(integrations) || !_submitReviewsTo.length)
          }
          variant={
            _submissionType === 'thirdparty' &&
            (_.isEmpty(integrations) || !_submitReviewsTo.length)
              ? 'tertiary'
              : 'secondary'
          }
        >
          {submitting === Loading.PENDING ? 'Please wait...' : 'Save settings'}
        </Button>
      </Footer>
    </Wrapper>
  );
}
