import { useEffect } from 'react';
import { batch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import ErrorComponent from '../../../../shared/components/ErrorComponent';
import Preloader from '../../../../shared/components/Preloader';
import { Loading } from '../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { fetchReviewForm } from '../../redux/actions';
import {
  PreviewScreen,
  reviewFormSelector,
  setPreviewScreen,
  setShowWheelLoseResult,
  setShowWheelWinResult,
} from '../../redux/review-form.slice';
import Preview from './Preview';
import Sidebar from './Sidebar';

/* eslint no-console: "warn" */

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const PreviewSpace = styled.div`
  flex: 1;
  background: #f6f6f9;
  min-width: 0;
  height: 100vh;
  position: sticky;
  top: 0;
  overflow-y: auto;
`;

export default function ReviewFormSettings() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const queryId = searchParams.get('id');

  const { loading } = useAppSelector(reviewFormSelector);

  useEffect(() => {
    batch(() => {
      dispatch(fetchReviewForm(queryId || ''));
      dispatch(setShowWheelWinResult(false));
      dispatch(setShowWheelLoseResult(false));
      dispatch(setPreviewScreen(PreviewScreen.WELCOME));
    });
  }, []);

  if (loading === Loading.PENDING) {
    return <Preloader />;
  }

  if (loading === Loading.ERROR) {
    return <ErrorComponent onClick={() => dispatch(fetchReviewForm())} />;
  }

  return (
    <Container>
      <Sidebar />
      <PreviewSpace>
        <Preview />
      </PreviewSpace>
    </Container>
  );
}
