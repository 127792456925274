import { useState } from 'react';
import { FaStar } from 'react-icons/fa';
import styled from 'styled-components';

const RateContainer = styled.div`
  display: flex;
  gap: 0px;
`;

const StarButton = styled.button`
  padding: 2px;
  font-size: 1.875rem; // text-3xl equivalent
  transition: all 0.2s ease;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

const Star = styled(FaStar)<{ $isFilled: boolean }>`
  color: ${(props) =>
    props.$isFilled ? '#facc15' : '#d1d5db'}; // yellow-400 : gray-300
  transition: color 0.2s ease;
`;

interface RateProps {
  value: number;
  onChange: (value: number) => void;
}

export default function Rate({ value, onChange }: RateProps) {
  const [hover, setHover] = useState<number | null>(null);

  return (
    <RateContainer>
      {[1, 2, 3, 4, 5].map((star) => {
        const isFilled = (hover ?? value) >= star;

        return (
          <StarButton
            key={star}
            type="button"
            onClick={() => onChange(star)}
            onMouseEnter={() => setHover(star)}
            onMouseLeave={() => setHover(null)}
            onTouchStart={() => setHover(star)}
            onTouchEnd={() => setHover(null)}
          >
            <Star $isFilled={isFilled} />
          </StarButton>
        );
      })}
    </RateContainer>
  );
}
