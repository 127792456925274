import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { IoChevronBack } from 'react-icons/io5';
import { MdEmail, MdError, MdOutlineEmail, MdPhone } from 'react-icons/md';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Button, Drawer, Placeholder } from 'rsuite';
import styled from 'styled-components';
import { accountSelector } from '../../../features/userData/redux/userData.slice';
import { axiosPrivate } from '../../../services/axiosPrivate';
import BackButton from '../../../shared/components/BackButton';
import FlexContainer from '../../../shared/components/layouts/flex-container';
import AppModal from '../../../shared/components/modal';
import useModal from '../../../shared/components/modal/useModal';
import { Title } from '../../../shared/components/Title';
import { Loading } from '../../../shared/types/review.types';
import removeTrailingSlash from '../../../shared/utils/removeTrailingSlash';
import { useAppDispatch, useAppSelector } from '../../../store';
import { ReviewsJetPlatform } from '../../product/types';
import { getBusinessInfo } from '../../settings/redux/actions';
import ReviewRequest from './ReviewRequest';
import NextStep from './ReviewRequest/NextStep';
import { Order } from './types';

/* eslint no-console: "warn" */

const Wrapper = styled.div`
  padding: 0px 30px 20px;
  max-width: 1500px;
  margin: auto;
`;

// Platform configuration for easy expansion
const PLATFORM_CONFIG = {
  [ReviewsJetPlatform.SHOPIFY]: {
    endpoint: '/shopify/orders',
    name: 'Shopify',
  },
  [ReviewsJetPlatform.WIX]: {
    endpoint: '/wix/orders',
    name: 'Wix',
  },
  [ReviewsJetPlatform.WEBFLOW]: {
    endpoint: '/webflow/orders',
    name: 'Webflow',
  },
  [ReviewsJetPlatform.WOOCOMMERCE]: {
    endpoint: '/woocommerce/orders',
    name: 'WooCommerce',
  },
} as const;

const LoadingMessage = styled.div`
  text-align: center;
  padding: 20px;
  color: #666;
`;

const EmptyMessage = styled.td`
  text-align: center;
  color: #6b7280;
  font-size: 14px;
  padding: 32px !important;
  background: #f9fafb;
`;

const ErrorMessage = styled(EmptyMessage)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  gap: 8px;

  svg {
    font-size: 18px;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  margin-top: 24px;
`;

const Th = styled.th`
  padding: 14px;
  text-align: left;
  background: #f8fafc;
  color: #64748b;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-bottom: 1px solid #e2e8f0;
`;

const Td = styled.td`
  padding: 16px;
  border-bottom: 1px solid #f1f5f9;
  font-size: 14px;
  color: #334155;
  vertical-align: top;

  &:first-child {
    font-weight: 500;
    color: #0f172a;
  }
`;

const ProductInfo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

const ProductImage = styled.img`
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
`;

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  > span {
    font-weight: 500;
    color: #0f172a;
  }
`;

const ProductDate = styled.div`
  font-size: 13px;
  color: #64748b;
`;

const ProductPrice = styled.div`
  font-weight: 500;
  color: #0f172a;
`;

const CustomerName = styled.div`
  font-weight: 500;
  color: #0f172a;
  margin-bottom: 6px;
`;

const ContactInfo = styled.div`
  font-size: 13px;
  color: #64748b;
  display: flex;
  flex-direction: column;
  gap: 4px;

  span {
    display: flex;
    align-items: center;
    gap: 6px;
  }
`;

const EmailIcon = styled.span`
  color: #64748b;
  font-size: 14px;
`;

const PhoneIcon = styled.span`
  color: #64748b;
  font-size: 14px;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const BulkActionButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  background: #0f172a;
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #1e293b;
  }

  svg {
    font-size: 16px;
  }
`;

const SelectedCount = styled.span`
  color: #64748b;
  font-size: 14px;
  margin-right: 16px;
`;

const LoadMoreButton = styled.button`
  margin: 32px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 24px;
  background: white;
  color: #0f172a;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  &:hover {
    background: #f8fafc;
    border-color: #cbd5e1;
  }

  &:disabled {
    background: #f1f5f9;
    color: #94a3b8;
    cursor: not-allowed;
  }

  svg {
    animation: ${(props) =>
      props.disabled ? 'spin 1s linear infinite' : 'none'};
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
  padding-left: 0px;
  color: #1e293b;
  font-size: 16px;
  font-weight: 500;

  svg {
    font-size: 18px;
  }
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  width: 16px;
  height: 16px;
  cursor: pointer;
  border-radius: 4px;
  border: 2px solid #cbd5e1;
  accent-color: #007bff;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  color: #64748b;
  border-radius: 4px;

  &:hover {
    background: #f1f5f9;
    color: #334155;
  }
`;

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownContent = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  right: 0;
  top: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  min-width: 160px;
  z-index: 10;
  display: ${(props) => (props.$isOpen ? 'block' : 'none')};
`;

const DropdownItem = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 16px;
  border: none;
  background: none;
  color: #334155;
  font-size: 14px;
  text-align: left;
  cursor: pointer;

  &:hover {
    background: #f8fafc;
  }

  svg {
    color: #64748b;
  }
`;

const LoadingState = styled.div`
  padding: 20px;
  text-align: center;
  color: #64748b;
  font-size: 16px;
`;

const RequestReviewButton = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 12px;
  background: #f8fafc;
  color: #0f172a;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;

  &:hover {
    background: #f1f5f9;
    border-color: #cbd5e1;
    color: #0f172a;
  }

  svg {
    font-size: 15px;
    color: #64748b;
  }
`;

const PageTitle = styled(Title)`
  /* margin-top: 50px; */
`;

const Link = styled(RouterLink)`
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
`;

export default function Orders() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [orders, setOrders] = useState<Order[]>([]);
  const [isLoading, setIsLoading] = useState(Loading.INITIAL);
  const [cursor, setCursor] = useState<string | null>(null);
  const [hasNext, setHasNext] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(Loading.INITIAL);
  const [selectedOrderIds, setSelectedOrderIds] = useState<string[]>([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage] = useState<number>(10);

  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { platform } = selectedSite ?? {};

  const selectedOrders = orders.filter((order) =>
    selectedOrderIds.includes(order.id)
  );

  const {
    showModal: showNextStepModal,
    handleCloseModal: handleCloseNextStepModal,
    handleOpenModal: handleOpenNextStepModal,
  } = useModal();

  const fetchOrders = async (currentCursor?: string, nextPage?: number) => {
    if (!platform || !(platform in PLATFORM_CONFIG)) {
      setIsLoading(Loading.ERROR);
      return;
    }

    try {
      const isWooCommerce = platform === ReviewsJetPlatform.WOOCOMMERCE;
      const isLoadingMore = isWooCommerce ? !!nextPage : !!currentCursor;

      if (!isLoadingMore) setIsLoading(Loading.PENDING);

      let url = `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}${PLATFORM_CONFIG[platform as keyof typeof PLATFORM_CONFIG].endpoint}`;

      if (isWooCommerce) {
        const page = nextPage || currentPage;
        url += `?page=${page}&per_page=${perPage}`;
      } else {
        if (currentCursor) {
          url += `?cursor=${currentCursor}`;
        }
      }

      const response = await axiosPrivate.get(url);

      if (response.status !== 200) {
        throw new Error(
          `Failed to fetch ${
            PLATFORM_CONFIG[platform as keyof typeof PLATFORM_CONFIG].name
          } orders`
        );
      }

      const data = response.data;

      if (isWooCommerce) {
        setOrders((prev) =>
          isLoadingMore ? [...prev, ...data.orders] : data.orders
        );
        setHasNext(data.hasNext);
        setCurrentPage(nextPage || 1);
      } else {
        setOrders((prev) =>
          isLoadingMore ? [...prev, ...data.orders] : data.orders
        );
        setCursor(data.cursor);
        setHasNext(data.hasNext);
      }

      setIsLoading(Loading.SUCCESS);
      setIsLoadingMore(Loading.SUCCESS);
    } catch (err) {
      setIsLoading(Loading.ERROR);
      setIsLoadingMore(Loading.ERROR);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [platform]);

  const handleLoadMore = () => {
    if (platform === ReviewsJetPlatform.WOOCOMMERCE) {
      if (hasNext) {
        setIsLoadingMore(Loading.PENDING);
        fetchOrders(undefined, currentPage + 1);
      }
    } else {
      if (cursor && hasNext) {
        setIsLoadingMore(Loading.PENDING);
        fetchOrders(cursor);
      }
    }
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedOrderIds(orders.map((order) => order.id));
    } else {
      setSelectedOrderIds([]);
    }
  };

  const handleSelectOrder = (orderId: string) => {
    setSelectedOrderIds((prev) =>
      prev.includes(orderId)
        ? prev.filter((id) => id !== orderId)
        : [...prev, orderId]
    );
  };

  const handleRequestReviews = (orderIds: string[]) => {
    setSelectedOrderIds(orderIds);
    setOpenDrawer(true);
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const handleRefresh = () => {
    fetchOrders();
    dispatch(getBusinessInfo());
  };

  useEffect(() => {
    dispatch(getBusinessInfo());
  }, []);

  return (
    <>
      <Wrapper>
        <Header>
          <BackButton onClick={() => navigate('/ecommerce')}>
            <IoChevronBack />
            E-Commerce
          </BackButton>
        </Header>

        <FlexContainer
          justifyContent="space-between"
          style={{ width: '100%', marginTop: 50 }}
        >
          <div>
            <PageTitle>Orders</PageTitle>
            <p>Send review request to your customers from your past orders</p>
            <p>
              <Link to="/emails">View email history</Link> to track all
              previously sent review requests
            </p>
          </div>
          <TableHeader>
            {selectedOrderIds.length > 0 && (
              <FlexContainer
                justifyContent="space-between"
                style={{ width: '100%' }}
              >
                <SelectedCount>
                  {selectedOrderIds.length}{' '}
                  {selectedOrderIds.length === 1 ? 'order' : 'orders'} selected
                </SelectedCount>
                <BulkActionButton
                  onClick={() => handleRequestReviews(selectedOrderIds)}
                >
                  <MdOutlineEmail />
                  Request Reviews
                </BulkActionButton>
              </FlexContainer>
            )}
          </TableHeader>
        </FlexContainer>

        <Table>
          <thead>
            <tr>
              <Th style={{ width: '50px' }}>
                <Checkbox
                  checked={
                    orders.length > 0 &&
                    selectedOrderIds.length === orders.length
                  }
                  onChange={handleSelectAll}
                  disabled={orders.length === 0}
                />
              </Th>
              <Th>Order #</Th>
              <Th>Customer Information</Th>
              <Th>Product Details</Th>
              <Th></Th>
            </tr>
          </thead>
          <tbody>
            {isLoading === Loading.PENDING && (
              <tr>
                <td colSpan={5}>
                  <>
                    <LoadingState>
                      <Placeholder.Paragraph rows={1} active />
                    </LoadingState>
                    <LoadingState>
                      <Placeholder.Paragraph rows={1} active />
                    </LoadingState>
                    <LoadingState>
                      <Placeholder.Paragraph rows={1} active />
                    </LoadingState>
                  </>
                </td>
              </tr>
            )}
            {isLoading === Loading.ERROR && (
              <tr>
                <td colSpan={5} style={{ textAlign: 'center' }}>
                  <ErrorMessage>
                    <MdError />
                    Failed to load orders. Please try again later.
                  </ErrorMessage>

                  <Button onClick={handleRefresh} style={{ margin: '10px 0' }}>
                    Try again
                  </Button>
                </td>
              </tr>
            )}
            {isLoading === Loading.SUCCESS ? (
              <>
                {orders.length === 0 ? (
                  <tr>
                    <EmptyMessage colSpan={5}>No orders found</EmptyMessage>
                  </tr>
                ) : (
                  <>
                    {orders.map((order) => (
                      <tr key={order.id}>
                        <Td>
                          <Checkbox
                            checked={selectedOrderIds.includes(order.id)}
                            onChange={() => handleSelectOrder(order.id)}
                          />
                        </Td>
                        <Td>{order.orderNumber}</Td>
                        <Td>
                          <CustomerName>{`${order.firstName} ${order.lastName}`}</CustomerName>
                          <ContactInfo>
                            <span>
                              <EmailIcon>
                                <MdEmail />
                              </EmailIcon>
                              {order.email}
                            </span>
                            {order.phone && (
                              <span>
                                <PhoneIcon>
                                  <MdPhone />
                                </PhoneIcon>
                                {order.phone}
                              </span>
                            )}
                          </ContactInfo>
                        </Td>
                        <Td>
                          <ProductInfo>
                            <ProductImage
                              src={order.productImage}
                              alt={order.productName}
                            />
                            <ProductDetails>
                              <span>{order.productName}</span>
                              <ProductPrice>{order.price}</ProductPrice>
                              <ProductDate>
                                {format(
                                  new Date(order.createdDate),
                                  'MMM d, yyyy'
                                )}
                              </ProductDate>
                            </ProductDetails>
                          </ProductInfo>
                        </Td>
                        <Td style={{ textAlign: 'right', width: '140px' }}>
                          <RequestReviewButton
                            onClick={() => handleRequestReviews([order.id])}
                          >
                            <MdOutlineEmail />
                            Request review
                          </RequestReviewButton>
                        </Td>
                      </tr>
                    ))}
                  </>
                )}
              </>
            ) : null}
          </tbody>
        </Table>

        {hasNext && (
          <LoadMoreButton
            onClick={handleLoadMore}
            disabled={isLoadingMore === Loading.PENDING}
          >
            {isLoadingMore === Loading.PENDING ? (
              <>
                <FaSpinner /> Loading more orders...
              </>
            ) : (
              <>Show more orders</>
            )}
          </LoadMoreButton>
        )}
      </Wrapper>
      <Drawer
        open={openDrawer}
        onClose={handleClose}
        size="full"
        className="review-request-drawer"
      >
        <Drawer.Body>
          <ReviewRequest
            onClose={handleClose}
            orders={selectedOrders}
            onSuccess={() => {
              // setSelectedOrderIds([]);
              handleOpenNextStepModal();
            }}
          />
        </Drawer.Body>
      </Drawer>

      <AppModal
        size="500px"
        shouldOpenModal={showNextStepModal}
        onModalClose={handleCloseNextStepModal}
        body={
          <NextStep
            onClose={handleCloseNextStepModal}
            totalSent={orders.length}
          />
        }
      />
    </>
  );
}
