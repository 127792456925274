import { useState } from 'react';
import { Input } from 'rsuite';
import styled from 'styled-components';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';

const Wrapper = styled(FlexContainer)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  background-color: #fff;
  padding: 25px;
  border-radius: 10px;
  border: 1px dashed #eaeaea;
`;

const Title = styled.h3`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
`;

const StyledInput = styled(Input)`
  border: none;
  font-size: 14px;
  background-color: #f5f5f5;

  &::placeholder {
    color: #8a8a8a;
  }
`;

const FormContainer = styled(FlexContainer)`
  width: 100%;
  gap: 10px;
  margin-top: 20px;

  input {
    flex: 1;
  }

  button {
    border: none;
    padding: 1px 20px;
    text-align: center;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #27272a;
    color: #fff;
  }
`;

export default function TryReviewsJet() {
  const [email, setEmail] = useState('');

  return (
    <Wrapper>
      <Title>You just submitted a review with ReviewsJet :)</Title>
      <p>Want to collect reviews for your business too? It's free!</p>

      <FormContainer justifyContent="space-between" alignItems="stretch">
        <StyledInput
          size="lg"
          placeholder="john@doe.com"
          value={email}
          onChange={(value: string) => setEmail(value)}
        />
        <button>Get started for free</button>
      </FormContainer>
    </Wrapper>
  );
}
