import { IoWarning } from 'react-icons/io5';
import styled from 'styled-components';
import ReviewsJetCrownLogo from '../ReviewsJetCrownLogo';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  background-color: #fafafa;
  overflow: auto;
  padding: 64px 20px;
`;

const Inner = styled.div`
  width: 100%;
  max-width: 800px;
  margin: auto;
`;

const LogoWrapper = styled.div`
  text-align: center;
  margin-bottom: 32px;

  svg {
    height: 32px;
    width: auto;
  }
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  font-family: 'Ubuntu', sans-serif;
  background: linear-gradient(135deg, #334155 0%, #475569 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 0 40px;
  letter-spacing: -0.5px;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 26px;
    margin-bottom: 30px;
  }
`;

const WarningBanner = styled.div`
  background: #fffbeb;
  border: 1px solid #fcd34d;
  border-radius: 12px;
  padding: 24px 28px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(8px);

  h3 {
    color: #92400e;
    margin: 0 0 16px 0;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
      color: #d97706;
    }
  }

  p {
    color: #78350f;
    margin: 0;
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0.1px;
  }

  a {
    color: #b45309;
    text-decoration: underline;
    font-weight: 500;
    transition: all 0.2s ease;

    &:hover {
      color: #92400e;
      text-decoration-thickness: 2px;
    }
  }
`;

const SupportMessage = styled.div`
  text-align: center;
  margin-top: 24px;
  color: #64748b;
  font-size: 14px;
  line-height: 1.6;

  a {
    color: #475569;
    text-decoration: underline;
    font-weight: 500;
    transition: all 0.2s ease;

    &:hover {
      color: #334155;
      text-decoration-thickness: 2px;
    }
  }
`;

export default function ActiveSubscriptionWarning() {
  return (
    <Wrapper>
      <Inner>
        <LogoWrapper>
          <ReviewsJetCrownLogo />
        </LogoWrapper>
        <Title>Active Subscription Detected</Title>
        <WarningBanner>
          <h3>
            <IoWarning size={22} /> Please Cancel Your Current Subscription
          </h3>
          <p>
            We've detected that you have an active subscription through Wix. To
            avoid double billing, please cancel your current subscription before
            proceeding.
          </p>
          <p>
            You can cancel your subscription by following the instructions in
            the{' '}
            <a
              href="https://support.wix.com/en/article/canceling-an-app-subscription"
              target="_blank"
              rel="noopener noreferrer"
            >
              Wix Support Article
            </a>
          </p>
        </WarningBanner>
        <SupportMessage>
          Need help? Contact us at{' '}
          <a href="mailto:hello@reviewsjet.com">hello@reviewsjet.com</a> or use
          the help widget in the bottom right corner of this page.
        </SupportMessage>
      </Inner>
    </Wrapper>
  );
}
