import { useState } from 'react';
import { batch } from 'react-redux';
import { Divider, Toggle } from 'rsuite';
import styled from 'styled-components';
import { Label } from '../../../../../../shared/components/Label';
import PremiumBanner from '../../../../../../shared/components/PremiumBanner';
import { SubscriptionPlan } from '../../../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import { toggleUpgradeModal } from '../../../../../../store/app.slice';
import { selectedSiteSelector } from '../../../../../userData/redux/userData.slice';
import FormRow from '../../../../../widgetSettings/components/form-row';
import {
  reviewFormSelector,
  setShowWheelLoseResult,
  setShowWheelWinResult,
  updateReviewFormSettings,
} from '../../../../redux/review-form.slice';
import ReviewOptions from './ReviewOptions';
import WheelSections from './WheelSections';
import WheelThemeChooser from './WheelTheme';

const InfoBanner = styled.div`
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-left: 4px solid #4dabf7;
  border-radius: 6px;
  padding: 12px 16px;
  margin: 16px 0;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
`;

const InfoIcon = styled.div`
  color: #4dabf7;
  flex-shrink: 0;
  margin-top: 2px;
`;

const InfoContent = styled.div`
  color: #495057;
  font-size: 14px;
  line-height: 1.5;

  strong {
    color: #212529;
    font-weight: 600;
  }
`;

export default function ReviewRewards() {
  const dispatch = useAppDispatch();
  const [wheelColor, setWheelColor] = useState<string>('#000');
  const selectedSite = useAppSelector(selectedSiteSelector);
  const { plan } = selectedSite ?? {};
  const { reviewFormSettings } = useAppSelector(reviewFormSelector);
  const { reward } = reviewFormSettings ?? {};
  const { enableReward, wheelTheme } = reward ?? {};

  return (
    <>
      <FormRow>
        <Label>Rewards</Label>
        <p style={{ marginBottom: '13px' }}>
          Users collect 2x more reviews when they use rewards. Enable rewards to
          encourage your customers to leave a review.
        </p>

        {plan !== SubscriptionPlan.ADVANCED && (
          <PremiumBanner
            availableOn={SubscriptionPlan.ADVANCED}
            description="Upgrade to reward your customers for submitting a review using ReviewsJet Wheel."
          />
        )}
      </FormRow>

      {plan === SubscriptionPlan.ADVANCED && (
        <>
          <FormRow gap="10px" justifyContent="flex-start">
            <Toggle
              checked={enableReward}
              // @ts-ignore
              onChange={(value) => {
                if (plan !== SubscriptionPlan.ADVANCED) {
                  return dispatch(
                    toggleUpgradeModal(SubscriptionPlan.ADVANCED)
                  );
                }

                batch(() => {
                  dispatch(
                    updateReviewFormSettings({
                      ...reviewFormSettings,
                      reward: { ...reward, enableReward: value },
                    })
                  );
                  dispatch(setShowWheelWinResult(false));
                  dispatch(setShowWheelLoseResult(false));
                });
              }}
              checkedChildren="Yes"
              unCheckedChildren="No"
            />
            <Label margin="0">Enable rewards</Label>
          </FormRow>
          {enableReward && (
            <>
              <ReviewOptions />
              <Divider>Theme</Divider>
              <WheelThemeChooser
                selectedWheel={wheelTheme}
                onWheelChange={(value) => {
                  batch(() => {
                    dispatch(
                      updateReviewFormSettings({
                        ...reviewFormSettings,
                        reward: { ...reward, wheelTheme: value },
                      })
                    );
                    dispatch(setShowWheelWinResult(false));
                    dispatch(setShowWheelLoseResult(false));
                  });
                }}
                wheelColor={wheelColor}
                onColorChange={setWheelColor}
              />

              <Divider>Edit Wheel Sections</Divider>

              <InfoBanner>
                <InfoIcon>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="12" cy="12" r="10" />
                    <line x1="12" y1="16" x2="12" y2="12" />
                    <line x1="12" y1="8" x2="12.01" y2="8" />
                  </svg>
                </InfoIcon>
                <InfoContent>
                  <strong>Important:</strong> Make sure to create the discount
                  codes on your website before adding them to the wheel. This
                  ensures customers can redeem their rewards immediately after
                  winning.
                </InfoContent>
              </InfoBanner>

              <WheelSections />
            </>
          )}
        </>
      )}
    </>
  );
}
