import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { MdContentCopy } from 'react-icons/md';
import { Button, Tooltip, Whisper } from 'rsuite';
import styled from 'styled-components';
import { useNotification } from '../hooks/useNotification';
import SyntaxHighlighter from './SyntaxHighlighter';

const Wrapper = styled.div`
  position: relative;
  background-color: #faf8f5;
  border-radius: 5px;
  padding: 10px 50px 10px 10px;
`;

const CopyButton = styled(Button)`
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 3px !important;
  width: 35px !important;
  height: 35px !important;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  color: #666 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  &:hover {
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
`;

interface CopyableCodeProps {
  code: string;
}

const CopyableCode = ({ code }: CopyableCodeProps) => {
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation('common');
  const { showInfoToast } = useNotification();

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
    showInfoToast('Installation code copied to clipboard');
  };

  return (
    <Wrapper className="copyable-code">
      <SyntaxHighlighter code={code} />

      <CopyToClipboard text={code}>
        <Whisper
          trigger="hover"
          placement="top"
          controlId={`control-id-top`}
          speaker={<Tooltip>Copy Installation Code</Tooltip>}
        >
          <CopyButton
            color={copied ? 'green' : 'ghost'}
            appearance="ghost"
            onClick={handleCopy}
          >
            <MdContentCopy size={18} />
          </CopyButton>
        </Whisper>
      </CopyToClipboard>
    </Wrapper>
  );
};

export default CopyableCode;
