import { IoDocumentTextOutline, IoImageOutline } from 'react-icons/io5';
import { batch } from 'react-redux';
import { Input } from 'rsuite';
import styled from 'styled-components';
import { Label } from '../../../../../../shared/components/Label';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import FormRow from '../../../../../widgetSettings/components/form-row';
import {
  reviewFormSelector,
  setShowWheelLoseResult,
  setShowWheelWinResult,
  updateReviewFormSettings,
} from '../../../../redux/review-form.slice';

const RewardOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

const Option = styled.div<{ $isSelected?: boolean }>`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  border-radius: 6px;
  background: ${(props) => (props.$isSelected ? '#f0f9ff' : '#fff')};
  border: 1px solid ${(props) => (props.$isSelected ? '#7dd3fc' : '#e2e8f0')};
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    background: ${(props) => (props.$isSelected ? '#f0f9ff' : '#f8fafc')};
    border-color: ${(props) => (props.$isSelected ? '#7dd3fc' : '#cbd5e1')};
  }
`;

const IconWrapper = styled.div<{ $isSelected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background: ${(props) => (props.$isSelected ? '#e0f2fe' : '#f1f5f9')};
  color: ${(props) => (props.$isSelected ? '#0284c7' : '#64748b')};
  font-size: 18px;
`;

const OptionContent = styled.div`
  flex: 1;
`;

const OptionTitle = styled.div`
  font-weight: 500;
  color: #334155;
  font-size: 14px;
`;

const OptionDescription = styled.div`
  color: #64748b;
  font-size: 13px;
  margin-top: 2px;
`;

export default function ReviewOptions() {
  const dispatch = useAppDispatch();
  const { reviewFormSettings } = useAppSelector(reviewFormSelector);
  const { reward, translation } = reviewFormSettings ?? {};
  const { rewardIncentiveText, rewardFor } = reward ?? {};
  const { claimRewardButtonLabel } = translation ?? {};

  return (
    <>
      <FormRow>
        <RewardOptions style={{ width: '100%' }}>
          <Option
            $isSelected={rewardFor === 'PHOTO_REVIEWS'}
            onClick={() => {
              dispatch(
                updateReviewFormSettings({
                  ...reviewFormSettings,
                  reward: { ...reward, rewardFor: 'PHOTO_REVIEWS' },
                })
              );
              dispatch(setShowWheelWinResult(false));
              dispatch(setShowWheelLoseResult(false));
            }}
          >
            <IconWrapper $isSelected={rewardFor === 'PHOTO_REVIEWS'}>
              <IoImageOutline />
            </IconWrapper>
            <OptionContent>
              <OptionTitle>Photo reviews</OptionTitle>
              <OptionDescription>
                Only reward reviews that include photos
              </OptionDescription>
            </OptionContent>
          </Option>
          <Option
            $isSelected={rewardFor === 'ANY_REVIEW'}
            onClick={() => {
              batch(() => {
                dispatch(
                  updateReviewFormSettings({
                    ...reviewFormSettings,
                    reward: { ...reward, rewardFor: 'ANY_REVIEW' },
                  })
                );
                dispatch(setShowWheelWinResult(false));
                dispatch(setShowWheelLoseResult(false));
              });
            }}
          >
            <IconWrapper $isSelected={rewardFor === 'ANY_REVIEW'}>
              <IoDocumentTextOutline />
            </IconWrapper>
            <OptionContent>
              <OptionTitle>Any review</OptionTitle>
              <OptionDescription>
                Reward all reviews with or without photos
              </OptionDescription>
            </OptionContent>
          </Option>
        </RewardOptions>
      </FormRow>

      <FormRow justifyContent="flex-start">
        <Label>Reward Incentive Text </Label>
        <Input
          maxLength={54}
          defaultValue={rewardIncentiveText}
          onChange={(value) => {
            batch(() => {
              dispatch(
                updateReviewFormSettings({
                  ...reviewFormSettings,
                  reward: { ...reward, rewardIncentiveText: value },
                })
              );
              dispatch(setShowWheelWinResult(false));
              dispatch(setShowWheelLoseResult(false));
            });
          }}
          placeholder={rewardIncentiveText}
        />
      </FormRow>

      <FormRow justifyContent="flex-start">
        <Label>Claim Reward Button Text</Label>
        <Input
          maxLength={54}
          defaultValue={claimRewardButtonLabel}
          onChange={(value) => {
            batch(() => {
              dispatch(
                updateReviewFormSettings({
                  ...reviewFormSettings,
                  translation: {
                    ...translation,
                    claimRewardButtonLabel: value,
                  },
                })
              );
              dispatch(setShowWheelWinResult(false));
              dispatch(setShowWheelLoseResult(false));
            });
          }}
          placeholder={claimRewardButtonLabel}
        />
      </FormRow>
    </>
  );
}
