import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from 'rsuite/esm/Input';
import styled from 'styled-components';
import { axiosPrivate } from '../../../services/axiosPrivate';
import Button from '../../../shared/components/Button';
import { Label } from '../../../shared/components/Label';
import { Title } from '../../../shared/components/Title';
import { useNotification } from '../../../shared/hooks/useNotification';
import { Loading } from '../../../shared/types/review.types';
import { handleApiError } from '../../../shared/utils/extractErrorMessage';
import removeTrailingSlash from '../../../shared/utils/removeTrailingSlash';
import FormRow from '../../widgetSettings/components/form-row';

/* eslint no-console: "warn" */

const Description = styled.p`
  font-size: 14px;
  color: #64748b;
  margin-bottom: 20px;
  margin-top: 10px;
`;

const SubmitButton = styled(Button)`
  width: 100%;
  color: #fff;
  background-color: #000;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  padding: 10px;
  margin-top: 20px;
  border: none;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: #e53e3e;
  font-size: 14px;
  margin-top: 6px;
  font-weight: 500;
`;

type Props = {
  onClose: () => void;
  formId: string | null;
};

export default function CreateFormModal({ onClose, formId }: Props) {
  const navigate = useNavigate();
  const [formName, setFormName] = useState('');
  const [isLoading, setIsLoading] = useState(Loading.INITIAL);
  const [error, setError] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const { showErrorToast: showNotificationErrorToast, showSuccessToast } =
    useNotification();

  // Set focus on input when component mounts
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSubmit = async () => {
    setError('');

    if (!formName.trim()) {
      setError('Please enter a form name');
      if (inputRef.current) {
        inputRef.current.focus();
      }
      return;
    }

    setIsLoading(Loading.PENDING);

    try {
      const response = await axiosPrivate.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/review-form/create`,
        {
          formId,
          name: formName,
        }
      );

      showSuccessToast('Form created successfully');
      onClose();

      setTimeout(() => {
        navigate(`/forms/edit?id=${response.data._id}`);
      }, 1000);
    } catch (err) {
      // Use the reusable error handler
      const errorMessage = handleApiError(
        err,
        showNotificationErrorToast,
        'Failed to create form'
      );
    } finally {
      setIsLoading(Loading.INITIAL);
    }
  };

  return (
    <div>
      <Title>{formId ? 'Duplicate form' : 'Create form'}</Title>

      <Description>
        {formId
          ? `Before generating a new copy of this form, please provide it with a new name.`
          : 'Name your form to get started. '}
        <br />
        {formId ? '' : 'You can customize all details after creation.'}
      </Description>

      <FormRow marginBottom="0">
        <Label>Form name</Label>
        <Input
          ref={inputRef}
          maxLength={50}
          placeholder="For paid users only"
          value={formName}
          onChange={(value) => {
            setFormName(value);
            if (error) setError(''); // Clear error when user types
          }}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </FormRow>

      <SubmitButton
        onClick={handleSubmit}
        type="button"
        disabled={isLoading === Loading.PENDING}
      >
        {isLoading === Loading.PENDING ? 'Creating...' : 'Create'}
      </SubmitButton>
    </div>
  );
}
