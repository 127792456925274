import { ReviewsJetPlatform } from '../../../product/types';
import { Order } from '../types';

/* eslint no-console: "warn" */

export const getCustomerDetails = (
  order: Order
): { firstName: string; lastName: string; fullName: string; email: string } => {
  const { firstName, lastName, email } = order;
  return {
    firstName,
    lastName,
    fullName: `${firstName} ${lastName}`.trim(),
    email,
  };
};

export const getProductDetails = (
  order: Order
): { name: string; price: string } => {
  const { productName, price } = order;
  return {
    name: productName,
    price,
  };
};

export const formatPrice = (
  price: string,
  platform?: ReviewsJetPlatform
): string => {
  if (platform === ReviewsJetPlatform.WIX) {
    return price;
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(Number(price));
};

export const formatEmailSubject = (emailSubject: string, firstName: string) => {
  return emailSubject
    .replaceAll(/{{first_name}}/g, firstName)
    .replaceAll(/{{ first_name}}/g, firstName)
    .replaceAll(/{{first_name }}/g, firstName)
    .replaceAll(/{{ first_name }}/g, firstName);
};

export const formatEmailMessage = (emailMessage: string, firstName: string) => {
  return emailMessage
    .replaceAll(/{{first_name}}/g, firstName)
    .replaceAll(/{{ first_name}}/g, firstName)
    .replaceAll(/{{first_name }}/g, firstName)
    .replaceAll(/{{ first_name }}/g, firstName);
};
