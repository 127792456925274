import {
  accountSelector,
  userAccountConfigSelector,
} from '../../../../features/userData/redux/userData.slice';
import { useAppSelector } from '../../../../store';
import CollapsedSidebar from './CollapsedSidebar';

/* eslint no-console: "warn" */

const Sidebar = ({
  hideSidebar,
  collapsedSidebar,
}: {
  hideSidebar: boolean;
  collapsedSidebar: boolean;
  toggleSidebar: (value: boolean) => void;
}) => {
  const siteConfig = useAppSelector(userAccountConfigSelector);
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { platform } = selectedSite ?? {};

  if (!siteConfig) {
    return null;
  }

  return (
    <CollapsedSidebar
      collapsedSidebar={collapsedSidebar}
      hideSidebar={hideSidebar}
    />
  );

  // if (platform === ReviewsJetPlatform.WIX) {
  //   return (
  //     <CollapsedSidebar
  //       collapsedSidebar={collapsedSidebar}
  //       hideSidebar={hideSidebar}
  //     />
  //   );
  // }

  // return <ExpandedSidebar hideSidebar={hideSidebar} />;
};

export default Sidebar;
