import { useNavigate } from 'react-router-dom';
import AppModal from '../../../../shared/components/modal';
import useModal from '../../../../shared/components/modal/useModal';
import { useAppSelector } from '../../../../store';
import AddIntegrationModal from '../../../settings/components/Integrations/components/AddIntegrationModal';
import { isSendgridConnectedSelector } from '../../../settings/components/Integrations/redux/integrations.slice';
import { PlatformButton } from '../MarketingPlatforms';

interface SendgridPlatformProps {
  onClose: () => void;
}

export default function SendgridPlatform({ onClose }: SendgridPlatformProps) {
  const navigate = useNavigate();
  const isSendgridConnected = useAppSelector(isSendgridConnectedSelector);
  const { handleCloseModal, handleOpenModal, showModal } = useModal();

  return (
    <>
      <PlatformButton
        onClick={() => {
          if (isSendgridConnected) {
            navigate('/share-form?source=sendgrid');
          } else {
            handleOpenModal();
          }
        }}
      >
        <img src="/images/sendgrid.png" alt="SendGrid" />
        Invite SendGrid contacts
      </PlatformButton>

      <AppModal
        size="sm"
        shouldOpenModal={showModal}
        onModalClose={handleCloseModal}
        body={
          <AddIntegrationModal
            onClose={handleCloseModal}
            onConnect={() => {}}
            integration="SendGrid"
            logo={
              <div className="logo">
                <img src="/images/sendgrid.png" alt="" style={{ width: 150 }} />
              </div>
            }
          />
        }
      />
    </>
  );
}
