function getSegmentLighterColor(hexColor: string): string {
  // Remove # if present
  const hex = hexColor.replace('#', '');

  // Convert hex to RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Lighten by mixing with white
  const lighterR = Math.round(r + (255 - r) * 0.5);
  const lighterG = Math.round(g + (255 - g) * 0.5);
  const lighterB = Math.round(b + (255 - b) * 0.5);

  return `rgba(${lighterR}, ${lighterG}, ${lighterB}, 1)`;
}

export default getSegmentLighterColor;
