import millify from 'millify';
import { useEffect, useState } from 'react';
import { BiSolidChat } from 'react-icons/bi';
import styled from 'styled-components';
import { useAppSelector } from '../../../../../store';
import { ReviewsJetPlatform } from '../../../../product/types';
import {
  siteWidgetSettingsSelector,
  WidgetPosition,
} from '../../../../widgetSettings/redux/widgetSettings.slice';
import ReviewRating from './ReviewRating';

const Wrapper = styled.div<{ $position?: WidgetPosition; $isSimple: boolean }>`
  display: flex;
  justify-content: ${({ $position }) =>
    $position === WidgetPosition.LEFT
      ? 'flex-start'
      : $position === WidgetPosition.RIGHT
      ? 'flex-end'
      : 'center'};
  align-items: center;
  gap: 10px;
  margin: 20px 0;
  line-height: 15px;
  ${({ $isSimple }) => !$isSimple && 'cursor: pointer;'}

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const RatingWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 7px;
  font-size: 14px;
`;

const QuestionsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  gap: 7px;
  border-left: 1px solid #ddd;
  padding-left: 10px;
`;

type Props = {
  siteId?: string;
  instanceId?: string;
  productId: string;
  platform: ReviewsJetPlatform;
  isSimple?: boolean;
};

export default function StarWidget(props: Props) {
  const totalDocs = 1200;
  const rating = 4.5;
  const questions = [];
  const { isSimple = false } = props;
  const settings = useAppSelector(siteWidgetSettingsSelector);
  const [summary, setSummary] = useState('');

  const { starWidget, productWidget, translation } = settings ?? {};
  const { position } = starWidget ?? {};
  const { reviewSummarySingular, reviewSummaryPlural } = translation ?? {};
  const {
    starColor = '#000',
    textColor = '#000',
    fontSize,
  } = productWidget ?? {};

  useEffect(() => {
    setSummary(reviewSummarySingular ?? '');
  }, [reviewSummarySingular]);

  useEffect(() => {
    const summary = reviewSummaryPlural?.replace(
      /{totalReview}/gi,
      `${millify(totalDocs)}`
    );

    setSummary(summary ?? '');
  }, [reviewSummaryPlural]);

  if (!settings) {
    return null;
  }

  return (
    <Wrapper role="presentation" $position={position} $isSimple={isSimple}>
      <RatingWrapper style={{ color: textColor }}>
        <ReviewRating size={16} color={starColor} rating={rating} gap={0} />{' '}
        {!!totalDocs && (
          <span
            className="assistant-400"
            style={{ fontSize, lineHeight: `${fontSize}px` }}
          >
            {summary}
          </span>
        )}
      </RatingWrapper>
      {!!questions.length && (
        <QuestionsWrapper style={{ color: textColor }}>
          <BiSolidChat size={16} color={textColor} />{' '}
          {millify(questions.length)}{' '}
          <span
            className="assistant-400"
            style={{ fontSize, lineHeight: `${fontSize}px` }}
          >
            questions
          </span>
        </QuestionsWrapper>
      )}
    </Wrapper>
  );
}
