import {
  reviewFormSelector,
  setLogoPreview,
} from '../../features/review-forms/redux/review-form.slice';
import { getBusinessInfo } from '../../features/settings/redux/actions';
import { axiosPrivate } from '../../services/axiosPrivate';
import { useAppDispatch, useAppSelector } from '../../store';
import removeTrailingSlash from '../utils/removeTrailingSlash';
import { useNotification } from './useNotification';

/* eslint no-console: "warn" */

export default function useUpdateLogo() {
  const dispatch = useAppDispatch();
  const { logoPreview } = useAppSelector(reviewFormSelector);
  const { showErrorToast } = useNotification();

  const updateLogo = async () => {
    try {
      if (!logoPreview) return;

      const formData = new FormData();
      formData.append('logo', logoPreview);

      await axiosPrivate.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/business-info/update-logo`,
        formData
      );

      dispatch(getBusinessInfo()).then(() => dispatch(setLogoPreview(null)));
    } catch (error) {
      showErrorToast('Failed to upload logo');
    }
  };

  return { updateLogo };
}
