import { unwrapResult } from '@reduxjs/toolkit';
import { useNotification } from '../../../../shared/hooks/useNotification';
import { Loading } from '../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { updateWidgetsSettings } from '../../../widgetSettings/redux/action';
import { settingSelector } from '../../../widgetSettings/redux/widgetSettings.slice';

export default function useSaveSettings() {
  const dispatch = useAppDispatch();
  const { showSuccessToast } = useNotification();
  const { updateWidgetSettings } = useAppSelector(settingSelector);

  const saveSettings = (showToast = true) => {
    dispatch(updateWidgetsSettings())
      .then(unwrapResult)
      .then(() => {
        if (showToast) {
          showSuccessToast('Settings successfully saved');
        }
      })
      .catch(() => {});
  };

  return {
    saveSettings,
    isSaving: updateWidgetSettings.saving === Loading.PENDING,
  };
}
