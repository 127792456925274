import { useEffect } from 'react';
import { ColorPicker } from '../../../../../shared/components/colorPicker';
import { Label } from '../../../../../shared/components/Label';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import Logo from '../../../../settings/components/Business/Logo';
import { getBusinessInfo } from '../../../../settings/redux/actions';
import { businessInfoSelector } from '../../../../settings/redux/business-info.slice';
import {
  reviewFormSelector,
  setLogoPreview,
  updateReviewFormSettings,
} from '../../../redux/review-form.slice';

/* eslint no-console: "warn" */

export default function Appearance() {
  const dispatch = useAppDispatch();
  const { reviewFormSettings, logoPreview } =
    useAppSelector(reviewFormSelector);
  const { data: businessInfo } = useAppSelector(businessInfoSelector);
  const { logo: savedLogo } = businessInfo ?? {};
  const { appearance } = reviewFormSettings ?? {};
  const { primaryColor = '#000', backgroundColor = '#fff' } = appearance ?? {};

  const onLogoUploaded = (file: string) => {
    dispatch(setLogoPreview(file));
  };

  const onLogoDeleted = () => {
    dispatch(setLogoPreview(null));
  };

  useEffect(() => {
    dispatch(getBusinessInfo());
  }, []);

  return (
    <>
      <FlexContainer
        stack
        justifyContent="flex-start"
        alignItems="flex-start"
        gap="20px"
      >
        <div>
          <Label>Logo</Label>
          <Logo
            name="Logo"
            onLogoUploaded={onLogoUploaded}
            onLogoDeleted={onLogoDeleted}
            logo={logoPreview ?? savedLogo ?? undefined}
            showDeleteButton={!!logoPreview}
          />
        </div>
        <FlexContainer
          justifyContent="space-between"
          gap="20px"
          style={{ width: '100%' }}
        >
          <div style={{ width: '50%' }}>
            <Label>Primary Color</Label>
            <ColorPicker
              color={primaryColor}
              isSimple={false}
              changeColor={(color) => {
                dispatch(
                  updateReviewFormSettings({
                    appearance: { ...appearance, primaryColor: color },
                  })
                );
              }}
              placement="top"
            />
          </div>
          <div style={{ width: '50%' }}>
            <Label>Background Color</Label>
            <ColorPicker
              color={backgroundColor}
              isSimple={false}
              changeColor={(color) => {
                dispatch(
                  updateReviewFormSettings({
                    appearance: { ...appearance, backgroundColor: color },
                  })
                );
              }}
              placement="top"
            />
          </div>
        </FlexContainer>
      </FlexContainer>
    </>
  );
}
