import { zodResolver } from '@hookform/resolvers/zod';
import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Input, InputGroup } from 'rsuite';
import styled, { css } from 'styled-components';
import { z, ZodType } from 'zod';
import { axiosPrivate } from '../../../../services/axiosPrivate';
import Button from '../../../../shared/components/Button';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import { useNotification } from '../../../../shared/hooks/useNotification';
import { Loading } from '../../../../shared/types/review.types';
import removeTrailingSlash from '../../../../shared/utils/removeTrailingSlash';

/* eslint no-console: "warn" */

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const Wrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const Header = styled.div`
  padding: 15px 20px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid rgb(235, 235, 235);
`;

const FormGroup = styled.div`
  width: 100%;
`;

const Content = styled.div`
  padding: 20px;
  margin-bottom: 20px;
`;

const Footer = styled(FlexContainer)`
  padding: 15px 20px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  border-top: 1px solid rgb(235, 235, 235);
`;

const CustomInput = styled(Input)<{ error?: boolean }>`
  font-style: normal;
  background: #fff;
  border-radius: 5px;
  max-width: 100%;
  outline: none !important;
  margin-bottom: 5px;
  border: 1px solid #cecece;
  &:active {
    border: 1px solid #cecece;
  }

  ${({ error }) =>
    error &&
    css`
      border-color: red;
    `}
`;

const ErroMessage = styled.span`
  display: block;
  color: red;
  margin-bottom: 15px;
`;

export type PasswordPayload = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const FormSchema: ZodType<PasswordPayload> = z
  .object({
    currentPassword: z.string().min(1, 'Current password is required'),
    newPassword: z.string().min(1, 'New password is required'),
    confirmPassword: z.string().min(1, 'Confirm password is required'),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword'], // path of error
  });

export default function UpdatePassword() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordPayload>({
    resolver: zodResolver(FormSchema),
    reValidateMode: 'onBlur',
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });
  const [visible, setVisible] = useState(false);
  const [saving, setSaving] = useState(Loading.INITIAL);
  const { showErrorToast, showSuccessToast } = useNotification();

  const onSubmit = useCallback(
    async (data: PasswordPayload) => {
      if (saving === Loading.PENDING) {
        return;
      }

      try {
        setSaving(Loading.PENDING);

        await axiosPrivate.post(
          `${removeTrailingSlash(
            process.env.REACT_APP_REVIEWS_JET_API!
          )}/account/update-password`,
          data
        );

        setSaving(Loading.SUCCESS);
        showSuccessToast('Your password has been updated!');
      } catch (error: any) {
        // Handle API error responses
        if (error.response?.data?.error) {
          showErrorToast(error.response.data.error);
        }
        // Handle network errors
        else if (error.request) {
          showErrorToast('Network error. Please check your connection.');
        }
        // Handle other errors
        else {
          showErrorToast('An unexpected error occurred. Please try again.');
        }

        setSaving(Loading.ERROR);
      }
    },
    [saving, showSuccessToast, showErrorToast]
  );

  return (
    <Wrapper>
      <Header>Update password</Header>
      <Content>
        <FlexContainer
          stack
          justifyContent="flex-start"
          alignItems="flex-start"
          gap="15px"
        >
          <FormGroup>
            <Label>Current password</Label>
            <Controller
              name="currentPassword"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <InputGroup inside style={{ marginBottom: 5 }}>
                  <CustomInput
                    style={{ marginBottom: 0 }}
                    type={visible ? 'text' : 'password'}
                    placeholder="Enter current password"
                    error={Boolean(errors.currentPassword)}
                    {...field}
                  />
                  <InputGroup.Button onClick={() => setVisible(!visible)}>
                    {visible ? <EyeIcon /> : <EyeSlashIcon />}
                  </InputGroup.Button>
                </InputGroup>
              )}
            />
            {errors.currentPassword && (
              <ErroMessage>{errors.currentPassword.message}</ErroMessage>
            )}
          </FormGroup>

          <FormGroup>
            <Label>New password</Label>
            <Controller
              name="newPassword"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomInput
                  type={visible ? 'text' : 'password'}
                  placeholder="Enter new password"
                  error={Boolean(errors.newPassword)}
                  {...field}
                />
              )}
            />
            {errors.newPassword && (
              <ErroMessage>{errors.newPassword.message}</ErroMessage>
            )}
          </FormGroup>
          <FormGroup>
            <Label>Confirm new password</Label>
            <Controller
              name="confirmPassword"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomInput
                  type={visible ? 'text' : 'password'}
                  placeholder="Confirm password"
                  error={Boolean(errors.confirmPassword)}
                  {...field}
                />
              )}
            />
            {errors.confirmPassword && (
              <ErroMessage>{errors.confirmPassword.message}</ErroMessage>
            )}
          </FormGroup>
        </FlexContainer>
      </Content>
      <Footer justifyContent="flex-end">
        <Button
          rounded
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={saving === Loading.PENDING}
          variant={saving === Loading.PENDING ? 'tertiary' : 'secondary'}
        >
          Update password
        </Button>
      </Footer>
    </Wrapper>
  );
}
