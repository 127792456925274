import { FiGift } from 'react-icons/fi';
import { MdPlayCircle } from 'react-icons/md';
import styled from 'styled-components';
import FlexContainer from '../../../../../../../shared/components/layouts/flex-container';
import MarkdownPreview from '../../../../../../../shared/components/MarkdownPreview';
import { SubscriptionPlan } from '../../../../../../../shared/types/review.types';
import { useAppSelector } from '../../../../../../../store';
import { selectedSiteSelector } from '../../../../../../userData/redux/userData.slice';
import { reviewFormSelector } from '../../../../../redux/review-form.slice';
import FormButton from '../../../FormButton';

const Title = styled.h3`
  text-align: left;
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 20px;
  letter-spacing: -1px;
  font-weight: 700;
  font-optical-sizing: auto;
  font-style: normal;
  color: #55595f;
  font-family: 'Ubuntu Sans', sans-serif;
`;

const VideoContainer = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  background: #f3f4f6;
  border-radius: 12px;
  margin: 24px 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #e5e7eb;

    .play-button {
      transform: scale(1.1);
    }
  }
`;

const VideoPlaceholder = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #6b7280;
  gap: 12px;
`;

const PlayButton = styled(MdPlayCircle)`
  font-size: 48px;
  color: #3b82f6;
  transition: transform 0.2s ease;
`;

const VideoText = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
`;

const WelcomeText = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
`;

export default function ThankYou() {
  const selectedSite = useAppSelector(selectedSiteSelector);
  const { plan } = selectedSite ?? {};
  const { reviewFormSettings } = useAppSelector(reviewFormSelector);
  const { successScreen, translation, appearance, reward } =
    reviewFormSettings ?? {};
  const { claimRewardButtonLabel } = translation ?? {};
  const { primaryColor = '#000' } = appearance ?? {};
  const { enableReward } = reward ?? {};

  const { pageTitle, message, video } = successScreen ?? {};

  return (
    <>
      {/* <VideoContainer>
        <VideoPlaceholder>
          <PlayButton className="play-button" />
          <VideoText>Watch our welcome message</VideoText>
        </VideoPlaceholder>
      </VideoContainer> */}
      <Title>{pageTitle}</Title>
      <MarkdownPreview message={message ?? ''} />

      {enableReward && plan === SubscriptionPlan.ADVANCED && (
        <FormButton $color={primaryColor}>
          <FlexContainer gap="10px">
            <FiGift size={18} />
            {claimRewardButtonLabel}
          </FlexContainer>
        </FormButton>
      )}

      {/* <ShareOnX />
      <ShareOnOtherPlatforms /> */}
    </>
  );
}
