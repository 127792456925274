import { Icon } from '@rsuite/icons';
import axios from 'axios';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { FaCheck, FaSpinner, FaXmark } from 'react-icons/fa6';
import { Form, Input, InputGroup } from 'rsuite';
import styled from 'styled-components';
import { Label } from '../../../../../shared/components/Label';
import { Loading } from '../../../../../shared/types/review.types';
import removeTrailingSlash from '../../../../../shared/utils/removeTrailingSlash';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  reviewFormSelector,
  updateReviewFormSettings,
} from '../../../redux/review-form.slice';

const HelpText = styled.p`
  color: #6b7280;
  font-size: 14px;
  margin: 4px 0 12px;
  line-height: 1.5;
`;

const ErrorText = styled(Form.HelpText)`
  color: #dc2626;
  margin-top: 6px;
  font-size: 13px;
`;

const StyledInput = styled(Input)`
  font-weight: bold;

  &:disabled {
    background-color: transparent;
  }
`;

export default function FormLink() {
  const dispatch = useAppDispatch();
  const [saving, setSaving] = useState(Loading.INITIAL);
  const { reviewFormSettings } = useAppSelector(reviewFormSelector);
  const { trustKey, isDefault } = reviewFormSettings ?? {};
  const [updatedTrustKey, setUpdatedTrustKey] = useState(trustKey);
  const [errorMessage, setErrorMessage] = useState('');

  const sanitizeUrlValue = (input: string) => {
    // Allow only lowercase letters, numbers, and hyphens
    return input.toLowerCase().replace(/[^a-z0-9-]/g, '');
  };

  const debouncedUpdate = useCallback(
    debounce(async (newValue: string) => {
      try {
        if (newValue) {
          setSaving(Loading.PENDING);
          await axios.post(
            `${removeTrailingSlash(
              process.env.REACT_APP_REVIEWS_JET_API!
            )}/review-form/validate-trust-key`,
            {
              trustKey: newValue,
            }
          );
          setSaving(Loading.SUCCESS);
          dispatch(updateReviewFormSettings({ trustKey: newValue }));
        } else {
          setSaving(Loading.ERROR);
          setErrorMessage('Value cannot be empty');
        }
      } catch (error) {
        setSaving(Loading.ERROR);
        setErrorMessage('Value already in use. Please use a different value.');
      }
    }, 300),
    []
  );

  return (
    <>
      <Label>Review Collection Form URL</Label>
      <HelpText>
        Share this link with customers to collect their reviews.
      </HelpText>
      <InputGroup inside>
        <InputGroup.Addon>https://reviewsjet.com/w/</InputGroup.Addon>
        <StyledInput
          readOnly={!isDefault}
          disabled={!isDefault}
          value={updatedTrustKey}
          onChange={(newValue: string) => {
            const sanitizedValue = sanitizeUrlValue(newValue);
            debouncedUpdate(sanitizedValue);
            setUpdatedTrustKey(sanitizedValue);
          }}
        />
        <InputGroup.Addon>
          {saving === Loading.PENDING && <Icon as={FaSpinner} pulse />}
          {saving === Loading.ERROR && <FaXmark size="14px" color="red" />}
          {saving === Loading.SUCCESS && <FaCheck size="14px" color="green" />}
        </InputGroup.Addon>
      </InputGroup>

      {saving === Loading.ERROR && <ErrorText>{errorMessage}</ErrorText>}
    </>
  );
}
