import styled from 'styled-components';
import { useAppSelector } from '../../../../../store';
import { reviewFormSelector } from '../../../redux/review-form.slice';
import RewardWheel from './RewardWheel';

/* eslint no-console: "warn" */

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  border-radius: 8px;
  width: 500px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const CloseButton = styled.button`
  position: absolute;
  top: -33px;
  right: -33px;
  background: transparent;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: white;
  opacity: 0.8;
  transition: opacity 0.2s, transform 0.2s;
  z-index: 1001;

  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }

  svg {
    width: 24px;
    height: 24px;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  }
`;

interface RewardModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function RewardModal({ isOpen, onClose }: RewardModalProps) {
  const { reviewFormSettings } = useAppSelector(reviewFormSelector);
  const { reward } = reviewFormSettings ?? {};
  const { wheelColor } = reward ?? {};

  if (!isOpen) return null;

  return (
    <Overlay onClick={onClose}>
      <div style={{ position: 'relative' }}>
        <CloseButton onClick={onClose} aria-label="Close modal">
          <svg
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="15" y1="9" x2="9" y2="15" />
            <line x1="9" y1="9" x2="15" y2="15" />
          </svg>
        </CloseButton>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <RewardWheel wheelColor={wheelColor ?? '#3b82f6'} />
        </ModalContent>
      </div>
    </Overlay>
  );
}
