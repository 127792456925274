import { ChangeEvent } from 'react';
import { useNotification } from './useNotification';

const MAX_FILE_SIZE = 5 * 1024 * 1024;
const ACCEPTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
  'image/avif',
];

type Props = {
  logo?: string | File;
  onLogoUploaded?: (value: string) => void;
};

export default function useChangeLogo({ logo, onLogoUploaded }: Props) {
  let logoUrl;

  if (logo) {
    // @ts-ignore
    if (typeof logo === 'object') {
      // @ts-ignore
      logoUrl = URL.createObjectURL(logo);
    } else if (typeof logo === 'string') {
      logoUrl = logo;
    } else {
      logoUrl = '';
    }
  }

  const { showWarningToast } = useNotification();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      if (files[0].size > MAX_FILE_SIZE) {
        return showWarningToast('File size must be less than 5MB');
      }

      if (!ACCEPTED_IMAGE_TYPES.includes(files[0].type)) {
        return showWarningToast(
          'File type must be .jpeg, .jpg, .png, .webp, or .avif'
        );
      }

      if (onLogoUploaded) {
        // @ts-ignore
        onLogoUploaded(files[0]);
      }
    }
  };

  return { logoUrl, handleChange };
}
