import { useAppDispatch } from '../../../store';
import { PreviewScreen, setPreviewScreen } from '../redux/review-form.slice';

export default function useSettingsScreen() {
  const dispatch = useAppDispatch();

  const switchPreviewScreen = (sectionId: string) => {
    switch (sectionId) {
      case 'design':
      case 'welcome':
      case 'form-link':
        dispatch(setPreviewScreen(PreviewScreen.WELCOME));
        break;
      case 'review':
        dispatch(setPreviewScreen(PreviewScreen.REVIEW));
        break;
      case 'user-information':
        dispatch(setPreviewScreen(PreviewScreen.USER_INFO));
        break;
      case 'thankyou':
      case 'share':
        dispatch(setPreviewScreen(PreviewScreen.SUCCESS));
        break;

      case 'rewards':
        dispatch(setPreviewScreen(PreviewScreen.REWARD));
        break;

      default:
        break;
    }
  };

  return switchPreviewScreen;
}
