import { unwrapResult } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FaGlobe, FaSpinner } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { axiosPrivate } from '../../../../services/axiosPrivate';
import Button from '../../../../shared/components/Button';
import Preloader from '../../../../shared/components/Preloader';
import ReviewsJetCrownLogo from '../../../../shared/components/ReviewsJetCrownLogo';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import { useNotification } from '../../../../shared/hooks/useNotification';
import { Loading } from '../../../../shared/types/review.types';
import removeTrailingSlash from '../../../../shared/utils/removeTrailingSlash';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { AuthTitle } from '../../components';
import ErrorPage from '../../components/error-page';
import { listWebsites } from '../../redux/actions';
import { WebflowWebsite } from '../../redux/webflow.slice';

/* eslint no-console: "warn" */

const StyledFlexContainer = styled(FlexContainer)`
  border-top: 1px solid #eeeeee;
  padding: 10px 0;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 20px 40px;
  max-width: 500px;
  max-height: 90%;
  overflow: auto;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  @media (max-width: 530px) {
    max-width: 100%;
    height: 100%;
    border-radius: 0px;
    border: 0;
    box-shadow: none;
  }

  input {
    border-radius: 25px;
  }

  .forgot-password {
    color: #8b8e99;
    /* text-decoration: underline !important;
    text-underline-offset: 4px; */
  }
`;

const StyledButton = styled(Button)`
  background-color: #000;
  color: #fff;
  border-radius: 25px;
  width: 100%;
  border-color: #000;
`;

const Intro = styled.p`
  margin-bottom: 30px;
  color: #737373;
  font-size: 15px;
`;

interface CustomJwtPayload {
  id: string;
  email: string;
  accountId: string;
  platform: string;
  siteId: string;
  fullName: string;
}

const SiteItem = styled.div<{ isSubmitting?: boolean }>`
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 12px;
  cursor: ${(props) => (props.isSubmitting ? 'not-allowed' : 'pointer')};
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 12px;
  opacity: ${(props) => (props.isSubmitting ? 0.7 : 1)};

  &:hover {
    background-color: ${(props) =>
      props.isSubmitting ? 'inherit' : '#f9f9f9'};
    transform: ${(props) => (props.isSubmitting ? 'none' : 'translateY(-2px)')};
    box-shadow: ${(props) =>
      props.isSubmitting ? 'none' : '0 4px 6px rgba(0, 0, 0, 0.05)'};
  }
`;

const SiteIcon = styled.div`
  color: #4a90e2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  flex-shrink: 0;
`;

const SiteName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 280px;
`;

const SpinnerIcon = styled(FaSpinner)`
  animation: spin 1s linear infinite;
  margin-left: 10px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SelectSitePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    sites: { items: webflowSites, loading },
  } = useAppSelector((state) => state.webflow);
  const [userData, setUserData] = useState<CustomJwtPayload | null>(null);
  const [submitting, setSubmitting] = useState(Loading.INITIAL);
  const { showErrorToast, showSuccessToast } = useNotification();
  const [submittingSiteId, setSubmittingSiteId] = useState<string | null>(null);

  const { token } = useParams<{ token: string }>();

  const getSites = (userData: CustomJwtPayload) => {
    try {
      const userId = userData.id;

      dispatch(listWebsites(userId))
        .then(unwrapResult)
        .catch(() => {});
    } catch (error) {}
  };

  useEffect(() => {
    if (userData) {
      getSites(userData);
    }
  }, [userData]);

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode<CustomJwtPayload>(token);

      const { email, platform, id, accountId, siteId, fullName } = decoded;

      setUserData({ email, platform, id, accountId, siteId, fullName });
    }
  }, [token]);

  const handleSiteSelect = async (site: WebflowWebsite) => {
    try {
      if (userData && submitting !== Loading.PENDING) {
        const { id, email, fullName } = userData;

        setSubmitting(Loading.PENDING);
        setSubmittingSiteId(site.id);

        const response = await axiosPrivate.post(
          `${removeTrailingSlash(
            process.env.REACT_APP_REVIEWS_JET_API!
          )}/webflow/select-site`,
          {
            id,
            email,
            fullName,
            webflowSiteId: site.id,
            siteDisplayName: site.displayName,
          }
        );

        const { data } = response;
        const { token } = data;

        setSubmitting(Loading.SUCCESS);
        setSubmittingSiteId(null);

        navigate(`/webflow/${token}`);
      }
    } catch (error) {
      setSubmitting(Loading.ERROR);
      setSubmittingSiteId(null);
      showErrorToast('Failed to select site');
    }
  };

  if (loading === Loading.PENDING) {
    return <Preloader />;
  }
  if (loading === Loading.ERROR) {
    return <ErrorPage onRetryClick={() => userData && getSites(userData)} />;
  }

  return (
    <>
      <Helmet>
        <title>Select Your Webflow Site | ReviewsJet Installation</title>
        <meta
          name="description"
          content="Choose which Webflow site you want to install ReviewsJet on. Collect, manage and display your reviews seamlessly with our powerful review management platform."
        />
      </Helmet>

      <Wrapper>
        <a href={process.env.REACT_APP_REVIEWSJET_WEBSITE!}>
          <ReviewsJetCrownLogo />
        </a>
        <AuthTitle marginBottom="10px">Select Your Webflow Site</AuthTitle>
        <Intro>
          Choose which site you'd like to install ReviewsJet on to start
          collecting and showcasing your reviews.
        </Intro>

        {webflowSites.map((site) => (
          <SiteItem
            key={site.id}
            onClick={() =>
              submitting !== Loading.PENDING && handleSiteSelect(site)
            }
            isSubmitting={
              submitting === Loading.PENDING && submittingSiteId === site.id
            }
          >
            <SiteIcon>
              <FaGlobe />
            </SiteIcon>
            <SiteName>{site.displayName}</SiteName>
            {submitting === Loading.PENDING && submittingSiteId === site.id && (
              <SpinnerIcon />
            )}
          </SiteItem>
        ))}
      </Wrapper>
    </>
  );
};

export default SelectSitePage;
