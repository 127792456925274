import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Preloader = ({ size = 134 }: { size?: number }) => {
  return (
    <Wrapper>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        style={{ margin: 'auto', display: 'block' }}
      >
        <circle
          cx="50"
          cy="50"
          r="0"
          fill="none"
          stroke="#85a2b6"
          strokeWidth="2"
        >
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="1.4925373134328357s"
            values="0;40"
            keyTimes="0;1"
            keySplines="0 0.2 0.8 1"
            calcMode="spline"
            begin="0s"
          />
          <animate
            attributeName="opacity"
            repeatCount="indefinite"
            dur="1.4925373134328357s"
            values="1;0"
            keyTimes="0;1"
            keySplines="0.2 0 0.8 1"
            calcMode="spline"
            begin="0s"
          />
        </circle>
        <circle
          cx="50"
          cy="50"
          r="0"
          fill="none"
          stroke="#bbcedd"
          strokeWidth="2"
        >
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="1.4925373134328357s"
            values="0;40"
            keyTimes="0;1"
            keySplines="0 0.2 0.8 1"
            calcMode="spline"
            begin="-0.7462686567164178s"
          />
          <animate
            attributeName="opacity"
            repeatCount="indefinite"
            dur="1.4925373134328357s"
            values="1;0"
            keyTimes="0;1"
            keySplines="0.2 0 0.8 1"
            calcMode="spline"
            begin="-0.7462686567164178s"
          />
        </circle>
      </svg>
    </Wrapper>
  );
};

export default Preloader;
