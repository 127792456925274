import { useCallback } from 'react';
import { FaMailchimp } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { axiosPrivate } from '../../../../services/axiosPrivate';
import AppModal from '../../../../shared/components/modal';
import useModal from '../../../../shared/components/modal/useModal';
import usePolling from '../../../../shared/hooks/usePolling';
import removeTrailingSlash from '../../../../shared/utils/removeTrailingSlash';
import { useAppSelector } from '../../../../store';
import AddIntegrationModal from '../../../settings/components/Integrations/components/AddIntegrationModal';
import { isMailChimpConnectedSelector } from '../../../settings/components/Integrations/redux/integrations.slice';
import { accountSelector } from '../../../userData/redux/userData.slice';
import { PlatformButton } from '../MarketingPlatforms';

interface MailchimpPlatformProps {
  onClose: () => void;
}

export default function MailchimpPlatform({ onClose }: MailchimpPlatformProps) {
  const navigate = useNavigate();
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { _id: siteId } = selectedSite ?? {};
  const isMailChimpConnected = useAppSelector(isMailChimpConnectedSelector);
  const { handleCloseModal, handleOpenModal, showModal } = useModal();

  const { setIntervalSeconds, setIsEnabled } = usePolling(
    useCallback(async () => {
      let url = `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/mailchimp/check-installation?siteId=${siteId}`;

      const { data } = await axiosPrivate.get(url);

      if (data === 'success') {
        setIsEnabled(false);
        navigate('/share-form?source=mailchimp');
      }
    }, [siteId])
  );

  const openWindow = () => {
    const width = 500;
    const height = 600;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const left = (screenWidth - width) / 2;
    const top = (screenHeight - height) / 2;

    setIsEnabled(true);
    setIntervalSeconds(2);

    let url = `${removeTrailingSlash(
      process.env.REACT_APP_REVIEWS_JET_API!
    )}/mailchimp/connect?siteId=${siteId}`;

    window.open(
      url,
      '_blank',
      `width=${width},height=${height},top=${top},left=${left}`
    );
  };

  return (
    <>
      <PlatformButton
        onClick={() => {
          if (isMailChimpConnected) {
            navigate('/share-form?source=mailchimp');
          } else {
            handleOpenModal();
          }
        }}
      >
        <FaMailchimp size={24} />
        Invite MailChimp contacts
      </PlatformButton>

      <AppModal
        size="sm"
        shouldOpenModal={showModal}
        onModalClose={handleCloseModal}
        body={
          <AddIntegrationModal
            onClose={handleCloseModal}
            onConnect={openWindow}
            logo={<FaMailchimp size={150} />}
            integration="MailChimp"
          />
        }
      />
    </>
  );
}
