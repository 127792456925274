const getLuminance = (hexColor: string) => {
  try {
    // Handle different color string formats
    const hex = hexColor.charAt(0) === '#' ? hexColor.substring(1) : hexColor;

    // Ensure we have a valid 6-character hex
    if (!/^[0-9A-Fa-f]{6}$/.test(hex)) {
      return 0.5; // fallback to middle value if invalid hex
    }

    // Convert hex to RGB
    const bigint = parseInt(hex, 16);
    const r = ((bigint >> 16) & 255) / 255;
    const g = ((bigint >> 8) & 255) / 255;
    const b = (bigint & 255) / 255;

    // Convert RGB to linear values before calculating luminance
    const linearR =
      r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
    const linearG =
      g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
    const linearB =
      b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

    // Calculate relative luminance using WCAG formula
    return 0.2126 * linearR + 0.7152 * linearG + 0.0722 * linearB;
  } catch (error) {
    console.error('Error calculating luminance:', error);
    return 0.5; // fallback to middle value
  }
};

export default getLuminance;
