import React from 'react';
import { AiFillHome } from 'react-icons/ai';
import { BsBuilding } from 'react-icons/bs';
import { FaAmazon, FaApple, FaGoogle, FaYelp } from 'react-icons/fa';
import { MdPlayCircle } from 'react-icons/md';
import { SiProducthunt, SiTripadvisor, SiTrustpilot } from 'react-icons/si';
import styled from 'styled-components';
import MarkdownPreview from '../../../../../../shared/components/MarkdownPreview';
import { useAppSelector } from '../../../../../../store';
import { IntegrationPlatform } from '../../../../../socialReviews/redux/social-reviews.slice';
import { selectedSiteSelector } from '../../../../../userData/redux/userData.slice';
import {
  reviewFormSelector,
  ThirdPartyAccount,
} from '../../../../redux/review-form.slice';
import FormButton from '../../FormButton';

/* eslint no-console: "warn" */

const Title = styled.h3`
  text-align: left;
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 10px;
  letter-spacing: -1px;
  font-weight: 700;
  font-optical-sizing: auto;
  font-style: normal;
  color: #55595f;
  font-family: 'Ubuntu Sans', sans-serif;
`;

const Wrapper = styled.div`
  ul {
    margin-top: 15px;
    padding-left: 20px;
    list-style: disc;
    margin-bottom: 20px;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  background: #f3f4f6;
  border-radius: 12px;
  margin: 24px 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #e5e7eb;

    .play-button {
      transform: scale(1.1);
    }
  }
`;

const VideoPlaceholder = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #6b7280;
  gap: 12px;
`;

const PlayButton = styled(MdPlayCircle)`
  font-size: 48px;
  color: #3b82f6;
  transition: transform 0.2s ease;
`;

const VideoText = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
`;

const WelcomeText = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
`;

// Styled components for the external sites list
const ExternalSitesList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
`;

const ExternalSiteItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 16px;
  border-radius: 8px;
  background-color: #f8f9fa;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    background-color: #f1f5f9;
  }
`;

const SiteLogo = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 6px;
  overflow: hidden;
  margin-right: 12px;
  flex-shrink: 0;
`;

const SiteName = styled.div`
  font-size: 14px;
  color: #4b5563;
  font-weight: 500;
  flex-grow: 1;
`;

const SiteArrow = styled.div`
  color: #9ca3af;
  font-size: 16px;
  margin-left: 8px;
`;

// Platform logo mapping

// Function to get the logo component based on platform
const PlatformLogo = ({
  platform,
}: {
  platform: IntegrationPlatform;
}): React.ReactElement => {
  const iconStyle = {
    width: '100%',
    height: '100%',
    color: getPlatformColor(platform),
  };

  switch (platform) {
    case IntegrationPlatform.GOOGLE_MAPS_REVIEWS:
      return <FaGoogle style={iconStyle} />;
    case IntegrationPlatform.YELP_REVIEWS:
      return <FaYelp style={{ ...iconStyle, color: '#d32323' }} />;
    case IntegrationPlatform.APPLE_REVIEWS:
      return <FaApple style={{ ...iconStyle, color: '#555555' }} />;
    case IntegrationPlatform.PRODUCTHUNT_REVIEWS:
      return <SiProducthunt style={{ ...iconStyle, color: '#da552f' }} />;
    case IntegrationPlatform.TRUSTPILOT_REVIEWS:
      return <SiTrustpilot style={{ ...iconStyle, color: '#00b67a' }} />;
    case IntegrationPlatform.CAPTERRA_REVIEWS:
      return <BsBuilding style={{ ...iconStyle, color: '#2563eb' }} />;
    case IntegrationPlatform.TRIPADVISOR_REVIEWS:
      return <SiTripadvisor style={{ ...iconStyle, color: '#00aa6c' }} />;
    case IntegrationPlatform.AIRBNB_REVIEWS:
      return <AiFillHome style={{ ...iconStyle, color: '#ff385c' }} />;
    case IntegrationPlatform.AMAZON_REVIEWS:
      return <FaAmazon style={{ ...iconStyle, color: '#ff9900' }} />;
    default:
      return (
        <div
          style={{
            ...iconStyle,
            backgroundColor: '#e5e7eb',
            borderRadius: '4px',
          }}
        ></div>
      );
  }
};

// Helper function to get brand colors
const getPlatformColor = (platform: IntegrationPlatform): string => {
  const colorMap = {
    [IntegrationPlatform.GOOGLE_MAPS_REVIEWS]: '#4285F4',
    [IntegrationPlatform.YELP_REVIEWS]: '#d32323',
    [IntegrationPlatform.APPLE_REVIEWS]: '#555555',
    [IntegrationPlatform.PRODUCTHUNT_REVIEWS]: '#da552f',
    [IntegrationPlatform.TRUSTPILOT_REVIEWS]: '#00b67a',
    [IntegrationPlatform.CAPTERRA_REVIEWS]: '#2563eb',
    [IntegrationPlatform.TRIPADVISOR_REVIEWS]: '#00aa6c',
    [IntegrationPlatform.AIRBNB_REVIEWS]: '#ff385c',
    [IntegrationPlatform.AMAZON_REVIEWS]: '#ff9900',
  };

  return colorMap[platform] || '#9ca3af';
};

const getExternalSiteUrl = (site: ThirdPartyAccount) => {
  switch (site.platform) {
    case IntegrationPlatform.GOOGLE_MAPS_REVIEWS: {
      return `https://search.google.com/local/writereview?placeid=${site.placeId}`;
    }

    case IntegrationPlatform.YELP_REVIEWS:
      return `https://www.yelp.com/writeareview/biz/${site.placeId}`;

    case IntegrationPlatform.PRODUCTHUNT_REVIEWS:
      return `https://www.producthunt.com/products/${site.pageId}/reviews/new`;

    case IntegrationPlatform.TRIPADVISOR_REVIEWS: {
      const [_, first, second] = site.pageId.split('-');

      return `https://www.tripadvisor.com/UserReviewEdit-${first}-${second}`;
    }

    case IntegrationPlatform.TRUSTPILOT_REVIEWS:
      return `https://www.trustpilot.com/evaluate/${site.pageId}`;

    default:
      break;
  }
};

// Add a styled divider component
const Divider = styled.div`
  width: 40px;
  height: 4px;
  background-color: ${(props) => props.color || '#e5e7eb'};
  border-radius: 2px;
  margin: 24px auto 20px;
  opacity: 0.6;
`;

// Add a styled component for the external link prompt
const ExternalLinkPrompt = styled.p`
  margin-top: 12px;
  margin-bottom: 16px;
  color: #4b5563;
  font-size: 14px;
  text-align: left;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.4;
`;

export default function Welcome() {
  const { reviewFormSettings, localThirdPartyAccounts } =
    useAppSelector(reviewFormSelector);
  const selectedSite = useAppSelector(selectedSiteSelector);
  const { plan } = selectedSite ?? {};
  const { appearance, welcomeScreen, translation } = reviewFormSettings ?? {};
  const { primaryColor = '#000' } = appearance ?? {};
  const { title, message, collectWithExternalSites } = welcomeScreen ?? {};
  const { writeReview } = translation ?? {};

  // Filter accounts that have collectReviews set to true
  const activeExternalSites =
    localThirdPartyAccounts?.filter((account) => account.collectReviews) || [];

  const handleClick = (site: ThirdPartyAccount) => {
    window.open(getExternalSiteUrl(site), '_blank');
  };

  return (
    <Wrapper>
      {/* <VideoContainer>
        <VideoPlaceholder>
          <PlayButton className="play-button" />
          <VideoText>Watch our welcome message</VideoText>
        </VideoPlaceholder>
      </VideoContainer> */}

      <Title>{title}</Title>

      {collectWithExternalSites && activeExternalSites.length > 0 ? (
        <>
          <ExternalLinkPrompt>
            {welcomeScreen?.externalLinkPrompt ||
              'Select where to leave your review:'}
          </ExternalLinkPrompt>

          <ExternalSitesList>
            {activeExternalSites.map((site) => (
              <ExternalSiteItem key={site.id} onClick={() => handleClick(site)}>
                <SiteLogo>
                  <PlatformLogo platform={site.platform} />
                </SiteLogo>
                <SiteName>{site.name}</SiteName>
                <SiteArrow>→</SiteArrow>
              </ExternalSiteItem>
            ))}
          </ExternalSitesList>
        </>
      ) : (
        <>
          <MarkdownPreview message={message ?? ''} />
          <FormButton $color={primaryColor}>{writeReview}</FormButton>
        </>
      )}
    </Wrapper>
  );
}
