import { Toggle } from 'rsuite';

import { Label } from '../../../../../shared/components/Label';
import { SubscriptionPlan } from '../../../../../shared/types/review.types';
import { useAppDispatch } from '../../../../../store';
import { toggleUpgradeModal } from '../../../../../store/app.slice';
import FormRow from '../../../../widgetSettings/components/form-row';

export default function BrandCustomization() {
  const dispatch = useAppDispatch();

  return (
    <>
      <FormRow gap="10px" justifyContent="flex-start">
        <Toggle
          // size="lg"
          checked={true}
          // @ts-ignore
          onChange={() => {
            dispatch(toggleUpgradeModal(SubscriptionPlan.ADVANCED));
          }}
          checkedChildren="Yes"
          unCheckedChildren="No"
        />
        <Label margin="0">Show Powered by ReviewsJet</Label>
      </FormRow>
    </>
  );
}
