import { FaArrowUpRightFromSquare } from 'react-icons/fa6';
import LogoCrown from '../../../../../shared/components/LogoCrown';

export default function ReviewsJetBranding() {
  const handleHover = (e: React.SyntheticEvent) => {
    const target = e.currentTarget as HTMLElement;
    target.style.backgroundColor = '#e5e7eb';
    target.style.color = '#111827';
    target.style.boxShadow = '0 4px 6px rgba(0,0,0,0.1)';
    target.style.transform = 'scale(1.05)';
  };

  const handleLeave = (e: React.SyntheticEvent) => {
    const target = e.currentTarget as HTMLElement;
    target.style.backgroundColor = '#f3f4f6';
    target.style.color = '#1f2937';
    target.style.boxShadow = '0 1px 3px rgba(0,0,0,0.1)';
    target.style.transform = 'scale(1)';
  };

  return (
    <div
      style={{
        position: 'relative',
        marginTop: 20,
        marginBottom: 10,
      }}
    >
      <div
        style={{
          width: 'fit-content',
          margin: 'auto',
          display: 'block',
          background: 'transparent',
          zIndex: 99999999,
        }}
      >
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            padding: '8px 16px',
            marginTop: '8px',
            backgroundColor: '#f3f4f6',
            color: '#1f2937',
            borderRadius: '9999px',
            fontSize: '14px',
            fontWeight: 'bold',
            border: '1px solid #fff',
            transition: 'transform 0.3s ease',
            width: 'fit-content',
            margin: 'auto',
            cursor: 'pointer',
          }}
          onMouseOver={handleHover}
          onFocus={handleHover}
          onMouseOut={handleLeave}
          onBlur={handleLeave}
          tabIndex={0}
          role="button"
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '24px',
              height: '24px',
              backgroundColor: '#fff',
              color: '#1f2937',
              borderRadius: '50%',
              marginRight: '8px',
              overflow: 'hidden',
            }}
          >
            <LogoCrown />
          </div>
          <span style={{ marginRight: '8px' }}>
            Collect reviews with ReviewsJet
          </span>
          <FaArrowUpRightFromSquare />
        </div>
      </div>
    </div>
  );
}
