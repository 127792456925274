import styled from 'styled-components';
import getLuminance from '../../../../shared/utils/getLuminance';

const Button = styled.button<{ $color: string }>`
  width: 100%;
  background-color: ${(props) => props.$color};
  color: ${(props) => (getLuminance(props.$color) > 0.5 ? '#000' : '#fff')};
  padding: 12px 0;
  font-size: 14px;
  border-radius: 6px;
  font-weight: 600;
  font-family: 'Ubuntu Sans', sans-serif;
`;

export default function FormButton({
  children,
  $color,
}: {
  children: React.ReactNode;
  $color: string;
}) {
  return <Button $color={$color}>{children}</Button>;
}
