import { TbPhoto } from 'react-icons/tb';
import { Input, Uploader } from 'rsuite';
import styled from 'styled-components';
import { Label } from '../../../../../../shared/components/Label';
import MarkdownPreview from '../../../../../../shared/components/MarkdownPreview';
import { useAppSelector } from '../../../../../../store';
import FormRow from '../../../../../widgetSettings/components/form-row';
import { reviewFormSelector } from '../../../../redux/review-form.slice';
import FormButton from '../../FormButton';

const Title = styled.h3`
  text-align: left;
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 10px;
  letter-spacing: -1px;
  font-weight: 700;
  font-optical-sizing: auto;
  font-style: normal;
  color: #55595f;
  font-family: 'Ubuntu Sans', sans-serif;
`;

const Wrapper = styled.div`
  ul {
    margin-top: 15px;
    padding-left: 20px;
    list-style: disc;
    margin-bottom: 20px;
  }

  .review-textarea {
    border: none;
    resize: none;
    font-size: 14px;
    background-color: #f5f5f5;

    &::placeholder {
      color: #8a8a8a;
    }
  }
`;

const StyledInput = styled(Input)`
  border: none;
  font-size: 14px;
  background-color: #f5f5f5;

  &::placeholder {
    color: #8a8a8a;
  }
`;

const StyledLabel = styled(Label)`
  b {
    font-size: 15px;
    font-weight: 600;
  }
`;

const UploadPrompt = styled.button`
  font-size: 14px;
  color: #707d86;
  font-weight: 500;
  display: block;
  cursor: pointer;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 10px;
  border: none;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #e0e0e0;
  }

  &:focus-visible {
    outline: 2px solid #3b82f6;
    outline-offset: 2px;
  }
`;

const UploaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

const AvatarButton = styled.button`
  width: 64px;
  height: 64px;
  background: #dddddd;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: background-color 0.2s ease;
  border-radius: 50% !important;
  &:hover {
    background-color: #e0e0e0;
  }

  &:focus-visible {
    outline: 2px solid #3b82f6;
    outline-offset: 2px;
  }
`;

export default function UserInformation() {
  const { reviewFormSettings } = useAppSelector(reviewFormSelector);
  const { userInfo, translation, appearance } = reviewFormSettings ?? {};
  const { primaryColor = '#000' } = appearance ?? {};
  const {
    yourNameLabel,
    yourEmailLabel,
    submitButtonLabel,
    profilePictureLabel,
    yourNamePlaceholder,
    yourEmailPlaceholder,
    emailAddressPrivacyNote,
    clickToUploadButtonLabel,
  } = translation ?? {};

  const { title, description } = userInfo ?? {};

  return (
    <Wrapper>
      <Title>{title}</Title>
      <MarkdownPreview message={description ?? ''} />

      <FormRow marginTop="20px">
        <Label>{yourNameLabel}</Label>
        <StyledInput size="lg" placeholder={yourNamePlaceholder} />
      </FormRow>

      <FormRow>
        <Label>{yourEmailLabel}</Label>
        <StyledInput size="lg" placeholder={yourEmailPlaceholder} />
        {emailAddressPrivacyNote && (
          <small
            style={{
              marginBottom: 15,
              color: '#707d86',
              fontSize: 13,
              marginTop: 15,
            }}
          >
            <sup style={{ fontSize: 15 }}>*</sup>
            {emailAddressPrivacyNote}
          </small>
        )}
      </FormRow>

      <FormRow width="100%">
        <StyledLabel>
          <b>{profilePictureLabel}</b>
        </StyledLabel>

        <UploaderContainer>
          <Uploader
            action=""
            name="reviewerAvatar"
            autoUpload={false}
            fileListVisible={false}
            listType="picture"
            appearance="subtle"
            multiple={false}
            accept=".jpg,.jpeg,.png,.gif"
            maxPreviewFileSize={10 * 1024 * 1024}
          >
            <AvatarButton id="avatar-uploader" type="button">
              <TbPhoto size={26} />
            </AvatarButton>
          </Uploader>

          <UploadPrompt
            type="button"
            onClick={() => {
              document.getElementById('avatar-uploader')?.click();
            }}
          >
            {clickToUploadButtonLabel}
          </UploadPrompt>
        </UploaderContainer>
      </FormRow>

      <FormButton $color={primaryColor}>{submitButtonLabel}</FormButton>
    </Wrapper>
  );
}
