import _ from 'lodash';
import { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { getStats } from '../../features/emails/redux/action';
import { emailStatsSelector } from '../../features/emails/redux/emails.slice';
import { getReviewsUsage } from '../../features/manage-reviews/redux/action';
import { reviewsUsageSelector } from '../../features/manage-reviews/redux/reviews.slice';
import { get3rdPartyAccount } from '../../features/socialReviews/redux/actions';
import { socialReviewsSelector } from '../../features/socialReviews/redux/social-reviews.slice';
import {
  seatsSelector,
  userCreditSelector,
} from '../../features/userData/redux/userData.slice';
import { useAppDispatch, useAppSelector } from '../../store';
import { Loading } from '../types/review.types';

/* eslint no-console: "warn" */

export default function useUsage() {
  const dispatch = useAppDispatch();

  const {
    totalReviewRequestsCredit,
    totalTextReviewsCredit,
    totalVideoReviewsCredit,
    totalIntegrationsCredit,
  } = useAppSelector(userCreditSelector);
  const totalSeats = useAppSelector(seatsSelector);
  const [textReviewsPerc, setTextReviewsPerc] = useState(0);
  const [videoReviewsPerc, setVideoReviewsPerc] = useState(0);
  const [reviewRequestsPerc, setReviewRequestsPerc] = useState(0);
  const [integrationsPerc, setIntegrationsPerc] = useState(0);
  const {
    textReviewsPublished,
    videoReviewsPublished,
    loading: loadingReviewsUsage,
  } = useAppSelector(reviewsUsageSelector);
  const {
    stats: { totalSentThisMonth },
  } = useAppSelector(emailStatsSelector);

  const {
    get3rdPartyAccount: { data: integrations, loading: loadingIntegrations },
  } = useAppSelector(socialReviewsSelector);

  useEffect(() => {
    if (loadingReviewsUsage === Loading.SUCCESS) {
      const textReviewsPercentage =
        typeof totalTextReviewsCredit === 'number'
          ? (textReviewsPublished * 100) / totalTextReviewsCredit
          : 0;

      const videoReviewsPercentage =
        typeof totalVideoReviewsCredit === 'number'
          ? (videoReviewsPublished * 100) / totalVideoReviewsCredit
          : 0;

      const emailSentPercentage =
        typeof totalReviewRequestsCredit === 'number'
          ? (totalSentThisMonth * 100) / totalReviewRequestsCredit
          : 0;

      setReviewRequestsPerc(emailSentPercentage);
      setTextReviewsPerc(textReviewsPercentage);
      setVideoReviewsPerc(videoReviewsPercentage);
    }
  }, [
    textReviewsPublished,
    videoReviewsPublished,
    totalTextReviewsCredit,
    loadingReviewsUsage,
    totalSentThisMonth,
    totalReviewRequestsCredit,
  ]);

  useEffect(() => {
    if (loadingIntegrations === Loading.SUCCESS) {
      const percentage =
        typeof totalIntegrationsCredit === 'number'
          ? (_.size(integrations) * 100) / totalIntegrationsCredit
          : 0;

      setIntegrationsPerc(percentage);
    }
  }, [integrations, totalIntegrationsCredit, loadingIntegrations]);

  useEffect(() => {
    batch(() => {
      // dispatch(getSite({ showPreloader: true }));
      dispatch(getReviewsUsage());
      dispatch(get3rdPartyAccount());
      dispatch(getStats());
    });
  }, []);

  return {
    textReviewsPerc,
    videoReviewsPerc,
    reviewRequestsPerc,
    integrationsPerc,
  };
}
