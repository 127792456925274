import Plan from './Plan';

const Account = () => {
  return (
    <>
      <Plan />
      {/* <AccountInfo /> */}
    </>
  );
};

export default Account;
