import deepEqual from 'deep-equal';
import React from 'react';
import styled from 'styled-components';
import useSaveSettings from './hooks/useSaveSettings';

/* eslint no-console: "warn" */

const StyledButton = styled.button<{ $visible?: boolean }>`
  position: fixed;
  bottom: 24px;
  left: 24px;
  width: 350px;
  padding: 12px 24px;
  background: #000;
  color: white;
  border: none;
  font-weight: 600;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 8px 16px -4px rgb(0 0 0 / 0.1), 0 4px 6px -2px rgb(0 0 0 / 0.1);
  z-index: 10;
  opacity: 1; /* Always visible */
  transform: translateY(0); /* Always positioned correctly */
  pointer-events: auto; /* Always clickable */
  border: 2px solid transparent;

  &:hover {
    background: #ffffff;
    border-color: #000000;
    color: #050505;
    transform: translateY(-1px);
    box-shadow: 0 12px 20px -6px rgb(0 0 0 / 0.1),
      0 4px 8px -4px rgb(0 0 0 / 0.1);
  }

  &:active {
    transform: translateY(0);
  }

  &:disabled {
    background: #94a3b8;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

// Function to find differences between two objects
function findDifferences(current: any, saved: any, path = ''): string[] {
  // Parse saved settings if it's a string
  const parsedSaved = typeof saved === 'string' ? JSON.parse(saved) : saved;

  if (!current || !parsedSaved) {
    return current !== parsedSaved ? [path] : [];
  }

  if (typeof current !== 'object' || typeof parsedSaved !== 'object') {
    return current !== parsedSaved ? [path] : [];
  }

  const allKeys = new Set([
    ...Object.keys(current),
    ...Object.keys(parsedSaved),
  ]) as Set<string>;
  const differences: string[] = [];

  for (const key of Array.from(allKeys)) {
    const currentPath = path ? `${path}.${key}` : key;

    if (!(key in current)) {
      differences.push(`${currentPath} (removed)`);
    } else if (!(key in parsedSaved)) {
      differences.push(`${currentPath} (added)`);
    } else if (
      typeof current[key] === 'object' &&
      current[key] !== null &&
      typeof parsedSaved[key] === 'object' &&
      parsedSaved[key] !== null
    ) {
      differences.push(
        ...findDifferences(current[key], parsedSaved[key], currentPath)
      );
    } else if (!deepEqual(current[key], parsedSaved[key])) {
      differences.push(
        `${currentPath} (changed: ${JSON.stringify(
          parsedSaved[key]
        )} → ${JSON.stringify(current[key])})`
      );
    }
  }

  return differences;
}

// Create a context to share the markThirdPartyAccountsChanged function
export const ReviewFormContext = React.createContext({
  markThirdPartyAccountsChanged: () => {},
});

export default function SaveButton() {
  const { saveSettings, isSaving } = useSaveSettings();

  return (
    <>
      <StyledButton onClick={() => saveSettings(true)} disabled={isSaving}>
        {isSaving ? 'Saving...' : 'Save Changes'}
      </StyledButton>
    </>
  );
}
