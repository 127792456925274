import { FaDotCircle } from 'react-icons/fa';
import { FaRegCircle } from 'react-icons/fa6';
import styled, { css } from 'styled-components';
import { accountSelector } from '../../../../features/userData/redux/userData.slice';
import { useAppSelector } from '../../../../store';
import { PlanCycle } from '../../../types/review.types';
import Button from '../../Button';
import FlexContainer from '../../layouts/flex-container';

const PriceCard = styled.div<{
  $isSelectedPlan: boolean;
  $isBasicPlan?: boolean;
  $inModal: boolean;
}>`
  min-width: 200px;
  display: flex;
  padding: 10px;
  position: relative;
  border-radius: 6px;
  border-width: 1px;
  min-height: 90px;
  border: 1px solid
    ${({ $isSelectedPlan, $inModal }) => {
      if (!$inModal) {
        return '#f4f2f2';
      }
      return !!$isSelectedPlan ? '#5069c8' : '#f4f2f2';
    }};
  align-items: flex-start !important;

  ${({ $isBasicPlan, $inModal }) =>
    !$isBasicPlan &&
    $inModal &&
    css`
      cursor: pointer;
      &:hover {
        border-width: 1px !important;
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
      }
    `}
  ${({ $isSelectedPlan }) =>
    $isSelectedPlan &&
    css`
      /* box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1); */
    `}
  
  .price-name {
    font-size: 15px;
    margin-bottom: 0;
  }

  @media (max-width: 690px) {
    width: 100%;
  }
  .selector {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;

const StyledButton = styled(Button)<{ $planName: string }>`
  width: 100%;
  margin-top: 10px;
  ${({ $planName }) =>
    $planName === 'Basic' &&
    css`
      visibility: hidden;
    `}
`;

type Props = {
  price: string;
  isBasicPlan?: boolean;
  cycle?: PlanCycle;
  planName: 'Basic' | 'Pro' | 'Advanced';
  unit: 'month' | 'year';
  isCurrentPlan: boolean;
  isSelectedPlan: boolean;
  monthlyPrice?: string;
  yearlyPrice?: string;
  selectPlan: (plan: 'Basic' | 'Pro' | 'Advanced') => void;
  inModal?: boolean;
  onUpgrade?: () => void;
  isProcessing?: boolean;
};

export default function PlanCard({
  price,
  unit,
  cycle,
  planName,
  isSelectedPlan,
  selectPlan,
  isBasicPlan,
  inModal = true,
  onUpgrade,
  isProcessing,
}: Props) {
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { plan, stripe } = selectedSite ?? {};
  const { subscriptionId } = stripe ?? {};

  return (
    <FlexContainer stack gap="5px">
      <PriceCard
        $isBasicPlan={isBasicPlan}
        $isSelectedPlan={isSelectedPlan}
        role="presentation"
        onClick={() => {
          if (!isBasicPlan) {
            selectPlan(planName);
          }
        }}
        $inModal={inModal}
      >
        {!isBasicPlan && inModal && (
          <>
            {isSelectedPlan ? (
              <FaDotCircle className="selector" color="#5069c8" />
            ) : (
              <FaRegCircle className="selector" />
            )}
          </>
        )}

        <FlexContainer
          stack
          gap="5px"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <p className="price-name">{planName}</p>

          <FlexContainer justifyContent="flex-start" gap="5px">
            <span className="price">${price}</span>
            {planName !== 'Basic' && <span className="per-month">/{unit}</span>}
          </FlexContainer>
          {planName !== 'Basic' && (
            <span className="billed-annually">
              Billed {cycle === PlanCycle.YEARLY ? 'annually' : 'monthly'}
            </span>
          )}
        </FlexContainer>
      </PriceCard>
      {!inModal && (
        <StyledButton
          size="large"
          onClick={onUpgrade}
          disabled={isProcessing}
          $planName={planName}
        >
          {isProcessing
            ? 'Please wait...'
            : subscriptionId
            ? 'Change plan'
            : ' Start trial'}
        </StyledButton>
      )}
    </FlexContainer>
  );
}
