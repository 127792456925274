import styled from 'styled-components';

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background: white;
  border: 1px solid #e2e8f0;
  padding: 8px 16px;
  color: #64748b;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);

  svg {
    width: 15px;
    height: 15px;
    transition: transform 0.2s ease;
  }

  &:hover {
    border-color: #3b82f6;
    color: #3b82f6;
    background: #f8fafc;
    box-shadow: 0 4px 12px rgba(59, 130, 246, 0.08);
    transform: translateY(-1px);

    svg {
      transform: scale(1.1);
    }
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(59, 130, 246, 0.06);
  }
`;

type Props = {
  onClick: () => void;
  children: React.ReactNode;
};

export default function HeaderButton({ children, onClick }: Props) {
  return <Wrapper onClick={onClick}>{children}</Wrapper>;
}
