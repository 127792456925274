import { useState } from 'react';
import { useAppSelector } from '../../../store';
import { ReviewsJetPlatform } from '../../product/types';
import { accountSelector } from '../../userData/redux/userData.slice';

/* eslint no-console: "warn" */

export default function useCancelSubscription() {
  const [cancelPlatform, setCancelPlatform] =
    useState<ReviewsJetPlatform | null>(null);
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { platform } = selectedSite ?? {};

  const handleCancel = () => {
    if (platform === ReviewsJetPlatform.WIX) {
      return window.open(
        'https://support.wix.com/en/article/canceling-an-app-subscription',
        '_blank'
      );
    }

    if (platform === ReviewsJetPlatform.SHOPIFY) {
      setCancelPlatform(ReviewsJetPlatform.SHOPIFY);
    } else {
      setCancelPlatform(ReviewsJetPlatform.OTHERS);
    }
  };

  return { cancelPlatform, handleCancel };
}
