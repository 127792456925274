import styled from 'styled-components';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import { useAppSelector } from '../../../../../store';
import { siteWidgetSettingsSelector } from '../../../../widgetSettings/redux/widgetSettings.slice';

const Wrapper = styled.div`
  flex: 1;
  @media (max-width: 1200px) {
    padding-top: 0;
  }
`;

const ContentWrapper = styled(FlexContainer)`
  margin: 0 auto 100px;
  color: #55595f;
  border-radius: 5px;
  border: 1px solid #ededed;
  background-color: #fff;

  .preview-content {
    width: 400px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    align-items: center;
  }
  h3 {
    text-align: center;
  }

  p {
    color: #707d86;
    font-size: 15px;
    text-align: center;
    max-width: 350px;
  }
`;

const Image = styled.img<{ $borderRadius: number }>`
  max-height: 200px;
  max-width: 250px;
  border-radius: ${({ $borderRadius }) => $borderRadius}%;
  padding: 2px;
  border: 1px solid #cecece;
`;

export default function ThankYouPreview() {
  const { thankYouPage } = useAppSelector(siteWidgetSettingsSelector) ?? {};
  const {
    image,
    title,
    message,
    showImage,
    imageBorderRadius = 0,
    allowSocialShare,
  } = thankYouPage ?? {};

  return (
    <Wrapper>
      <ContentWrapper stack gap="10px">
        <div className="preview-content">
          {showImage && (
            <Image src={image} $borderRadius={imageBorderRadius} alt="" />
          )}
          <h3>{title}</h3>
          <p>{message}</p>

          {allowSocialShare && (
            <FlexContainer gap="10px">
              <img src="/images/socials.png" style={{ height: 50 }} alt="" />
            </FlexContainer>
          )}
        </div>
      </ContentWrapper>
    </Wrapper>
  );
}
