import styled from 'styled-components';
import Button from './Button';
import FlexContainer from './layouts/flex-container';

const Wrapper = styled(FlexContainer)`
  width: 100%;
  .title {
    font-size: 15px;
    margin: 0;
    line-height: 20px;
  }
  p {
    margin: 0 0 20px;
  }
`;

export default function ErrorComponent({ onClick }: { onClick: () => void }) {
  return (
    <Wrapper stack gap="10px">
      <h3 className="title">Oops! Something went wrong</h3>
      <p>Please try again by clicking the button below.</p>

      <Button rounded onClick={onClick}>
        Try again
      </Button>
    </Wrapper>
  );
}
