import { ReviewsJetPlatform } from '../../features/product/types';

type TypeOfInstallation = 'automated' | 'manual' | 'n/a';

type Functionality = {
  canAddManualReviews: boolean;
  canImportReviewsFrom3rdParty: boolean;
  canLogout: boolean;
  canBeReviewed: boolean;
  canEditReview: boolean;
  canConvertToProductReview: boolean;
  tabWidget: {
    installation: {
      type: TypeOfInstallation;
      instructionsUrl?: string;
    };
  };
  productWidget: {
    type: TypeOfInstallation;
  };
  supportProductReviews: boolean;
};

export type SiteConfig = {
  [key in ReviewsJetPlatform]?: Functionality;
};

const siteConfigs: SiteConfig = {
  wix: {
    canBeReviewed: true,
    canLogout: false,
    canEditReview: true,
    canConvertToProductReview: true,
    tabWidget: {
      installation: {
        type: 'automated',
        instructionsUrl: '',
      },
    },
    productWidget: {
      type: 'automated',
    },
    supportProductReviews: true,
    canAddManualReviews: true,
    canImportReviewsFrom3rdParty: true,
  },
  others: {
    canBeReviewed: false,
    canLogout: true,
    canEditReview: true,
    canConvertToProductReview: true,
    tabWidget: {
      installation: {
        type: 'manual',
        instructionsUrl: '',
      },
    },
    productWidget: {
      type: 'n/a',
    },
    supportProductReviews: false,
    canAddManualReviews: true,
    canImportReviewsFrom3rdParty: true,
  },
  shopify: {
    canBeReviewed: true,
    canLogout: true,
    canEditReview: false,
    canConvertToProductReview: false,
    tabWidget: {
      installation: {
        type: 'automated',
        instructionsUrl: '',
      },
    },
    productWidget: {
      type: 'automated',
    },
    supportProductReviews: true,
    canAddManualReviews: false,
    canImportReviewsFrom3rdParty: false,
  },
  webflow: {
    canBeReviewed: true,
    canLogout: true,
    canEditReview: true,
    canConvertToProductReview: true,
    tabWidget: {
      installation: {
        type: 'automated',
        instructionsUrl: '',
      },
    },
    productWidget: {
      type: 'automated',
    },
    supportProductReviews: true,
    canAddManualReviews: true,
    canImportReviewsFrom3rdParty: true,
  },
  woocommerce: {
    canBeReviewed: true,
    canLogout: true,
    canEditReview: false,
    canConvertToProductReview: false,
    tabWidget: {
      installation: {
        type: 'automated',
        instructionsUrl: '',
      },
    },
    productWidget: {
      type: 'automated',
    },
    supportProductReviews: true,
    canAddManualReviews: true,
    canImportReviewsFrom3rdParty: true,
  },
};

export default siteConfigs;
