import styled from 'styled-components';

const ResultTitle = styled.h3`
  margin: 0 0 8px;
  font-size: 18px;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 0;
  font-family: 'Ubuntu', sans-serif;
`;

const CloseButton = styled.button`
  margin-top: 16px;
  padding: 8px 20px;
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  color: #495057;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;

  &:hover {
    background: #e9ecef;
    border-color: #ced4da;
  }

  &:active {
    background: #dee2e6;
  }
`;

interface LoseResultProps {
  loseTitle?: string;
  loseMessage?: string;
  onClose: () => void;
}

export default function LoseResult({
  loseTitle,
  loseMessage,
  onClose,
}: LoseResultProps) {
  return (
    <>
      <ResultTitle>{loseTitle}</ResultTitle>
      <div>{loseMessage}</div>
      <CloseButton onClick={onClose}>Close</CloseButton>
    </>
  );
}
