const md = new MarkdownIt();
import MarkdownIt from 'markdown-it';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 10px !important;

  p {
    margin-bottom: 16px !important;
  }

  ul {
    padding-left: 20px !important;
    list-style-type: disc !important;
  }

  ol {
    padding-left: 16px !important;
    list-style-type: decimal !important;
  }

  li {
    margin-bottom: 3px !important;
  }

  li p {
    margin-bottom: 0 !important;
  }
`;

export default function MarkdownPreview({ message }: { message: string }) {
  const htmlContent = md.render(message);

  return <Wrapper dangerouslySetInnerHTML={{ __html: htmlContent }} />;
}
