import { useCallback, useEffect, useMemo, useState } from 'react';
import { FaArrowRightLong } from 'react-icons/fa6';
import { Drawer, Button as RsButton } from 'rsuite';
import styled from 'styled-components';
import { InviteTypeEnum } from '../../..';
import Button from '../../../../../../../../shared/components/Button';
import FlexContainer from '../../../../../../../../shared/components/layouts/flex-container';
import AppModal from '../../../../../../../../shared/components/modal';
import useModal from '../../../../../../../../shared/components/modal/useModal';
import Preloader from '../../../../../../../../shared/components/Preloader';
import { Loading } from '../../../../../../../../shared/types/review.types';
import { getLimit } from '../../../../../../../../shared/utils/usage-limits';
import { useAppDispatch, useAppSelector } from '../../../../../../../../store';
import { getStats } from '../../../../../../../emails/redux/action';
import {
  ContactItem,
  emailsSelector,
  setSelectedOrders,
} from '../../../../../../../emails/redux/emails.slice';
import CreditLimitModal from '../../../../../../../review-forms/components/CreditLimitModal';
import { accountSelector } from '../../../../../../../userData/redux/userData.slice';
import useContacts, { ContactsSource } from '../../hooks/useContacts';
import {
  InviteCustomerSteps,
  useIniviteCustomerContext,
} from '../../InviteCustomerProvider';
import getPageAttributes from '../../utils/getPageAttributes';
import { SubmitButton } from '../SubmitButton';
import Contact from './Contact';
import MailchimpLists from './MailchimpLists';
import Placeholder from './Placeholder';
import SelectedContact from './SelectedContact';

/* eslint no-console: "warn" */

const Wrapper = styled.div`
  .start-paragraph {
    margin-bottom: 20px;
  }
  .select-more {
    cursor: pointer;
    margin: 10px 0;
    text-align: center;
    text-decoration: underline;
    text-underline-offset: 5px;
  }
`;

const DrawerBody = styled(Drawer.Body)`
  .load-more {
    display: block;
    margin-top: 20px;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-style: dashed;
    text-underline-offset: 5px;
  }
`;

const NextButton = styled(SubmitButton)`
  width: 100%;
`;

type Props = {
  source: InviteTypeEnum | null;
};

export default function ContactsList({ source }: Props) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const { setCurrentStep } = useIniviteCustomerContext();
  const {
    selectedContacts,
    stats: { loading, totalSentThisMonth },
  } = useAppSelector(emailsSelector);
  const [openMailchimpLists, setOpenMailchimpLists] = useState(false);
  const { account, loading: loadingSite } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { platform, plan } = selectedSite ?? {};
  const {
    handleCloseModal: handleCloseCreditLimitModal,
    handleOpenModal: handleOpenCreditLimitModal,
    showModal: showCreditLmitModal,
  } = useModal();

  const {
    hasNext,
    contacts,
    mailchimpLists,
    getMailchimpLists,
    loadingMailchimpLists,
    onSelectMailChimpList,
    loadingMailChimpContacts,
    getIntercomContacts,
    loadingIntercomContacts,
    startingAfter,
    mailchimpOffset,
    loadingInitialIntercomContacts,
    mailchimpListId,
    getMailChimpContacts,
    loadingInitialMailchimpContacts,
    loadingInitialKlaviyoContacts,
    loadingKlaviyoContacts,
    getKlaviyoContacts,
    klaviyoNextCursor,
    getOrders,
    loadingInitialOrderContacts,
    loadingOrders,
    hasMoreActiveCampaignContacts,
    loadingActiveCampaignContacts,
    loadingInitialCampaignContacts,
    getActiveCampaignContacts,
    getSendGridContacts,
    hasMoreSendGridContacts,
    loadingSendGridContacts,
    loadingInitialSendGridContacts,
  } = useContacts(source as ContactsSource);

  const emailsCredit = getLimit(plan)?.reviewsRequests;

  const canRequest = useMemo(() => {
    return emailsCredit > totalSentThisMonth;
  }, [emailsCredit, totalSentThisMonth]);

  const onselectOrder = useCallback(
    (contact: ContactItem) => {
      let updatedSelectedOrder: ContactItem[] = [];

      if (
        selectedContacts.some(
          (selectedContact) => selectedContact.id === contact.id
        )
      ) {
        dispatch(setSelectedOrders([]));

        updatedSelectedOrder = selectedContacts.filter(
          (selectedContact) => selectedContact.id !== contact.id
        );
        dispatch(setSelectedOrders(updatedSelectedOrder));
      } else {
        const total = totalSentThisMonth + selectedContacts.length + 1;

        if (total > emailsCredit) {
          handleOpenCreditLimitModal();
        } else {
          dispatch(setSelectedOrders([]));

          updatedSelectedOrder = [...selectedContacts, contact];
          dispatch(setSelectedOrders(updatedSelectedOrder));
        }
      }
    },
    [selectedContacts, totalSentThisMonth, emailsCredit]
  );

  useEffect(() => {
    if (source === 'orders') {
      getOrders();
    }
  }, [source, platform]);

  useEffect(() => {
    dispatch(getStats());
  }, []);

  const totalDisplay = 2;
  const more = useMemo(() => {
    if (!selectedContacts.length) {
      return 0;
    }

    return selectedContacts.length - totalDisplay;
  }, [selectedContacts, totalDisplay]);

  const pageAttributes = getPageAttributes(source);

  if (loadingSite === Loading.PENDING) {
    return <Preloader />;
  }

  return (
    <>
      <Wrapper>
        {!selectedContacts.length ? (
          <p className="start-paragraph">{pageAttributes.message}</p>
        ) : (
          <p style={{ marginBottom: 20 }}>
            You're about to send review requests to {selectedContacts.length}{' '}
            contact{selectedContacts.length > 1 ? 's' : ''}.
          </p>
        )}
        {selectedContacts.slice(0, totalDisplay).map((contact) => (
          <SelectedContact key={contact.id} contact={contact} />
        ))}

        {selectedContacts.length > totalDisplay && (
          <p style={{ margin: '10px 0' }}>And {more} more</p>
        )}

        {!!selectedContacts.length ? (
          <>
            <NextButton
              type="button"
              onClick={async () => {
                setCurrentStep(InviteCustomerSteps.MESSAGE);
              }}
            >
              <FlexContainer gap="5px">
                <span>Next</span> <FaArrowRightLong />
              </FlexContainer>
            </NextButton>
            <p
              className="select-more"
              role="presentation"
              onClick={() => {
                if (source === 'mailchimp') {
                  setOpenMailchimpLists(true);
                } else {
                  setOpen(true);
                }
              }}
            >
              Select more
            </p>
          </>
        ) : (
          <Button
            rounded
            onClick={() => {
              if (source === 'mailchimp') {
                setOpenMailchimpLists(true);
              } else if (source === 'intercom') {
                getIntercomContacts();
                setOpen(true);
              } else if (source === 'klaviyo') {
                getKlaviyoContacts();
                setOpen(true);
              } else if (source === 'activecampaign') {
                getActiveCampaignContacts();
                setOpen(true);
              } else if (source === 'sendgrid') {
                getSendGridContacts();
                setOpen(true);
              } else {
                setOpen(true);
              }
            }}
          >
            {pageAttributes.buttonLabel}
          </Button>
        )}
      </Wrapper>

      <AppModal
        size="sm"
        title={
          <>
            <p>MailChimp Audiences</p>
            <p style={{ fontSize: 13, color: '#707070' }}>
              Select an audience below
            </p>
          </>
        }
        shouldOpenModal={openMailchimpLists}
        onModalClose={() => setOpenMailchimpLists(false)}
        body={
          <MailchimpLists
            onSelectList={(listId: string) => {
              onSelectMailChimpList(listId);
              setOpenMailchimpLists(false);
              setOpen(true);
            }}
            mailchimpLists={mailchimpLists}
            loading={loadingMailchimpLists}
            getMailchimpLists={getMailchimpLists}
          />
        }
        footer={
          loadingMailchimpLists === Loading.PENDING ? null : (
            <FlexContainer
              justifyContent="flex-end"
              style={{
                borderTop: `${mailchimpLists.length ? '1px' : 0} solid #DEDEDE`,
                paddingTop: 20,
              }}
            >
              <Button
                size="small"
                variant="tertiary"
                rounded
                onClick={() => setOpenMailchimpLists(false)}
              >
                Close
              </Button>
            </FlexContainer>
          )
        }
      />

      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        backdrop="static"
        size="500px"
      >
        <Drawer.Header style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Drawer.Title>
            <div>
              <p>{pageAttributes.drawerTitle}</p>
              {/* <p>Good</p> */}
            </div>
          </Drawer.Title>
          <Drawer.Actions>
            <RsButton size="sm" onClick={() => setOpen(false)}>
              Cancel
            </RsButton>
            {!!selectedContacts.length && (
              <RsButton
                size="sm"
                appearance="primary"
                onClick={() => setOpen(false)}
              >
                Confirm
              </RsButton>
            )}
          </Drawer.Actions>
        </Drawer.Header>
        <DrawerBody style={{ paddingLeft: 0, paddingRight: 0 }}>
          {[
            loadingInitialOrderContacts,
            loadingInitialIntercomContacts,
            loadingInitialMailchimpContacts,
            loadingInitialKlaviyoContacts,
            loadingInitialCampaignContacts,
            loadingInitialSendGridContacts,
          ].includes(Loading.PENDING) ? (
            <Placeholder />
          ) : (
            <>
              {!!contacts.length ? (
                contacts.map((contact) => (
                  <Contact
                    key={contact.id}
                    contact={contact}
                    onSelect={(contact: ContactItem) => onselectOrder(contact)}
                    selected={selectedContacts.some(
                      (item) => item.id === contact.id
                    )}
                  />
                ))
              ) : (
                <p style={{ marginLeft: 20 }}>We couldn’t find any data.</p>
              )}
            </>
          )}

          {(hasNext ||
            startingAfter ||
            mailchimpOffset ||
            klaviyoNextCursor ||
            hasMoreActiveCampaignContacts) && (
            <FlexContainer>
              <span
                className="load-more"
                role="presentation"
                onClick={() => {
                  if (source === 'orders') {
                    getOrders();
                  } else if (source === 'intercom') {
                    getIntercomContacts();
                  } else if (source === 'mailchimp' && mailchimpListId) {
                    getMailChimpContacts(mailchimpListId);
                  } else if (source === 'klaviyo') {
                    getKlaviyoContacts();
                  } else if (source === 'activecampaign') {
                    getActiveCampaignContacts();
                  }
                }}
              >
                {[
                  loadingOrders,
                  loadingIntercomContacts,
                  loadingMailChimpContacts,
                  loadingKlaviyoContacts,
                  loadingActiveCampaignContacts,
                  loadingSendGridContacts,
                ].includes(Loading.PENDING)
                  ? 'Please wait...'
                  : 'Load more'}
              </span>
            </FlexContainer>
          )}
        </DrawerBody>
      </Drawer>

      <AppModal
        size="sm"
        shouldOpenModal={showCreditLmitModal}
        onModalClose={handleCloseCreditLimitModal}
        body={<CreditLimitModal onClose={handleCloseCreditLimitModal} />}
      />
    </>
  );
}
