import { countries as rawCountries } from 'countries-list';
import { useEffect, useState } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { Input, InputPicker } from 'rsuite';
import styled, { css } from 'styled-components';
import { BusinessProfile } from '.';
import FlexContainer from '../../../../shared/components/layouts/flex-container';

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #ebebeb;
`;

const FormGroup = styled.div`
  width: 100%;
`;

const Content = styled.div`
  padding: 20px;

  .title {
    font-size: 15px;
    margin-bottom: 20px;
  }
`;

const ErroMessage = styled.span`
  display: block;
  color: red;
  margin-bottom: 15px;
`;

const CustomInput = styled(Input)<{ error?: boolean }>`
  font-style: normal;
  background: #fff;
  border-radius: 5px;
  max-width: 100%;
  outline: none !important;
  margin-bottom: 5px;
  border: 1px solid #cecece;
  &:active {
    border: 1px solid #cecece;
  }

  ${({ error }) =>
    error &&
    css`
      border-color: red;
    `}
`;

const CustomInputPicker = styled(InputPicker)<{ error?: boolean }>`
  font-style: normal;
  background: #fff;
  border-radius: 5px;
  width: 100%;
  outline: none !important;
  margin-bottom: 5px;
  border: 1px solid #cecece;
  &:active {
    border: 1px solid #cecece;
  }

  ${({ error }) =>
    error &&
    css`
      border-color: red;
    `}
`;

type Props = {
  errors: FieldErrors<BusinessProfile>;
  control: Control<BusinessProfile, any>;
};

export default function Location({ control, errors }: Props) {
  const [countries, setCountries] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    const arr = [];

    for (const [_, { name }] of Object.entries(rawCountries)) {
      arr.push({ label: name, value: name });
    }

    setCountries(arr);
  }, [rawCountries]);

  return (
    <Wrapper>
      <Content>
        <h4 className="title">Business location info</h4>
        <FlexContainer
          stack
          justifyContent="flex-start"
          alignItems="flex-start"
          gap="15px"
        >
          <FormGroup>
            <Label>Street address</Label>
            <Controller
              name="streetAddress"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomInput
                  placeholder="One Apple Park Way"
                  error={Boolean(errors.streetAddress)}
                  {...field}
                />
              )}
            />
            {errors.streetAddress && (
              <ErroMessage>{errors.streetAddress.message}</ErroMessage>
            )}
          </FormGroup>

          <FormGroup>
            <Label>City</Label>
            <Controller
              name="city"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomInput
                  placeholder="Cupertino"
                  error={Boolean(errors.city)}
                  {...field}
                />
              )}
            />
            {errors.city && <ErroMessage>{errors.city.message}</ErroMessage>}
          </FormGroup>

          <FormGroup>
            <Label>State</Label>
            <Controller
              name="state"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomInput
                  placeholder="California"
                  error={Boolean(errors.state)}
                  {...field}
                />
              )}
            />
            {errors.state && <ErroMessage>{errors.state.message}</ErroMessage>}
          </FormGroup>
          <FormGroup>
            <Label>Postal code</Label>
            <Controller
              name="postalCode"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomInput
                  placeholder="95014"
                  error={Boolean(errors.postalCode)}
                  {...field}
                />
              )}
            />
            {errors.postalCode && (
              <ErroMessage>{errors.postalCode.message}</ErroMessage>
            )}
          </FormGroup>

          <FormGroup>
            <Label>Country</Label>
            <Controller
              name="country"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <CustomInputPicker
                  size="md"
                  error={Boolean(errors.country)}
                  placeholder="United States"
                  data={countries}
                  autoComplete={false}
                  {...field}
                />
              )}
            />
            {errors.country && (
              <ErroMessage>{errors.country.message}</ErroMessage>
            )}
          </FormGroup>
        </FlexContainer>
      </Content>
      {/* <Footer justifyContent="flex-end">
        <Button
          rounded
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={saving === Loading.PENDING}
          variant={saving === Loading.PENDING ? 'tertiary' : 'secondary'}
        >
          {saving === Loading.PENDING ? 'Please wait...' : 'Save info'}
        </Button>
      </Footer> */}
    </Wrapper>
  );
}
