import styled from 'styled-components';

const LabelWrapper = styled.label<{ $margin?: string }>`
  font-size: 14px;
  font-weight: 500;
  color: #1e293b;
  margin-bottom: ${(props) => props.$margin};
  display: block;
`;

export const Label = ({
  children,
  margin = '5px',
  style,
}: {
  children?: React.ReactNode;
  margin?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <LabelWrapper $margin={margin} style={style}>
      {children}
    </LabelWrapper>
  );
};
