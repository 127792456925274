import { useEffect, useRef, useState } from 'react';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import styled from 'styled-components';
import HeaderButton from './HeaderButton';

const DropdownButton = styled(HeaderButton)<{ $isOpen?: boolean }>`
  position: relative;

  ${(props) =>
    props.$isOpen &&
    `
    background: #f8fafc;
    border-color: #3b82f6;
    color: #3b82f6;
  `}
`;

const DropdownContent = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  min-width: 220px;
  background: white;
  border-radius: 8px;
  padding: 6px;
  box-shadow: 0 10px 38px -10px rgba(22, 23, 24, 0.35),
    0 10px 20px -15px rgba(22, 23, 24, 0.2);
  z-index: 100;
  border: 1px solid #e2e8f0;
`;

const DropdownItem = styled.button`
  font-size: 14px;
  line-height: 1;
  color: #1e293b;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 36px;
  padding: 0 8px;
  width: 100%;
  border: none;
  background: none;
  cursor: pointer;

  &:hover {
    background-color: #f1f5f9;
    color: #3b82f6;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const DropdownSeparator = styled.div`
  height: 1px;
  background-color: #e2e8f0;
  margin: 6px;
`;

interface DropdownProps {
  trigger: React.ReactNode;
  items: {
    icon: React.ReactNode;
    label: string;
    onClick: () => void;
    separator?: boolean;
  }[];
}

const Dropdown: React.FC<DropdownProps> = ({ trigger, items }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div style={{ position: 'relative' }} ref={dropdownRef}>
      <DropdownButton $isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        {trigger}
        <MdOutlineKeyboardArrowDown size={10} />
      </DropdownButton>
      {isOpen && (
        <DropdownContent>
          {items.map((item, index) => (
            <div key={index}>
              {item.separator && <DropdownSeparator />}
              <DropdownItem
                onClick={() => {
                  item.onClick();
                  setIsOpen(false);
                }}
              >
                {item.icon}
                {item.label}
              </DropdownItem>
            </div>
          ))}
        </DropdownContent>
      )}
    </div>
  );
};

export default Dropdown;
