import millify from 'millify';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SiteWidgetSettings } from '../../../../../../widgetSettings/redux/widgetSettings.slice';
import ReviewRating from '../shared/ReviewRating';

const Wrapper = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  .rating-description {
    font-size: 14px;
    max-width: 230px;
    line-height: 20px;
  }

  .reviewsjet-rating-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    font-family: 'Body-Font', sans-serif;

    .rating {
      font-size: 55px;
    }
    .overrall {
      font-size: 25px;
    }
    .stars-wrapper {
      display: flex;
      gap: 3px;
      align-items: center;
    }
  }
`;

type Props = {
  settings: SiteWidgetSettings;
  rating: number;
  totalDocs: number;
};

export default function OverrallRating({ settings, rating, totalDocs }: Props) {
  const [summary, setSummary] = useState('');
  const {
    productWidget: { textColor, starColor, fontSize },
    translation: { basedOnTotalReviews },
  } = settings;

  useEffect(() => {
    if (basedOnTotalReviews) {
      setSummary(
        basedOnTotalReviews.replace('{totalReview}', `${millify(totalDocs)}`)
      );
    }
  }, [basedOnTotalReviews, totalDocs]);

  return (
    <Wrapper>
      <div className="reviewsjet-rating-info">
        <div className="rating-wrapper">
          <span className="rating assistant-500" style={{ color: textColor }}>
            {rating}
          </span>
          <span className="overrall assistant-500" style={{ color: textColor }}>
            /5
          </span>
        </div>
        <div className="stars-wrapper">
          <ReviewRating color={starColor} rating={rating} />
        </div>
        <div
          className="rating-description assistant-400"
          style={{
            color: textColor,
            fontSize,
            lineHeight: `${fontSize + 5}px`,
          }}
        >
          {summary}
        </div>
      </div>
    </Wrapper>
  );
}
