import { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import Button from '../../../../../../shared/components/Button';
import getLuminance from '../../../../../../shared/utils/getLuminance';
import { getSegmentDarkerColor } from '../../../../../../shared/utils/getSegmentDarkerColor';
import getLighterColor from '../../../../../../shared/utils/getSegmentLighterColor';
import { useAppSelector } from '../../../../../../store';
import { reviewFormSelector } from '../../../../redux/review-form.slice';
import LoseResult from './LoseResult';
import WinResult from './WinResult';
/* eslint no-console: "warn" */

const WheelContainer = styled.div`
  width: 450px;
  height: 450px;
  position: relative;
  margin: 0 auto;
`;

const ModalHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Ubuntu', sans-serif;
  color: #2b3044;

  span {
    display: block;
    font-size: 15px;
    font-weight: normal;
    color: #6b7280;
    margin-top: 4px;
  }
`;

const Wheel = styled.svg<{ $isSpinning?: boolean; $rotation?: number }>`
  width: 100%;
  height: 100%;
  transform-origin: center;
  transform: rotate(${(props) => props.$rotation || 0}deg);
  transition: transform 3s cubic-bezier(0.3, 0, 0.2, 1);
`;

const PlayButton = styled(Button)<{ $color: string }>`
  display: block;
  margin: 0 auto 5px;
  padding: 8px 24px;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  ${({ $color }) => css`
    background: linear-gradient(
      to bottom,
      ${$color},
      ${getSegmentDarkerColor($color)}
    );
  `}
  color: ${(props) => (getLuminance(props.$color) > 0.5 ? '#000' : '#fff')};
  border: none;
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(52, 152, 219, 0.25);
  transition: all 0.2s ease;
  position: relative;
  z-index: 2;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(52, 152, 219, 0.35);
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 1px 4px rgba(52, 152, 219, 0.2);
  }

  &:disabled {
    background: linear-gradient(to bottom, #95a5a6, #7f8c8d);
    box-shadow: none;
    transform: none;
    cursor: not-allowed;
  }
`;

const WheelPointer = styled.div<{ $isSpinning: boolean }>`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 24px;
  height: 40px;
  background: linear-gradient(to bottom, #ff6b6b, #e63946);
  clip-path: polygon(0% 30%, 50% 0%, 100% 30%, 50% 100%);
  border-radius: 4px;
  box-shadow: inset 0 2px 4px rgba(255, 255, 255, 0.5),
    inset 0 -2px 4px rgba(0, 0, 0, 0.3), 0 4px 8px rgba(230, 57, 70, 0.4);
  z-index: 2;
  border: 2px solid white;
  animation: ${(props) =>
    props.$isSpinning
      ? 'tickerShake 0.08s linear infinite'
      : 'pointerPulse 2s ease-in-out infinite'};

  &::after {
    content: '';
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 12px;
    height: 12px;
    background: #ffd700;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border: 2px solid white;
  }

  @keyframes tickerShake {
    0% {
      transform: translateX(-50%) rotate(-6deg);
    }
    25% {
      transform: translateX(-53%) rotate(0deg);
    }
    50% {
      transform: translateX(-50%) rotate(6deg);
    }
    75% {
      transform: translateX(-47%) rotate(0deg);
    }
    100% {
      transform: translateX(-50%) rotate(-6deg);
    }
  }

  @keyframes pointerPulse {
    0% {
      transform: translateX(-50%) scale(1);
    }
    50% {
      transform: translateX(-50%) scale(1.1);
    }
    100% {
      transform: translateX(-50%) scale(1);
    }
  }
`;

const CloseButton = styled.button`
  margin-top: 16px;
  padding: 8px 20px;
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  color: #495057;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;

  &:hover {
    background: #e9ecef;
    border-color: #ced4da;
  }

  &:active {
    background: #dee2e6;
  }
`;

const ResultDisplay = styled.div`
  text-align: center;
  padding: 12px 20px;
  background: white;
  border-radius: 6px;
  font-size: 14px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

const ResultTitle = styled.h3`
  margin: 0 0 8px;
  font-size: 18px;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 0;
  font-family: 'Ubuntu', sans-serif;
`;

const ResultCode = styled.div`
  background: #f8f9fa;
  border: 1px dashed #dee2e6;
  border-radius: 4px;
  padding: 6px 12px;
  margin-top: 8px;
  font-family: monospace;
  font-size: 15px;
  color: #495057;
  display: flex;
  font-weight: 900;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  user-select: none;

  &:hover {
    background: #f1f3f5;
  }

  &:active {
    background: #e9ecef;
  }
`;

const CopyButton = styled.button`
  background: transparent;
  border: none;
  padding: 4px;
  color: #6c757d;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color 0.2s ease;

  &:hover {
    color: #495057;
  }
`;

const TrialsCounter = styled.div`
  text-align: center;
  margin-top: 8px;
  font-size: 13px;
  color: #666;
`;

const emojis = ['❤️', '🚀', '🤗', '⭐️', '🥰', '🎉', '🌟', '💫', '✨', '💝'];

export default function RewardWheel({ wheelColor }: { wheelColor: string }) {
  const [isSpinning, setIsSpinning] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [selectedSegment, setSelectedSegment] = useState<
    (typeof defaultSegments)[0] | null
  >(null);
  const [copied, setCopied] = useState(false);
  const [trialsLeft, setTrialsLeft] = useState(3);
  const [hasWon, setHasWon] = useState(false);
  const { reviewFormSettings, showWheelLoseResult, showWheelWinResult } =
    useAppSelector(reviewFormSelector);
  const { reward } = reviewFormSettings ?? {};
  const {
    segments: rewardSegments = [],
    wheelTitle,
    wheelMessage,
    winTitle,
    winMessage,
    loseTitle,
    loseMessage,
  } = reward ?? {};

  const defaultSegments = useMemo(() => {
    // Map reward segments to wheel format
    const wheelRewardSegments = rewardSegments.map((reward) => ({
      label: `${reward.discountValue}% OFF`,
      code: reward.couponCode,
      value: reward.discountValue,
    }));

    // Get emojis for filling
    const shuffledEmojis = [...emojis].sort(() => Math.random() - 0.5);

    // Create emoji segments
    const emojiSegments = shuffledEmojis.map((emoji) => ({
      label: emoji,
      code: '',
      value: '',
    }));

    // Initialize array with 10 emoji segments
    let allSegments = [...emojiSegments.slice(0, 10)];

    // Helper function to check if a position is valid
    const isValidPosition = (
      position: number,
      segments: typeof allSegments
    ) => {
      // Check previous, current, and next positions (considering wheel wrap-around)
      const prevPos = (position - 1 + 10) % 10;
      const nextPos = (position + 1) % 10;

      return !segments[prevPos].code && !segments[nextPos].code;
    };

    // Place reward segments with at least one emoji segment between them
    wheelRewardSegments.forEach((reward) => {
      // Find all valid positions
      const validPositions = allSegments
        .map((_, index) => index)
        .filter(
          (pos) => !allSegments[pos].code && isValidPosition(pos, allSegments)
        );

      if (validPositions.length === 0) {
        console.error('No valid positions found for reward segment');
        return;
      }

      // Randomly select one of the valid positions
      const randomIndex = Math.floor(Math.random() * validPositions.length);
      const position = validPositions[randomIndex];
      // Place the reward segment
      allSegments[position] = {
        ...reward,
        value: String(reward.value), // Convert number to string
      };
    });

    return allSegments;
  }, [rewardSegments]);

  const handleSpin = () => {
    if (isSpinning || trialsLeft <= 0) return;

    setIsSpinning(true);

    // Calculate segment angle based on number of segments
    const numSegments = defaultSegments.length; // 10 segments
    const segmentAngle = 360 / numSegments; // 36 degrees per segment

    const fullRotations = 5 + Math.floor(Math.random() * 3);
    const randomSegmentIndex = Math.floor(Math.random() * numSegments);
    const randomOffset = Math.random() * (segmentAngle * 0.5);
    const extraDegrees = randomSegmentIndex * segmentAngle + randomOffset;
    const totalDegrees = rotation + (fullRotations * 360 + extraDegrees);

    setRotation(totalDegrees);

    setTimeout(() => {
      const normalizedRotation = totalDegrees % 360;
      const adjustedRotation = (normalizedRotation + 90) % 360;
      const invertedRotation = (360 - adjustedRotation) % 360;
      const segmentIndex =
        Math.floor(invertedRotation / segmentAngle) % numSegments;

      const landedSegment = defaultSegments[segmentIndex];

      setSelectedSegment(landedSegment);

      if (landedSegment.code) {
        setHasWon(true);
      } else {
        setTrialsLeft((prev) => prev - 1);
      }

      setIsSpinning(false);
    }, 3000);
  };

  const handleClose = () => {
    setSelectedSegment(null);
    setHasWon(false);
    setTrialsLeft(3);
    setRotation(0);
  };

  const showWheel = !hasWon && trialsLeft > 0;

  const segments = defaultSegments.map((segment, index) => {
    // Start at top (0 degrees) and move clockwise
    const startAngle = index * 36;
    const endAngle = (index + 1) * 36;
    const startRad = (startAngle * Math.PI) / 180;
    const endRad = (endAngle * Math.PI) / 180;
    const startX = 225 + 200 * Math.cos(startRad);
    const startY = 225 + 200 * Math.sin(startRad);
    const endX = 225 + 200 * Math.cos(endRad);
    const endY = 225 + 200 * Math.sin(endRad);

    // Calculate text position
    const midAngle = (startAngle + endAngle) / 2;
    const midRad = (midAngle * Math.PI) / 180;
    const textRadius = 140;
    const textX = 225 + textRadius * Math.cos(midRad);
    const textY = 225 + textRadius * Math.sin(midRad);
    const textRotation = midAngle;

    return {
      ...segment,
      path: [
        'M',
        225,
        225,
        'L',
        startX,
        startY,
        'A',
        200,
        200,
        0,
        0,
        1,
        endX,
        endY,
        'Z',
      ].join(' '),
      color: index % 2 === 0 ? wheelColor : getLighterColor(wheelColor),
      textX,
      textY,
      textRotation,
    };
  });

  if (!!showWheelWinResult) {
    return (
      <ResultDisplay>
        <WinResult
          winTitle={winTitle}
          onClose={handleClose}
          segments={rewardSegments}
          winMessage={winMessage}
          couponCode={selectedSegment?.code}
        />
      </ResultDisplay>
    );
  }

  if (!!showWheelLoseResult) {
    return (
      <ResultDisplay>
        <LoseResult
          loseTitle={loseTitle}
          loseMessage={loseMessage}
          onClose={handleClose}
        />
      </ResultDisplay>
    );
  }

  return (
    <>
      {showWheel && (
        <ModalHeader>
          {wheelTitle}
          <span>{wheelMessage}</span>
        </ModalHeader>
      )}
      {showWheel ? (
        <>
          <WheelContainer>
            <Wheel
              viewBox="0 0 450 450"
              $isSpinning={isSpinning}
              $rotation={rotation}
            >
              <defs>
                <radialGradient
                  id="centerGradient"
                  gradientUnits="userSpaceOnUse"
                  cx="35%"
                  cy="35%"
                  r="60%"
                >
                  <stop offset="0%" stopColor="#e2c19d" />
                  <stop offset="20%" stopColor="#b17f4a" />
                  <stop offset="40%" stopColor="#8b4513" />
                  <stop offset="60%" stopColor="#654321" />
                  <stop offset="100%" stopColor="#3b2506" />
                </radialGradient>
                <filter id="woodShadow">
                  <feGaussianBlur in="SourceAlpha" stdDeviation="2" />
                  <feOffset dx="1" dy="2" result="offsetblur" />
                  <feFlood floodColor="#000000" floodOpacity="0.3" />
                  <feComposite in2="offsetblur" operator="in" />
                  <feMerge>
                    <feMergeNode />
                    <feMergeNode in="SourceGraphic" />
                  </feMerge>
                </filter>
                <pattern
                  id="woodPattern"
                  width="4"
                  height="4"
                  patternUnits="userSpaceOnUse"
                >
                  <path
                    d="M0 0h4v1H0zM0 2h4v1H0z"
                    fill="#7d4b28"
                    fillOpacity="0.3"
                  />
                </pattern>
              </defs>

              {segments.map((segment, index) => (
                <g key={index}>
                  <path
                    d={segment.path}
                    fill={segment.color}
                    stroke="#fff"
                    strokeWidth="1"
                  />
                  <text
                    x={segment.textX}
                    y={segment.textY}
                    fill={getLuminance(segment.color) > 0.5 ? '#000' : '#fff'}
                    fontSize="16"
                    fontWeight="bold"
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    transform={`
                      rotate(${segment.textRotation}, ${segment.textX}, ${
                      segment.textY
                    })
                      ${
                        segment.textRotation > 90 && segment.textRotation < 270
                          ? 'rotate(180,' +
                            segment.textX +
                            ',' +
                            segment.textY +
                            ')'
                          : ''
                      }
                    `}
                  >
                    {segment.label}
                  </text>
                </g>
              ))}

              {/* Center piece */}
              <circle
                cx="225"
                cy="225"
                r="40"
                fill="url(#centerGradient)"
                stroke="#8b5e3c"
                strokeWidth="2"
              />
              <circle
                cx="225"
                cy="225"
                r="40"
                fill="url(#woodPattern)"
                fillOpacity="0.7"
              />
              <circle
                cx="225"
                cy="225"
                r="15"
                fill="radial-gradient(circle at 30% 30%, #d4a36f, #8b4513)"
              />
            </Wheel>

            <WheelPointer $isSpinning={isSpinning} />
          </WheelContainer>

          <PlayButton
            onClick={handleSpin}
            disabled={isSpinning}
            $color={wheelColor}
          >
            {isSpinning ? '...' : 'Play'}
          </PlayButton>

          <TrialsCounter>
            {trialsLeft} {trialsLeft === 1 ? 'try' : 'tries'} remaining
          </TrialsCounter>
        </>
      ) : (
        <ResultDisplay>
          {hasWon ? (
            <WinResult
              winTitle={winTitle}
              winMessage={winMessage}
              couponCode={selectedSegment?.code}
              onClose={handleClose}
              segments={rewardSegments}
            />
          ) : (
            <LoseResult
              loseTitle={loseTitle}
              loseMessage={loseMessage}
              onClose={handleClose}
            />
          )}
        </ResultDisplay>
      )}
    </>
  );
}
