import { EmbedFormSettingsPayload } from '../../features/review-forms';

interface GetEmbedAttributesProps {
  formKey: string;
  embedFormSettings: EmbedFormSettingsPayload | null;
}

const getInlineAttributes = (settings: EmbedFormSettingsPayload) => {
  const attrs = ['widget-type="inline"'];
  if (settings.embedStyle === 'styled') {
    attrs.push('widget-style="styled"');
  } else if (settings.embedStyle === 'unstyle') {
    attrs.push('widget-style="unstyled"');
  }
  return attrs;
};

const getPopupAttributes = (settings: EmbedFormSettingsPayload) => {
  const attrs = ['widget-type="popup"'];

  switch (settings.openTrigger) {
    case 'on-button-click':
      attrs.push('trigger="click"');
      break;
    case 'after-elapsed-time':
      attrs.push('trigger="elapsed-time"');
      attrs.push(`delay="${settings.delay}"`);
      break;
    case 'after-scrolling':
      attrs.push('trigger="scroll"');
      attrs.push(`position="${settings.percentageOfPageScrolled}"`);
      break;
  }

  return attrs;
};

export const getEmbedAttributes = ({
  formKey,
  embedFormSettings,
}: GetEmbedAttributesProps): string => {
  const baseAttrs = [`form="${formKey}"`];

  if (!embedFormSettings) return baseAttrs.join(' ');

  const typeSpecificAttrs =
    embedFormSettings.activeOption === 'inline'
      ? getInlineAttributes(embedFormSettings)
      : getPopupAttributes(embedFormSettings);

  return [...baseAttrs, ...typeSpecificAttrs].join(' ');
};
