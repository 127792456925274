import millify from 'millify';
import styled from 'styled-components';
import { Tab } from '../../../../../review/types';
import { SiteWidgetSettings } from '../../../../../widgetSettings/redux/widgetSettings.slice';

type Props = {
  tab: Tab;
  settings: SiteWidgetSettings;
  onChange: (tab: Tab) => void;
  totalQuestions: number;
  totalReviews: number;
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  font-size: 15px;
  cursor: pointer;
  .tab {
    padding: 10px 25px;
    border-radius: 20px;
    text-decoration: underline;
    text-underline-offset: 5px;
    border: 1px solid transparent;
    font-family: 'Body-Font', sans-serif;

    &.active {
      background-color: #ffffff;
      text-decoration: none;
      border: 1px solid;
    }
  }
`;

export default function Tabs({
  tab,
  onChange,
  settings,
  totalReviews,
  totalQuestions,
}: Props) {
  const {
    productWidget: { buttonBackgroundColor, textColor, fontSize },
  } = settings;

  return (
    <Wrapper>
      <div
        className={`tab ${tab === 'reviews' ? 'active' : ''}`}
        role="presentation"
        onClick={() => onChange('reviews')}
        style={{
          borderColor: tab === 'reviews' ? buttonBackgroundColor : '',
          color: tab === 'reviews' ? '#000' : textColor,
          fontSize,
        }}
      >
        Reviews ({millify(totalReviews)})
      </div>
      <div
        className={`tab ${tab === 'questions' ? 'active' : ''}`}
        role="presentation"
        onClick={() => onChange('questions')}
        style={{
          borderColor: tab === 'questions' ? buttonBackgroundColor : '',
          color: tab === 'questions' ? '#000' : textColor,
          fontSize,
        }}
      >
        Questions ({millify(totalQuestions)})
      </div>
    </Wrapper>
  );
}
