import { BiLogOut } from 'react-icons/bi';
import { FaExchangeAlt } from 'react-icons/fa';
import { FaMap, FaStar, FaUser } from 'react-icons/fa6';
import { IoHelpBuoySharp } from 'react-icons/io5';
import { Dropdown, Popover, Tag } from 'rsuite';
import styled from 'styled-components';
import { Loading } from '../../../types/review.types';
import localStorage from '../../../utils/localStorage';
import removeTrailingSlash from '../../../utils/removeTrailingSlash';
import FlexContainer from '../flex-container';

/* eslint no-console: "warn" */

const DropdownMenu = styled(Dropdown.Menu)`
  width: 200px;
`;

const renderSpeaker = (
  {
    onClose,
    left,
    top,
    canLogout,
    className,
    onClickAccountDetails,
    onClickRoadmap,
    onClickChangelog,
    reviewUs,
    help,
    planDescription,
    loadingPlanDescriptor,
    switchAccount,
    hasMultipleSeats,
  }: any,
  // @ts-ignore
  ref
) => {
  const handleSelect = (eventKey: any) => {
    onClose();

    switch (eventKey) {
      case 1:
        onClickAccountDetails();
        break;
      case 2:
        onClickRoadmap();
        break;
      case 3:
        onClickChangelog();
        break;

      case 4:
        reviewUs();
        break;

      case 5:
        help();
        break;

      case 7:
        switchAccount();
        break;

      default:
        break;
    }
  };

  const logout = () => {
    if (process.env.REACT_APP_DASHBOARD_URL) {
      localStorage.remove('session');
      window.open(
        `${removeTrailingSlash(process.env.REACT_APP_DASHBOARD_URL)}/login`,
        '_self'
      );
    }
  };

  return (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <DropdownMenu onSelect={handleSelect}>
        <Dropdown.Item eventKey={1}>
          <FlexContainer
            stack
            gap="5px"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <FlexContainer gap="5px" justifyContent="flex-start">
              <FaUser />
              Account Details
            </FlexContainer>
            {loadingPlanDescriptor === Loading.SUCCESS && (
              <Tag style={{ marginLeft: 17 }}>
                <b>{planDescription}</b>
              </Tag>
            )}
          </FlexContainer>
        </Dropdown.Item>
        <Dropdown.Separator />

        {hasMultipleSeats && (
          <>
            <Dropdown.Item eventKey={7}>
              <FlexContainer gap="5px" justifyContent="flex-start">
                <FaExchangeAlt />
                <span>Switch Account</span>
              </FlexContainer>
            </Dropdown.Item>
          </>
        )}

        {/* <Dropdown.Item eventKey={3}>
          <FlexContainer gap="5px" justifyContent="flex-start">
            <BiSolidMegaphone />
            <span>What's new?</span>
          </FlexContainer>
        </Dropdown.Item> */}
        <Dropdown.Item eventKey={2}>
          <FlexContainer gap="5px" justifyContent="flex-start">
            <FaMap />
            <span>Roadmap</span>
          </FlexContainer>
        </Dropdown.Item>
        <Dropdown.Item eventKey={4}>
          <FlexContainer gap="5px" justifyContent="flex-start">
            <FaStar />
            <span>Rate us</span>
          </FlexContainer>
        </Dropdown.Item>
        <Dropdown.Item eventKey={5}>
          <FlexContainer gap="5px" justifyContent="flex-start">
            <IoHelpBuoySharp size={18} />
            <span>Help</span>
          </FlexContainer>
        </Dropdown.Item>
        {canLogout && (
          <>
            <Dropdown.Separator />
            <Dropdown.Item onClick={logout} eventKey={6}>
              <FlexContainer gap="5px" justifyContent="flex-start">
                <BiLogOut color="crimson" />
                <span style={{ color: 'crimson' }}>Sign out</span>
              </FlexContainer>
            </Dropdown.Item>
          </>
        )}
      </DropdownMenu>
    </Popover>
  );
};

export default renderSpeaker;
