import { useEffect, useRef, useState } from 'react';
import { FaSortAmountDownAlt } from 'react-icons/fa';
import styled from 'styled-components';
import './SortButton.css';

const DropdownWrapper = styled.div`
  .dropdown-option {
    font-size: 15px;
  }
  .dropdown-button {
    font-size: 15px;
  }
`;

const Dropdown = ({ fontSize }: { fontSize: number }) => {
  const [selected, setSelected] = useState('Most recent');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: string) => {
    setIsOpen(false);
    setSelected(option);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropdownWrapper className="dropdown" ref={dropdownRef}>
      <button
        className="dropdown-button assistant-500"
        onClick={toggleDropdown}
        style={{ fontSize, lineHeight: `${fontSize}px` }}
      >
        <FaSortAmountDownAlt />
        {selected}
      </button>
      {isOpen && (
        <ul className="dropdown-menu">
          <li
            className="dropdown-option assistant-500"
            onClick={() => handleOptionClick('Most recent')}
            role="presentation"
            style={{ fontSize, lineHeight: `${fontSize}px` }}
          >
            Most recent
          </li>
          <li
            className="dropdown-option assistant-500"
            onClick={() => handleOptionClick('Highest rating')}
            role="presentation"
            style={{ fontSize, lineHeight: `${fontSize}px` }}
          >
            Highest rating
          </li>
          <li
            className="dropdown-option assistant-500"
            onClick={() => handleOptionClick('Lowest rating')}
            role="presentation"
            style={{ fontSize, lineHeight: `${fontSize}px` }}
          >
            Lowest rating
          </li>
          <li
            className="dropdown-option assistant-500"
            onClick={() => handleOptionClick('Pictures first')}
            role="presentation"
            style={{ fontSize, lineHeight: `${fontSize}px` }}
          >
            Pictures first
          </li>
        </ul>
      )}
    </DropdownWrapper>
  );
};

export default Dropdown;
