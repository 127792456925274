import { FaStar } from 'react-icons/fa';
import { IoMdArrowDropright } from 'react-icons/io';
import { batch } from 'react-redux';
import { Toggle } from 'rsuite';
import styled from 'styled-components';
import { Label } from '../../../../shared/components/Label';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import PremiumBanner from '../../../../shared/components/PremiumBanner';
import { SubscriptionPlan } from '../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { selectedSiteSelector } from '../../../userData/redux/userData.slice';
import FormRow from '../../../widgetSettings/components/form-row';
import {
  siteWidgetSettingsSelector,
  updateSiteWidgetSettings,
} from '../../../widgetSettings/redux/widgetSettings.slice';

const Circle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f2f3f6;
`;

const Content = styled(FlexContainer)`
  padding: 20px;
  width: 100%;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  border-left: 1px solid rgb(221 221 221 / 59%);
  border-right: 1px solid rgb(221 221 221 / 59%);

  border-bottom: 1px solid rgb(221 221 221 / 59%);

  &.first {
    border-top: 1px solid rgb(221 221 221 / 59%);
  }
  &:last-child {
    border-bottom: none;
  }

  .bars {
    width: 100%;
  }

  .bar {
    width: 100%;
    height: 20px;
    background-color: #f2f3f6;

    &:nth-child(2) {
      width: 80%;
    }

    &:nth-child(3) {
      width: 50%;
    }
  }
  .logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 1px solid #eaecf0;
    border-radius: 50%;
  }
  .website-url {
    font-size: 12px;
    color: #667085;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .your-website {
    font-size: 15px;
    color: rgb(100, 152, 255);
    font-weight: 600;
  }
  .page-description {
    font-size: 12px;
    color: #667085;
  }
  .rating {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    background-color: #fff;
    padding: 1px 5px;
    margin-top: 10px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  &.second {
    position: relative;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    background: linear-gradient(
      rgb(255 255 255) 0%,
      rgba(243, 244, 246, 0) 100%
    );
    border: 1px solid #a9deff;

    .arrow {
      position: absolute;
      transform: translateY(-50%);
      object-fit: contain;
      z-index: 99999;
      bottom: -90px;
      width: 100px;
      left: 20px;
    }
  }
`;

export default function GoogleRichSnippets() {
  const dispatch = useAppDispatch();
  const settings = useAppSelector(siteWidgetSettingsSelector);
  const { productWidget } = settings ?? {};
  const { enableRichSnippets } = productWidget ?? {};

  const selectedSite = useAppSelector(selectedSiteSelector);
  const { plan } = selectedSite ?? {};

  return (
    <>
      <FormRow>
        <p style={{ marginBottom: 20 }}>
          Google Rich Snippets display your product's star ratings and reviews
          directly in search results, helping increase visibility and
          click-through rates for your products.
        </p>

        <Content className="second">
          <img src="/images/arrow.png" alt="" className="arrow" />
          <FlexContainer
            stack
            className="bars"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <div className="website-url">
              https://website.com <IoMdArrowDropright /> products{' '}
              <IoMdArrowDropright /> product1
            </div>
            <div className="your-website">Personalized Gift Necklace</div>
            <div className="page-description">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam.
            </div>
            <div className="rating">
              <span>5.0</span>
              <FlexContainer gap="2px">
                <FaStar color="#FFD700" />
                <FaStar color="#FFD700" />
                <FaStar color="#FFD700" />
                <FaStar color="#FFD700" />
                <FaStar color="#FFD700" />
              </FlexContainer>
              <span>(350)</span>
            </div>
          </FlexContainer>
        </Content>
      </FormRow>

      {plan === SubscriptionPlan.FREE && (
        <PremiumBanner
          availableOn={SubscriptionPlan.PRO}
          description="Upgrade to enable Google Rich Snippets."
        />
      )}

      {plan !== SubscriptionPlan.FREE && (
        <FormRow gap="10px" justifyContent="flex-start" marginTop="50px">
          <Toggle
            // size="lg"
            checked={enableRichSnippets}
            // @ts-ignore
            onChange={() => {
              batch(() => {
                dispatch(
                  updateSiteWidgetSettings({
                    ...settings,
                    productWidget: {
                      ...productWidget,
                      enableRichSnippets: !enableRichSnippets,
                    },
                  })
                );
              });
            }}
            checkedChildren="Yes"
            unCheckedChildren="No"
          />
          <Label margin="0">Enable Google Rich Snippets</Label>
        </FormRow>
      )}
    </>
  );
}
