function getLighterColor(hexColor: string): string {
  // Remove # if present
  let hex = hexColor.replace('#', '');

  // Convert 3-digit hex to 6-digit hex
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  // Convert hex to RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate relative luminance
  // Using YIQ formula which is better for perceived brightness
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Return white for dark colors, black for light colors
  return yiq >= 128 ? '#000000' : '#ffffff';
}

export default getLighterColor;
