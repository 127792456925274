import { useState } from 'react';
import { FaPhotoFilm } from 'react-icons/fa6';
import { Input, Uploader } from 'rsuite';
import styled from 'styled-components';
import { Label } from '../../../../../../shared/components/Label';
import MarkdownPreview from '../../../../../../shared/components/MarkdownPreview';
import Rate from '../../../../../../shared/components/Rate';
import { useAppSelector } from '../../../../../../store';
import FormRow from '../../../../../widgetSettings/components/form-row';
import { reviewFormSelector } from '../../../../redux/review-form.slice';
import FormButton from '../../FormButton';

const Title = styled.h3`
  text-align: left;
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 10px;
  letter-spacing: -1px;
  font-weight: 700;
  font-optical-sizing: auto;
  font-style: normal;
  color: #55595f;
  font-family: 'Ubuntu Sans', sans-serif;
`;

const Wrapper = styled.div`
  ul {
    margin-top: 15px;
    padding-left: 20px;
    list-style: disc;
    margin-bottom: 20px;
  }

  .review-textarea {
    border: none;
    resize: none;
    font-size: 14px;
    background-color: #f5f5f5;

    &::placeholder {
      color: #8a8a8a;
    }
  }
`;

const StyledInput = styled(Input)`
  border: none;
  font-size: 14px;
  background-color: #f5f5f5;

  &::placeholder {
    color: #8a8a8a;
  }
`;

const StyledLabel = styled(Label)`
  b {
    font-size: 15px;
    font-weight: 600;
  }
`;

const UploaderWrapper = styled.div`
  width: 100%;

  .rs-uploader-picture {
    button {
      display: flex !important;
    }
  }
  .rs-uploader-trigger {
    width: 100%;
  }
  .rs-uploader-picture {
    width: 100%;

    button {
      width: 100%;
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 10px;
      height: 100px;
      span {
        color: #8f8f8f !important;
        font-size: 12px;
      }
    }
  }
`;

export default function Review() {
  const [rate, setRate] = useState(3);
  const { reviewFormSettings } = useAppSelector(reviewFormSelector);
  const { reviewScreen, appearance, translation } = reviewFormSettings ?? {};
  const { primaryColor = '#000' } = appearance ?? {};
  const { title, prompt, addPhotosLabel, photoUploadPrompt } =
    reviewScreen ?? {};
  const { continueLabel, enterReviewTitle, writeYourReview } =
    translation ?? {};

  return (
    <Wrapper>
      <Title>{title}</Title>
      <MarkdownPreview message={prompt ?? ''} />

      <FormRow marginBottom="5px">
        <Rate value={rate} onChange={setRate} />
      </FormRow>

      <FormRow>
        <StyledInput size="lg" placeholder={enterReviewTitle} />
      </FormRow>

      <FormRow>
        <Input
          size="lg"
          as="textarea"
          rows={5}
          placeholder={writeYourReview}
          className="review-textarea"
        />
      </FormRow>

      <FormRow width="100%" marginBottom="0">
        <StyledLabel>
          <b>{addPhotosLabel}</b>
        </StyledLabel>
        <MarkdownPreview message={photoUploadPrompt ?? ''} />

        <UploaderWrapper>
          <Uploader
            multiple
            draggable
            action=""
            name="reviewPhotos"
            listType="picture"
            autoUpload={false}
            disabledFileItem={false}
            accept=".jpg,.jpeg,.png,.gif"
            maxPreviewFileSize={10 * 1024 * 1024} // 10MB in bytes
          >
            <button type="button">
              <FaPhotoFilm size={26} />
              <span className="ubuntu-sans-400">
                PNG, JPG, or GIF up to 5MB
              </span>
            </button>
          </Uploader>
        </UploaderWrapper>
      </FormRow>

      <FormButton $color={primaryColor}>{continueLabel}</FormButton>
    </Wrapper>
  );
}
