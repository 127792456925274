import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import setSession from '../../../../shared/utils/setSession';

export default function Login() {
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode<{
        jwtAccessToken: string;
        jwtRefreshToken: string;
      }>(token);

      setSession({
        jwtAccessToken: decoded.jwtAccessToken,
        jwtRefreshToken: decoded.jwtRefreshToken,
      });

      setTimeout(() => {
        navigate('/manage-reviews');
      }, 1000);
    }
  }, [token]);

  return null;
}
