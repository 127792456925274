import styled from 'styled-components';

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background: white;
  border: 1px solid #e2e8f0;
  padding: 7px 12px;
  color: #1e293b;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  &:hover {
    border-color: #3b82f6;
    color: #3b82f6;
    background: #f8fafc;
    box-shadow: 0 2px 4px rgba(59, 130, 246, 0.1);
  }

  &:active {
    transform: scale(0.98);
    box-shadow: 0 1px 2px rgba(59, 130, 246, 0.1);
  }

  svg {
    transition: transform 0.2s ease;
    color: #64748b;
  }

  &:hover svg {
    transform: translateX(-4px);
    color: #3b82f6;
  }
`;

type Props = {
  children: React.ReactNode;
  onClick: () => void;
};

export default function BackButton({ children, onClick }: Props) {
  return <Wrapper onClick={onClick}>{children}</Wrapper>;
}
