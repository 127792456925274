import styled from 'styled-components';
import getDarkerColor from '../../../../../../../shared/utils/getDarkerColor';
import getLighterColor from '../../../../../../../shared/utils/getLighterColor';
import { SiteWidgetSettings } from '../../../../../../widgetSettings/redux/widgetSettings.slice';

const Wrapper = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }

  a {
    width: 100%;
    text-decoration: none !important;
    max-width: 240px;
    padding: 11px;
    text-align: center;
    /* color: #fff; */
    font-weight: bold;
    font-size: 14px;
    border-radius: 20px;
    display: block;
    cursor: pointer;

    &.primary {
      /* color: #fff;
      background-color: #0c3b74; */
      border: 2px solid transparent;
    }
    &.secondary {
      /* color: #0c3b74; */
      background-color: #fff;
      border: 2px solid;
    }

    @media (max-width: 1024px) {
      padding: 15px 0 !important;
      max-width: 100% !important;
      display: block;
      border: 5px solid red;
      line-height: 10px;
    }
  }
`;

type Props = {
  settings: SiteWidgetSettings;
  productId: string;
  siteName: string;
  openQuestionForm: () => void;
};

export default function ActionsButtons({
  settings,
  productId,
  siteName,
  openQuestionForm,
}: Props) {
  const {
    translation: {
      questionForm: { addQuestion },
      reviewForm: { addReview },
    },
    productWidget: {
      buttonBackgroundColor,
      collectQuestions,
      collectReviews,
      fontSize,
    },
  } = settings;

  const buttonTextColor = getLighterColor(buttonBackgroundColor);

  return (
    <>
      {(collectReviews || collectQuestions) && (
        <Wrapper>
          {collectReviews && (
            <a
              href="/"
              className="primary assistant-500"
              style={{
                fontSize,
                backgroundColor: buttonBackgroundColor,
                color: buttonTextColor,
                lineHeight: `${fontSize + 5}px`,
              }}
              target="_blank"
              role="button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {addReview}
            </a>
          )}
          {collectQuestions && (
            <a
              className="secondary assistant-500"
              style={{
                fontSize,
                borderColor: buttonBackgroundColor,
                color: getDarkerColor(buttonBackgroundColor),
                lineHeight: `${fontSize + 5}px`,
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                openQuestionForm();
              }}
              href="/"
              role="button"
            >
              {addQuestion}
            </a>
          )}
        </Wrapper>
      )}
    </>
  );
}
