import styled from 'styled-components';
import SeatSelector from '../../../../routes/components/SeatSelector';
import Button from '../../Button';
import { Title } from '../../Title';

const StyledButton = styled(Button)`
  width: 100%;
  border: none;
  background-color: #000;
`;

export default function SwitchAccount({ onClose }: { onClose: () => void }) {
  return (
    <div>
      <Title>Switch Account</Title>
      <p>
        You can switch between your accounts by clicking the dropdown below.
      </p>

      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <SeatSelector />
      </div>

      <StyledButton rounded onClick={onClose} size="large">
        Close
      </StyledButton>
    </div>
  );
}
