import styled from 'styled-components';
import Button from '../../../../../shared/components/Button';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import { IntegrationPlatform } from '../redux/integrations.slice';
import getPlatformName from '../utils/getPlatformName';

const Wrapper = styled.div`
  h3 {
    font-size: 18px;
  }
  p {
    margin-bottom: 20px;
  }
`;

type Props = {
  onClose: () => void;
  isDisconnecting: boolean;
  selectedPlatform: IntegrationPlatform | null;
  onDisconnect: (platform: IntegrationPlatform) => void;
};

export default function ConfirmDisconnect({
  onClose,
  onDisconnect,
  isDisconnecting,
  selectedPlatform,
}: Props) {
  if (!selectedPlatform) {
    return null;
  }

  const platformName = getPlatformName(selectedPlatform);

  return (
    <Wrapper>
      <h3>Disconnect {platformName}?</h3>
      <p>
        If you disconnect your {platformName} account, you won’t be able to send
        review requests to your {platformName} contacts anymore. Click{' '}
        <b>Disconnect</b> to proceed.
      </p>

      <FlexContainer justifyContent="flex-end" gap="10px">
        {!isDisconnecting && (
          <Button variant="tertiary" size="small" rounded onClick={onClose}>
            Cancel
          </Button>
        )}

        <Button
          rounded
          size="small"
          variant={`${isDisconnecting ? 'tertiary' : 'danger'}`}
          disabled={isDisconnecting}
          onClick={() => onDisconnect(selectedPlatform)}
        >
          {isDisconnecting ? 'Please wait...' : 'Disconnect'}
        </Button>
      </FlexContainer>
    </Wrapper>
  );
}
