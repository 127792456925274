import { createSlice } from '@reduxjs/toolkit';

type BaseRecipient = {
  name: string;
  email: string;
};

type OrderProduct = {
  name: string;
  imageUrl?: string;
  price: string;
};

export type Recipient =
  | (BaseRecipient & {
      type: 'order';
      orderNumber: string;
      product: OrderProduct;
    })
  | (BaseRecipient & {
      type: 'marketing';
    });

export type ReviewRequestData = {
  formKey: string;
  senderName: string;
  senderEmail: string;
  emailSubject: string;
  message: string;
  rightToLeftContent: boolean;
  recipients: Recipient[];
  formButtonText: string;
};

type AppState = {
  data: ReviewRequestData | null;
};

const initialState: AppState = {
  data: null,
};

const app = createSlice({
  name: 'review-request-data',
  initialState,
  reducers: {
    setReviewRequestData: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setReviewRequestData } = app.actions;

export default app.reducer;
