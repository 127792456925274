export default function LogoCrown() {
  return (
    <svg
      width="511"
      height="511"
      viewBox="0 0 511 511"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="511" height="511" fill="white" />
      <path
        d="M80.9363 202.849L96.2131 217.826L117.736 215.631L107.907 234.722L116.503 254.235L95.1522 251.057L78.9422 265.312L75.5749 244.256L56.9606 233.553L76.2306 223.719L80.9363 202.849Z"
        fill="black"
      />
      <path
        d="M430.994 203.764L434.948 224.789L453.853 235.308L434.867 245.337L430.747 266.258L415.059 251.431L393.607 253.842L402.898 234.649L393.76 215.218L415.19 218.184L430.994 203.764Z"
        fill="black"
      />
      <path
        d="M154.146 170.176L169.574 190.073L195.018 190.941L180.518 211.635L187.434 235.757L163.045 228.65L141.874 242.69L141.301 217.603L121.301 202.159L145.336 193.761L154.146 170.176Z"
        fill="black"
      />
      <path
        d="M356.535 170.157L365.423 193.713L389.485 202.032L369.536 217.542L369.046 242.63L347.829 228.66L323.463 235.847L330.299 211.703L315.731 191.056L341.173 190.104L356.535 170.157Z"
        fill="black"
      />
      <path
        d="M258.564 118L279.818 155.893L323.208 163.968L292.954 195.461L298.516 238.345L258.564 219.916L218.611 238.345L224.173 195.461L193.919 163.968L237.309 155.893L258.564 118Z"
        fill="black"
      />
      <circle cx="134.191" cy="272.743" r="10.1233" fill="black" />
      <circle cx="377.151" cy="272.743" r="10.1233" fill="black" />
      <circle cx="192.039" cy="258.281" r="10.1233" fill="black" />
      <circle cx="319.304" cy="258.281" r="10.1233" fill="black" />
      <circle cx="254.225" cy="253.942" r="11.5695" fill="black" />
      <path
        d="M141.422 321.913C132.166 309.187 132.745 287.687 134.191 278.527C134.191 298.774 160.223 304.559 171.792 301.666C183.362 298.774 192.039 287.205 192.039 266.958C198.981 293.568 218.07 292.989 223.855 292.989C229.64 292.989 244.969 294.146 254.225 264.065C254.225 284.312 274.472 295.882 284.595 295.882C294.719 295.882 307.734 291.543 317.858 269.85C319.304 282.866 330.328 299.784 340.997 301.666C365.582 306.005 375.705 262.619 377.152 278.527C378.598 294.435 369.921 317.575 361.243 330.59C352.566 343.606 355.459 356.622 356.905 369.637C330.873 385.546 320.75 391.33 251.333 394.223C195.799 396.537 163.597 378.797 154.438 369.637C153.956 359.032 150.678 334.64 141.422 321.913Z"
        fill="black"
      />
      <path
        d="M250.737 352.704C292.027 349.86 340.477 362.185 352.566 366.926C316.299 387.072 295.375 387.072 264.687 389.442C233.999 391.812 186.572 384.702 155.884 366.926L155.89 366.924C199.126 356.26 199.128 356.259 250.737 352.704Z"
        fill="white"
      />
    </svg>
  );
}
