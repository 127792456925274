import { useState } from 'react';
import styled from 'styled-components';
import { useAppSelector } from '../../../../store';
import { reviewFormSelector } from '../../redux/review-form.slice';
import FormPreview from './FormPreview';
import Header from './Header';

/* eslint no-console: "warn" */

const PreviewToggleContainer = styled.div`
  display: flex;
  width: 72px;
  justify-content: space-between;
  padding: 5px;
  border-radius: 8px;
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #e5e5e5;
  border-radius: 10px;
  z-index: 100;
`;

const PreviewToggleButton = styled.button<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 32px;
  padding: 0 5px;
  border: 1px solid ${(props) => (props.$isActive ? '#3b82f6' : 'transparent')};
  border-radius: 6px;
  background: ${(props) => (props.$isActive ? '#fff' : 'transparent')};
  color: ${(props) => (props.$isActive ? '#3b82f6' : '#64748b')};
  cursor: pointer;
  transition: all 0.2s ease;

  svg {
    font-size: 20px;
  }
`;

const PreviewFrame = styled.div<{
  $backgroundColor?: string;
  $mode: 'mobile' | 'desktop';
}>`
  width: ${(props) => (props.$mode === 'mobile' ? '375px' : '100%')};
  height: ${(props) => (props.$mode === 'mobile' ? '667px' : '100%')};
  margin: ${(props) => (props.$mode === 'mobile' ? '20px auto' : '0')};
  border-radius: ${(props) => (props.$mode === 'mobile' ? '20px' : 0)};
  background: ${(props) =>
    props.$backgroundColor
      ? `linear-gradient(
          180deg,
          ${props.$backgroundColor} 0%,
          ${props.$backgroundColor}ee 45%,
          ${props.$backgroundColor}dd 75%,
          ${props.$backgroundColor}cc 100%
        )`
      : '#ffffff'};
  backdrop-filter: blur(10px);
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: ${(props) =>
      props.$backgroundColor
        ? `radial-gradient(circle at 50% 0%, 
            ${props.$backgroundColor}22 0%,
            transparent 50%)`
        : 'transparent'};
    pointer-events: none;
  }
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: auto;
  flex: ${(props) => (props.$mode === 'desktop' ? '1' : 'none')};
  align-self: center;
  transform-origin: center;
  transform: ${(props) => (props.$mode === 'mobile' ? 'scale(1)' : 'none')};
  display: flex;
  align-items: center;

  /* ${(props) =>
    props.$mode === 'mobile' &&
    `
    top: 80px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 40%;
      height: 20px;
      background: #e2e8f0;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      z-index: 100;
    }
  `} */

  ${(props) =>
    props.$mode === 'mobile' &&
    `top: 80px;
  `}
`;

const PreviewContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function Preview() {
  const { reviewFormSettings } = useAppSelector(reviewFormSelector);
  const { appearance } = reviewFormSettings ?? {};
  const { backgroundColor } = appearance ?? {};
  const [previewMode, setPreviewMode] = useState<'mobile' | 'desktop'>(
    'desktop'
  );

  return (
    <PreviewContainer>
      {/* <PreviewToggleContainer>
        <PreviewToggleButton
          $isActive={previewMode === 'desktop'}
          onClick={() => setPreviewMode('desktop')}
          title="Desktop preview"
        >
          <MdDesktopWindows />
        </PreviewToggleButton>
        <PreviewToggleButton
          $isActive={previewMode === 'mobile'}
          onClick={() => setPreviewMode('mobile')}
          title="Mobile preview"
        >
          <MdPhoneIphone />
        </PreviewToggleButton>
      </PreviewToggleContainer> */}

      <Header />

      <PreviewFrame $mode={previewMode} $backgroundColor={backgroundColor}>
        <FormPreview mode={previewMode} />
      </PreviewFrame>
    </PreviewContainer>
  );
}
