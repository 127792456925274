import { unwrapResult } from '@reduxjs/toolkit';
import { capitalize } from 'lodash';
import { FaPlus } from 'react-icons/fa6';
import { Button, InputPicker } from 'rsuite';
import styled from 'styled-components';
import InputPickerLabel from '../../../../shared/components/InputPickerLabel';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import { useNotification } from '../../../../shared/hooks/useNotification';
import { Loading } from '../../../../shared/types/review.types';
import setSession from '../../../../shared/utils/setSession';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { ReviewsJetPlatform } from '../../../product/types';
import { selectSeat } from '../../../userData/redux/action';
import { accountSelector } from '../../../userData/redux/userData.slice';

/* eslint no-console: "warn" */

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const Wrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;

  .title {
    font-size: 14px;
    font-weight: bold;
  }
`;

const Header = styled.div`
  padding: 15px 20px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid rgb(235, 235, 235);
`;

const FormGroup = styled.div`
  width: 100%;
`;

const Content = styled.div`
  padding: 20px;
  margin-bottom: 20px;
`;

const Footer = styled(FlexContainer)`
  padding: 15px 20px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  border-top: 1px solid rgb(235, 235, 235);
`;

const StyledContent = styled(Content)`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Description = styled.p`
  color: #666;
  line-height: 1.5;
  margin: 0;
`;

const SiteSelector = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
`;

const AddSiteButton = styled(Button)`
  background-color: #111827;
  color: white;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  border-radius: 6px;
  font-weight: 500;
  border: none;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #1f2937;
    transform: translateY(-1px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
    background-color: #000000;
  }

  svg {
    font-size: 14px;
  }
`;

export type PasswordPayload = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export default function ConnectedSites() {
  const dispatch = useAppDispatch();
  const { account, loading } = useAppSelector(accountSelector);
  const { selectedSite, seats = [] } = account ?? {};
  const { showErrorToast } = useNotification();

  const handleAddSite = () => {
    window.location.href = '/add-site';
  };

  if (loading !== Loading.SUCCESS) {
    return null;
  }

  return (
    <Wrapper>
      <StyledContent>
        <TitleSection>
          <h3 className="title">Workspace</h3>
          <AddSiteButton appearance="primary" onClick={handleAddSite}>
            <FaPlus />
            <b>Add Site</b>
          </AddSiteButton>
        </TitleSection>

        <Description>
          Manage all your client websites from one workspace. Easily switch
          between websites or add new ones to your workspace.
        </Description>

        <SiteSelector>
          <InputPicker
            cleanable={false}
            placeholder="Select a website"
            data={seats
              .filter(
                (seat) =>
                  ![
                    '65a58aaa8e7e78680643d312',
                    '6682d52bcd5af1b56c6ee4d9',
                  ].includes(seat._id)
              )
              .map((seat) => ({
                label: `${seat.siteDisplayName ?? seat.url} ${
                  seat.platform !== ReviewsJetPlatform.OTHERS
                    ? `(${capitalize(seat.platform)})`
                    : ''
                }`,
                value: seat._id,
              }))}
            renderValue={() => (
              <InputPickerLabel
                label=""
                value={selectedSite?.siteDisplayName ?? selectedSite?.url ?? ''}
              />
            )}
            value={selectedSite?._id ?? ''}
            onChange={(value) => {
              dispatch(selectSeat(value))
                .then(unwrapResult)
                .then(setSession)
                .then(() => (window.location.href = '/manage-reviews'))
                .catch((e) => {
                  showErrorToast('Something went wrong. Please try it again.');
                });
            }}
            style={{ width: '100%' }}
          />
        </SiteSelector>
      </StyledContent>
    </Wrapper>
  );
}
