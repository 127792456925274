import { IoWarning } from 'react-icons/io5';
import styled from 'styled-components';

const ModalContent = styled.div`
  padding: 24px;
  text-align: center;
`;

const WarningIcon = styled(IoWarning)`
  color: #d97706;
  margin-bottom: 16px;
  width: 32px;
  height: 32px;
`;

const Title = styled.h3`
  font-size: 20px;
  font-weight: 600;
  color: #92400e;
  margin: 0 0 16px;
`;

const Message = styled.p`
  color: #78350f;
  margin: 0 0 20px;
  font-size: 15px;
  line-height: 1.6;
`;

const Link = styled.a`
  color: #b45309;
  text-decoration: underline;
  font-weight: 500;
  transition: all 0.2s ease;

  &:hover {
    color: #92400e;
    text-decoration-thickness: 2px;
  }
`;

const SupportMessage = styled.p`
  color: #64748b;
  font-size: 14px;
  margin: 20px 0 0;
`;

export default function WarningDuplicateSubscription() {
  return (
    <ModalContent>
      <WarningIcon />
      <Title>Active Subscription Detected</Title>
      <Message>
        We've detected that you have an active subscription through Wix. To
        avoid double billing, please cancel your current subscription before
        proceeding.
      </Message>
      <Link
        href="https://support.wix.com/en/article/canceling-an-app-subscription"
        target="_blank"
        rel="noopener noreferrer"
      >
        View cancellation instructions
      </Link>
      <SupportMessage>
        Need help? Contact us at{' '}
        <Link href="mailto:hello@reviewsjet.com">hello@reviewsjet.com</Link> or
        use the help widget in the bottom right corner.
      </SupportMessage>
    </ModalContent>
  );
}
