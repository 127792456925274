import { createAsyncThunk } from '@reduxjs/toolkit';
import Http from '../../../services/http';
import {
  AppleConnectFormData,
  GoogleConnectFormData,
  Loading,
} from '../../../shared/types/review.types';
import removeTrailingSlash from '../../../shared/utils/removeTrailingSlash';
import { RootState } from '../../../store';
import { TransformedProduct } from '../../product/types';
import { IntegrationPlatform } from './social-reviews.slice';

const http = new Http();

export const findYelpPlace = createAsyncThunk<
  any,
  string,
  { rejectValue: string; state: RootState }
>('integration/findYelpPlace', async (yelpUrl, { rejectWithValue }) => {
  try {
    const url = `${removeTrailingSlash(
      process.env.REACT_APP_REVIEWS_JET_API!
    )}/yelp/search?yelpUrl=${yelpUrl}`;

    const response = await http.get(url);
    return response.data.response;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const searchYelpBusiness = createAsyncThunk<
  any,
  { name: string; address: string; start?: number },
  { rejectValue: string; state: RootState }
>(
  'integration/searchYelpBusiness',
  async ({ name, address, start = 0 }, { rejectWithValue }) => {
    try {
      let url = `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/yelp/search?name=${encodeURIComponent(
        name
      )}&address=${encodeURIComponent(address)}`;

      if (start) {
        url += `&start=${start}`;
      }

      const response = await http.get(url);
      return response.data.response;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const addYelpAccount = createAsyncThunk<
  any,
  { placeId: string; pageTitle: string },
  { rejectValue: string; state: RootState }
>(
  'integration/addYelpAccount',
  async ({ placeId, pageTitle }, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/yelp/account`,
        { placeId, pageTitle }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const syncYelpAccount = createAsyncThunk<
  any,
  { placeId: string },
  { rejectValue: string; state: RootState }
>('integration/syncYelpAccount', async ({ placeId }, { rejectWithValue }) => {
  try {
    const response = await http.post(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/yelp/sync/${placeId}/`
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const syncGoogleAccount = createAsyncThunk<
  any,
  { placeId: string; dataId?: string },
  { rejectValue: string; state: RootState }
>(
  'integration/google/sync',
  async ({ placeId, dataId }, { rejectWithValue }) => {
    const data = { placeId, dataId };

    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/google/account/sync`,
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const getGoogleBusinesses = createAsyncThunk<
  any,
  GoogleConnectFormData,
  { rejectValue: string; state: RootState }
>(
  'integration/google/businesses',
  async (
    { gpsCoordinates, businessAddress, hl, start = 0 },
    { rejectWithValue }
  ) => {
    try {
      let url = `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/google/businesses?businessAddress=${encodeURIComponent(
        businessAddress
      )}&gpsCoordinates=${gpsCoordinates}&hl=${hl}`;

      if (start) {
        url += `&start=${start}`;
      }

      const response = await http.get(url);
      return response.data.response;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const addGoogleAccount = createAsyncThunk<
  any,
  { dataId: string; placeId: string; language: string; pageTitle: string },
  { rejectValue: string; state: RootState }
>(
  'integration/google/add',
  async ({ dataId, placeId, language, pageTitle }, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/google/account`,
        { dataId, placeId, language, pageTitle }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const get3rdPartyAccount = createAsyncThunk<
  any,
  void,
  { rejectValue: string; state: RootState }
>(
  'integration/third-party/connected',
  async (_args, { rejectWithValue }) => {
    try {
      const response = await http.get(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/settings/third-party/connected`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        socialReviews: {
          get3rdPartyAccount: { loading },
        },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const deleteIntegration = createAsyncThunk<
  any,
  {
    placeId?: string;
    pageId?: string;
    dataId?: string;
    productId?: string;
    propertyId?: string;
    platform: IntegrationPlatform;
  },
  { rejectValue: string; state: RootState }
>(
  'integration/delete',
  async (
    { placeId, dataId, platform, productId, pageId, propertyId },
    { rejectWithValue }
  ) => {
    let url = '';

    switch (platform) {
      case IntegrationPlatform.GOOGLE_MAPS_REVIEWS:
        url = `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/google/account/${placeId}/${dataId}`;
        break;
      case IntegrationPlatform.YELP_REVIEWS:
        url = `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/yelp/${placeId}`;
        break;
      case IntegrationPlatform.APPLE_REVIEWS:
        url = `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/apple/account/${productId}`;
        break;
      case IntegrationPlatform.PRODUCTHUNT_REVIEWS:
        url = `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/producthunt/${pageId}`;
        break;
      case IntegrationPlatform.TRUSTPILOT_REVIEWS:
        url = `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/trustpilot/${pageId}`;
        break;
      case IntegrationPlatform.CAPTERRA_REVIEWS: {
        if (!pageId) {
          throw new Error('pageId missing');
        }
        url = `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/capterra/${encodeURIComponent(pageId)}`;
        break;
      }
      case IntegrationPlatform.TRIPADVISOR_REVIEWS: {
        if (!pageId) {
          throw new Error('pageId missing');
        }
        url = `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/tripadvisor/${encodeURIComponent(pageId)}`;
        break;
      }
      case IntegrationPlatform.AIRBNB_REVIEWS: {
        if (!propertyId) {
          throw new Error('propertyId missing');
        }
        url = `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/airbnb/${propertyId}`;
        break;
      }
      default:
        break;
    }

    try {
      const response = await http.delete(url);
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const getAppleStores = createAsyncThunk<
  any,
  AppleConnectFormData,
  { rejectValue: string; state: RootState }
>(
  'integration/apple/stores',
  async ({ search, country, lang, page = 0 }, { rejectWithValue }) => {
    try {
      let url = `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/apple/stores?search=${search}&lang=${lang}&country=${country}&page=${page}`;

      const response = await http.get(url);
      return response.data.response;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const addAppleAccount = createAsyncThunk<
  any,
  { productId: string; pageTitle: string; language: string; country: string },
  { rejectValue: string; state: RootState }
>(
  'integration/apple/add',
  async ({ productId, pageTitle, language, country }, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/apple/account`,
        { productId, pageTitle, language, country }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const syncAppleAccount = createAsyncThunk<
  any,
  { productId: string; country: string },
  { rejectValue: string; state: RootState }
>(
  'integration/apple/sync',
  async ({ productId, country }, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/apple/account/sync`,
        { productId, country }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const syncProducthuntAccount = createAsyncThunk<
  any,
  { pageId: string },
  { rejectValue: string; state: RootState }
>(
  'integration/syncProducthuntAccount',
  async ({ pageId }, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/producthunt/account`,
        { search: pageId }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const addProducthuntAccount = createAsyncThunk<
  any,
  { search: string; connectAndGetReviews?: boolean },
  { rejectValue: string; state: RootState }
>(
  'integration/addProducthuntAccount',
  async ({ search, connectAndGetReviews = false }, { rejectWithValue }) => {
    try {
      let url = 'producthunt/account';

      if (connectAndGetReviews) {
        url = 'producthunt/connect-and-get-reviews';
      }

      const response = await http.post(
        `${removeTrailingSlash(process.env.REACT_APP_REVIEWS_JET_API!)}/${url}`,
        { search, action: 'connect' }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const syncTrustpilotAccount = createAsyncThunk<
  any,
  { pageId: string },
  { rejectValue: string; state: RootState }
>(
  'integration/syncTrustpilotAccount',
  async ({ pageId }, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/trustpilot/account`,
        { search: pageId }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const addTrustpilotAccount = createAsyncThunk<
  any,
  { search: string; connectAndGetReviews?: boolean },
  { rejectValue: string; state: RootState }
>(
  'integration/addTrustpilotAccount',
  async ({ search, connectAndGetReviews = false }, { rejectWithValue }) => {
    let url = 'trustpilot/account';

    if (connectAndGetReviews) {
      url = 'trustpilot/connect-and-get-reviews';
    }

    try {
      const response = await http.post(
        `${removeTrailingSlash(process.env.REACT_APP_REVIEWS_JET_API!)}/${url}`,
        { search, action: 'connect' }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const addTripadvisorAccount = createAsyncThunk<
  any,
  { search: string; connectAndGetReviews?: boolean },
  { rejectValue: string; state: RootState }
>(
  'integration/addTripadvisorAccount',
  async ({ search, connectAndGetReviews = false }, { rejectWithValue }) => {
    let url = 'tripadvisor/account';

    if (connectAndGetReviews) {
      url = 'tripadvisor/connect-and-get-reviews';
    }

    try {
      const response = await http.post(
        `${removeTrailingSlash(process.env.REACT_APP_REVIEWS_JET_API!)}/${url}`,
        { search, action: 'connect' }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const addCapterraAccount = createAsyncThunk<
  any,
  { search: string },
  { rejectValue: string; state: RootState }
>('integration/addCapterraAccount', async ({ search }, { rejectWithValue }) => {
  try {
    const response = await http.post(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/capterra/account`,
      { search, action: 'connect' }
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const syncCapterraAccount = createAsyncThunk<
  any,
  { pageId: string },
  { rejectValue: string; state: RootState }
>(
  'integration/syncCapterraAccount',
  async ({ pageId }, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/capterra/account`,
        { search: pageId }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const syncTripadvisorAccount = createAsyncThunk<
  any,
  { pageId: string },
  { rejectValue: string; state: RootState }
>(
  'integration/syncTripadvisorAccount',
  async ({ pageId }, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/tripadvisor/account`,
        { search: pageId }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const addAirbnbAccount = createAsyncThunk<
  any,
  { search: string },
  { rejectValue: string; state: RootState }
>('integration/addAirbnbAccount', async ({ search }, { rejectWithValue }) => {
  try {
    const response = await http.post(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/airbnb/account`,
      { search }
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const syncAirbnbAccount = createAsyncThunk<
  any,
  { search: string },
  { rejectValue: string; state: RootState }
>('integration/syncAirbnbAccount', async ({ search }, { rejectWithValue }) => {
  try {
    const response = await http.post(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/airbnb/sync/${search}/`
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const addAmazonProductPage = createAsyncThunk<
  any,
  { search: string; product: TransformedProduct | null },
  { rejectValue: string; state: RootState }
>(
  'integration/addAmazonProductPage',
  async ({ search, product }, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/amazon/addProduct`,
        { search, product, action: 'connect' }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const syncAmazonProductReviews = createAsyncThunk<
  any,
  { pageId: string; product: TransformedProduct | null },
  { rejectValue: string; state: RootState }
>(
  'integration/syncAmazonProductReviews',
  async ({ pageId, product }, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/amazon/addProduct`,
        { search: pageId, product }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);

export const addBookingAccount = createAsyncThunk<
  any,
  { search: string },
  { rejectValue: string; state: RootState }
>('integration/addBookingAccount', async ({ search }, { rejectWithValue }) => {
  try {
    const response = await http.post(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/booking/account`,
      { search, action: 'connect' }
    );
    return response.data;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const syncBookingReviews = createAsyncThunk<
  any,
  { pageId: string; product: TransformedProduct | null },
  { rejectValue: string; state: RootState }
>(
  'integration/syncBookingReviews',
  async ({ pageId, product }, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/booking/account`,
        { search: pageId, product }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  }
);
