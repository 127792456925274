import { ReactNode } from 'react';
import styled from 'styled-components';
import Button from '../../../../../shared/components/Button';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import Tag from '../../../../../shared/components/Tag';

const Wrapper = styled(FlexContainer)`
  padding: 15px 20px;
  justify-content: space-between;
  border-bottom: 1px solid rgb(235, 235, 235);
  /* margin-bottom: 10px; */

  &:last-child {
    border: 0;
  }

  .name {
    display: block;
    min-width: 150px;
    color: #3e3e3e;
    font-weight: bold;
  }
`;

type Props = {
  title: string;
  logo: ReactNode;
  isConnected: boolean;
  onConnect: () => void;
  onDisconnect: () => void;
};

export default function Item(props: Props) {
  const { isConnected, onDisconnect, title, logo, onConnect } = props;

  return (
    <Wrapper>
      <FlexContainer justifyContent="flex-start" gap="15px">
        {logo}
        <span className="name">{title}</span>
        {isConnected && <Tag color="blue">Connected</Tag>}
      </FlexContainer>
      {isConnected ? (
        <Button size="small" variant="danger" rounded onClick={onDisconnect}>
          Disconnect
        </Button>
      ) : (
        <Button size="small" rounded onClick={onConnect}>
          Connect
        </Button>
      )}
    </Wrapper>
  );
}
