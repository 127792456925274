import styled from 'styled-components';
import { ReviewRatingSummary } from '../../../../../../review/types';
import { SiteWidgetSettings } from '../../../../../../widgetSettings/redux/widgetSettings.slice';
import FullStar from '../shared/FullStar';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 300px;
  min-width: 200px;
  width: 50%;
`;

const BarWrapper = styled.div<{ value: number; color: string }>`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;

  .star-number {
    display: flex;
    gap: 2px;
    align-items: center;
    font-size: 14px;
    white-space: nowrap;
    font-family: 'Body-Font', sans-serif;
    line-height: 10px;
    width: 025px;
  }
  .bar {
    width: calc(100% - 65px);
    height: 15px;
    background-color: #c8c8c8;
    border-radius: 0;
  }
  .active-bar {
    background: ${({ color }) => color};
    width: ${({ value }) => value}%;
    height: 100%;
    border-radius: 0;

    &:empty {
      display: block !important;
    }
  }
  .total-reviews {
    font-size: 14px;
    font-family: 'Body-Font', sans-serif;
    line-height: 10px;
  }
`;

type RatingBarWrapperProps = {
  star: number;
  color: string;
  value: number;
  perValue: number;
  textColor: string;
  fontSize: number;
  // ratingSummary: ReviewRatingSummary | null;
};

const RatingBarWrapper = ({
  color,
  value,
  star,
  perValue,
  textColor,
  fontSize,
}: RatingBarWrapperProps) => {
  return (
    <BarWrapper value={perValue} color={color}>
      <div className="star-number" style={{ color: textColor, fontSize }}>
        {star} <FullStar color={color} size={fontSize - 3} />
      </div>
      <div className="bar">
        <div className="active-bar"></div>
      </div>
      <div className="total-reviews" style={{ color: textColor, fontSize }}>
        {value}
      </div>
    </BarWrapper>
  );
};

type Props = {
  totalDocs: number;
  settings: SiteWidgetSettings;
  ratingSummary: ReviewRatingSummary | null;
};

export default function RatingSummary({
  settings,
  ratingSummary,
  totalDocs,
}: Props) {
  const {
    productWidget: { textColor, starColor, fontSize },
  } = settings;

  return (
    <Wrapper>
      <RatingBarWrapper
        key={5}
        color={starColor}
        value={ratingSummary?.fiveStars ?? 0}
        star={5}
        perValue={((ratingSummary?.fiveStars ?? 0) * 100) / totalDocs}
        textColor={textColor}
        fontSize={fontSize}
      />

      <RatingBarWrapper
        key={4}
        color={starColor}
        value={ratingSummary?.fourStars ?? 0}
        star={4}
        perValue={((ratingSummary?.fourStars ?? 0) * 100) / totalDocs}
        textColor={textColor}
        fontSize={fontSize}
      />

      <RatingBarWrapper
        key={3}
        color={starColor}
        value={ratingSummary?.threeStars ?? 0}
        star={3}
        perValue={((ratingSummary?.threeStars ?? 0) * 100) / totalDocs}
        textColor={textColor}
        fontSize={fontSize}
      />

      <RatingBarWrapper
        key={2}
        color={starColor}
        value={ratingSummary?.twoStars ?? 0}
        star={2}
        perValue={((ratingSummary?.twoStars ?? 0) * 100) / totalDocs}
        textColor={textColor}
        fontSize={fontSize}
      />
      <RatingBarWrapper
        key={1}
        color={starColor}
        value={ratingSummary?.oneStars ?? 0}
        star={1}
        perValue={((ratingSummary?.oneStars ?? 0) * 100) / totalDocs}
        textColor={textColor}
        fontSize={fontSize}
      />
    </Wrapper>
  );
}

// 60 + 20 + 13 + 5 + 2
