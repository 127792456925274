import { Control, Controller, FieldErrors } from 'react-hook-form';
import { Input, InputGroup } from 'rsuite';
import styled, { css } from 'styled-components';
import { BusinessProfile } from '.';
import FlexContainer from '../../../../shared/components/layouts/flex-container';

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: #fff;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const Header = styled.div`
  padding: 15px 20px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  border-top: 1px solid rgb(235, 235, 235);
  border-bottom: 1px solid rgb(235, 235, 235);
`;

const FormGroup = styled.div`
  width: 100%;
`;

const Content = styled.div`
  padding: 20px;
  margin-bottom: 20px;

  .title {
    font-size: 15px;
    margin-bottom: 20px;
  }
`;

const Footer = styled(FlexContainer)`
  padding: 15px 20px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  border-top: 1px solid rgb(235, 235, 235);
`;

const CustomInput = styled(Input)<{ error?: boolean }>`
  font-style: normal;
  background: #fff;
  border-radius: 5px;
  max-width: 100%;
  outline: none !important;
  margin-bottom: 5px;
  border: 1px solid #cecece;
  &:active {
    border: 1px solid #cecece;
  }

  ${({ error }) =>
    error &&
    css`
      border-color: red;
    `}
`;

const ErroMessage = styled.span`
  display: block;
  color: red;
  margin-bottom: 15px;
`;

export type BusinessContactInfo = {
  website: string;
  email: string;
  telephone: string;
  x: string;
  facebook: string;
  instagram: string;
};

type Props = {
  errors: FieldErrors<BusinessProfile>;
  control: Control<BusinessProfile, any>;
};

export default function Contact({ control, errors }: Props) {
  return (
    <Wrapper>
      <Content>
        <h4 className="title">Business contact info</h4>

        <FlexContainer
          stack
          justifyContent="flex-start"
          alignItems="flex-start"
          gap="15px"
        >
          <FlexContainer
            style={{ width: '100%' }}
            justifyContent="space-between"
            gap="10px"
          >
            <div style={{ width: '100%' }}>
              <FormGroup>
                <Label>Website</Label>
                <Controller
                  name="website"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomInput
                      placeholder="https://apple.com"
                      error={Boolean(errors.website)}
                      {...field}
                    />
                  )}
                />
                {errors.website && (
                  <ErroMessage>{errors.website.message}</ErroMessage>
                )}
              </FormGroup>
            </div>
            <div style={{ width: '100%' }}>
              <FormGroup>
                <Label>Email</Label>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomInput
                      placeholder="https://hello@apple.com"
                      error={Boolean(errors.email)}
                      {...field}
                    />
                  )}
                />
                {errors.email && (
                  <ErroMessage>{errors.email.message}</ErroMessage>
                )}
              </FormGroup>
            </div>
          </FlexContainer>
          <FlexContainer
            style={{ width: '100%' }}
            justifyContent="space-between"
            gap="10px"
          >
            <div style={{ width: '100%' }}>
              <FormGroup>
                <Label>Telephone</Label>
                <Controller
                  name="telephone"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomInput
                      placeholder="805-123-4568"
                      error={Boolean(errors.telephone)}
                      {...field}
                    />
                  )}
                />
                {errors.telephone && (
                  <ErroMessage>{errors.telephone.message}</ErroMessage>
                )}
              </FormGroup>
            </div>
            <div style={{ width: '100%' }}>
              <FormGroup>
                <Label>X (Formerly Twitter)</Label>
                <Controller
                  name="x"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <InputGroup inside>
                      <InputGroup.Addon>https://x.com/</InputGroup.Addon>
                      <CustomInput
                        placeholder="apple"
                        error={Boolean(errors.x)}
                        {...field}
                        style={{ margin: 0 }}
                      />
                    </InputGroup>
                  )}
                />

                {errors.x && <ErroMessage>{errors.x.message}</ErroMessage>}
              </FormGroup>
            </div>
          </FlexContainer>

          <FlexContainer
            style={{ width: '100%' }}
            justifyContent="space-between"
            gap="10px"
          >
            <div style={{ width: '100%' }}>
              <FormGroup>
                <Label>Facebook</Label>
                <Controller
                  name="facebook"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <InputGroup inside>
                      <InputGroup.Addon>https://facebook.com/</InputGroup.Addon>
                      <CustomInput
                        placeholder="apple"
                        error={Boolean(errors.facebook)}
                        {...field}
                        style={{ margin: 0 }}
                      />
                    </InputGroup>
                  )}
                />
                {errors.facebook && (
                  <ErroMessage>{errors.facebook.message}</ErroMessage>
                )}
              </FormGroup>
            </div>
            <div style={{ width: '100%' }}>
              <FormGroup>
                <Label>Instagram</Label>
                <Controller
                  name="instagram"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <InputGroup inside>
                      <InputGroup.Addon>
                        https://instagram.com/
                      </InputGroup.Addon>
                      <CustomInput
                        placeholder="apple"
                        error={Boolean(errors.instagram)}
                        {...field}
                        style={{ margin: 0 }}
                      />
                    </InputGroup>
                  )}
                />
                {errors.instagram && (
                  <ErroMessage>{errors.instagram.message}</ErroMessage>
                )}
              </FormGroup>
            </div>
          </FlexContainer>
        </FlexContainer>
      </Content>
      {/* <Footer justifyContent="flex-end">
        <Button
          rounded
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={saving === Loading.PENDING}
          variant={saving === Loading.PENDING ? 'tertiary' : 'secondary'}
        >
          {saving === Loading.PENDING ? 'Please wait...' : 'Save info'}
        </Button>
      </Footer> */}
    </Wrapper>
  );
}
