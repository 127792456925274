import parseHtml from 'html-react-parser';
import styled, { css } from 'styled-components';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import useChangeLogo from '../../../../shared/hooks/useChangeLogo';
import { SubscriptionPlan } from '../../../../shared/types/review.types';
import { useAppSelector } from '../../../../store';
import {
  accountSelector,
  selectedSiteSelector,
} from '../../../userData/redux/userData.slice';
import { Order } from '../types';
import {
  formatEmailMessage,
  formatEmailSubject,
  getCustomerDetails,
} from '../utils/common';

const Container = styled.div`
  display: flex;
  gap: 24px;
  padding: 20px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const PreviewSection = styled.div`
  flex: 1;
  /* min-width: 650px; */
  background: white;
  border-radius: 12px;
  padding: 10px 24px;

  .tag-wrapper {
    margin-bottom: 16px;
  }
`;

const ConfigSection = styled.div`
  max-width: 650px;
  background: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const SectionTitle = styled.h2`
  margin-bottom: 16px;
`;

const EmailPreview = styled.div`
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  overflow: hidden;
`;

const EmailHeader = styled.div`
  background: #fff;
  padding: 16px 0;
  border-bottom: 1px solid #e2e8f0;
`;

const EmailField = styled.div`
  display: flex;
  gap: 8px;
  font-size: 14px;
  color: #475569;
  margin-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  &.subject {
    border-top: 1px solid #e2e8f0;
    padding-top: 16px;
  }
`;

const FieldLabel = styled.span`
  font-weight: 600;
  color: #000;
  width: 70px;
  font-size: 14px;
`;

const EmailBody = styled.div`
  padding: 50px;
  color: #334155;
  font-size: 14px;
  line-height: 1.6;
  background-color: #f3f4f6;

  .email-body-wrapper {
    padding: 30px;
    max-width: 520px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #e2e8f0;

    .unsubscribe {
      display: block;
      text-align: center;
      margin-top: 24px;
      font-size: 12px;
      color: #6b7280;
    }
  }
`;

const Message = styled.div<{ $rtl: boolean }>`
  margin-bottom: 24px;
  ${({ $rtl }) =>
    $rtl &&
    css`
      direction: rtl;
    `}
`;

const ReviewButton = styled.button`
  display: inline-block;
  background: #0f172a;
  color: white;
  padding: 12px 24px;
  border-radius: 6px;
  font-weight: 600;
  border: none;
  margin: 8px 0 0;
  cursor: pointer;
  width: 100%;
`;

const PoweredBy = styled.div`
  text-align: center;
  margin-top: 24px;
  border-top: 1px solid #e2e8f0;
  font-size: 12px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border: 1px solid #e2e8f0;
  padding: 3px 10px;
  border-radius: 30px;
  width: max-content;
  margin: 24px auto 0;
  img {
    width: 26px;
    height: 26px;
    margin-right: 2px;
  }

  span {
    font-weight: 500;
    color: #000000;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;

  img {
    max-width: 100px;
    max-height: 70px;
    object-fit: contain;
  }
`;

const HtmlContentWrapper = styled.div`
  p {
    margin: 0;
  }
`;

const ProductDetails = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
  margin-top: 16px;
`;

const ProductImage = styled.div`
  width: 80px;
  height: 80px;
  margin-right: 1rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
`;

const ProductInfo = styled.div`
  flex: 1;
`;

const ProductName = styled.div`
  font-weight: 600;
  margin-bottom: 0.25rem;
`;

const ProductPrice = styled.div`
  color: #666;
`;

export default function Preview({ orders }: { orders: Order[] }) {
  const selectedSite = useAppSelector(selectedSiteSelector);
  const { plan } = selectedSite ?? {};
  const { account } = useAppSelector(accountSelector);
  const { ownerEmail } = account ?? {};
  const { fullName, email, firstName } = getCustomerDetails(orders[0]);
  const { data: reviewRequestData } = useAppSelector(
    (state) => state.reviewRequestData
  );
  const {
    emailSubject = '',
    message = '',
    formButtonText,
    rightToLeftContent,
  } = reviewRequestData ?? {};
  const formattedMessage = formatEmailMessage(message, firstName);
  const { data: businessInfo } = useAppSelector((state) => state.businessInfo);
  const { logo } = businessInfo ?? {};
  const { logoUrl } = useChangeLogo({ logo });

  return (
    <PreviewSection>
      <EmailPreview>
        <EmailHeader>
          <EmailField>
            <FieldLabel>From:</FieldLabel>
            <span>
              <b> ReviewsJet</b> &lt;<span>{ownerEmail}</span>&gt;
            </span>
          </EmailField>
          <EmailField>
            <FieldLabel>To:</FieldLabel>
            <span>
              <b>{fullName}</b> &lt;<span>{email}</span>&gt;
            </span>
          </EmailField>
          <EmailField className="subject">
            <FieldLabel>Subject:</FieldLabel>
            <span>{formatEmailSubject(emailSubject, firstName)}</span>
          </EmailField>
        </EmailHeader>

        <EmailBody>
          <div className="email-body-wrapper">
            {logoUrl && (
              <LogoWrapper>
                <img src={logoUrl} alt="ReviewsJet" className="logo" />
              </LogoWrapper>
            )}

            <Message $rtl={!!rightToLeftContent}>
              <HtmlContentWrapper>
                {parseHtml(formattedMessage)}
              </HtmlContentWrapper>

              {orders[0].productName && (
                <ProductDetails key={orders[0].id}>
                  {orders[0].productImage && (
                    <ProductImage>
                      <img
                        src={orders[0].productImage}
                        alt={orders[0].productName}
                        onError={(e) => {
                          e.currentTarget.style.display = 'none';
                        }}
                      />
                    </ProductImage>
                  )}
                  <ProductInfo>
                    <ProductName>{orders[0].productName}</ProductName>
                    <ProductPrice>Order: {orders[0].orderNumber}</ProductPrice>
                  </ProductInfo>
                </ProductDetails>
              )}
            </Message>

            <ReviewButton>{formButtonText}</ReviewButton>

            {plan === SubscriptionPlan.FREE && (
              <PoweredBy>
                Powered by{' '}
                <FlexContainer gap="5px">
                  <img src="/images/logo-crown.svg" alt="" /> ReviewsJet
                </FlexContainer>
              </PoweredBy>
            )}
            <span className="unsubscribe">Unsubscribe</span>
          </div>
        </EmailBody>
      </EmailPreview>
    </PreviewSection>
  );
}
