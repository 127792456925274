import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import FlexContainer from '../../../shared/components/layouts/flex-container';
import useQuery from '../../../shared/hooks/useQuery';
import localStorage from '../../../shared/utils/localStorage';
import { useAppSelector } from '../../../store';
import { OnboardingStep } from '../../onboarding/redux/onboarding.slice';
import { selectedSiteSelector } from '../../userData/redux/userData.slice';

/* eslint no-console: "warn" */

const PageContainer = styled.div``;

const FullScreenDiv = styled(FlexContainer)<{ $isInstallWidget: boolean }>`
  justify-content: flex-start;
  align-items: flex-start;
  height: 100vh;
  background-color: #fff;

  .left-side {
    flex-grow: 1;
    min-width: 400px;
    display: flex;
    justify-content: center;
    align-items: ${({ $isInstallWidget }) =>
      $isInstallWidget ? 'flex-start' : 'center'};
    background-color: #fff;
    width: ${({ $isInstallWidget }) => ($isInstallWidget ? '100%' : 'auto')};
    ${({ $isInstallWidget }) =>
      !$isInstallWidget &&
      css`
        height: 100%;
      `}

    @media (max-width: 768px) {
    }
  }

  .right-side {
    width: 800px;
    height: 100%;
    background-color: #d1d1d1;
    display: ${({ $isInstallWidget }) => ($isInstallWidget ? 'none' : 'block')};

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const Auth = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const session = localStorage.get('session');
  const hasPassword = query.get('p');
  const selectedSite = useAppSelector(selectedSiteSelector);
  const { onboarding } = selectedSite ?? {};
  const { step: onboardingStep } = onboarding ?? {};
  const currentOnboardingStep = useAppSelector(
    (state) => state.onboarding.currentStep
  );
  const isInstallWidget = ['install-widget', 'start-free-trial'].includes(
    currentOnboardingStep
  );

  useEffect(() => {
    if (!session) {
      const currentPath = window.location.pathname;

      if (
        currentPath !== '/forgot-password' &&
        currentPath !== '/signup' &&
        currentPath !== '/webflow/auth' &&
        !currentPath.startsWith('/set-password/') &&
        !currentPath.startsWith('/webflow') &&
        !currentPath.startsWith('/select-site')
      ) {
        return navigate('/login');
      }
    }

    if (!hasPassword) {
      if (onboardingStep === OnboardingStep.Complete) {
        navigate('/manage-reviews');
      }
    }
  }, [session, hasPassword]);

  return (
    <PageContainer>
      <FullScreenDiv $isInstallWidget={isInstallWidget}>
        <div className="left-side">
          <Outlet />
        </div>
        {/* <div className="right-side">
          <Reviews />
        </div> */}
      </FullScreenDiv>
    </PageContainer>
  );
};

export default Auth;
