import { Prism as Highlighter } from 'react-syntax-highlighter';
import { duotoneLight as style } from 'react-syntax-highlighter/dist/esm/styles/prism';

export default function SyntaxHighlighter({ code }: { code: string }) {
  return (
    <Highlighter
      language="html"
      wrapLongLines={true}
      style={style}
      wrapLines={true}
    >
      {code}
    </Highlighter>
  );
}
