import styled from 'styled-components';
import ReviewsJetBranding from './ReviewsJetBranding';

import { useEffect, useState } from 'react';
import { SubscriptionPlan } from '../../../../../shared/types/review.types';
import { useAppSelector } from '../../../../../store';
import { businessInfoSelector } from '../../../../settings/redux/business-info.slice';
import { selectedSiteSelector } from '../../../../userData/redux/userData.slice';
import {
  PreviewScreen,
  reviewFormSelector,
} from '../../../redux/review-form.slice';
import RewardBanner from './RewardBanner';
import RewardModal from './RewardModal';
import Review from './Screens/Review';
import ThankYou from './Screens/ThankYou';
import UserInformation from './Screens/UserInformation';
import Welcome from './Screens/Welcome';
import TryReviewsJet from './TryReviewsJet';

/* eslint no-console: "warn" */

const MainContainer = styled.div`
  position: relative;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 500px;
  padding: 0 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: auto;
`;

const FormWrapper = styled.div`
  padding: 25px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border: 1px solid #e5e7eb;
  margin-top: 10px;
`;

const LogoPlaceholder = styled.div`
  width: 160px;
  height: 64px;
  background: #f3f4f6;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9ca3af;
  font-size: 14px;
  margin: 0 0 20px;
  border: 2px dashed #e5e7eb;
`;

const Logo = styled.img`
  max-width: 200px;
  max-height: 60px;
  object-fit: contain;
  margin-bottom: 20px;
`;

type FormPreviewProps = {
  mode: 'desktop' | 'mobile';
};

/* eslint no-console: "warn" */

export default function FormPreview({ mode }: FormPreviewProps) {
  const { previewScreen, reviewFormSettings } =
    useAppSelector(reviewFormSelector);
  const { data: businessInfo } = useAppSelector(businessInfoSelector);
  const logoPreview = useAppSelector((state) => state.reviewForm.logoPreview);
  const { logo: savedLogo } = businessInfo ?? {};
  const { welcomeScreen, reward } = reviewFormSettings ?? {};
  const { collectWithExternalSites } = welcomeScreen ?? {};
  const {
    enableReward,
    wheelColor,
    wheelTheme,
    segments,
    wheelTitle,
    wheelMessage,
    winTitle,
    winMessage,
    loseTitle,
    loseMessage,
  } = reward ?? {};
  const selectedSite = useAppSelector(selectedSiteSelector);
  const { plan } = selectedSite ?? {};
  const [isRewardModalOpen, setRewardModalOpen] = useState(false);

  const getLogoSrc = (logo: File | string | null) => {
    if (logo instanceof File) {
      return URL.createObjectURL(logo);
    }
    return logo ?? '';
  };

  useEffect(() => {
    if (enableReward) {
      setRewardModalOpen(true);
    }
  }, [
    wheelColor,
    wheelTheme,
    segments,
    wheelTitle,
    wheelMessage,
    winTitle,
    winMessage,
    loseTitle,
    loseMessage,
  ]);

  return (
    <MainContainer>
      <Container>
        <Wrapper>
          <FormWrapper>
            {savedLogo || logoPreview ? (
              <Logo
                src={logoPreview ? getLogoSrc(logoPreview) : savedLogo}
                alt="Company logo"
              />
            ) : (
              <LogoPlaceholder>Your Logo</LogoPlaceholder>
            )}
            {previewScreen === PreviewScreen.WELCOME && <Welcome />}
            {previewScreen === PreviewScreen.REVIEW && <Review />}
            {previewScreen === PreviewScreen.USER_INFO && <UserInformation />}
            {[PreviewScreen.SUCCESS, PreviewScreen.REWARD].includes(
              previewScreen
            ) && <ThankYou />}
          </FormWrapper>

          {!collectWithExternalSites && enableReward && (
            <RewardBanner plan={plan} />
          )}

          {plan === 'free' && (
            <>
              <ReviewsJetBranding />
              {previewScreen === PreviewScreen.SUCCESS && <TryReviewsJet />}
            </>
          )}
        </Wrapper>
      </Container>
      {plan === SubscriptionPlan.ADVANCED &&
        !!enableReward &&
        previewScreen === PreviewScreen.REWARD && (
          <RewardModal
            isOpen={isRewardModalOpen}
            onClose={() => setRewardModalOpen(false)}
          />
        )}
    </MainContainer>
  );
}
