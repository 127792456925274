import { FaAlignCenter, FaAlignLeft, FaAlignRight } from 'react-icons/fa';
import { batch } from 'react-redux';
import { Input } from 'rsuite';
import styled from 'styled-components';
import { Label } from '../../../../shared/components/Label';
import { useAppDispatch, useAppSelector } from '../../../../store';
import FormRow from '../../../widgetSettings/components/form-row';
import {
  siteWidgetSettingsSelector,
  updateSiteWidgetSettings,
  WidgetPosition,
} from '../../../widgetSettings/redux/widgetSettings.slice';
import {
  OptionBeingUpdated,
  setOptionBeingUpdated,
} from '../redux/product-reviews-settings.slice';

const AlignmentOptions = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
`;

const AlignmentOption = styled.div<{ $isSelected: boolean }>`
  width: 80px;
  height: 80px;
  border-radius: 8px;
  border: 2px solid ${(props) => (props.$isSelected ? '#4d24ff' : '#eee')};
  background: ${(props) => (props.$isSelected ? '#fafafa' : 'white')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    border-color: ${(props) => (props.$isSelected ? '#4d24ff' : '#ddd')};
    transform: translateY(-1px);
  }

  svg {
    font-size: 20px;
    color: ${(props) => (props.$isSelected ? '#4d24ff' : '#666')};
  }

  span {
    font-size: 12px;
    color: ${(props) => (props.$isSelected ? '#4d24ff' : '#666')};
    font-weight: ${(props) => (props.$isSelected ? '500' : '400')};
  }
`;

export default function ReviewSummary() {
  const dispatch = useAppDispatch();
  const settings = useAppSelector(siteWidgetSettingsSelector);
  const { translation, starWidget } = settings ?? {};
  const { reviewSummarySingular, reviewSummaryPlural } = translation ?? {};

  const alignmentOptions = [
    {
      value: WidgetPosition.LEFT,
      icon: <FaAlignLeft />,
      label: 'Left',
    },
    {
      value: WidgetPosition.CENTER,
      icon: <FaAlignCenter />,
      label: 'Center',
    },
    {
      value: WidgetPosition.RIGHT,
      icon: <FaAlignRight />,
      label: 'Right',
    },
  ];

  return (
    <>
      <Label>Alignment</Label>
      <AlignmentOptions>
        {alignmentOptions.map((option) => (
          <AlignmentOption
            key={option.value}
            $isSelected={starWidget?.position === option.value}
            onClick={() => {
              batch(() => {
                dispatch(
                  updateSiteWidgetSettings({
                    ...settings,
                    starWidget: {
                      ...starWidget,
                      position: option.value,
                    },
                  })
                );
                dispatch(
                  setOptionBeingUpdated(
                    `${OptionBeingUpdated.REVIEW_SUMMARY_ALIGNMENT}-${option.value}`
                  )
                );
              });
            }}
          >
            {option.icon}
            <span>{option.label}</span>
          </AlignmentOption>
        ))}
      </AlignmentOptions>

      <FormRow marginBottom="20px">
        <Label>When you have only 1 review</Label>
        <Input
          placeholder="1 review"
          value={reviewSummarySingular}
          onChange={(value) => {
            dispatch(
              updateSiteWidgetSettings({
                ...settings,
                translation: {
                  ...settings?.translation,
                  reviewSummarySingular: value,
                },
              })
            );
          }}
          onClick={() => {
            dispatch(
              setOptionBeingUpdated(
                `${OptionBeingUpdated.REVIEW_SUMMARY_SINGULAR}`
              )
            );
          }}
        />
      </FormRow>

      <FormRow marginBottom="0">
        <Label>When you have multiple reviews</Label>
        <Input
          placeholder="{totalReview} reviews"
          value={reviewSummaryPlural}
          onChange={(value) => {
            dispatch(
              updateSiteWidgetSettings({
                ...settings,
                translation: {
                  ...settings?.translation,
                  reviewSummaryPlural: value,
                },
              })
            );
          }}
          onClick={() => {
            dispatch(
              setOptionBeingUpdated(
                `${OptionBeingUpdated.REVIEW_SUMMARY_PLURAL}`
              )
            );
          }}
        />
      </FormRow>
    </>
  );
}
