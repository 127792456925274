import { useEffect, useState } from 'react';
import Preloader from '../shared/Preloader';

interface ImageProps {
  media: string;
  showPreloader?: boolean;
}

export default function ImageElement({
  media,
  showPreloader = true,
}: ImageProps) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(false);
    const img = new Image();
    img.src = media;
    img.onload = () => setIsLoaded(true);
  }, [media]);

  return (
    <>
      {isLoaded ? (
        <img src={media} alt="Preloaded content" />
      ) : (
        <>{showPreloader ? <Preloader size={50} /> : null}</>
      )}
    </>
  );
}
