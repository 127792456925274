import range from '../../../../../../../shared/utils/range';

type Props = {
  color: string;
  rating: number;
  center?: boolean;
  size?: number;
  gap?: number;
};

const FullRating = ({ color, size }: { color: string; size?: number }) => {
  return (
    <svg
      stroke={color}
      fill={color}
      strokeWidth="0"
      viewBox="0 0 576 512"
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"></path>
    </svg>
  );
};

const EmptyRating = ({ size }: { size?: number }) => {
  return (
    <svg
      strokeWidth="0"
      viewBox="0 0 576 512"
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#999999"
        d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
      ></path>
    </svg>
  );
};

const ReviewRating = ({ color, rating, size = 20, gap = 2 }: Props) => {
  return (
    <div
      style={{
        gap,
        display: 'flex',
        justifyContent: 'flex-start',
      }}
    >
      {[...range(1, rating)].map((key) => (
        <FullRating key={`full-rating-${key}`} color={color} size={size} />
      ))}
      {[...range(1, 5 - rating)].map((key) => (
        <EmptyRating key={`empty-rating-${key}`} size={size} />
      ))}
    </div>
  );
};

export default ReviewRating;
