import { Nav } from 'rsuite';

import { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import FlexContainer from '../../shared/components/layouts/flex-container';
import Preloader from '../../shared/components/Preloader';
import { Title } from '../../shared/components/Title';
import { Loading } from '../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../store';
import { getSite } from '../userData/redux/action';
import { accountSelector } from '../userData/redux/userData.slice';
import { fetchWidgetSettings } from '../widgetSettings/redux/action';
import Account from './components/Account';
import Business from './components/Business';
import ConnectedSites from './components/ConnectedSites';
import Extra from './components/Extra';
import Integrations from './components/Integrations';
import Profile from './components/Profile';
import ReviewSettings from './components/ReviewSettings';
import { getBusinessInfo } from './redux/actions';

/* eslint no-console: "warn" */

export type SettingsPage =
  | 'account'
  | 'usage'
  | 'business'
  | 'reviews'
  | 'ecommerce'
  | 'integrations'
  | 'profile'
  | 'extra'
  | 'sites';

const Wrapper = styled.div`
  padding: 0px 30px 20px;
  max-width: 1100px;
  margin: auto;
`;

const TitleWrapper = styled(FlexContainer)`
  width: 100%;
  /* margin-bottom: 20px; */
  span {
    display: block;
    font-size: 13px;
  }
  .rs-tag-text {
    color: #000;
  }
`;

const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [active, setActive] = useState<SettingsPage>('account');
  const { loading } = useAppSelector(accountSelector);

  useEffect(() => {
    batch(() => {
      dispatch(getBusinessInfo());
      dispatch(getSite({ showPreloader: false }));
      dispatch(fetchWidgetSettings());
    });
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab') as SettingsPage;
    if (tab) {
      setActive(tab);
    }
  }, [location]);

  if (loading === Loading.PENDING) {
    return <Preloader />;
  }

  return (
    <Wrapper>
      <FlexContainer
        justifyContent="space-between"
        alignItems="flex-end"
        style={{ marginBottom: 20 }}
      >
        <TitleWrapper
          gap="15px"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <FlexContainer
            stack
            justifyContent="flex-start"
            alignItems="flex-start"
            gap="10px"
          >
            <Title>Settings</Title>
            <p>
              View and update your account details, business information and
              more.
            </p>
          </FlexContainer>
        </TitleWrapper>
      </FlexContainer>

      <Nav appearance="subtle" activeKey={active} onSelect={setActive}>
        <Nav.Item eventKey="account" onClick={() => navigate('?tab=account')}>
          Account Details
        </Nav.Item>
        <Nav.Item eventKey="profile" onClick={() => navigate('?tab=profile')}>
          Profile
        </Nav.Item>
        <Nav.Item eventKey="business" onClick={() => navigate('?tab=business')}>
          Business Information
        </Nav.Item>
        {/* <Nav.Item eventKey="reviews" onClick={() => navigate('?tab=reviews')}>
          Review Settings
        </Nav.Item> */}

        <Nav.Item
          eventKey="integrations"
          onClick={() => navigate('?tab=integrations')}
        >
          Marketing Tools
        </Nav.Item>
        {/* <Nav.Item eventKey="sites" onClick={() => navigate('?tab=sites')}>
          Workspace
        </Nav.Item> */}
        {/* <Nav.Item eventKey="extra" onClick={() => navigate('?tab=extra')}>
          Extra
        </Nav.Item> */}
        {/* <Nav.Item eventKey="published" onClick={() => {}}>
          Branding
        </Nav.Item>
        <Nav.Item eventKey="pending" onClick={() => {}}>
          Billing
        </Nav.Item> */}
      </Nav>
      {active === 'account' && <Account />}
      {active === 'profile' && <Profile />}
      {active === 'business' && <Business />}
      {active === 'reviews' && <ReviewSettings />}
      {active === 'integrations' && <Integrations />}
      {active === 'extra' && <Extra />}
      {active === 'sites' && <ConnectedSites />}
    </Wrapper>
  );
};

export default Settings;
