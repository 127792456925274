import { ChangeEventHandler, FormEvent, useRef, useState } from 'react';
import styled from 'styled-components';
import { SiteWidgetSettings } from '../../../../../../widgetSettings/redux/widgetSettings.slice';
import ThankYou from './ThankYou';

const Wrapper = styled.div`
  padding: 20px;
  p.title {
    font-weight: bold;
    font-size: 18px;
    margin: 0 0 20px;
  }

  textarea {
    height: 120px;
    width: 100%;
  }

  .rj-form-input {
    width: calc(100% - 20px);
    resize: none;
    padding: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    background: #f5f5f5;
    border-radius: 10px;
    border: 1px solid transparent;
    outline: none !important;
    margin-bottom: 10px;
    font-size: 15px;
  }
`;

const Form = styled.form`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  flex-direction: column;

  .rj-footer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    .cancel {
      background: none;
      border: none;
      outline: none;
      font-size: 15px;
      cursor: pointer;
    }
    .submit {
      font-size: 15px;
      padding: 10px 20px;
      background-color: #000;
      color: #fff;
      border-radius: 20px;
      outline: none;
      border: none;
      cursor: pointer;
    }
  }
`;

type Props = {
  productId: string;
  settings: SiteWidgetSettings;
  onClose: () => void;
  siteId?: string;
  instanceId?: string;
};

export default function QuestionForm(props: Props) {
  const {
    onClose,
    settings: {
      thankYouPage,
      brandingSettings: { rtl },
      translation: {
        reviewForm: {
          messageErrorMessage,
          nameErrorMessage,
          emailErrorMessage,
          submitButtonText,
          cancel,
        },
        questionForm: {
          close,
          namePlaceholder,
          emailPlaceholder,
          addQuestion,
          askQuestionPlaceholder,
          thankYouMessage,
          thankYouTitle,
        },
      },
    },
  } = props;
  const messageArea = useRef<HTMLTextAreaElement>(null);
  const nameArea = useRef<HTMLInputElement>(null);
  const emailArea = useRef<HTMLInputElement>(null);
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [displayThankYou, setDisplayThankYou] = useState(false);

  const handleMessageInvalid = () => {
    if (messageArea.current) {
      messageArea.current.setCustomValidity(messageErrorMessage);
    }
  };

  const handleNameInvalid = () => {
    if (nameArea.current) {
      nameArea.current.setCustomValidity(nameErrorMessage);
    }
  };

  const handleEmailInvalid = () => {
    if (emailArea.current) {
      emailArea.current.setCustomValidity(emailErrorMessage);
    }
  };

  const handleChangeMessage = (
    event: ChangeEventHandler<HTMLTextAreaElement>
  ) => {
    if (messageArea.current) {
      messageArea.current.setCustomValidity('');
      // @ts-ignore
      setMessage(event.target.value);
    }
  };

  const handleChangeName = (event: ChangeEventHandler<HTMLInputElement>) => {
    if (nameArea.current) {
      nameArea.current.setCustomValidity('');

      // @ts-ignore
      setName(event.target.value);
    }
  };

  const handleChangeEmail = (event: ChangeEventHandler<HTMLInputElement>) => {
    if (emailArea.current) {
      emailArea.current.setCustomValidity('');

      // @ts-ignore
      setEmail(event.target.value);
    }
  };

  const submitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <Wrapper>
      {displayThankYou ? (
        <ThankYou
          onClose={onClose}
          close={close}
          thankYouMessage={thankYouMessage}
          thankYouTitle={thankYouTitle}
          thankYouSettings={thankYouPage}
        />
      ) : (
        <>
          <p className="title assistant-500">{addQuestion}</p>
          <Form autoComplete="off" onSubmit={(e) => submitForm(e)}>
            <textarea
              ref={messageArea}
              value={message}
              className="rj-form-input assistant-400"
              placeholder={askQuestionPlaceholder}
              name="message"
              required={true}
              onInvalid={handleMessageInvalid}
              // @ts-ignore
              onChange={handleChangeMessage}
              style={{ direction: rtl ? 'rtl' : 'ltr' }}
            />
            <input
              name="name"
              value={name}
              className="rj-form-input assistant-400"
              placeholder={namePlaceholder}
              ref={nameArea}
              required={true}
              // @ts-ignore
              onChange={handleChangeName}
              onInvalid={handleNameInvalid}
            />
            <input
              name="email"
              value={email}
              className="rj-form-input assistant-400"
              placeholder={emailPlaceholder}
              ref={emailArea}
              required={true}
              type="email"
              // @ts-ignore
              onChange={handleChangeEmail}
              onInvalid={handleEmailInvalid}
            />

            <div className="rj-footer">
              <button
                className="cancel assistant-500"
                type="button"
                onClick={onClose}
              >
                {cancel}
              </button>
              <button className="submit assistant-500" type="submit">
                {submitButtonText}
              </button>
            </div>
          </Form>
        </>
      )}
    </Wrapper>
  );
}
