import { batch } from 'react-redux';
import styled from 'styled-components';
import ColorPicker from '../../../../shared/components/colorPicker';
import PremiumBanner from '../../../../shared/components/PremiumBanner';
import { SubscriptionPlan } from '../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { selectedSiteSelector } from '../../../userData/redux/userData.slice';
import FormRow from '../../../widgetSettings/components/form-row';
import {
  siteWidgetSettingsSelector,
  updateSiteWidgetSettings,
} from '../../../widgetSettings/redux/widgetSettings.slice';
import { setOptionBeingUpdated } from '../redux/product-reviews-settings.slice';

const Wrapper = styled.div`
  .react-colorful {
    height: 120px !important;
  }
`;

export default function Colors() {
  const dispatch = useAppDispatch();
  const settings = useAppSelector(siteWidgetSettingsSelector);
  const { productWidget } = settings ?? {};
  const { textColor, starColor, buttonBackgroundColor } = productWidget ?? {};
  const selectedSite = useAppSelector(selectedSiteSelector);
  const { plan } = selectedSite ?? {};
  const disabled = plan === SubscriptionPlan.FREE;

  return (
    <Wrapper>
      {plan === SubscriptionPlan.FREE && (
        <PremiumBanner
          availableOn={SubscriptionPlan.PRO}
          description="Upgrade to update the colors."
        />
      )}

      <FormRow
        stack
        gap="10px"
        alignItems="flex-start"
        justifyContent="flex-start"
        marginTop={plan === SubscriptionPlan.FREE ? '20px' : '0'}
      >
        Text color
        <ColorPicker
          disabled={disabled}
          color={textColor ?? '#000'}
          isSimple={false}
          changeColor={(color: string) => {
            if (disabled) return;

            batch(() => {
              dispatch(
                updateSiteWidgetSettings({
                  ...settings,
                  productWidget: {
                    ...productWidget,
                    textColor: color,
                  },
                })
              );
              dispatch(setOptionBeingUpdated(null));
            });
          }}
        />
      </FormRow>
      <FormRow
        stack
        gap="10px"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        Rating color
        <ColorPicker
          disabled={disabled}
          color={starColor ?? '#000'}
          isSimple={false}
          changeColor={(color: string) => {
            if (disabled) return;

            batch(() => {
              dispatch(
                updateSiteWidgetSettings({
                  ...settings,
                  productWidget: { ...productWidget, starColor: color },
                })
              );
              dispatch(setOptionBeingUpdated(null));
            });
          }}
        />
      </FormRow>
      <FormRow
        stack
        gap="10px"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        Button color
        <ColorPicker
          disabled={disabled}
          color={buttonBackgroundColor ?? '#000'}
          isSimple={false}
          placement="top"
          changeColor={(color: string) => {
            if (disabled) return;

            batch(() => {
              dispatch(
                updateSiteWidgetSettings({
                  ...settings,
                  productWidget: {
                    ...productWidget,
                    buttonBackgroundColor: color,
                  },
                })
              );
              dispatch(setOptionBeingUpdated(null));
            });
          }}
        />
      </FormRow>
    </Wrapper>
  );
}
