import { useState } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import styled from 'styled-components';
import { MediaWithReview } from '../../../../../../review/types';
import { SiteWidgetSettings } from '../../../../../../widgetSettings/redux/widgetSettings.slice';
import Modal from '../shared/Modal';
import PhotoReviewModal from '../shared/PhotoReviewModal';
import MediaElement from './MediaElement';

const Wrapper = styled.div`
  color: #7b7b7b;
  display: flex;
  gap: 10px;
  width: 100%;
  margin: 20px 0;
  justify-content: center;
  align-items: center;
  text-align: left;
  flex-direction: column;
  p {
    margin: 0 0 10px;
    font-size: 15px;
  }
`;
const Images = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

const SeeMore = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  cursor: pointer;

  &:hover {
    color: #000;
  }
`;

type Props = {
  settings: SiteWidgetSettings;
  mediaWithReviews: MediaWithReview[];
};

export default function PhotosVideos({ mediaWithReviews, settings }: Props) {
  const [isOpen, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [selectedMediaReview, setSelectedMediaReview] =
    useState<MediaWithReview | null>(null);
  const {
    translation: { customerPhotosAndVideos },
    productWidget: { textColor, fontSize },
  } = settings;

  const openMedia = (mediaWithReview: MediaWithReview) => {
    setOpen(true);
    setSelectedMediaReview(mediaWithReview);
  };

  if (!mediaWithReviews.length) {
    return null;
  }

  return (
    <>
      <Wrapper>
        <div>
          <p style={{ color: textColor, opacity: 0.7, fontSize }}>
            {customerPhotosAndVideos}
          </p>
          <Images>
            {mediaWithReviews.slice(0, 7).map((mediaWithReview, index) => (
              <MediaElement
                key={`media-${index}`}
                mediaWithReview={mediaWithReview}
                onClick={() => {
                  openMedia(mediaWithReview);
                  setCurrentIndex(index);
                }}
              />
            ))}

            {mediaWithReviews.length > 7 && (
              <SeeMore
                role="presentation"
                onClick={() => {
                  openMedia(mediaWithReviews[7]);
                  setCurrentIndex(7);
                }}
              >
                <BsThreeDots size={26} />
              </SeeMore>
            )}
          </Images>
        </div>
      </Wrapper>

      <Modal
        size="1000px"
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        children={
          <PhotoReviewModal
            settings={settings}
            currentIndex={currentIndex}
            selectedMediaReview={selectedMediaReview}
            mediaWithReviews={mediaWithReviews}
            onSelect={(selectedMediaReview, index) => {
              setSelectedMediaReview(selectedMediaReview);
              setCurrentIndex(index);
            }}
          />
        }
      />
    </>
  );
}
