export default function abbreviateLastName(fullName?: string): string {
  if (!fullName) {
    return '';
  }

  const parts = fullName.trim().split(' ');

  if (parts.length < 2) {
    return fullName;
  }

  const [firstName, lastName] = parts;
  const abbreviatedLastName = `${lastName.charAt(0)}.`;

  return `${firstName} ${abbreviatedLastName}`;
}
