import { QRCodeSVG } from 'qrcode.react';
import { useRef } from 'react';
import { FaArrowUpRightFromSquare } from 'react-icons/fa6';
import styled from 'styled-components';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import removeTrailingSlash from '../../../../../shared/utils/removeTrailingSlash';
import { useAppSelector } from '../../../../../store';
import { reviewFormSelector } from '../../../redux/review-form.slice';
import Invite from './Invite';
import Share from './Share';

/* eslint no-console: "warn" */

const Wrapper = styled(FlexContainer)`
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 24px;
  background: white;
  border-bottom: 1px solid #e2e8f0;
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.03),
    0 2px 4px -1px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.98);
`;

const HeaderButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background: white;
  border: 1px solid #e2e8f0;
  padding: 8px 16px;
  color: #64748b;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02);

  svg {
    width: 15px;
    height: 15px;
    transition: transform 0.2s ease;
  }

  &:hover {
    border-color: #3b82f6;
    color: #3b82f6;
    background: #f8fafc;
    box-shadow: 0 4px 12px rgba(59, 130, 246, 0.08);
    transform: translateY(-1px);

    svg {
      transform: scale(1.1);
    }
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(59, 130, 246, 0.06);
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 12px;
  padding: 0 4px;
`;

const DropdownButton = styled(HeaderButton)<{ $isOpen?: boolean }>`
  position: relative;

  ${(props) =>
    props.$isOpen &&
    `
    background: #f8fafc;
    border-color: #3b82f6;
    color: #3b82f6;
  `}
`;

const DropdownContent = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  min-width: 220px;
  background: white;
  border-radius: 8px;
  padding: 6px;
  box-shadow: 0 10px 38px -10px rgba(22, 23, 24, 0.35),
    0 10px 20px -15px rgba(22, 23, 24, 0.2);
  z-index: 100;
  border: 1px solid #e2e8f0;
`;

const DropdownItem = styled.button`
  font-size: 14px;
  line-height: 1;
  color: #1e293b;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 36px;
  padding: 0 8px;
  width: 100%;
  border: none;
  background: none;
  cursor: pointer;

  &:hover {
    background-color: #f1f5f9;
    color: #3b82f6;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const DropdownSeparator = styled.div`
  height: 1px;
  background-color: #e2e8f0;
  margin: 6px;
`;

interface DropdownProps {
  trigger: React.ReactNode;
  items: {
    icon: React.ReactNode;
    label: string;
    onClick: () => void;
    separator?: boolean;
  }[];
}

export default function Header() {
  const qrCodeRef = useRef<SVGSVGElement | null>(null);
  const qrCodeSize = 256;
  const { reviewFormSettings } = useAppSelector(reviewFormSelector);
  const { trustKey } = reviewFormSettings ?? {};
  const formUrl = `${removeTrailingSlash(
    process.env.REACT_APP_REVIEWSJET_WEBSITE!
  )}/w/${trustKey}`;

  return (
    <>
      <Wrapper>
        <ButtonGroup>
          <Invite formUrl={formUrl} />
          <Share formUrl={formUrl} />

          <HeaderButton
            onClick={() => {
              window.open(formUrl, '_blank');
            }}
          >
            <FaArrowUpRightFromSquare size={15} />
            View
          </HeaderButton>
        </ButtonGroup>
      </Wrapper>

      <div style={{ display: 'none' }}>
        <QRCodeSVG
          value={formUrl}
          ref={qrCodeRef}
          size={qrCodeSize}
          bgColor="#ffffff"
          fgColor="#000000"
        />
      </div>
    </>
  );
}
