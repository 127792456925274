import { Accordion } from 'rsuite';
import styled from 'styled-components';
import { useAppSelector } from '../../../../../store';
import { selectedSiteSelector } from '../../../../userData/redux/userData.slice';
import CategoryStarWidget from './category-star-widget';
import ProductStarWidget from './product-star-widget';
import ProductWidget from './product-widget';

const Wrapper = styled.div`
  ul {
    margin-left: 20px;
    margin-bottom: 25px;
  }

  ul li {
    position: relative;
    margin-bottom: 10px;

    &::before {
      content: '';
      position: absolute;
      left: -20px;
      top: 3px;
      width: 1em;
      height: 1em;
      background-repeat: no-repeat;
      background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M362.6 192.9L345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z"></path></svg>');
    }
  }

  width: 100%;
  max-width: 900px;
  margin: auto;
  pre {
    margin: 0 0 20px !important;
    border: 1px solid #dedede;
    border-radius: 10px;
  }
  img.screenshot {
    margin-bottom: 20px;
    margin-top: 10px;
    border: 1px solid #dedede;
    padding: 2px;
    border-radius: 10px;
    width: 100%;
  }
`;

export default function WebflowProductWidget() {
  const selectedSite = useAppSelector(selectedSiteSelector);
  const { _id: siteId } = selectedSite ?? {};

  return (
    <Wrapper>
      <p style={{ marginBottom: 10 }}>This tutorial will show you how to:</p>
      <ul>
        <li>Add Product Reviews to product pages</li>
        <li>Display Average Ratings for products</li>
        <li>Show ratings on collection pages</li>
      </ul>

      <p style={{ marginBottom: 20 }}>
        Please install of the widgets described below to complete the tutorial.{' '}
      </p>

      <Accordion>
        <Accordion.Panel
          header="Add Product Reviews to product pages"
          eventKey={1}
        >
          <ProductWidget siteId={siteId} />
        </Accordion.Panel>
        <Accordion.Panel
          header="Display Average Ratings for products"
          eventKey={2}
        >
          <ProductStarWidget siteId={siteId} />
        </Accordion.Panel>
        <Accordion.Panel header="Show ratings on collection pages" eventKey={3}>
          <CategoryStarWidget siteId={siteId} />
        </Accordion.Panel>
      </Accordion>
    </Wrapper>
  );
}
