import { useEffect, useState } from 'react';
import { IoCheckmark, IoCopy } from 'react-icons/io5';
import styled from 'styled-components';
import ErrorPage from '../../shared/components/error-page';
import Upgrade from '../../shared/components/modal/Upgrade';
import Preloader from '../../shared/components/Preloader';
import ReviewsJetCrownLogo from '../../shared/components/ReviewsJetCrownLogo';
import ActiveSubscriptionWarning from '../../shared/components/warning-banner';
import useQuery from '../../shared/hooks/useQuery';
import useWixAuth from '../../shared/hooks/useWixAuth';
import { Loading, SubscriptionPlan } from '../../shared/types/review.types';
import { useAppSelector } from '../../store';
import { accountSelector } from '../userData/redux/userData.slice';

const Wrapper = styled.div`
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f7fa;
  overflow: auto;
  padding-top: 50px;
`;

const Inner = styled.div`
  width: 100%;
  max-width: 1100px;
  /* background-color: #fff; */
  margin: auto;
  /* padding: 40px; */
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  font-family: 'Ubuntu', sans-serif;
  background: linear-gradient(135deg, #1a365d 0%, #2d3748 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 40px 0 30px;
  letter-spacing: -0.5px;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 28px;
    margin: 30px 0 20px;
  }
`;

const UpgradeWrapper = styled.div`
  padding: 10px 20px 20px;
  background-color: #fff;
  border-radius: 8px;
  margin: 0 10px;
`;

const PremiumBanner = styled.div`
  background: linear-gradient(135deg, #f8fafc 0%, #e2e8f0 100%);
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 15px 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 10px;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  @media (max-width: 768px) {
    border-radius: 0;
  }
`;

const BannerContent = styled.div`
  flex: 1;
  position: relative;
  z-index: 1;

  h3 {
    color: rgba(255, 255, 255, 0.95);
    margin: 0 0 8px 0;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: -0.2px;
  }

  p {
    margin: 0;
    color: #475569;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
  }
`;

const DiscountCode = styled.button`
  display: inline-flex;
  align-items: center;
  gap: 6px;
  background: #1a365d;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  padding: 3px 8px;
  color: #ffffff;
  font-family: monospace;
  font-size: 14px;
  margin: 0 4px;
  letter-spacing: 0.5px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;

  &:hover {
    background: #2d4a7c;
    border-color: rgba(255, 255, 255, 0.2);
  }

  .copy-icon {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    opacity: 0.7;
    transition: opacity 0.2s ease;
  }

  &:hover .copy-icon {
    opacity: 1;
  }
`;

const CopyFeedback = styled.div<{ show: boolean }>`
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background: #1a365d;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-family: system-ui, -apple-system, sans-serif;
  pointer-events: none;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.2s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

const BannerFeatures = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 8px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.75);

  span {
    display: flex;
    align-items: center;
    gap: 5px;

    &::before {
      content: '✓';
      font-size: 12px;
      background: rgba(0, 0, 0, 0.15);
      border-radius: 50%;
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 768px) {
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 12px;
  }
`;

const WarningBanner = styled.div`
  background: #fff7ed;
  border: 1px solid #fed7aa;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 10px;

  h3 {
    color: #9a3412;
    margin: 0 0 12px 0;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  p {
    color: #7c2d12;
    margin: 0;
    font-size: 15px;
    line-height: 1.5;
  }

  a {
    color: #ea580c;
    text-decoration: underline;
    font-weight: 500;

    &:hover {
      color: #9a3412;
    }
  }
`;

const LogoWrapper = styled.div`
  text-align: center;
  margin-bottom: 32px;

  svg {
    height: 32px;
    width: auto;
  }
`;

const StripeFooter = styled.div`
  text-align: center;
  padding: 0;

  img {
    height: 100px;
    width: auto;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 1;
    }
  }
`;

export default function UpgradePage() {
  const query = useQuery();
  const { authWixAccount, loading, error } = useWixAuth();
  const [copySuccess, setCopySuccess] = useState(false);
  const [showCopyFeedback, setShowCopyFeedback] = useState(false);
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { plan, stripe, planCycle } = selectedSite ?? {};
  const { subscriptionId } = stripe ?? {};
  const instance = query.get('instance');

  useEffect(() => {
    if (!instance) {
      window.location.href = `${process.env.REACT_APP_REVIEWSJET_WEBSITE}/pricing`;
    }
  }, [instance]);

  const handleCopyCode = async () => {
    try {
      await navigator.clipboard.writeText('SNUGSAVINGS');
      setCopySuccess(true);
      setShowCopyFeedback(true);

      setTimeout(() => {
        setShowCopyFeedback(false);
      }, 2000);

      setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const getTitle = () => {
    if (plan === SubscriptionPlan.FREE) {
      return 'Try Premium Features for 14 Days';
    }

    if (planCycle?.toLowerCase() === 'monthly') {
      return 'Switch to Annual Plan & Save 20%';
    }

    if (plan === SubscriptionPlan.ADVANCED) {
      return 'Upgrade to Professional Plan';
    }

    if (plan === SubscriptionPlan.PRO) {
      return 'Upgrade to Advanced Plan';
    }

    return 'Change Your Plan';
  };

  if (!subscriptionId && plan && plan !== SubscriptionPlan.FREE) {
    return <ActiveSubscriptionWarning />;
  }

  if (loading === Loading.PENDING) {
    return <Preloader />;
  } else if (loading === Loading.ERROR || !!error) {
    return <ErrorPage onRetryClick={authWixAccount} />;
  }

  if (loading === Loading.INITIAL) {
    return null;
  }

  return (
    <Wrapper>
      <Inner>
        <LogoWrapper>
          <ReviewsJetCrownLogo />
        </LogoWrapper>
        <Title>{getTitle()}</Title>

        {plan === SubscriptionPlan.FREE && (
          <PremiumBanner>
            <BannerContent>
              <p>
                Unlock premium features and publish more reviews. Get 20% off
                with code{' '}
                <DiscountCode onClick={handleCopyCode}>
                  SNUGSAVINGS
                  {copySuccess ? (
                    <IoCheckmark className="copy-icon" />
                  ) : (
                    <IoCopy className="copy-icon" />
                  )}
                  <CopyFeedback show={showCopyFeedback}>Copied!</CopyFeedback>
                </DiscountCode>
              </p>
              <BannerFeatures>
                <span>No charge for 14 days</span>
                <span>Trial reminder</span>
                <span>Cancel anytime</span>
              </BannerFeatures>
            </BannerContent>
          </PremiumBanner>
        )}
        <UpgradeWrapper>
          <Upgrade inModal={false} />
        </UpgradeWrapper>
        <StripeFooter>
          <img
            src="/images/powered-by-stripe.svg"
            alt="Powered by Stripe"
            title=""
          />
        </StripeFooter>
        <div style={{ height: '100px' }} />
      </Inner>
    </Wrapper>
  );
}
