import { useState } from 'react';
import { BiPaint } from 'react-icons/bi';
import { BsFileEarmarkText, BsGoogle } from 'react-icons/bs';
import { FaCode, FaRegStar } from 'react-icons/fa6';
import {
  IoChevronBack,
  IoChevronDown,
  IoChevronUp,
  IoLanguageOutline,
  IoShieldOutline,
} from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import BackButton from '../../../shared/components/BackButton';
import { SubscriptionPlan } from '../../../shared/types/review.types';
import { useAppSelector } from '../../../store';
import { selectedSiteSelector } from '../../userData/redux/userData.slice';
import SaveButton from './SaveButton';
import Branding from './Settings/Branding';
import Colors from './Settings/Colors';
import GoogleRichSnippets from './Settings/GoogleRichSnippets';
import Installation from './Settings/Installation';
import Language from './Settings/Language';
import ReviewContent from './Settings/ReviewContent';
import ReviewSummary from './Settings/ReviewSummary';

const SettingsSidebar = styled.div`
  width: 400px;
  flex-shrink: 0;
  background: white;
  border-right: 1px solid #e2e8f0;
  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
`;

const TopSection = styled.div`
  padding: 24px 24px 0;
  background: white;
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0));
    pointer-events: none;
  }
`;

const ScrollableContent = styled.div`
  padding: 24px;
  padding-bottom: 80px;
  overflow-y: auto;
  flex: 1;
  margin-top: -20px; // Overlap with the gradient for smooth transition
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
  padding-left: 0px;
  color: #1e293b;
  font-size: 16px;
  font-weight: 500;

  svg {
    font-size: 18px;
  }
`;

const Subtitle = styled.p`
  color: #64748b;
  font-size: 14px;
  margin: 0 0 24px 0;
  line-height: 1.5;
`;

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  /* .react-colorful {
    height: 150px !important;
    width: 100px !important;
  } */
`;

const AccordionItem = styled.div`
  border-bottom: 1px solid #e2e8f0;
  border-radius: 0;
  overflow: hidden;
`;

const AccordionHeader = styled.button`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 16px;
  background: white;
  border: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  color: #1e293b;
  transition: all 0.2s ease;

  &:hover {
    background: #f8fafc;
  }
`;

const AccordionContent = styled.div<{ $isOpen: boolean }>`
  padding: ${(props) => (props.$isOpen ? '16px' : '0')};
  height: ${(props) => (props.$isOpen ? 'auto' : '0')};
  opacity: ${(props) => (props.$isOpen ? '1' : '0')};
  overflow: hidden;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border-top: ${(props) => (props.$isOpen ? '1px solid #e2e8f0' : 'none')};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  svg:first-child {
    font-size: 18px;
  }
`;

const SectionIcon = styled.div<{ $color: string }>`
  display: flex;
  align-items: center;
  color: ${(props) => props.$color};
  font-size: 16px;
`;

export default function Sidebar({ onInstall }: { onInstall: () => void }) {
  const navigate = useNavigate();
  const [openSection, setOpenSection] = useState('review-summary');
  const selectedSite = useAppSelector(selectedSiteSelector);
  const { plan } = selectedSite ?? {};

  const sections = [
    {
      id: 'review-summary',
      title: 'Review Summary',
      icon: <FaRegStar />,
      color: '#ffca6f',
      component: <ReviewSummary />,
    },
    {
      id: 'colors',
      title: 'Colors',
      icon: <BiPaint />,
      color: '#60a5fa',
      component: <Colors />,
    },
    {
      id: 'content',
      title: 'Content',
      icon: <BsFileEarmarkText />,
      color: '#a78bfa',
      component: <ReviewContent />,
    },
    {
      id: 'rich-snippets',
      title: 'Google Rich Snippets',
      icon: <BsGoogle />,
      color: '#3e8af4',
      component: <GoogleRichSnippets />,
    },
    {
      id: 'translation',
      title: 'Language settings',
      icon: <IoLanguageOutline />,
      color: '#818cf8',
      component: <Language />,
    },
  ];

  if (plan === SubscriptionPlan.FREE) {
    sections.push({
      id: 'branding',
      title: 'Remove ReviewsJet branding',
      icon: <IoShieldOutline />,
      color: '#94a3b8',
      component: <Branding />,
    });
  }

  sections.push({
    id: 'installation',
    title: 'Add to your website',
    icon: <FaCode />,
    color: '#ff8457',
    component: <Installation onInstall={onInstall} />,
  });

  return (
    <>
      <SettingsSidebar>
        <TopSection>
          <Header>
            <BackButton onClick={() => navigate('/ecommerce')}>
              <IoChevronBack />
              E-Commerce
            </BackButton>
          </Header>
          <Subtitle>
            Customize how your product widget looks and behaves. Click the Save
            changes button when you're done.
          </Subtitle>
        </TopSection>

        <ScrollableContent>
          <AccordionContainer>
            {sections.map((section) => (
              <AccordionItem key={section.id}>
                <AccordionHeader
                  onClick={() => {
                    setOpenSection(
                      openSection === section.id ? '' : section.id
                    );
                  }}
                >
                  <IconWrapper>
                    {openSection === section.id ? (
                      <IoChevronUp />
                    ) : (
                      <IoChevronDown />
                    )}
                    <SectionIcon $color={section.color}>
                      {section.icon}
                    </SectionIcon>
                  </IconWrapper>
                  {section.title}
                </AccordionHeader>
                <AccordionContent $isOpen={openSection === section.id}>
                  {section.component}
                </AccordionContent>
              </AccordionItem>
            ))}
          </AccordionContainer>
        </ScrollableContent>
        <SaveButton />
      </SettingsSidebar>
    </>
  );
}
