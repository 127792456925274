import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaInfoCircle } from 'react-icons/fa';
import { InputPicker, Toggle } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../../shared/components/Button';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import { PlanRestrictionMessage } from '../../../../shared/components/PlanRestrictionMessage';
import { useNotification } from '../../../../shared/hooks/useNotification';
import {
  Loading,
  SubscriptionPlan,
} from '../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { selectedSiteSelector } from '../../../userData/redux/userData.slice';
import FormRow from '../../../widgetSettings/components/form-row';
import { setAutoPublish } from '../../../widgetSettings/redux/action';
import { siteWidgetSettingsSelector } from '../../../widgetSettings/redux/widgetSettings.slice';

/* eslint no-console: "warn" */

const Form = styled.form`
  width: 100%;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const Header = styled.div`
  padding: 15px 20px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid rgb(235, 235, 235);
`;

const FormGroup = styled.div`
  width: 100%;
`;

const Content = styled.div`
  /* padding: 20px; */
  margin: 20px 0;
`;

const Footer = styled(FlexContainer)`
  padding: 15px 20px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
`;

const StyledInputPicker = styled(InputPicker)`
  width: 100%;
  margin-top: 10px;

  &.rs-picker {
    border-radius: 6px !important;
  }
`;

const InfoBanner = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 16px;
  margin: 16px 0;
  background-color: #eff6ff;
  border-left: 4px solid #3b82f6;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
`;

const IconWrapper = styled.div`
  color: #3b82f6;
  flex-shrink: 0;
  margin-top: 2px;
`;

const BannerText = styled.p`
  margin: 0;
  color: #1e40af;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
`;

export type AccountInfo = {
  fullName: string;
  email: string;
};

const STARS_COUNT = ['5', '4', '3'].map((stars) => ({
  label: stars === '5' ? '5 stars only' : `${stars} and above`,
  value: stars,
}));

export default function AutoPublish({ onClose }: { onClose?: () => void }) {
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState(false);
  const [submitting, setSubmitting] = useState<Loading>(Loading.INITIAL);
  const siteWidgetSettings = useAppSelector(siteWidgetSettingsSelector);
  const { brandingSettings } = siteWidgetSettings ?? {};
  const { showErrorToast, showSuccessToast } = useNotification();
  const {
    autoPublishRating: rating = 4,
    autoPublishEnabled: autoPublishStatus = false,
  } = brandingSettings || {};
  const selectedSite = useAppSelector(selectedSiteSelector);
  const { plan } = selectedSite ?? {};

  const { control, handleSubmit, setValue } = useForm<{
    starsCount: string;
    status: string;
  }>({
    defaultValues: {
      status: autoPublishStatus ? 'Yes' : 'No',
      starsCount: String(rating),
    },
  });

  useEffect(() => {
    if (plan === SubscriptionPlan.FREE) {
      return setStatus(false);
    }

    setValue('starsCount', String(rating));
    setStatus(autoPublishStatus);
  }, [autoPublishStatus, rating, plan]);

  const onSubmit = handleSubmit(async (data) => {
    setSubmitting(Loading.PENDING);
    dispatch(
      setAutoPublish({
        status,
        value: data.starsCount,
      })
    )
      .then(unwrapResult)
      .then(() => {
        onClose && onClose();
        setSubmitting(Loading.SUCCESS);
        showSuccessToast('Settings saved successfully.');
      })
      .catch(() => {
        setSubmitting(Loading.ERROR);
        showErrorToast('Something went wrong. Please try it again.');
      })
      .finally(() => {
        setSubmitting(Loading.INITIAL);
      });
  });

  if (plan === SubscriptionPlan.FREE) {
    return (
      <div style={{ marginTop: '30px' }}>
        <p style={{ marginBottom: '20px' }}>
          Auto-publish reviews that meet or exceed your rating threshold. Save
          time while maintaining quality standards.
        </p>
        <PlanRestrictionMessage availableOn={['Advanced']} onClick={onClose} />
      </div>
    );
  }

  return (
    <Form onSubmit={onSubmit}>
      <InfoBanner>
        <IconWrapper>
          <FaInfoCircle size={18} />
        </IconWrapper>
        <BannerText>
          Auto-publish reviews that meet or exceed your rating threshold. Save
          time while maintaining quality standards.
        </BannerText>
      </InfoBanner>
      <Content>
        <FlexContainer
          stack
          justifyContent="flex-start"
          alignItems="flex-start"
          gap="15px"
        >
          <FormRow gap="10px" justifyContent="flex-start">
            <span>Active</span>
            <Toggle
              checked={status}
              // @ts-ignore
              onChange={(value) => {
                if (
                  [SubscriptionPlan.PRO, SubscriptionPlan.ADVANCED].includes(
                    plan!
                  )
                ) {
                  setStatus(value);
                }
              }}
              checkedChildren="Yes"
              unCheckedChildren="No"
            />
          </FormRow>

          <FormGroup>
            <span>Which reviews should be auto-published?</span>

            <Controller
              name="starsCount"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <StyledInputPicker
                  cleanable={false}
                  searchable={false}
                  size="md"
                  data={STARS_COUNT}
                  disabled={!status}
                  placeholder="Select an option"
                  {...field}
                />
              )}
            />
          </FormGroup>
        </FlexContainer>
      </Content>
      <Footer justifyContent="flex-end">
        <Button
          rounded
          type="submit"
          disabled={submitting === Loading.PENDING}
          variant={submitting === Loading.PENDING ? 'tertiary' : 'primary'}
        >
          {submitting === Loading.PENDING ? 'Please wait...' : 'Save settings'}
        </Button>
      </Footer>
    </Form>
  );
}
