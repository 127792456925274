import { createSlice } from '@reduxjs/toolkit';

export enum OptionBeingUpdated {
  REVIEW_SUMMARY_ALIGNMENT = 'review-summary-alignment',
  REVIEW_SUMMARY_SINGULAR = 'review-summary-singular',
  REVIEW_SUMMARY_PLURAL = 'review-summary-plural',
  PRODUCT_REVIEWS_EMPTY_STATE = 'product-reviews-empty-state',
}

type AppState = {
  optionBeingUpdated: string | null;
};

const initialState: AppState = {
  optionBeingUpdated: null,
};

const app = createSlice({
  name: 'product-reviews-settings',
  initialState,
  reducers: {
    setOptionBeingUpdated: (state, { payload }) => {
      state.optionBeingUpdated = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setOptionBeingUpdated } = app.actions;

export default app.reducer;
