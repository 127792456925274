import { FaPlus } from 'react-icons/fa6';
import { PlatformButton } from '../MarketingPlatforms';

interface RequestIntegrationProps {
  onClose: () => void;
}

export default function RequestIntegration({
  onClose,
}: RequestIntegrationProps) {
  return (
    <PlatformButton
      onClick={() => {
        onClose();
        window.open('https://reviewsjet.featurebase.app/', '_blank');
      }}
    >
      <FaPlus color="#000" size={24} />
      Request an integration
    </PlatformButton>
  );
}
