import styled from 'styled-components';
import { Review } from '../../../../../../../manage-reviews/redux/reviews.slice';
import { SiteWidgetSettings } from '../../../../../../../widgetSettings/redux/widgetSettings.slice';
import ReviewItem from './ReviewItem';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;
`;

type Props = {
  settings: SiteWidgetSettings;
  reviews: Review[];
  onClickImage: (reviews: Review, index: number) => void;
};

export default function ReviewsList({
  reviews,
  settings,
  onClickImage,
}: Props) {
  return (
    <Wrapper>
      {reviews.map((review) => (
        <ReviewItem
          key={review._id}
          review={review}
          settings={settings}
          onClickImage={onClickImage}
        />
      ))}
    </Wrapper>
  );
}
