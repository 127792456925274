import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa6';
import { Button as RsButton, Tag, Tooltip, Whisper } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../../../shared/components/Button';
import ChromeLogo from '../../../../../shared/components/ChromeLogo';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import { ReviewerPlatform } from '../../../../../shared/types/review.types';
import { useAppSelector } from '../../../../../store';
import { accountSelector } from '../../../../userData/redux/userData.slice';

const Title = styled.h4`
  color: #151719;
  font-weight: 600;
  margin: 0 0 30px;
  font-size: 20px;
  font-family: 'Ubuntu', sans-serif;
`;

const AccountId = styled.h5`
  margin: 20px 0;
  font-size: 14px;
  p {
    color: #707070;
    font-weight: 400;
  }
`;

const StyledButton = styled(Button)<{ disabled?: boolean }>`
  background-color: #000;
  border-color: #000;
`;

const getPlatformName = (platform: ReviewerPlatform) => {
  switch (platform) {
    case ReviewerPlatform.ALI_EXPRESS:
      return 'AliExpress';
    case ReviewerPlatform.ETSY:
      return 'Etsy';
    case ReviewerPlatform.TOUR_RADAR:
      return 'TourRadar';
    case ReviewerPlatform.AMAZON:
      return 'Amazon';

    default:
      break;
  }
};

const DisplaySiteIdPage = ({ platform }: { platform: ReviewerPlatform }) => {
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { _id: siteId } = selectedSite ?? {};
  const { showInfoToast } = useNotification();

  return (
    <div style={{ padding: '0 0 20px' }}>
      <Title>Import {getPlatformName(platform)} reviews</Title>
      <FlexContainer justifyContent="space-between" gap="20px">
        <div>
          <p>1. Copy your Account ID</p>
          <AccountId>
            <FlexContainer justifyContent="flex-start" gap="10px">
              <Tag size="lg">{siteId}</Tag>
              <Whisper
                placement="top"
                controlId="control-id-context-menu"
                trigger="hover"
                speaker={<Tooltip>Copy Account ID</Tooltip>}
              >
                <CopyToClipboard hidden text={siteId}>
                  <RsButton
                    size="sm"
                    onClick={() =>
                      showInfoToast(`${siteId} copied successfully!`)
                    }
                  >
                    <FaCopy />
                  </RsButton>
                </CopyToClipboard>
              </Whisper>
            </FlexContainer>
          </AccountId>

          <p>2. Install our Chrome Extension</p>

          <p>3. Visit your {`${getPlatformName(platform)} page`} page</p>

          <p>
            4. Click the <b>Import Reviews</b> button
          </p>
        </div>
        <FlexContainer
          justifyContent="flex-end"
          stack
          gap="10px"
          style={{
            flex: 1,
            background: '#f1f1f1',
            padding: '20px',
            borderRadius: '10px',
          }}
        >
          <ChromeLogo />
          <p style={{ fontSize: 12, textAlign: 'center' }}>
            Click the button below to install the Chrome extension
          </p>
          <StyledButton
            rounded
            size="small"
            variant="primary"
            onClick={() =>
              window.open(
                'https://chromewebstore.google.com/detail/reviewsjet/cdoapcelcmonibckhnojjalhildkhbbf',
                '_blank'
              )
            }
          >
            <FlexContainer gap="10px">
              <b>Install</b>
            </FlexContainer>
          </StyledButton>
        </FlexContainer>
      </FlexContainer>
    </div>
  );
};

export default DisplaySiteIdPage;
