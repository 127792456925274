import { unwrapResult } from '@reduxjs/toolkit';
import { Input, InputPicker, Toggle } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../../../shared/components/Button';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import { Loading } from '../../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import FormRow from '../../../../widgetSettings/components/form-row';
import { updateWidgetsSettings } from '../../../../widgetSettings/redux/action';
import {
  settingSelector,
  siteWidgetSettingsSelector,
  updateSiteWidgetSettings,
} from '../../../../widgetSettings/redux/widgetSettings.slice';
import Questions from './Questions';
import ReviewQuestionsPreview from './ReviewQuestionsPreview';

/* eslint no-console: "warn" */

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const Wrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const Header = styled.div`
  padding: 15px 20px;
  border-bottom: 1px solid rgb(235, 235, 235);

  span {
    font-weight: bold;
    font-size: 16px;
    color: #000;
  }
`;
const Content = styled.div`
  padding: 20px;
  margin-bottom: 20px;
`;

const Footer = styled(FlexContainer)`
  padding: 15px 20px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  border-top: 1px solid rgb(235, 235, 235);
`;

export type AccountInfo = {
  fullName: string;
  email: string;
};

export default function FeedbackBuilder() {
  const dispatch = useAppDispatch();
  const siteWidgetSettings = useAppSelector(siteWidgetSettingsSelector);
  const { reviewQuestions } = siteWidgetSettings ?? {};
  const { collectionType, title, message, status } = reviewQuestions ?? {};
  const { showSuccessToast } = useNotification();
  const {
    updateWidgetSettings: { saving },
  } = useAppSelector(settingSelector);

  const updateSettings = () => {
    dispatch(updateWidgetsSettings())
      .then(unwrapResult)
      .then(() => {
        showSuccessToast('Settings successfully saved');
      })
      .catch(() => {});
  };

  return (
    <Wrapper>
      <Header>
        <span>Review questions</span>
        <p>
          Allow users to submit their reviews either as text or through a
          recorded video.
        </p>
      </Header>
      <Content>
        <FormRow
          alignItems="center"
          justifyContent="flex-start"
          gap="50px"
          marginBottom="10px"
          style={{ height: 40 }}
        >
          <FlexContainer justifyContent="flex-start" gap="5px">
            Enable
            <Toggle
              size="md"
              checked={!!status}
              checkedChildren="Yes"
              unCheckedChildren="No"
              onClick={() => {
                dispatch(
                  updateSiteWidgetSettings({
                    ...siteWidgetSettings,
                    reviewQuestions: {
                      ...siteWidgetSettings?.reviewQuestions,
                      status: !status,
                    },
                  })
                );
              }}
            />
          </FlexContainer>
        </FormRow>

        <FlexContainer justifyContent="space-between" gap="20px">
          <div style={{ width: '55%' }}>
            <FormRow stack alignItems="flex-start">
              <Label htmlFor="header-title">
                Header title <sup>*</sup>
              </Label>
              <Input
                id="header-title"
                value={title}
                placeholder="Would you like to give a shoutout for xyz?"
                onChange={(value) => {
                  dispatch(
                    updateSiteWidgetSettings({
                      ...siteWidgetSettings,
                      reviewQuestions: {
                        ...siteWidgetSettings?.reviewQuestions,
                        title: value,
                      },
                    })
                  );
                }}
              />
            </FormRow>
            <FormRow stack alignItems="flex-start">
              <Label htmlFor="custom-message">
                Your custom message <sup>*</sup>
              </Label>
              <Input
                as="textarea"
                rows={3}
                value={message}
                id="custom-message"
                placeholder="Write a warm message to your customers, and give them simple directions on how to make the best testimonial."
                defaultValue=""
                onChange={(value) => {
                  dispatch(
                    updateSiteWidgetSettings({
                      ...siteWidgetSettings,
                      reviewQuestions: {
                        ...siteWidgetSettings?.reviewQuestions,
                        message: value,
                      },
                    })
                  );
                }}
              />
            </FormRow>
          </div>
          <div>
            <ReviewQuestionsPreview
              settings={siteWidgetSettings as never as any}
            />
          </div>
        </FlexContainer>
        <Questions />

        <FormRow stack alignItems="flex-start">
          <Label>Collection type</Label>
          <InputPicker
            cleanable={false}
            value={collectionType}
            data={[
              { label: 'Text and video', value: 'both' },
              { label: 'Text only', value: 'text' },
              { label: 'Video only', value: 'video' },
            ]}
            onChange={(value) => {
              dispatch(
                updateSiteWidgetSettings({
                  ...siteWidgetSettings,
                  reviewQuestions: {
                    ...siteWidgetSettings?.reviewQuestions,
                    collectionType: value,
                  },
                })
              );
            }}
          />
        </FormRow>
      </Content>
      <Footer justifyContent="flex-end">
        <Button
          rounded
          disabled={saving === Loading.PENDING}
          variant={saving === Loading.PENDING ? 'tertiary' : 'primary'}
          onClick={updateSettings}
        >
          {saving === Loading.PENDING ? 'Please wait...' : 'Save settings'}
        </Button>
      </Footer>
    </Wrapper>
  );
}
