import { capitalize } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Drawer } from 'rsuite';
import styled from 'styled-components';
import Button from '../../shared/components/Button';
import ErrorComponent from '../../shared/components/ErrorComponent';
import FlexContainer from '../../shared/components/layouts/flex-container';
import AppModal from '../../shared/components/modal';
import useModal from '../../shared/components/modal/useModal';
import Preloader from '../../shared/components/Preloader';
import { Title } from '../../shared/components/Title';
import { Loading } from '../../shared/types/review.types';
import removeTrailingSlash from '../../shared/utils/removeTrailingSlash';
import { getLimit } from '../../shared/utils/usage-limits';
import { useAppDispatch, useAppSelector } from '../../store';
import { emailsSelector } from '../emails/redux/emails.slice';
import { ReviewsJetPlatform } from '../product/types';
import GoDaddyReviewForm from '../tutorials/components/godaddy/review-form';
import OthersReviewForm from '../tutorials/components/others/review-form';
import PixpaReviewForm from '../tutorials/components/pixpa/review-form';
import ShopifyGoogleRichSnippet from '../tutorials/components/shopify/google-rich-snippet';
import SquarespaceReviewForm from '../tutorials/components/squarespace/review-form';
import WebflowReviewForm from '../tutorials/components/webflow/review-form';
import WixReviewForm from '../tutorials/components/wix/review-form';
import WordpressReviewForm from '../tutorials/components/wordpress/review-form';
import {
  accountSelector,
  isEcommerceSelector,
} from '../userData/redux/userData.slice';
import HowToInstall from '../widgetSettings/widgets/HowToInstall';
import ConnectEcommerceModal from './components/ConnectEcommerceModal';
import CreateFormModal from './components/CreateFormModal';
import CreditLimitModal from './components/CreditLimitModal';
import DeleteFormModal from './components/DeleteFormModal';
import EmbedFormSettings from './components/EmbedFormSettings';
import FormListItem from './components/FormListItem';
import MarketingPlatforms from './components/MarketingPlatforms';
import { fetchReviewForms } from './redux/actions';
import {
  ReviewForm,
  reviewFormSelector,
  updateReviewFormSettings,
} from './redux/review-form.slice';

const Wrapper = styled.div`
  padding: 0px 30px 20px;
  max-width: 1100px;
  margin: auto;
`;

const Subtitle = styled.p`
  font-size: 15px;
  color: #64748b;
  margin: 0 0 40px;
  max-width: 500px;
  line-height: 1.5;
`;

const FormList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CreateButton = styled(Button)`
  background-color: #111827;
  color: white;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  border-radius: 6px;
  font-weight: 500;
  border: none;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #1f2937;
    transform: translateY(-1px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
    background-color: #000000;
  }

  svg {
    font-size: 14px;
  }
`;

export type EmbedFormSettingsPayload = {
  activeOption: string;
  embedStyle: string;
  openTrigger: string;
  delay: number;
  percentageOfPageScrolled: number;
};

export default function ReviewForms() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [embedFormSettings, setEmbedFormSettings] =
    useState<EmbedFormSettingsPayload | null>(null);

  const [formUrl, setFormUrl] = useState<string | null>(null);
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { plan, platform } = selectedSite ?? {};
  const {
    stats: { totalSentThisMonth },
  } = useAppSelector(emailsSelector);
  const { reviewForms } = useAppSelector(reviewFormSelector);
  const { data: reviewFormsData = [], loading: reviewFormsLoading } =
    reviewForms ?? {};
  const [formId, setFormId] = useState<string | null>(null);

  const {
    handleCloseModal: handleCloseCreditLimitModal,
    handleOpenModal: handleOpenCreditLimitModal,
    showModal: showCreditLmitModal,
  } = useModal();
  const { handleCloseModal, handleOpenModal, showModal } = useModal();
  const {
    handleOpenModal: handleOpenMarketingPlatformsModal,
    handleCloseModal: handleCloseMarketingPlatformsModal,
    showModal: showMarketingPlatformsModal,
  } = useModal();
  const {
    handleCloseModal: handleCloseCreateFormModal,
    handleOpenModal: handleOpenCreateFormModal,
    showModal: showCreateFormModal,
  } = useModal();

  const {
    handleCloseModal: handleCloseDeleteFormModal,
    handleOpenModal: handleOpenDeleteFormModal,
    showModal: showDeleteFormModal,
  } = useModal();

  const {
    handleCloseModal: handleCloseEmbedFormModal,
    handleOpenModal: handleOpenEmbedFormModal,
    showModal: showEmbedFormModal,
  } = useModal();

  const {
    handleCloseModal: handleCloseEmbedFormSettingsModal,
    handleOpenModal: handleOpenEmbedFormSettingsModal,
    showModal: showEmbedFormSettingsModal,
  } = useModal();

  const [openTutorial, setOpenTutorial] = useState<ReviewsJetPlatform | null>(
    null
  );

  const [selectedForm, setSelectedForm] = useState<ReviewForm | null>(null);

  const emailsCredit = getLimit(plan)?.reviewsRequests;

  const canRequest = useMemo(() => {
    return emailsCredit > totalSentThisMonth;
  }, [emailsCredit, totalSentThisMonth]);

  const isCommerce = useAppSelector(isEcommerceSelector);

  const handleShareClick = (option: string, formUrl: string) => {
    const text = 'Share your experience with us!';

    const shareUrls = {
      twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        formUrl
      )}&text=${encodeURIComponent(text)}`,
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        formUrl
      )}`,
      linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        formUrl
      )}`,
      whatsapp: `https://wa.me/?text=${encodeURIComponent(
        `${text} ${formUrl}`
      )}`,
      sms: `sms:?body=${encodeURIComponent(`${text} ${formUrl}`)}`,
    };

    if (option === 'sms') {
      window.location.href = shareUrls[option as keyof typeof shareUrls];
    } else {
      window.open(shareUrls[option as keyof typeof shareUrls], '_blank');
    }
  };

  useEffect(() => {
    dispatch(fetchReviewForms());
  }, []);

  return (
    <>
      <Wrapper>
        <FlexContainer justifyContent="space-between">
          <div>
            <Title>Review forms</Title>
            <Subtitle>
              Make review collection painless for both you and your customers.
            </Subtitle>
          </div>
          <CreateButton onClick={handleOpenCreateFormModal}>
            <FaPlus />
            <b>Create form</b>
          </CreateButton>
        </FlexContainer>

        {reviewFormsLoading === Loading.PENDING && <Preloader />}
        {reviewFormsLoading === Loading.ERROR && (
          <ErrorComponent onClick={() => dispatch(fetchReviewForms())} />
        )}

        {reviewFormsLoading === Loading.SUCCESS && (
          <FormList>
            {reviewFormsData.map((form) => (
              <FormListItem
                key={form._id}
                isDefault={form.isDefault}
                reviewForm={form}
                formUrl={`${removeTrailingSlash(
                  process.env.REACT_APP_REVIEWSJET_WEBSITE!
                )}/w/${form.trustKey}`}
                createdAt={form.created_at ?? ''}
                title={form.name}
                responsesCount={form.totalReviewsCollected}
                handleShareClick={handleShareClick}
                onClick={(e) => {
                  e?.stopPropagation();

                  if (form.isDefault) {
                    navigate('/forms/edit');
                  } else {
                    navigate(`/forms/edit?id=${form._id}`);
                  }
                }}
                onSingleInviteClick={(reviewForm) => {
                  if (canRequest) {
                    dispatch(updateReviewFormSettings(reviewForm));
                    navigate(`/share-form?key=${reviewForm.trustKey}`);
                  } else {
                    handleOpenCreditLimitModal();
                  }
                }}
                onInviteFromOrdersClick={(reviewForm) => {
                  if (isCommerce) {
                    if (canRequest) {
                      navigate(
                        `/share-form?source=orders&key=${reviewForm.trustKey}`
                      );
                    } else {
                      handleOpenCreditLimitModal();
                    }
                  } else {
                    handleOpenModal();
                  }
                }}
                onInviteFromMarketingPlatformsClick={() => {
                  if (canRequest) {
                    handleOpenMarketingPlatformsModal();
                  } else {
                    handleOpenCreditLimitModal();
                  }
                }}
                handleShowDuplicateFormModal={() => {
                  setFormId(form._id);
                  handleOpenCreateFormModal();
                }}
                handleOpenDeleteFormModal={() => {
                  setFormId(form._id);
                  handleOpenDeleteFormModal();
                }}
                onEmbedFormClick={() => {
                  setSelectedForm(form);
                  handleOpenEmbedFormSettingsModal();

                  // if (platform === ReviewsJetPlatform.WIX) {
                  //   setOpenTutorial(ReviewsJetPlatform.WIX);
                  // } else if (platform === ReviewsJetPlatform.WEBFLOW) {
                  //   setOpenTutorial(ReviewsJetPlatform.WEBFLOW);
                  // } else if (platform === ReviewsJetPlatform.SHOPIFY) {
                  //   setOpenTutorial(ReviewsJetPlatform.SHOPIFY);
                  // } else {
                  //   handleOpenEmbedFormModal();
                  // }
                }}
              />
            ))}
          </FormList>
        )}
      </Wrapper>

      <AppModal
        size="sm"
        shouldOpenModal={showModal}
        onModalClose={handleCloseModal}
        body={<ConnectEcommerceModal onClose={handleCloseModal} />}
      />
      <AppModal
        size="sm"
        shouldOpenModal={showCreditLmitModal}
        onModalClose={handleCloseCreditLimitModal}
        body={<CreditLimitModal onClose={handleCloseCreditLimitModal} />}
      />

      <AppModal
        size="xs"
        shouldOpenModal={showMarketingPlatformsModal}
        onModalClose={handleCloseMarketingPlatformsModal}
        body={
          <MarketingPlatforms onClose={handleCloseMarketingPlatformsModal} />
        }
        footer={
          <FlexContainer justifyContent="flex-end">
            <Button onClick={handleCloseMarketingPlatformsModal}>Close</Button>
          </FlexContainer>
        }
      />

      <AppModal
        size="xs"
        shouldOpenModal={showCreateFormModal}
        onModalClose={handleCloseCreateFormModal}
        body={
          <CreateFormModal
            onClose={handleCloseCreateFormModal}
            formId={formId}
          />
        }
      />

      <AppModal
        size="xs"
        shouldOpenModal={showDeleteFormModal}
        onModalClose={handleCloseDeleteFormModal}
        body={
          <DeleteFormModal
            formId={formId}
            onClose={handleCloseDeleteFormModal}
          />
        }
      />

      <AppModal
        size="sm"
        shouldOpenModal={showEmbedFormModal}
        onModalClose={handleCloseEmbedFormModal}
        body={
          <HowToInstall
            widget="form"
            onClose={handleCloseEmbedFormModal}
            openTutorial={(platform: ReviewsJetPlatform) =>
              setOpenTutorial(platform)
            }
          />
        }
      />

      <Drawer
        size="lg"
        open={!!openTutorial}
        onClose={() => setOpenTutorial(null)}
        className="tutorial-drawer"
      >
        <Drawer.Header>
          <Drawer.Title>{`How To Install the Review Form on ${capitalize(
            openTutorial ?? ''
          )}`}</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => setOpenTutorial(null)}>Close</Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          {openTutorial === ReviewsJetPlatform.WORDPRESS && (
            <WordpressReviewForm
              formKey={selectedForm?.trustKey ?? ''}
              embedFormSettings={embedFormSettings}
            />
          )}
          {openTutorial === ReviewsJetPlatform.WIX && (
            <WixReviewForm
              formKey={selectedForm?.trustKey ?? ''}
              embedFormSettings={embedFormSettings}
            />
          )}
          {openTutorial === ReviewsJetPlatform.SHOPIFY && (
            <ShopifyGoogleRichSnippet />
          )}
          {openTutorial === ReviewsJetPlatform.WEBFLOW && (
            <WebflowReviewForm
              formKey={selectedForm?.trustKey ?? ''}
              embedFormSettings={embedFormSettings}
            />
          )}
          {openTutorial === ReviewsJetPlatform.SQUARESPACE && (
            <SquarespaceReviewForm formKey={selectedForm?.trustKey ?? ''} />
          )}
          {openTutorial === ReviewsJetPlatform.GO_DADDY && (
            <GoDaddyReviewForm formKey={selectedForm?.trustKey ?? ''} />
          )}
          {openTutorial === ReviewsJetPlatform.PIXPA && (
            <PixpaReviewForm formKey={selectedForm?.trustKey ?? ''} />
          )}
          {openTutorial === ReviewsJetPlatform.OTHERS && (
            <OthersReviewForm
              formKey={selectedForm?.trustKey ?? ''}
              embedFormSettings={embedFormSettings}
            />
          )}
        </Drawer.Body>
      </Drawer>

      <AppModal
        size="md"
        shouldOpenModal={showEmbedFormSettingsModal}
        onModalClose={handleCloseEmbedFormSettingsModal}
        body={
          <EmbedFormSettings
            onClose={handleCloseEmbedFormSettingsModal}
            onContinue={(payload) => {
              setEmbedFormSettings(payload);
              handleCloseEmbedFormSettingsModal();

              if (platform === ReviewsJetPlatform.WIX) {
                setOpenTutorial(ReviewsJetPlatform.WIX);
              } else if (platform === ReviewsJetPlatform.WEBFLOW) {
                setOpenTutorial(ReviewsJetPlatform.WEBFLOW);
              } else if (platform === ReviewsJetPlatform.SHOPIFY) {
                setOpenTutorial(ReviewsJetPlatform.SHOPIFY);
              } else {
                handleOpenEmbedFormModal();
              }
            }}
          />
        }
      />
    </>
  );
}
