export const YELP_LOCATIONS_PER_PAGE = 10;
export const GOOGLE_BUSINESSESS_PER_PAGE = 10;
export const GOOGLE_APIS_BASE_URL = 'https://www.googleapis.com';
export const VALID_PRODUCTHUNT_DOMAIN = 'www.producthunt.com';
export const VALID_CAPTERRA_DOMAIN = 'www.capterra.com';
export const VALID_BOOKING_DOMAIN = 'booking.com';
export const VALID_TRUSTPILOT_DOMAINS = [
  'www.trustpilot.com',
  'ch.trustpilot.com',
  'fr.trustpilot.com',
  'de.trustpilot.com',
  'es.trustpilot.com',
  'it.trustpilot.com',
  'nl.trustpilot.com',
  'pl.trustpilot.com',
  'pt.trustpilot.com',
  'ro.trustpilot.com',
  'ru.trustpilot.com',
  'tr.trustpilot.com',
  'ua.trustpilot.com',
  'vi.trustpilot.com',
  'zh.trustpilot.com',
  'dk.trustpilot.com',
  'no.trustpilot.com',
  'se.trustpilot.com',
  'fi.trustpilot.com',
  'gr.trustpilot.com',
  'cz.trustpilot.com',
  'be.trustpilot.com',
  'bg.trustpilot.com',
  'hr.trustpilot.com',
  'cy.trustpilot.com',
  'hu.trustpilot.com',
  'is.trustpilot.com',
  'lt.trustpilot.com',
  'lv.trustpilot.com',
  'mk.trustpilot.com',
  'mt.trustpilot.com',
  'sk.trustpilot.com',
  'si.trustpilot.com',
];
export const VALID_AMAZON_DOMAINS = [
  'amazon.com',
  'amazon.ca',
  'amazon.com.mx',
  'amazon.co.uk',
  'amazon.fr',
  'amazon.de',
  'amazon.it',
  'amazon.es',
  'amazon.in',
  'amazon.jp',
  'amazon.nl',
  'amazon.com.tr',
  'amazon.sa',
  'amazon.ae',
  'amazon.au',
  'amazon.sg',
  'amazon.com.br',
];

export const VALID_AIRBNB_DOMAINS = [
  'airbnb.ca',
  'airbnb.ca',
  'airbnb.co.uk',
  'airbnb.com.au',
  'airbnb.de',
  'airbnb.fr',
  'airbnb.it',
  'airbnb.es',
  'airbnb.co.in',
  'airbnb.jp',
  'airbnb.cn',
  'airbnb.com',
  'airbnb.cat',
];

export const VALID_TRIPADVISOR_DOMAINS = [
  'www.tripadvisor.com', // United States
  'www.tripadvisor.ca', // Canada
  'www.tripadvisor.com.mx', // Mexico
  'www.tripadvisor.co.uk', // United Kingdom
  'www.tripadvisor.de', // Germany
  'www.tripadvisor.fr', // France
  'www.tripadvisor.it', // Italy
  'www.tripadvisor.es', // Spain
  'www.tripadvisor.nl', // Netherlands
  'www.tripadvisor.se', // Sweden
  'www.tripadvisor.dk', // Denmark
  'www.tripadvisor.no', // Norway
  'www.tripadvisor.ie', // Ireland
  'www.tripadvisor.in', // India
  'www.tripadvisor.jp', // Japan
  'www.tripadvisor.cn', // China
  'www.tripadvisor.com.sg', // Singapore
  'www.tripadvisor.co.th', // Thailand
  'www.tripadvisor.com.my', // Malaysia
  'www.tripadvisor.com.au', // Australia
  'www.tripadvisor.co.nz', // New Zealand
  'www.tripadvisor.com.br', // Brazil
  'www.tripadvisor.com.ar', // Argentina
  'www.tripadvisor.cl', // Chile
  'www.tripadvisor.co.za', // South Africa
  'www.tripadvisor.ae', // United Arab Emirates
  'www.tripadvisor.co.il', // Israel
  'www.tripadvisor.sa', // Saudi Arabia
];

export const HIDE_THIRD_PARTY_IMPORT_FOR_SHOPIFY = true;
