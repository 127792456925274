import styled from 'styled-components';

const Button = styled.span`
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 3px;
`;

export default function Installation({ onInstall }: { onInstall: () => void }) {
  return (
    <div>
      <p>
        Click{' '}
        <Button onClick={onInstall} role="presentation">
          here
        </Button>{' '}
        to install the product reviews widget.
      </p>
    </div>
  );
}
