import { useEffect, useRef } from 'react';
import { Placeholder } from 'rsuite';
import styled from 'styled-components';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import { useAppSelector } from '../../../../store';
import { ReviewsJetPlatform } from '../../../product/types';
import Header from '../Header';
import { OptionBeingUpdated } from '../redux/product-reviews-settings.slice';
import ProductWidget from './product-widget';
import StarWidget from './start-widget';

const PreviewFrame = styled.div<{
  $backgroundColor?: string;
  $mode: 'mobile' | 'desktop';
}>`
  padding-top: 30px;
  width: ${(props) => (props.$mode === 'mobile' ? '375px' : '100%')};
  height: ${(props) => (props.$mode === 'mobile' ? '667px' : '100%')};
  margin: ${(props) => (props.$mode === 'mobile' ? '20px auto' : '0')};
  border-radius: ${(props) => (props.$mode === 'mobile' ? '20px' : 0)};
  background-color: #fff;
  backdrop-filter: blur(10px);
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: ${(props) =>
      props.$backgroundColor
        ? `radial-gradient(circle at 50% 0%, 
            ${props.$backgroundColor}22 0%,
            transparent 50%)`
        : 'transparent'};
    pointer-events: none;
  }
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: auto;
  flex: ${(props) => (props.$mode === 'desktop' ? '1' : 'none')};
  align-self: center;
  transform-origin: center;
  transform: ${(props) => (props.$mode === 'mobile' ? 'scale(1)' : 'none')};
  display: flex;
  align-items: flex-start;
  justify-content: center;

  ${(props) =>
    props.$mode === 'mobile' &&
    `top: 80px;
  `}
  .rs-placeholder-row {
    background: rgb(229 229 229);
  }
  @media (max-width: 644px) {
    .product-image {
      width: 100%;
      max-width: 300px;
    }
    .more-description {
      display: none;
    }
  }
`;

const PreviewContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProductInformation = styled(FlexContainer)`
  width: calc(100% - 40px);
  max-width: 1100px;
  margin: auto;
  margin-bottom: 20px;
`;

export default function Preview({ onInstall }: { onInstall: () => void }) {
  const previewContainerRef = useRef<HTMLDivElement>(null);
  const { optionBeingUpdated } = useAppSelector(
    (state) => state.productReviewsSettings
  );

  useEffect(() => {
    if (
      optionBeingUpdated?.includes(
        OptionBeingUpdated.REVIEW_SUMMARY_ALIGNMENT
      ) ||
      optionBeingUpdated?.includes(
        OptionBeingUpdated.REVIEW_SUMMARY_SINGULAR
      ) ||
      optionBeingUpdated?.includes(OptionBeingUpdated.REVIEW_SUMMARY_PLURAL)
    ) {
      previewContainerRef.current?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [optionBeingUpdated]);

  return (
    <PreviewContainer>
      <Header onInstall={onInstall} />

      <PreviewFrame
        $mode="desktop"
        $backgroundColor={'#f0f0f0'}
        ref={previewContainerRef}
      >
        <div style={{ width: '100%', maxWidth: '1100px', margin: 'auto' }}>
          {optionBeingUpdated !==
            OptionBeingUpdated.PRODUCT_REVIEWS_EMPTY_STATE && (
            <ProductInformation
              justifyContent="flex-start"
              alignItems="flex-start"
              gap="30px"
            >
              <Placeholder.Graph width={200} height={200} />
              <div style={{ width: 'calc(100% - 200px)' }}>
                <Placeholder.Paragraph />
                <StarWidget
                  productId="123"
                  instanceId="123"
                  siteId="123"
                  platform={ReviewsJetPlatform.WIX}
                />
                <Placeholder.Paragraph rows={1} />
                <div className="more-description">
                  <Placeholder.Paragraph />
                </div>
              </div>
            </ProductInformation>
          )}

          <Placeholder.Paragraph rows={2} />

          <ProductWidget
            productId="123"
            instanceId="123"
            siteId="123"
            platform={ReviewsJetPlatform.WIX}
          />
        </div>
      </PreviewFrame>
    </PreviewContainer>
  );
}
