import { unwrapResult } from '@reduxjs/toolkit';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store';
import { verifyInstance } from '../../store/actions';
import localStorage from '../utils/localStorage';
import setSession from '../utils/setSession';
import useQuery from './useQuery';

export default function useWixAuth() {
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const instance = query.get('instance');
  const [error, setError] = useState<string | null>(null);
  const loading = useAppSelector(
    (state) => state.userData.verifyInstance.loading
  );

  const authWixAccount = useCallback(() => {
    if (instance) {
      localStorage.remove('session');

      dispatch(verifyInstance({ instance }))
        .then(unwrapResult)
        .then(({ jwtAccessToken, jwtRefreshToken }) => {
          setSession({ jwtAccessToken, jwtRefreshToken });
          setError(null);

          if (window.location.pathname !== '/plans') {
            setTimeout(() => {
              navigate(`/manage-reviews?instance=${instance}`, {
                replace: true,
              });
            }, 500);
          }
        })
        .catch(() => {
          setError('Failed to verify instance');
        });
    }
  }, [instance]);

  useEffect(() => {
    authWixAccount();
  }, []);

  return { loading, error, authWixAccount };
}
