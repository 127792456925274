import { useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { FaRegTrashCan } from 'react-icons/fa6';
import { MdDragIndicator } from 'react-icons/md';
import { Input, InputGroup } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../../../../shared/components/Button';
import { Label } from '../../../../../../shared/components/Label';
import FlexContainer from '../../../../../../shared/components/layouts/flex-container';
import { useNotification } from '../../../../../../shared/hooks/useNotification';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import FormRow from '../../../../../widgetSettings/components/form-row';
import {
  reviewFormSelector,
  updateReviewFormSettings,
} from '../../../../redux/review-form.slice';

/* eslint no-console: "warn" */

const IconButton = styled.button`
  display: flex;
  align-items: center;
  padding: 6px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background: #fff;
  color: #64748b;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;

  &:hover {
    background: #f8fafc;
    border-color: #cbd5e1;
  }

  &:hover::after {
    content: 'Add a wheel section';
    position: absolute;
    bottom: calc(100% + 8px);
    right: 0;
    background: #1e293b;
    color: white;
    padding: 6px 10px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    pointer-events: none;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  }

  &:hover::before {
    content: '';
    position: absolute;
    bottom: calc(100% + 2px);
    right: 10px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #1e293b;
  }
`;

const DiscountItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 5px 8px;
  background: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  /* transition: all 0.2s ease; */

  &:hover {
    border-color: #cbd5e1;
    background: #f8fafc;
  }
`;

const DragHandle = styled.div`
  color: #94a3b8;
  cursor: grab;
  display: flex;
  align-items: center;

  &:active {
    cursor: grabbing;
  }
`;

const DiscountInfo = styled.div`
  flex: 1;
`;

const DiscountLabel = styled.div`
  font-weight: 500;
  color: #334155;
  font-size: 12px;
`;

const DiscountCode = styled.div`
  color: #64748b;
  font-size: 12px;
  margin-top: 2px;
  font-family: monospace;
`;

const DiscountValue = styled.div`
  background: #f1f5f9;
  color: #475569;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 900;
`;

const DeleteButton = styled.button`
  color: #94a3b8;
  padding: 6px;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 4px;
  transition: all 0.2s ease;

  &:hover {
    color: #ef4444;
    background: #fee2e2;
  }
`;

const DraggableDiscountList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  width: 100%;
`;

const DraggableDiscountItem = styled(DiscountItem)<{ isDragging: boolean }>`
  background: ${(props) => (props.isDragging ? '#f8fafc' : '#fff')};
  box-shadow: ${(props) =>
    props.isDragging ? '0 4px 6px -1px rgb(0 0 0 / 0.1)' : 'none'};
`;

const NewDiscountForm = styled(FlexContainer)`
  margin-top: 16px;
`;

const MAX_SEGMENTS = 5;

export default function WheelSections() {
  const dispatch = useAppDispatch();
  const { showWarningToast } = useNotification();
  const [newDiscount, setNewDiscount] = useState({
    code: '',
    value: '',
  });

  const { reviewFormSettings } = useAppSelector(reviewFormSelector);
  const { reward } = reviewFormSettings ?? {};
  const { segments = [] } = reward ?? {};

  const handleDeleteSegment = (index: number) => {
    // Prevent deletion if this is the last segment
    if ((segments?.length ?? 0) <= 1) {
      showWarningToast(
        'You must have at least one reward when using the wheel reward'
      );
      return;
    }

    const updatedSegments = segments?.filter((_, i) => i !== index) ?? [];
    dispatch(
      updateReviewFormSettings({
        ...reviewFormSettings,
        reward: { ...reward, segments: updatedSegments },
      })
    );
  };

  const handleReorderSegments = (startIndex: number, endIndex: number) => {
    // Don't proceed if we don't have segments
    if (!segments?.length) {
      return;
    }

    const reorderedSegments = Array.from(segments);
    const [removed] = reorderedSegments.splice(startIndex, 1);
    reorderedSegments.splice(endIndex, 0, removed);
    dispatch(
      updateReviewFormSettings({
        ...reviewFormSettings,
        reward: { ...reward, segments: reorderedSegments },
      })
    );
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    if (sourceIndex === destinationIndex) return;

    handleReorderSegments(sourceIndex, destinationIndex);
  };

  const handleDiscountCodeChange = (value: string) => {
    // Remove special characters and spaces, keep only letters and numbers
    const cleanValue = value.replace(/[^a-zA-Z0-9]/g, '');

    // Limit to 30 characters
    const truncatedValue = cleanValue.slice(0, 30);

    setNewDiscount((prev) => ({ ...prev, code: truncatedValue }));
  };

  const handleDiscountValueChange = (value: string) => {
    const numericValue = value.replace(/\D/g, '');

    if (Number(numericValue) > 100) {
      setNewDiscount((prev) => ({ ...prev, value: '100' }));
      return '100';
    }

    setNewDiscount((prev) => ({
      ...prev,
      value: numericValue === '' ? '' : Number(numericValue).toString(),
    }));

    return numericValue === '' ? '' : Number(numericValue).toString();
  };

  return (
    <>
      <FormRow>
        {segments.length < MAX_SEGMENTS && (
          <NewDiscountForm justifyContent="space-between" gap="10px">
            <div style={{ width: 225 }}>
              <Label>Discount Code</Label>
              <Input
                placeholder="WELCOME25"
                value={newDiscount.code}
                onChange={handleDiscountCodeChange}
                maxLength={30}
              />
            </div>
            <div style={{ width: 130 }}>
              <Label>Discount Value</Label>
              <InputGroup inside>
                <Input
                  type="text"
                  placeholder="20"
                  value={newDiscount.value}
                  onChange={handleDiscountValueChange}
                />
                <InputGroup.Button>%</InputGroup.Button>
              </InputGroup>
            </div>
            <div>
              <Label style={{ visibility: 'hidden' }}>.</Label>

              <Button
                variant="primary"
                rounded
                onClick={() => {
                  if (newDiscount.code === '' || newDiscount.value === '') {
                    return;
                  }

                  dispatch(
                    updateReviewFormSettings({
                      ...reviewFormSettings,
                      reward: {
                        ...reward,
                        segments: [
                          ...segments,
                          {
                            discountLabel: newDiscount.code,
                            couponCode: newDiscount.code,
                            discountValue: Number(newDiscount.value),
                          },
                        ],
                      },
                    })
                  );
                  setNewDiscount({ code: '', value: '' });
                }}
              >
                Add discount
              </Button>
            </div>
          </NewDiscountForm>
        )}

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="discount-list">
            {(provided) => (
              <DraggableDiscountList
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {segments.map((segment, index) => (
                  <Draggable
                    key={index}
                    draggableId={`segment-${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <DraggableDiscountItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        isDragging={snapshot.isDragging}
                      >
                        <DragHandle {...provided.dragHandleProps}>
                          <MdDragIndicator size={20} />
                        </DragHandle>
                        <DiscountInfo>
                          <DiscountLabel>
                            {segment.discountValue}% OFF
                          </DiscountLabel>
                          <DiscountCode>{segment.couponCode}</DiscountCode>
                        </DiscountInfo>
                        <DiscountValue>
                          {segment.discountValue.toFixed(2)}%
                        </DiscountValue>
                        <DeleteButton
                          onClick={() => handleDeleteSegment(index)}
                        >
                          <FaRegTrashCan size={16} />
                        </DeleteButton>
                      </DraggableDiscountItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </DraggableDiscountList>
            )}
          </Droppable>
        </DragDropContext>
      </FormRow>
    </>
  );
}
