import { useEffect, useState } from 'react';
import { fetchPlans } from '../../features/plans/redux/actions';
import { plansSelector } from '../../features/plans/redux/plans.slice';
import { accountSelector } from '../../features/userData/redux/userData.slice';
import { useAppDispatch, useAppSelector } from '../../store';
import { Loading, PlanCycle, SubscriptionPlan } from '../types/review.types';

export default function usePlanDescriptor() {
  const dispatch = useAppDispatch();
  const { account, loading } = useAppSelector(accountSelector);
  const { selectedSite } = account || {};
  const { plan, planCycle } = selectedSite ?? {};
  const { loading: loadingPlans, data: plans } = useAppSelector(plansSelector);
  const [planDescription, setPlanDescription] = useState('');

  useEffect(() => {
    dispatch(fetchPlans());
  }, []);

  useEffect(() => {
    if (loadingPlans === Loading.SUCCESS) {
      if (plan === SubscriptionPlan.FREE) {
        setPlanDescription(`Basic ~ $0/month`);
      } else if (plan === SubscriptionPlan.PRO) {
        // @ts-ignore
        const planPrice = plans.find((_plan) => _plan.name === plan);

        if (planPrice) {
          setPlanDescription(
            `Pro ~ $${
              planCycle === PlanCycle.MONTHLY
                ? planPrice.monthly
                : planPrice.anually
            }/${planCycle === PlanCycle.MONTHLY ? 'month' : 'year'} `
          );
        }
      } else if (plan === SubscriptionPlan.ADVANCED) {
        // @ts-ignore
        const planPrice = plans.find((_plan) => _plan.name === plan);

        if (planPrice) {
          setPlanDescription(
            `Advanced ~ $${
              planCycle === PlanCycle.MONTHLY
                ? planPrice.monthly
                : planPrice.anually
            }/${planCycle === PlanCycle.MONTHLY ? 'month' : 'year'} `
          );
        }
      }
    }
  }, [plan, plans, loadingPlans, planCycle]);

  return { planDescription, loading };
}
