export const TAB_WIDGET_PRODUCTION_CSS = `https://cdn.reviewsjet.com/@FlexByt/widgets/
modal-widget/bundle.css?v=1.0.0`;
export const TAB_WIDGET_PRODUCTION_JS = `https://cdn.reviewsjet.com/@FlexByt/widgets/
modal-widget/bundle.js?v=1.0.0`;

export const TAB_WIDGET_DEVELOPMENT_CSS = `https://cdn.reviewsjet.com/TEST/@FlexByt/widgets/
modal-widget/bundle.css?v=1.0.0`;
export const TAB_WIDGET_DEVELOPMENT_JS = `https://cdn.reviewsjet.com/TEST/@FlexByt/widgets/
modal-widget/bundle.js?v=1.0.0`;

export const EMBEDDED_PRODUCTION_JS = `https://cdn.reviewsjet.com/@FlexByt/widgets/
embedded-widgets/bundle.js?v=1.0.0`;
export const EMBEDDED_DEVELOPMENT_JS = `https://cdn.reviewsjet.com/TEST/@FlexByt/widgets/
embedded-widgets/bundle.js?v=1.0.0`;

export const PRODUCT_WIDGET_PRODUCTION_JS = `https://cdn.reviewsjet.com/@FlexByt/widgets/
product-widget/bundle.js`;
export const PRODUCT_WIDGET_PRODUCTION_CSS = `https://cdn.reviewsjet.com/@FlexByt/widgets/
product-widget/bundle.css`;

export const PRODUCT_WIDGET_DEVELOPMENT_JS = `https://cdn.reviewsjet.com/TEST/@FlexByt/widgets/
product-widget/bundle.js`;
export const PRODUCT_WIDGET_DEVELOPMENT_CSS = `https://cdn.reviewsjet.com/TEST/@FlexByt/widgets/
product-widget/bundle.css`;

export const GOOGLE_SNIPPET_JS = `https://cdn.reviewsjet.com/@FlexByt/widgets/business-google-rich-snippet/
business-rich-snippet.bundle.js?v=1.0.2`;
