import dayjs from 'dayjs';
import { QRCodeSVG } from 'qrcode.react';
import { useEffect, useRef, useState } from 'react';
import {
  FaFacebook,
  FaLinkedin,
  FaPencilAlt,
  FaQrcode,
  FaRegEye,
  FaRegTrashAlt,
  FaSms,
  FaTwitter,
  FaWhatsapp,
} from 'react-icons/fa';
import { FaCode } from 'react-icons/fa6';
import { GoShareAndroid } from 'react-icons/go';
import { IoDuplicateOutline } from 'react-icons/io5';
import styled from 'styled-components';
import FormSvgIcon from '../../../shared/components/FormSvgIcon';
import useModal from '../../../shared/components/modal/useModal';
import { ReviewsJetPlatform } from '../../product/types';
import { ReviewForm } from '../redux/review-form.slice';

/* eslint no-console: "warn" */

const FormItem = styled.div<{ $primaryColor: string }>`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  background-color: white;
  transition: all 0.2s ease-out;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background-color: transparent;
    transition: background-color 0.2s ease;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    border-left: none !important;
  }

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    border-color: #d1d5db;
    background-color: #fafafa;

    &::before {
      background-color: ${(props) => props.$primaryColor};
    }
  }
`;

const FormInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const FormIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #f3f4f6;
  border-radius: 8px;
`;

const FormDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormName = styled.h3`
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  margin: 0 0 0px 0;
  line-height: 25px;
`;

const FormMeta = styled.div`
  font-size: 14px;
  color: #6b7280;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Separator = styled.span`
  font-size: 15px;
  font-weight: 600;
`;

const FormActions = styled.div`
  display: flex;
  align-items: stretch;
  gap: 8px;
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px;
  border-radius: 4px;
  color: #4b5563;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f3f4f6;
  }
`;

const DropdownMenu = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 200px;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 9999900;
  overflow: hidden;
  opacity: ${(props) => (props.$isOpen ? 1 : 0)};
  visibility: ${(props) => (props.$isOpen ? 'visible' : 'hidden')};
  transform: ${(props) =>
    props.$isOpen ? 'translateY(0)' : 'translateY(-8px)'};
  transition: opacity 0.2s ease, transform 0.2s ease, visibility 0.2s;
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  text-align: left;
  padding: 10px 12px;
  background: #fff;
  border: none;
  cursor: pointer;
  color: #4b5563;
  font-size: 14px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f3f4f6;
  }

  svg {
    color: #6b7280;
  }
`;

const DeleteButton = styled(ActionButton)`
  &:hover {
    background-color: #fee2e2;
    color: #ef4444;
  }
`;

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-flex;
`;

const TooltipContent = styled.div<{ $visible: boolean }>`
  position: absolute;
  bottom: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  padding: 6px 10px;
  background-color: #333;
  color: white;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
  pointer-events: none;
  opacity: ${(props) => (props.$visible ? 1 : 0)};
  transition: opacity 0.2s ease;
  z-index: 10;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 4px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }
`;

interface TooltipProps {
  text: string;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <TooltipWrapper
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      <TooltipContent $visible={isVisible}>{text}</TooltipContent>
    </TooltipWrapper>
  );
};

const ResponseCount = styled.span<{ $hasResponses: boolean }>`
  color: ${(props) => (props.$hasResponses ? '#3b82f6' : 'inherit')};
  font-weight: ${(props) => (props.$hasResponses ? '500' : 'normal')};
  transition: color 0.2s ease;
`;

type Props = {
  title: string;
  formUrl: string;
  createdAt: string;
  isDefault?: boolean;
  responsesCount: number;
  reviewForm: ReviewForm;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onSingleInviteClick?: (form: ReviewForm) => void;
  onInviteFromOrdersClick?: (form: ReviewForm) => void;
  onInviteFromMarketingPlatformsClick?: () => void;
  handleShowDuplicateFormModal: () => void;
  handleOpenDeleteFormModal: () => void;
  handleShareClick: (option: string, formLink: string) => void;
  onEmbedFormClick: () => void;
};

const FormListItem = ({
  formUrl,
  createdAt,
  title,
  responsesCount,
  isDefault,
  reviewForm,
  onClick,
  onSingleInviteClick,
  onInviteFromOrdersClick,
  handleShowDuplicateFormModal,
  onInviteFromMarketingPlatformsClick,
  handleOpenDeleteFormModal,
  handleShareClick,
  onEmbedFormClick,
}: Props) => {
  const qrCodeRef = useRef<SVGSVGElement | null>(null);
  const [inviteDropdownOpen, setInviteDropdownOpen] = useState(false);
  const [shareDropdownOpen, setShareDropdownOpen] = useState(false);
  const inviteDropdownRef = useRef<HTMLDivElement>(null);
  const shareDropdownRef = useRef<HTMLDivElement>(null);
  const { handleCloseModal, handleOpenModal, showModal } = useModal();
  const [open, setOpen] = useState<ReviewsJetPlatform | null>(null);

  const qrCodeSize = 256;

  const handleDownloadQR = () => {
    if (qrCodeRef.current) {
      const svg = qrCodeRef.current;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = qrCodeSize;
      canvas.height = qrCodeSize;

      const svgData = new XMLSerializer().serializeToString(svg);
      const img = new Image();
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;

      img.onload = () => {
        if (ctx) {
          ctx.drawImage(img, 0, 0, qrCodeSize, qrCodeSize);
          const pngUrl = canvas.toDataURL('image/png');
          const downloadLink = document.createElement('a');
          downloadLink.href = pngUrl;
          downloadLink.download = 'review-form-qr-code.png';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      };
    }
  };

  // Close dropdowns when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inviteDropdownRef.current &&
        !inviteDropdownRef.current.contains(event.target as Node)
      ) {
        setInviteDropdownOpen(false);
      }
      if (
        shareDropdownRef.current &&
        !shareDropdownRef.current.contains(event.target as Node)
      ) {
        setShareDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <FormItem
        onClick={onClick}
        role="presentation"
        $primaryColor={reviewForm.appearance.primaryColor}
      >
        <FormInfo>
          <FormIcon>
            <FormSvgIcon />
          </FormIcon>
          <FormDetails>
            <FormName>{title}</FormName>
            <FormMeta>
              <ResponseCount $hasResponses={responsesCount > 0}>
                {responsesCount} response{responsesCount > 1 ? 's' : ''}
              </ResponseCount>
              <Separator>•</Separator>
              <span>Created on {dayjs(createdAt).format('MMM D, YYYY')}</span>
            </FormMeta>
          </FormDetails>
        </FormInfo>

        <FormActions>
          {/* <InviteButton
            isOpen={inviteDropdownOpen}
            onInviteOpen={(e) => {
              e?.stopPropagation();
              setInviteDropdownOpen(!inviteDropdownOpen);
            }}
            onClose={() => setInviteDropdownOpen(false)}
            onSingleInviteClick={() => {
              onSingleInviteClick && onSingleInviteClick(reviewForm);
            }}
            onInviteFromOrdersClick={() => {
              onInviteFromOrdersClick && onInviteFromOrdersClick(reviewForm);
            }}
            onInviteFromMarketingPlatformsClick={
              onInviteFromMarketingPlatformsClick
            }
          /> */}

          <Tooltip text="Embed form">
            <ActionButton
              style={{ paddingLeft: 8, paddingRight: 8 }}
              onClick={(e) => {
                e?.stopPropagation();
                onEmbedFormClick();
              }}
            >
              <FaCode size={20} />
            </ActionButton>
          </Tooltip>

          <div ref={shareDropdownRef} style={{ position: 'relative' }}>
            <Tooltip text="Share form">
              <ActionButton
                onClick={(e) => {
                  e?.stopPropagation();
                  setShareDropdownOpen(!shareDropdownOpen);
                }}
                aria-expanded={shareDropdownOpen}
                aria-haspopup="true"
              >
                <GoShareAndroid size={20} />
              </ActionButton>
            </Tooltip>

            <DropdownMenu $isOpen={shareDropdownOpen}>
              <DropdownItem
                onClick={(e) => {
                  e.stopPropagation();
                  setShareDropdownOpen(false);
                  handleShareClick('whatsapp', formUrl);
                }}
              >
                <FaWhatsapp size={14} color="#25D366" />
                WhatsApp
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  e.stopPropagation();
                  setShareDropdownOpen(false);
                  handleShareClick('facebook', formUrl);
                }}
              >
                <FaFacebook size={14} color="#1877F2" />
                Facebook
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  e.stopPropagation();
                  setShareDropdownOpen(false);
                  handleShareClick('twitter', formUrl);
                }}
              >
                <FaTwitter size={14} color="#1DA1F2" />
                Twitter
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  e.stopPropagation();
                  setShareDropdownOpen(false);
                  handleShareClick('linkedin', formUrl);
                }}
              >
                <FaLinkedin size={14} color="#0A66C2" />
                LinkedIn
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  e.stopPropagation();
                  setShareDropdownOpen(false);
                  handleShareClick('sms', formUrl);
                }}
              >
                <FaSms size={14} color="#6B7280" />
                SMS
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  e.stopPropagation();
                  setShareDropdownOpen(false);
                  handleDownloadQR();
                }}
              >
                <FaQrcode size={14} color="#6B7280" />
                Download QR Code
              </DropdownItem>
            </DropdownMenu>
          </div>

          <Tooltip text="Preview form">
            <ActionButton
              style={{ paddingLeft: 8, paddingRight: 8 }}
              onClick={(e) => {
                e?.stopPropagation();
                window.open(formUrl, '_blank');
              }}
            >
              <FaRegEye size={20} />
            </ActionButton>
          </Tooltip>

          <Tooltip text="Duplicate form">
            <ActionButton
              style={{ paddingLeft: 8, paddingRight: 8 }}
              onClick={(e) => {
                e?.stopPropagation();
                handleShowDuplicateFormModal();
              }}
            >
              <IoDuplicateOutline size={20} />
            </ActionButton>
          </Tooltip>

          <Tooltip text="Edit form">
            <ActionButton
              style={{ paddingLeft: 8, paddingRight: 8 }}
              // @ts-ignore
              onClick={onClick}
            >
              <FaPencilAlt size={18} />
            </ActionButton>
          </Tooltip>

          <div style={{ visibility: isDefault ? 'hidden' : 'visible' }}>
            <Tooltip text="Delete form">
              <DeleteButton
                style={{ paddingLeft: 8, paddingRight: 8 }}
                onClick={(e) => {
                  if (isDefault) {
                    return;
                  }

                  e?.stopPropagation();
                  handleOpenDeleteFormModal();
                }}
              >
                <FaRegTrashAlt size={18} />
              </DeleteButton>
            </Tooltip>
          </div>
        </FormActions>
      </FormItem>

      <div style={{ display: 'none' }}>
        <QRCodeSVG
          value={formUrl}
          ref={qrCodeRef}
          size={qrCodeSize}
          bgColor="#ffffff"
          fgColor="#000000"
        />
      </div>
    </>
  );
};

export default FormListItem;
