import styled from 'styled-components';
import { ReviewRatingSummary } from '../../../../../../review/types';
import { SiteWidgetSettings } from '../../../../../../widgetSettings/redux/widgetSettings.slice';
import ActionsButtons from './ActionsButtons';
import OverrallRating from './OverrallRating';
import RatingSummary from './RatingSummary';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding-bottom: 16px;
  box-shadow: 0 1px 0 rgba(142, 142, 142, 0.1);

  @media (max-width: 1024px) {
    display: block;
  }

  .overall-rating-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    gap: 50px;

    @media (max-width: 1024px) {
      width: 100%;
      flex-direction: column;
      margin-bottom: 20px;
    }
  }
`;

type Props = {
  rating: number;
  totalDocs: number;
  productId: string;
  siteName: string;
  settings: SiteWidgetSettings;
  openQuestionForm: () => void;
  ratingSummary: ReviewRatingSummary | null;
};

export default function Header({
  settings,
  rating,
  totalDocs,
  ratingSummary,
  productId,
  siteName,
  openQuestionForm,
}: Props) {
  return (
    <Wrapper>
      <div className="overall-rating-wrapper">
        <OverrallRating
          settings={settings}
          rating={rating}
          totalDocs={totalDocs}
        />
        <RatingSummary
          settings={settings}
          ratingSummary={ratingSummary}
          totalDocs={totalDocs}
        />
      </div>
      <ActionsButtons
        settings={settings}
        productId={productId}
        siteName={siteName}
        openQuestionForm={openQuestionForm}
      />
    </Wrapper>
  );
}
