import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaRegTrashCan } from 'react-icons/fa6';
import { FiPlusCircle } from 'react-icons/fi';
import { Input, InputGroup } from 'rsuite';
import styled from 'styled-components';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import StrictModeDroppable from '../../../../../shared/components/StrictModeDroppable';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import FormRow from '../../../../widgetSettings/components/form-row';
import {
  siteWidgetSettingsSelector,
  updateSiteWidgetSettings,
} from '../../../../widgetSettings/redux/widgetSettings.slice';

const Label = styled.label`
  display: block;
  color: black;
  margin-bottom: 5px;
`;
const AddQuestion = styled(FlexContainer)`
  cursor: pointer;
  padding-top: 20px;
  margin-left: 20px;
`;
const RemoveQuestionBtn = styled(FlexContainer)`
  cursor: pointer;
`;

const CharactersLength = styled(FlexContainer)`
  padding-right: 5px;
  color: #777777;
`;

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#f9f9f9' : '',

  width: '100%',
});

const reorder = (list: string[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function Questions() {
  const dispatch = useAppDispatch();
  const settings = useAppSelector(siteWidgetSettingsSelector);
  const { reviewQuestions } = settings ?? {};
  const { questions = [] } = reviewQuestions ?? {};

  const handleInputChange = (index: number, value: string) => {
    const newQuestions = [...questions];
    newQuestions[index] = value;
    // setQuestions(newQuestions);
    dispatch(
      updateSiteWidgetSettings({
        ...settings,
        reviewQuestions: {
          ...settings?.reviewQuestions,
          questions: newQuestions,
        },
      })
    );
  };

  const addQuestion = () => {
    if (questions.length < 5) {
      // setQuestions([...questions, '']);
      dispatch(
        updateSiteWidgetSettings({
          ...settings,
          reviewQuestions: {
            ...settings?.reviewQuestions,
            questions: [...questions, ''],
          },
        })
      );
    }
  };

  const removeQuestion = (index: number) => {
    const newQuestions = questions.filter((_, i) => i !== index);
    dispatch(
      updateSiteWidgetSettings({
        ...settings,
        reviewQuestions: {
          ...settings?.reviewQuestions,
          questions: newQuestions,
        },
      })
    );
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const updatedQuestions = reorder(
      questions,
      result.source.index,
      result.destination.index
    );

    dispatch(
      updateSiteWidgetSettings({
        ...settings,
        reviewForm: {
          ...settings?.reviewQuestions,
          questions: updatedQuestions,
        },
      })
    );
  };

  return (
    <FormRow stack alignItems="flex-start">
      <Label>Feedback Builder</Label>
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <FlexContainer
                stack
                gap="10px"
                style={{ width: '100%' }}
                alignItems="normal"
              >
                {questions.map((question, index) => (
                  <Draggable
                    key={index.toString()}
                    draggableId={index.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <FlexContainer
                          key={index}
                          justifyContent="flex-start"
                          gap="5px"
                          style={{ width: '100%' }}
                        >
                          <BsThreeDotsVertical size={20} />
                          <InputGroup inside>
                            <Input
                              maxLength={50}
                              placeholder="Keep it short"
                              value={question}
                              onChange={(value) =>
                                handleInputChange(index, value)
                              }
                            />
                            <CharactersLength>
                              <span>{question?.length}/50</span>
                            </CharactersLength>
                          </InputGroup>
                          <RemoveQuestionBtn
                            role="presentation"
                            onClick={() => removeQuestion(index)}
                          >
                            <FaRegTrashCan size={18} />
                          </RemoveQuestionBtn>
                        </FlexContainer>
                      </div>
                    )}
                  </Draggable>
                ))}
              </FlexContainer>
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>

      {questions.length < 5 && (
        <AddQuestion
          justifyContent="flex-start"
          gap="5px"
          role="presentation"
          onClick={addQuestion}
        >
          <FiPlusCircle size={18} /> Add one (up to 5)
        </AddQuestion>
      )}
    </FormRow>
  );
}
