import { capitalize } from 'lodash';
import { useState } from 'react';
import { FaDotCircle } from 'react-icons/fa';
import { FaRegCircle } from 'react-icons/fa6';
import { InputNumber, InputPicker, Button as RsButton } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../shared/components/Button';
import { Title } from '../../../shared/components/Title';
import FlexContainer from '../../../shared/components/layouts/flex-container';
import FormRow from '../../widgetSettings/components/form-row';

const Wrapper = styled.div``;

const StyledTitle = styled(Title)`
  font-size: 16px;
  font-weight: 600;
`;

const Label = styled.span`
  display: block;
  margin-bottom: 8px;
`;

const StyledButton = styled(Button)`
  background: #000;
  color: #fff;
  border: none;
`;

const Option = styled.div<{ $active: boolean }>`
  width: 50%;
  height: 250px;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
  padding-bottom: 10px;
  position: relative;
  background: ${({ $active }) => ($active ? '#f8f8f8' : 'transparent')};
  &:hover {
    background: #f8f8f8;
  }

  span {
    display: block;
    text-align: center;
    margin-top: 10px;
    font-weight: bold;
  }

  img {
    width: 100%;
    height: 90%;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

  .icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: ${({ $active }) => ($active ? '#000' : '#b6b6b6')};
  }
`;

const CloseButton = styled(RsButton)`
  color: #000;

  &:hover {
    color: #000;
  }
`;

const Options = styled(FlexContainer)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

type Props = {
  onClose: () => void;
  onContinue: ({
    activeOption,
    embedStyle,
    openTrigger,
    delay,
    percentageOfPageScrolled,
  }: {
    activeOption: string;
    embedStyle: string;
    openTrigger: string;
    delay: number;
    percentageOfPageScrolled: number;
  }) => void;
};

export default function EmbedFormSettings(props: Props) {
  const { onClose, onContinue } = props;

  const [activeOption, setActiveOption] = useState<string>('inline');
  const [embedStyle, setEmbedStyle] = useState<string>('unstyled');
  const [openTrigger, setOpenTrigger] = useState<string>('on-button-click');
  const [delay, setDelay] = useState<number>(10);
  const [percentageOfPageScrolled, setPercentageOfPageScrolled] =
    useState<number>(50);
  return (
    <Wrapper>
      <StyledTitle>How would you like to embed your form?</StyledTitle>
      <p>You can embed your form as an inline embed or as a popup widget.</p>

      <Options justifyContent="space-between" gap="20px">
        <Option
          $active={activeOption === 'inline'}
          onClick={() => setActiveOption('inline')}
        >
          {activeOption === 'inline' ? (
            <FaDotCircle className="icon" size={20} />
          ) : (
            <FaRegCircle className="icon" size={20} />
          )}
          <img src="/images/review-form/inline.jpg" alt="Inline embed" />
          <span>Inline</span>
        </Option>
        <Option
          $active={activeOption === 'popup'}
          onClick={() => setActiveOption('popup')}
        >
          {activeOption === 'popup' ? (
            <FaDotCircle className="icon" size={20} />
          ) : (
            <FaRegCircle className="icon" size={20} />
          )}
          <img src="/images/review-form/popup.jpg" alt="Popup widget" />
          <span>Modal</span>
        </Option>
      </Options>

      {activeOption === 'inline' ? (
        <FormRow
          stack
          justifyContent="flex-start"
          alignItems="flex-start"
          marginBottom="30px"
        >
          <Label>Embed style</Label>
          <InputPicker
            data={['unstyled', 'styled'].map((option) => ({
              label: capitalize(option),
              value: option,
            }))}
            cleanable={false}
            value={embedStyle}
            onChange={(value) => setEmbedStyle(value)}
            style={{ width: '100%' }}
          />
        </FormRow>
      ) : (
        <>
          <FormRow
            stack
            justifyContent="flex-start"
            alignItems="flex-start"
            marginBottom="30px"
          >
            <Label>Open trigger</Label>
            <InputPicker
              data={[
                { label: 'On button click', value: 'on-button-click' },
                { label: 'After elapsed time', value: 'after-elapsed-time' },
                { label: 'After scrolling', value: 'after-scrolling' },
              ]}
              cleanable={false}
              value={openTrigger}
              onChange={(value) => setOpenTrigger(value)}
              style={{ width: '100%' }}
            />
          </FormRow>

          {openTrigger === 'after-elapsed-time' && (
            <FormRow
              stack
              justifyContent="flex-start"
              alignItems="flex-start"
              marginBottom="30px"
            >
              <Label>Delay (in seconds)</Label>
              <InputNumber
                value={delay}
                onChange={(value) => setDelay(value as number)}
                style={{ width: '100%' }}
              />
            </FormRow>
          )}

          {openTrigger === 'after-scrolling' && (
            <FormRow
              stack
              justifyContent="flex-start"
              alignItems="flex-start"
              marginBottom="30px"
            >
              <Label>Percentage of page scrolled</Label>
              <InputNumber
                max={99}
                value={percentageOfPageScrolled}
                onChange={(value) =>
                  setPercentageOfPageScrolled(value as number)
                }
                style={{ width: '100%' }}
              />
            </FormRow>
          )}
        </>
      )}

      <FlexContainer justifyContent="flex-end" gap="10px">
        <CloseButton appearance="link" onClick={onClose}>
          Cancel
        </CloseButton>
        <StyledButton
          rounded
          onClick={() =>
            onContinue({
              activeOption,
              embedStyle,
              openTrigger,
              delay,
              percentageOfPageScrolled,
            })
          }
        >
          Installation Instructions
        </StyledButton>
      </FlexContainer>
    </Wrapper>
  );
}
