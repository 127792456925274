import styled from 'styled-components';
import ActiveCampaignPlatform from './platforms/ActiveCampaignPlatform';
import KlaviyoPlatform from './platforms/KlaviyoPlatform';
import MailchimpPlatform from './platforms/MailChimpPlatform';
import RequestIntegration from './platforms/RequestIntegration';
import SendgridPlatform from './platforms/SendgridPlatform';

const PlatformList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PlatformButton = styled.button`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: white;
  color: #475569;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #f8fafc;
    border-color: #cbd5e1;
    color: #1e293b;
  }

  &:last-child {
    color: #3b82f6;
    border-style: dashed;

    &:hover {
      background: #eff6ff;
      border-color: #3b82f6;
    }
  }

  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  font-family: 'Ubuntu', sans-serif;
`;

interface MarketingPlatformsProps {
  onClose: () => void;
}

export default function MarketingPlatforms({
  onClose,
}: MarketingPlatformsProps) {
  return (
    <>
      <Title>Import Contacts</Title>
      <PlatformList>
        <MailchimpPlatform onClose={onClose} />
        <KlaviyoPlatform onClose={onClose} />
        <ActiveCampaignPlatform onClose={onClose} />
        <SendgridPlatform onClose={onClose} />
        <RequestIntegration onClose={onClose} />
      </PlatformList>
    </>
  );
}
