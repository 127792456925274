import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { axiosPrivate } from '../../../services/axiosPrivate';
import Button from '../../../shared/components/Button';
import { Title } from '../../../shared/components/Title';
import { useNotification } from '../../../shared/hooks/useNotification';
import { Loading } from '../../../shared/types/review.types';
import { handleApiError } from '../../../shared/utils/extractErrorMessage';
import removeTrailingSlash from '../../../shared/utils/removeTrailingSlash';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  reviewFormSelector,
  updateReviewForms,
} from '../redux/review-form.slice';

/* eslint no-console: "warn" */

const Description = styled.p`
  font-size: 14px;
  color: #64748b;
  margin-bottom: 20px;
  margin-top: 10px;
`;

const SubmitButton = styled(Button)`
  width: 100%;
  color: #fff;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  padding: 10px;
  margin-top: 20px;
  border: none;
  background-color: #be123c;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: #e53e3e;
  font-size: 14px;
  margin-top: 6px;
  font-weight: 500;
`;

type Props = {
  onClose: () => void;
  formId: string | null;
};

export default function DeleteFormModal({ onClose, formId }: Props) {
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(Loading.INITIAL);
  const { reviewForms } = useAppSelector(reviewFormSelector);
  const { data: reviewFormsData = [] } = reviewForms ?? {};
  const { showErrorToast: showNotificationErrorToast, showSuccessToast } =
    useNotification();

  // Set focus on input when component mounts
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSubmit = async () => {
    setIsLoading(Loading.PENDING);

    try {
      const response = await axiosPrivate.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/review-form/delete`,
        {
          formId,
        }
      );

      showSuccessToast('Form deleted successfully');
      onClose();

      const updatedForms = reviewFormsData.filter(
        (form) => form._id !== formId
      );

      dispatch(
        updateReviewForms({
          data: updatedForms,
        })
      );
    } catch (err) {
      // Use the reusable error handler
      const errorMessage = handleApiError(
        err,
        showNotificationErrorToast,
        'Failed to delete form'
      );
    } finally {
      setIsLoading(Loading.INITIAL);
    }
  };

  return (
    <div>
      <Title>Delete form</Title>

      <Description>
        <p>
          <strong>This form will be permanently deleted. Continue?</strong>
        </p>
      </Description>

      <Description>
        The form will be removed, but all collected testimonials will be
        preserved.
      </Description>

      <SubmitButton
        onClick={handleSubmit}
        type="button"
        disabled={isLoading === Loading.PENDING}
      >
        {isLoading === Loading.PENDING ? 'Deleting...' : 'Delete form'}
      </SubmitButton>
    </div>
  );
}
