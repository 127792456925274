import { Toggle } from 'rsuite';

import FormRow from '../../../widgetSettings/components/form-row';

import { Label } from '../../../../shared/components/Label';
import PremiumBanner from '../../../../shared/components/PremiumBanner';
import { SubscriptionPlan } from '../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { toggleUpgradeModal } from '../../../../store/app.slice';
import { selectedSiteSelector } from '../../../userData/redux/userData.slice';

export default function Branding() {
  const dispatch = useAppDispatch();
  const selectedSite = useAppSelector(selectedSiteSelector);
  const { plan } = selectedSite ?? {};

  if (plan !== SubscriptionPlan.FREE) {
    return null;
  }

  return (
    <>
      {plan === SubscriptionPlan.FREE && (
        <PremiumBanner
          availableOn={SubscriptionPlan.PRO}
          description="Upgrade to remove the ReviewsJet branding."
        />
      )}

      <FormRow
        gap="10px"
        justifyContent="flex-start"
        marginTop={plan === SubscriptionPlan.FREE ? '20px' : '0'}
      >
        <Toggle
          // size="lg"
          checked={false}
          // @ts-ignore
          onChange={() => {
            dispatch(toggleUpgradeModal(SubscriptionPlan.PRO));
          }}
          checkedChildren="Yes"
          unCheckedChildren="No"
        />
        <Label margin="0">Remove Powered by ReviewsJet</Label>
      </FormRow>
    </>
  );
}
