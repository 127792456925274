import { Input } from 'rsuite';
import { Label } from '../../../../../shared/components/Label';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import FormRow from '../../../../widgetSettings/components/form-row';
import {
  reviewFormSelector,
  updateReviewFormSettings,
} from '../../../redux/review-form.slice';

export default function UserInformation() {
  const dispatch = useAppDispatch();
  const { reviewFormSettings } = useAppSelector(reviewFormSelector);
  const { userInfo } = reviewFormSettings ?? {};
  const { title, description } = userInfo ?? {};

  return (
    <>
      <FormRow>
        <Label>Title</Label>
        <Input
          placeholder={title}
          value={title}
          onChange={(value) =>
            dispatch(
              updateReviewFormSettings({
                userInfo: { ...userInfo, title: value },
              })
            )
          }
        />
      </FormRow>

      <FormRow>
        <Label>Description</Label>
        <Input
          placeholder={description}
          value={description}
          onChange={(value) =>
            dispatch(
              updateReviewFormSettings({
                userInfo: { ...userInfo, description: value },
              })
            )
          }
        />
      </FormRow>
    </>
  );
}
