import MarkdownIt from 'markdown-it';
import { Input } from 'rsuite';
import styled from 'styled-components';
import { Label } from '../../../../../shared/components/Label';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import FormRow from '../../../../widgetSettings/components/form-row';
import {
  reviewFormSelector,
  updateReviewFormSettings,
} from '../../../redux/review-form.slice';

const md = new MarkdownIt();

const Preview = styled.div`
  margin-top: 16px;
  padding: 16px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;

  p {
    margin-bottom: 16px;
  }

  ul {
    padding-left: 16px;
    list-style-type: disc;
  }

  ol {
    padding-left: 16px;
    list-style-type: decimal;
  }
`;

const VideoSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #343434;
  line-height: 1.42857143;
  width: 100%;
  background: #fff;
  border: 1px solid #e5e5ea;
  border-radius: 6px;
  padding: 7px 11px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: bold;

  &:hover {
    background: #f8f9fa;
    border-color: #d1d1d6;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  svg {
    font-size: 18px;
    color: #343434;
  }
  p {
    margin: 0;
  }
`;

const PremiumBanner = styled.div`
  margin-top: 24px;
  padding: 12px 16px;
  background: #eef2ff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;

  &:hover {
    background: #e0e7ff;
  }
`;

const PremiumIcon = styled.div`
  color: #6366f1;
  display: flex;
  align-items: center;
  font-size: 18px;
`;

const PremiumContent = styled.div`
  flex: 1;
  font-size: 14px;
  color: #4338ca;
  font-weight: 500;
`;

const PremiumTitle = styled.div`
  font-weight: 500;
  color: #92400e;
  margin-bottom: 4px;
`;

const PremiumDescription = styled.div`
  color: #92400e;
  font-size: 14px;
  line-height: 1.5;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const InfoIcon = styled.div`
  color: #94a3b8;
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: help;
  position: relative;

  &:hover::after {
    content: 'Use this to help your reviewers know what to say';
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
    background: #1e293b;
    color: white;
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 10;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  }
`;

export default function FeedbackForm() {
  const dispatch = useAppDispatch();
  const { reviewFormSettings } = useAppSelector(reviewFormSelector);
  const { reviewScreen } = reviewFormSettings ?? {};
  const { title, prompt, addPhotosLabel, photoUploadPrompt } =
    reviewScreen ?? {};

  return (
    <>
      <FormRow>
        <Label>Title</Label>
        <Input
          placeholder="Enter your title"
          value={title}
          onChange={(value) =>
            dispatch(
              updateReviewFormSettings({
                reviewScreen: {
                  title: value,
                  prompt,
                  addPhotosLabel,
                  photoUploadPrompt,
                },
              })
            )
          }
        />
      </FormRow>
      <FormRow>
        <Label>Ask Customers</Label>
        <Input
          as="textarea"
          rows={5}
          placeholder="Enter your title"
          value={prompt}
          onChange={(value) =>
            dispatch(
              updateReviewFormSettings({
                reviewScreen: {
                  title,
                  prompt: value,
                  addPhotosLabel,
                  photoUploadPrompt,
                },
              })
            )
          }
          style={{ resize: 'none' }}
        />
      </FormRow>

      <FormRow>
        <Label>Add photos label</Label>
        <Input
          placeholder="Add photos (Optional)"
          value={addPhotosLabel}
          onChange={(value) =>
            dispatch(
              updateReviewFormSettings({
                reviewScreen: {
                  title,
                  prompt,
                  addPhotosLabel: value,
                  photoUploadPrompt,
                },
              })
            )
          }
        />
      </FormRow>

      <FormRow>
        <Label>Photo upload prompt</Label>
        <Input
          placeholder="Enter your title"
          value={photoUploadPrompt}
          onChange={(value) =>
            dispatch(
              updateReviewFormSettings({
                reviewScreen: {
                  title,
                  prompt,
                  addPhotosLabel,
                  photoUploadPrompt: value,
                },
              })
            )
          }
        />
      </FormRow>
    </>
  );
}
