import { FaHeart } from 'react-icons/fa6';
import styled from 'styled-components';

const Wrapper = styled.div`
  button.like-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    border-radius: 15px;
    color: #898787;
    &.liked {
      color: #000;
    }
  }
`;

export default function LikeButton({
  count,
  color,
}: {
  count: string;
  color: string;
}) {
  return (
    <Wrapper>
      <button
        className="like-btn"
        style={{ color, border: `1px solid ${color}` }}
      >
        <FaHeart /> {count}
      </button>
    </Wrapper>
  );
}
