import { useCallback, useRef, useState } from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import styled, { css } from 'styled-components';
import useClickOutside from '../../hooks/useClickOutside';
import FlexContainer from '../layouts/flex-container';

type Props = {
  color: string;
  size?: number;
  isSimple?: boolean;
  placement?: 'top' | 'bottom';
  changeColor: (value: string) => void;
  disabled?: boolean;
};

const Picker = styled.div<{ $isSimple?: boolean }>`
  position: relative;
  ${({ $isSimple }) =>
    !$isSimple &&
    css`
      width: 100%;
    `}
`;
const Swatch = styled.div<{ $size?: number; $isSimple?: boolean }>`
  ${({ $size }) => css`
    width: ${$size ?? 28}px;
    height: ${$size ?? 28}px;
  `}

  border-radius: ${({ $isSimple }) => ($isSimple ? '8px' : '50%')};
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const Popover = styled.div<{ placement?: 'top' | 'bottom' }>`
  position: absolute;

  left: 0;
  border-radius: 9px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  z-index: 99999999999;
  ${({ placement }) =>
    placement === 'top'
      ? css`
          bottom: calc(100% + 2px);
        `
      : css`
          top: calc(100% + 2px);
        `};
`;

const ColorInput = styled(HexColorInput)<{ $isSimple?: boolean }>`
  border-width: 0;
  text-transform: uppercase;
  width: 100px;
  color: #858585;
  font-weight: bold;
  padding-left: 5px;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  ${({ $isSimple }) =>
    !$isSimple &&
    css`
      width: calc(100% - 20px);
    `}
`;

const RelativeContainer = styled.div<{ $isSimple?: boolean }>`
  position: relative;
  ${({ $isSimple }) =>
    !$isSimple &&
    css`
      width: calc(100% - 25px);
    `}
  span {
    color: #858585;
    font-weight: bold;
    padding-left: 10px;
  }
`;

const ColorWrapper = styled(FlexContainer)<{ $isSimple?: boolean }>`
  padding: 5px;
  border-radius: 8px;
  ${({ $isSimple }) =>
    !$isSimple &&
    css`
      border: 1px solid #e2e8f0;
    `}
`;

export const ColorPicker = ({
  color,
  changeColor,
  size,
  placement = 'bottom',
  isSimple = true,
  disabled = false,
}: Props) => {
  const popover = useRef() as any;
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  return (
    <Picker>
      <ColorWrapper $isSimple={isSimple} justifyContent="flex-start">
        <Swatch
          $size={size}
          className="swatch"
          style={{ backgroundColor: color }}
          onClick={() => toggle(true)}
          role="presentation"
          $isSimple={isSimple}
        />
        <RelativeContainer $isSimple={isSimple}>
          <span>#</span>
          <ColorInput
            color={color}
            onChange={changeColor}
            readOnly={disabled}
          />
        </RelativeContainer>
      </ColorWrapper>

      {isOpen && !disabled && (
        <Popover ref={popover} placement={placement}>
          <HexColorPicker color={color} onChange={changeColor} />
        </Popover>
      )}
    </Picker>
  );
};

export default ColorPicker;
