import { batch } from 'react-redux';
import { InputPicker, Toggle } from 'rsuite';
import styled from 'styled-components';
import PremiumBanner from '../../../../shared/components/PremiumBanner';
import { SubscriptionPlan } from '../../../../shared/types/review.types';
import DATE_FORMATS from '../../../../shared/utils/dateFormats';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { selectedSiteSelector } from '../../../userData/redux/userData.slice';
import FormRow from '../../../widgetSettings/components/form-row';
import {
  siteWidgetSettingsSelector,
  updateSiteWidgetSettings,
} from '../../../widgetSettings/redux/widgetSettings.slice';
import { setOptionBeingUpdated } from '../redux/product-reviews-settings.slice';
const Wrapper = styled.div`
  .rs-picker {
    border-radius: 6px;
  }
`;

/* eslint no-console: "warn" */

export default function ReviewContent() {
  const dispatch = useAppDispatch();
  const settings = useAppSelector(siteWidgetSettingsSelector);
  const { productWidget, brandingSettings } = settings ?? {};
  const {
    displayLocation = false,
    showFullName,
    dateFormat,
  } = brandingSettings ?? {};
  const {
    collectQuestions,
    collectReviews,
    showAllCustomerPhotosAndVideos,
    showReviewDate,
    fontSize,
  } = productWidget ?? {};

  const selectedSite = useAppSelector(selectedSiteSelector);
  const { plan } = selectedSite ?? {};
  const disabled = plan === SubscriptionPlan.FREE;

  return (
    <>
      {plan === SubscriptionPlan.FREE && (
        <PremiumBanner
          availableOn={SubscriptionPlan.PRO}
          description="Upgrade to update the review content."
        />
      )}
      <Wrapper>
        <FormRow
          justifyContent="space-between"
          gap="10px"
          marginTop={plan === SubscriptionPlan.FREE ? '20px' : '0'}
        >
          <span style={{ flex: 1 }}>Font size</span>
          <InputPicker
            size="md"
            data={[13, 14, 15, 16, 17, 18, 19, 20].map((size) => ({
              label: `${size}px`,
              value: size,
            }))}
            value={fontSize}
            cleanable={false}
            readOnly={disabled}
            onChange={(value) => {
              if (disabled) return;

              batch(() => {
                dispatch(
                  updateSiteWidgetSettings({
                    ...settings,
                    productWidget: {
                      ...productWidget,
                      fontSize: value,
                    },
                  })
                );
                dispatch(setOptionBeingUpdated(null));
              });
            }}
            style={{ width: '100px' }}
          />
        </FormRow>

        <FormRow justifyContent="space-between" gap="10px">
          Show reviewer location
          <Toggle
            size="md"
            checked={displayLocation}
            checkedChildren="Yes"
            unCheckedChildren="No"
            readOnly={disabled}
            onClick={() => {
              if (disabled) return;

              batch(() => {
                dispatch(
                  updateSiteWidgetSettings({
                    ...settings,
                    brandingSettings: {
                      ...brandingSettings,
                      displayLocation: !displayLocation,
                    },
                  })
                );
                dispatch(setOptionBeingUpdated(null));
              });
            }}
          />
        </FormRow>
        <FormRow justifyContent="space-between" gap="10px">
          Show reviewer full name
          <Toggle
            size="md"
            checked={showFullName}
            checkedChildren="Yes"
            unCheckedChildren="No"
            readOnly={disabled}
            onClick={() => {
              if (disabled) return;

              batch(() => {
                dispatch(
                  updateSiteWidgetSettings({
                    ...settings,
                    brandingSettings: {
                      ...brandingSettings,
                      showFullName: !showFullName,
                    },
                  })
                );
                dispatch(setOptionBeingUpdated(null));
              });
            }}
          />
        </FormRow>
        <FormRow justifyContent="space-between" gap="10px">
          Collect reviews
          <Toggle
            size="md"
            checked={collectReviews}
            checkedChildren="Yes"
            unCheckedChildren="No"
            readOnly={disabled}
            onClick={() => {
              if (disabled) return;

              batch(() => {
                dispatch(
                  updateSiteWidgetSettings({
                    ...settings,
                    productWidget: {
                      ...productWidget,
                      collectReviews: !collectReviews,
                    },
                  })
                );
                dispatch(setOptionBeingUpdated(null));
              });
            }}
          />
        </FormRow>
        <FormRow justifyContent="space-between" gap="10px">
          Collect questions
          <Toggle
            size="md"
            checked={collectQuestions}
            checkedChildren="Yes"
            unCheckedChildren="No"
            readOnly={disabled}
            onClick={() => {
              if (disabled) return;

              batch(() => {
                dispatch(
                  updateSiteWidgetSettings({
                    ...settings,
                    productWidget: {
                      ...productWidget,
                      collectQuestions: !collectQuestions,
                    },
                  })
                );
                dispatch(setOptionBeingUpdated(null));
              });
            }}
          />
        </FormRow>
        <FormRow justifyContent="space-between" gap="10px">
          Show all customer photos and videos
          <Toggle
            size="md"
            checked={showAllCustomerPhotosAndVideos}
            checkedChildren="Yes"
            unCheckedChildren="No"
            readOnly={disabled}
            onClick={() => {
              if (disabled) return;

              batch(() => {
                dispatch(
                  updateSiteWidgetSettings({
                    ...settings,
                    productWidget: {
                      ...productWidget,
                      showAllCustomerPhotosAndVideos:
                        !showAllCustomerPhotosAndVideos,
                    },
                  })
                );
                dispatch(setOptionBeingUpdated(null));
              });
            }}
          />
        </FormRow>
        <FormRow justifyContent="space-between" gap="10px">
          Show dates and likes
          <Toggle
            size="md"
            checked={showReviewDate}
            checkedChildren="Yes"
            unCheckedChildren="No"
            readOnly={disabled}
            onClick={() => {
              if (disabled) return;

              batch(() => {
                dispatch(
                  updateSiteWidgetSettings({
                    ...settings,
                    productWidget: {
                      ...productWidget,
                      showReviewDate: !showReviewDate,
                    },
                  })
                );
                dispatch(setOptionBeingUpdated(null));
              });
            }}
          />
        </FormRow>

        {showReviewDate && (
          <FormRow
            stack
            justifyContent="space-between"
            gap="5px"
            width="100%"
            alignItems="flex-start"
          >
            <div>Date format</div>
            <InputPicker
              data={DATE_FORMATS}
              value={dateFormat}
              style={{ width: '100%' }}
              cleanable={false}
              readOnly={disabled}
              onChange={(format: string) => {
                if (disabled) return;

                batch(() => {
                  dispatch(
                    updateSiteWidgetSettings({
                      ...settings,
                      brandingSettings: {
                        ...brandingSettings,
                        dateFormat: format,
                      },
                    })
                  );
                  dispatch(setOptionBeingUpdated(null));
                });
              }}
            />
          </FormRow>
        )}
      </Wrapper>
    </>
  );
}
