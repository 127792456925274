import { Button as RsButton } from 'rsuite';
import styled from 'styled-components';
import { axiosPrivate } from '../../../../services/axiosPrivate';
import Button from '../../../../shared/components/Button';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import { Title } from '../../../../shared/components/Title';
import useChangeLogo from '../../../../shared/hooks/useChangeLogo';
import useConfetti from '../../../../shared/hooks/useConfetti';
import { useNotification } from '../../../../shared/hooks/useNotification';
import { Loading } from '../../../../shared/types/review.types';
import removeTrailingSlash from '../../../../shared/utils/removeTrailingSlash';
import { useAppSelector } from '../../../../store';
import { emailsSelector } from '../../../emails/redux/emails.slice';
import { accountSelector } from '../../../userData/redux/userData.slice';
import { Order } from '../types';
import { formatEmailMessage, formatEmailSubject } from '../utils/common';

const StyledTitle = styled(Title)`
  font-size: 18px;
  margin-bottom: 16px;
  color: #111827;
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: #4b5563;
  margin-bottom: 20px;
`;

const CustomerList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 8px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  background: #000;
  border: none;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px;

  &:disabled {
    background: #ccc;
  }
`;

const CustomerItem = styled.li`
  padding: 10px 14px;
  background: #f9fafb;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease;

  &:hover {
    background: #f3f4f6;
  }

  .name {
    font-weight: 500;
    color: #111827;
  }

  .email {
    color: #6b7280;
    margin-left: 4px;
  }
`;

const MoreCustomers = styled.li`
  color: #6b7280;
  font-size: 13px;
  padding: 10px 14px;
  list-style: none;
  font-weight: 500;
`;

const Footer = styled(FlexContainer)`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 28px;
  padding-top: 24px;
  border-top: 1px solid #e5e7eb;
`;

export default function ConfirmSendEmails({
  onClose,
  recipients,
  onSuccess,
}: {
  recipients: Order[];
  onClose: () => void;
  onSuccess: () => void;
}) {
  const { sendingSingleReviewRquest } = useAppSelector(emailsSelector);
  const { data: reviewRequestData } = useAppSelector(
    (state) => state.reviewRequestData
  );
  const { realisticLook } = useConfetti();
  const { data: businessInfo } = useAppSelector((state) => state.businessInfo);
  const { logo } = businessInfo ?? {};
  const { logoUrl = '' } = useChangeLogo({ logo });

  const { account } = useAppSelector(accountSelector);
  const { ownerEmail = '' } = account ?? {};

  const { showSuccessToast, showErrorToast } = useNotification();
  const {
    emailSubject = '',
    message = '',
    formButtonText = '',
    senderName = '',
    formKey = '',
    rightToLeftContent,
  } = reviewRequestData ?? {};

  const handleSend = async () => {
    const contacts = recipients.map((contact) => ({
      toEmail: contact.email,
      toName: `${contact.firstName} ${contact.lastName}`,
      subject: formatEmailSubject(emailSubject, contact.firstName),
      message: formatEmailMessage(message, contact.firstName),
      testimonialLink: `${process.env.REACT_APP_REVIEWSJET_WEBSITE}/w/${formKey}`,
      replyToEmail: ownerEmail,
      replyFromName: senderName,
      callToAction: formButtonText,
      productId: contact.productId ?? '',
      logo: logoUrl,
      productName: contact.productName,
      productImage: contact.productImage,
      orderNumber: contact.orderNumber,
      rtl: rightToLeftContent,
    }));

    try {
      await axiosPrivate.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/emails/bulk-requests`,
        { contacts }
      );

      onClose();
      onSuccess();
      realisticLook();
      showSuccessToast('Review requests sent successfully');
    } catch (error) {
      // @ts-ignore
      const errorMessage = error.message;
      showErrorToast(errorMessage ?? 'Failed to send review request');
    }
  };

  return (
    <div>
      <StyledTitle>Confirm Send Emails</StyledTitle>
      <Description>
        You are about to send the review request emails to the following
        customers:
      </Description>
      <CustomerList>
        {recipients.slice(0, 2).map((recipient) => (
          <CustomerItem key={recipient.email}>
            <span className="name">{recipient.firstName}</span>
            <span className="email">&lt;{recipient.email}&gt;</span>
          </CustomerItem>
        ))}
        {recipients.length > 2 && (
          <MoreCustomers>
            and {recipients.length - 2} more customer
            {recipients.length - 2 > 1 ? 's' : ''}
          </MoreCustomers>
        )}
      </CustomerList>
      <Footer>
        {sendingSingleReviewRquest !== Loading.PENDING && (
          <RsButton appearance="subtle" onClick={onClose}>
            Cancel
          </RsButton>
        )}
        <StyledButton
          onClick={handleSend}
          disabled={sendingSingleReviewRquest === Loading.PENDING}
        >
          {sendingSingleReviewRquest === Loading.PENDING
            ? 'Please wait...'
            : 'Send Test Email'}
        </StyledButton>
      </Footer>
    </div>
  );
}
