import { createSlice } from '@reduxjs/toolkit';
import { Loading } from '../../../shared/types/review.types';
import { RootState } from '../../../store';
import { IntegrationPlatform } from '../../socialReviews/redux/social-reviews.slice';
import { fetchReviewForm, fetchReviewForms } from './actions';

export type Segment = {
  couponCode: string;
  discountValue: number;
};

export enum WheelTheme {
  BLUE = 'blue',
  ORANGE = 'orange',
  GREEN = 'green',
  VIOLET = 'violet',
}

export type ReviewForm = {
  _id: string;
  name: string;
  siteId: string;
  isDefault: boolean;
  trustKey: string;
  appearance: {
    primaryColor: string;
    backgroundColor: string;
  };
  welcomeScreen: {
    title: string;
    message: string;
    video?: string;
    collectWithExternalSites: boolean;
    externalLinkPrompt: string;
  };
  reviewScreen: {
    title: string;
    prompt: string;
    addPhotosLabel: string;
    photoUploadPrompt: string;
  };
  userInfo: {
    title: string;
    description: string;
  };
  successScreen: {
    useCustomSuccessScreen?: boolean;
    redirectUrl?: string;
    pageTitle: string;
    message: string;
    video?: string;
    callToAction?: {
      label: string;
      url: string;
    };
  };
  shareScreen: {
    allowShare: boolean;
    featureX?: boolean;
    website?: string;
    xHandle?: string;
  };
  reward: {
    enableReward: boolean;
    wheelTheme: WheelTheme;
    wheelColor: string;
    rewardFor: 'ANY_REVIEW' | 'PHOTO_REVIEWS';
    rewardIncentiveText: string;
    wheelTitle: string;
    wheelMessage: string;
    winTitle: string;
    winMessage: string;
    loseTitle: string;
    loseMessage: string;
    segments: Segment[];
  };
  translation: {
    writeReview: string;
    enterReviewTitle: string;
    writeYourReview: string;
    continueLabel: string;
    addPhotos: string;
    photoUploadPrompt: string;
    skipButtonLabel: string;
    yourNameLabel: string;
    yourNamePlaceholder: string;
    yourEmailLabel: string;
    yourEmailPlaceholder: string;
    emailAddressPrivacyNote: string;
    profilePictureLabel: string;
    clickToUploadButtonLabel: string;
    submitButtonLabel: string;
    claimRewardButtonLabel: string;
  };
  totalReviewsCollected: number;
  created_at: string;
  updated_at: string;
};

export interface ThirdPartyAccount {
  id: string;
  name: string;
  platform: IntegrationPlatform;
  pageTitle?: string;
  url?: string;
  collectReviews: boolean;
  pageId: string;
  placeId: string;
}

export enum PreviewScreen {
  WELCOME = 'WELCOME',
  REVIEW = 'REVIEW',
  USER_INFO = 'USER_INFO',
  SUCCESS = 'SUCCESS',
  REWARD = 'REWARD',
}

type ReviewFormState = {
  loading: Loading;
  reviewFormSettings: ReviewForm | null;
  previewScreen: PreviewScreen;
  logoPreview: File | null;
  showWheelWinResult: boolean;
  showWheelLoseResult: boolean;
  reviewForms: {
    data: ReviewForm[];
    loading: Loading;
  } | null;
  localThirdPartyAccounts: ThirdPartyAccount[];
};

const initialState: ReviewFormState = {
  reviewForms: null,
  logoPreview: null,
  reviewFormSettings: null,
  loading: Loading.INITIAL,
  showWheelWinResult: false,
  showWheelLoseResult: false,
  previewScreen: PreviewScreen.WELCOME,
  localThirdPartyAccounts: [],
};

const reviewForm = createSlice({
  name: 'reviewForm',
  initialState,
  reducers: {
    updateReviewFormSettings: (state, { payload }) => {
      state.reviewFormSettings = {
        ...state.reviewFormSettings,
        ...payload,
      };
    },
    updateReviewForms: (state, { payload }) => {
      state.reviewForms = {
        ...state.reviewForms,
        ...payload,
      };
    },
    setPreviewScreen: (state, { payload }) => {
      state.previewScreen = payload;
    },
    setLogoPreview: (state, { payload }) => {
      state.logoPreview = payload;
    },
    setShowWheelWinResult: (state, { payload }) => {
      state.showWheelWinResult = payload;
    },
    setShowWheelLoseResult: (state, { payload }) => {
      state.showWheelLoseResult = payload;
    },
    setLocalThirdPartyAccounts: (state, { payload }) => {
      state.localThirdPartyAccounts = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReviewForm.pending, (state) => {
      state.loading = Loading.PENDING;
    });
    builder.addCase(fetchReviewForm.rejected, (state) => {
      state.loading = Loading.ERROR;
    });
    builder.addCase(fetchReviewForm.fulfilled, (state, { payload }) => {
      state.loading = Loading.SUCCESS;
      state.reviewFormSettings = payload;
    });

    builder.addCase(fetchReviewForms.pending, (state) => {
      state.reviewForms = {
        data: [],
        loading: Loading.PENDING,
      };
    });
    builder.addCase(fetchReviewForms.rejected, (state) => {
      state.reviewForms = {
        data: [],
        loading: Loading.ERROR,
      };
    });
    builder.addCase(fetchReviewForms.fulfilled, (state, { payload }) => {
      state.reviewForms = {
        data: payload,
        loading: Loading.SUCCESS,
      };
    });
  },
});

export const reviewFormSelector = (state: RootState) => state.reviewForm;

export const {
  updateReviewFormSettings,
  setPreviewScreen,
  setLogoPreview,
  setShowWheelWinResult,
  setShowWheelLoseResult,
  updateReviewForms,
  setLocalThirdPartyAccounts,
} = reviewForm.actions;
export default reviewForm.reducer;
