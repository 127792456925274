import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import setSession from '../../../../shared/utils/setSession';
import { useAppSelector } from '../../../../store';

const WebflowAuth = () => {
  const navigate = useNavigate();

  const {
    query: { a, r },
  } = useAppSelector((state) => state.router.location);

  const authWebflowAccount = useCallback(() => {
    setSession({ jwtAccessToken: a, jwtRefreshToken: r });

    setTimeout(() => {
      navigate('/manage-reviews');
    }, 100);
  }, [a, r, navigate]);

  useEffect(() => {
    authWebflowAccount();
  }, []);

  return null;
};

export default WebflowAuth;
