import { useMemo } from 'react';
import { FaRegEnvelope } from 'react-icons/fa';
import { HiUserAdd } from 'react-icons/hi';
import { HiShoppingBag, HiUsers } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';
import { Button } from 'rsuite';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import AppModal from '../../../../../shared/components/modal';
import useModal from '../../../../../shared/components/modal/useModal';
import { getLimit } from '../../../../../shared/utils/usage-limits';
import { useAppSelector } from '../../../../../store';
import { emailsSelector } from '../../../../emails/redux/emails.slice';
import {
  accountSelector,
  isEcommerceSelector,
} from '../../../../userData/redux/userData.slice';
import ConnectEcommerceModal from '../../ConnectEcommerceModal';
import CreditLimitModal from '../../CreditLimitModal';
import MarketingPlatforms from '../../MarketingPlatforms';
import Dropdown from './Dropdown';

/* eslint no-console: "warn" */

type Props = {
  formUrl: string;
};

export default function Invite({ formUrl }: Props) {
  const navigate = useNavigate();
  const isCommerce = useAppSelector(isEcommerceSelector);
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { plan } = selectedSite ?? {};
  const {
    stats: { totalSentThisMonth },
  } = useAppSelector(emailsSelector);
  const {
    handleCloseModal: handleCloseCreditLimitModal,
    handleOpenModal: handleOpenCreditLimitModal,
    showModal: showCreditLmitModal,
  } = useModal();
  const { handleCloseModal, handleOpenModal, showModal } = useModal();
  const {
    handleOpenModal: handleOpenMarketingPlatformsModal,
    handleCloseModal: handleCloseMarketingPlatformsModal,
    showModal: showMarketingPlatformsModal,
  } = useModal();

  const emailsCredit = getLimit(plan)?.reviewsRequests;

  const canRequest = useMemo(() => {
    return emailsCredit > totalSentThisMonth;
  }, [emailsCredit, totalSentThisMonth]);

  return (
    <>
      <Dropdown
        trigger={
          <>
            <FaRegEnvelope size={18} />
            Invite
          </>
        }
        items={[
          {
            icon: <HiUserAdd />,
            label: 'Single Customer',
            onClick: () => {
              if (canRequest) {
                navigate('/share-form');
              } else {
                handleOpenCreditLimitModal();
              }
            },
          },
          {
            icon: <HiShoppingBag />,
            label: 'From Orders',
            onClick: () => {
              if (isCommerce) {
                if (canRequest) {
                  navigate('/share-form?source=orders');
                } else {
                  handleOpenCreditLimitModal();
                }
              } else {
                handleOpenModal();
              }
            },
          },
          {
            icon: <HiUsers />,
            label: 'From Marketing Lists',
            onClick: () => {
              handleOpenMarketingPlatformsModal();
            },
          },
        ]}
      />

      <AppModal
        size="sm"
        shouldOpenModal={showModal}
        onModalClose={handleCloseModal}
        body={<ConnectEcommerceModal onClose={handleCloseModal} />}
      />
      <AppModal
        size="sm"
        shouldOpenModal={showCreditLmitModal}
        onModalClose={handleCloseCreditLimitModal}
        body={<CreditLimitModal onClose={handleCloseCreditLimitModal} />}
      />
      <AppModal
        size="xs"
        shouldOpenModal={showMarketingPlatformsModal}
        onModalClose={handleCloseMarketingPlatformsModal}
        body={
          <MarketingPlatforms onClose={handleCloseMarketingPlatformsModal} />
        }
        footer={
          <FlexContainer justifyContent="flex-end">
            <Button onClick={handleCloseMarketingPlatformsModal}>Close</Button>
          </FlexContainer>
        }
      />
    </>
  );
}
