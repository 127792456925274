import { useAppSelector } from '../../../../store';
import { userAccountConfigSelector } from '../../../userData/redux/userData.slice';
import UpdatePassword from './UpdatePassword';
import UserInfo from './UserInfo';

/* eslint no-console: "warn" */

export default function Profile() {
  const siteConfig = useAppSelector(userAccountConfigSelector);

  return (
    <>
      <UserInfo />
      {siteConfig?.canLogout && <UpdatePassword />}
    </>
  );
}
