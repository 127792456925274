import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNotification } from '../../../../shared/hooks/useNotification';
import { Loading } from '../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { ReviewsJetPlatform } from '../../../product/types';
import {
  IntegrationPlatform,
  socialReviewsSelector,
} from '../../../socialReviews/redux/social-reviews.slice';
import { accountSelector } from '../../../userData/redux/userData.slice';
import { setAutoPublish } from '../../../widgetSettings/redux/action';
import { siteWidgetSettingsSelector } from '../../../widgetSettings/redux/widgetSettings.slice';
import AutoPublish from './AutoPublish';
import FeedbackBuilder from './FeedbackBuilder';
import ReviewsCollection from './ReviewsCollection';
import ThankYou from './ThankYou';

export type FormType = {
  submissionType: 'reviewsjet' | 'thirdparty';
  submitReviewsTo: IntegrationPlatform[];
};

/* eslint no-console: "warn" */

export default function ReviewSettings() {
  const dispatch = useAppDispatch();

  const [submitting, setSubmitting] = useState<Loading>(Loading.INITIAL);
  const siteWidgetSettings = useAppSelector(siteWidgetSettingsSelector);
  const { brandingSettings } = siteWidgetSettings ?? {};
  const { showErrorToast, showSuccessToast } = useNotification();
  const { submissionType = 'reviewsjet', submitReviewsTo = [] } =
    brandingSettings || {};
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { platform } = selectedSite ?? {};

  const { control, handleSubmit, watch, setValue } = useForm<FormType>({
    defaultValues: {
      submissionType: 'reviewsjet',
      submitReviewsTo: [],
    },
  });

  const {
    get3rdPartyAccount: { data: integrations },
  } = useAppSelector(socialReviewsSelector);

  const { submissionType: _submissionType, submitReviewsTo: _submitReviewsTo } =
    watch();

  const onSubmit = handleSubmit(async (data) => {
    setSubmitting(Loading.PENDING);

    dispatch(
      setAutoPublish({
        submissionType: data.submissionType,
        submitReviewsTo:
          data.submissionType === 'reviewsjet' ? [] : data.submitReviewsTo,
      })
    )
      .then(unwrapResult)
      .then(() => {
        showSuccessToast('Settings saved successfully.');
        setSubmitting(Loading.SUCCESS);
      })
      .catch(() => {
        showErrorToast('Something went wrong. Please try it again.');
        setSubmitting(Loading.ERROR);
      })
      .finally(() => {
        setSubmitting(Loading.INITIAL);
      });
  });

  useEffect(() => {
    setValue(
      'submitReviewsTo',
      submitReviewsTo as never as IntegrationPlatform[]
    );
  }, [submitReviewsTo]);

  useEffect(() => {
    setValue('submissionType', submissionType);
  }, [submissionType]);

  return (
    <>
      <AutoPublish />
      {platform !== ReviewsJetPlatform.SHOPIFY && (
        <ReviewsCollection
          onSubmit={onSubmit}
          _submissionType={_submissionType}
          _submitReviewsTo={_submitReviewsTo}
          control={control}
          integrations={integrations}
          submitReviewsTo={submitReviewsTo}
          submitting={submitting}
        />
      )}

      {_submissionType === 'reviewsjet' && (
        <>
          <FeedbackBuilder />
          <ThankYou />
        </>
      )}
    </>
  );
}
