import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider } from 'rsuite';
import styled from 'styled-components';
import StyledText from '../../../../shared/components/styled-text';
import { Title } from '../../../../shared/components/Title';
import useInstallation, {
  Widget,
} from '../../../../shared/hooks/useInstallation';
import { useAppDispatch } from '../../../../store';
import { toggleFeedbackDrawer } from '../../../userData/redux/userData.slice';

const Message = styled.div`
  border-left: 4px solid #5bc0de;
  background-color: #e2f2fb;
  padding: 20px;
  margin-bottom: 50px;
  font-size: 14px;
  border-radius: 5px;

  .message-title {
    color: #4a90e2;
    margin-bottom: 0px;
    font-size: 18px;
    line-height: 30px;
    font-family: 'Ubuntu', sans-serif;
  }

  p {
    margin: 0 0 20px;
  }

  .contact-us {
    background-color: #fff;
    border: 1px solid #4a90e2;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
  }

  .contact-us:hover {
    background-color: #4a90e2;
    color: #fff;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 900px;
  margin: auto;

  img.screenshot {
    margin-bottom: 20px;
    margin-top: 10px;
    border: 1px solid #dedede;
    padding: 2px;
    border-radius: 10px;
    width: 100%;
  }
`;

const PageTitle = styled(StyledText)`
  font-size: 25px;
  margin-bottom: 40px;
  margin-top: 50px;
  font-weight: bold;
  text-align: center;
`;

const Step = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background-color: #f3f3f3;
  justify-content: flex-start;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 20px;
`;
const StepNumber = styled.div`
  height: 100%;
  width: 80px;
  background-color: #cfe4ff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-weight: bold;
`;

const StepDesc = styled.div`
  margin-bottom: 10px;
`;
const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
  margin: auto;
  gap: 5px;
  margin-top: 10px;
`;
const StepWrapper = styled.div`
  margin-bottom: 30px;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 20px;
  font-size: 20px;
`;

const WixProductWidget = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('common');
  const [copied, setCopied] = useState(false);
  const { installationCode } = useInstallation(Widget.WIX_PRODUCT_WIDGET);
  return (
    <Wrapper>
      <StyledTitle>
        How to install the Product Reviews Widget on Wix
      </StyledTitle>

      <Message>
        <p style={{ margin: 0 }}>
          The Product Reviews Widget is auto-installed on your Wix website.
          Please visit your product page to see the widget.
        </p>
      </Message>

      <StepWrapper>
        <img
          src="/images/product-widget-screenshot.png"
          alt=""
          className="screenshot"
        />
      </StepWrapper>

      <Divider />

      <Message>
        <div className="message-title">
          Having trouble installing the Product Widget?
        </div>
        <p>Click the button below to contact us now.</p>
        <button
          className="contact-us"
          onClick={() => dispatch(toggleFeedbackDrawer())}
        >
          Contact Us
        </button>
      </Message>
    </Wrapper>
  );
};

export default WixProductWidget;
