export enum PreviewType {
  DESKTOP,
  MOBILE,
}

export enum WidgetPageTypes {
  FORM = 'form',
  THANK_YOU = 'thank-you',
  REVIEW = 'review',
  EMPTY = 'empty',
}

export enum SettingsTabs {
  VISUAL_SETTING = 'visual setting',
  REVIEW_CONTENT = 'review content',
  TRANSLATION = 'translation',
  INSTALLATION_INSTRUCTIONS = 'installation_instructions',
}

export enum ReviewTabsEnum {
  BUSINESS = 'business',
  PRODUCT = 'product',
}

export const MEDIUM_SCREEN = 728;
export const SMALL_SCREEN = 500;
