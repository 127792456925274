'use client';
import styled from 'styled-components';

const Wrapper = styled.div<{ $imageBorderRadius: number }>`
  flex: 1;
  padding: 35px 25px;

  .rj-form-title {
    font-weight: 700;
    font-size: 25px;
    line-height: 40px;
    color: #000;
    text-align: center;
    width: 100%;
    display: block;
    margin: 0 0 5px;
  }

  .rj-thank-you-icon-wrapper {
    margin: 0 auto 30px;
    text-align: center;
    height: 180px;
  }
  .rj-thank-you-icon-wrapper img {
    height: 100%;
    max-height: 180px;
    border-radius: 100%;
    border: 1px solid rgb(221, 221, 221);
    padding: 1px;
    min-width: 180px;
    margin: auto;
    border-radius: ${({ $imageBorderRadius }) => `${$imageBorderRadius}%`};
  }
  .rj-thank-you-description {
    font-size: 16px;
    text-align: center;
    max-width: 380px;
    margin: auto;
    display: block;
  }
  .rj-share-on-social-medial-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 30px;
  }
  .rj-add-review-button {
    background-color: #000;
    color: #f7f7f7;

    /* background-color: {primaryColor};
            color: {buttonTextColor}; */
  }
  .rj-button {
    border: none;
    padding: 10px 20px;
    text-align: center;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    text-decoration: none;
    font-size: 15px;
    cursor: pointer;
    border-radius: 10px;
  }
  .button-wrapper {
    width: 200px;
    margin: 0px auto;
    text-align: center;

    button {
      font-size: 14px;
      padding: 10px 20px;
      background-color: #000;
      color: #fff;
      border-radius: 20px;
      outline: none;
      border: none;
      cursor: pointer;
    }
  }
`;

const ThankYou = ({
  close,
  onClose,
  thankYouMessage,
  thankYouTitle,
  thankYouSettings,
}: {
  close: string;
  thankYouTitle: string;
  thankYouMessage: string;
  onClose: (e: unknown) => void;
  thankYouSettings: any;
}) => {
  const { imageBorderRadius } = thankYouSettings;
  return (
    <>
      <Wrapper $imageBorderRadius={imageBorderRadius}>
        <span className="rj-form-title assistant-500">{thankYouTitle}</span>
        <span className="rj-thank-you-description assistant-400">
          {thankYouMessage}
        </span>
        <div className="rj-share-on-social-medial-wrapper">
          <span className="rj-facebook-icon" />
          <span className="rj-twitter-icon" />
        </div>

        <div className="button-wrapper ">
          <button type="button" onClick={onClose} className="assistant-500">
            {close}
          </button>
        </div>
      </Wrapper>
    </>
  );
};

export default ThankYou;
