import { useState } from 'react';
import styled from 'styled-components';
import { Segment } from '../../../../redux/review-form.slice';

const ResultTitle = styled.h3`
  margin: 0 0 8px;
  font-size: 18px;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 0;
  font-family: 'Ubuntu', sans-serif;
`;

const ResultCode = styled.div`
  background: #f8f9fa;
  border: 1px dashed #dee2e6;
  border-radius: 4px;
  padding: 6px 12px;
  margin-top: 8px;
  font-family: monospace;
  font-size: 15px;
  color: #495057;
  display: flex;
  font-weight: 900;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  user-select: none;

  &:hover {
    background: #f1f3f5;
  }

  &:active {
    background: #e9ecef;
  }
`;

const CopyButton = styled.button`
  background: transparent;
  border: none;
  padding: 4px;
  color: #6c757d;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color 0.2s ease;

  &:hover {
    color: #495057;
  }
`;

const CloseButton = styled.button`
  margin-top: 16px;
  padding: 8px 20px;
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  color: #495057;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;

  &:hover {
    background: #e9ecef;
    border-color: #ced4da;
  }

  &:active {
    background: #dee2e6;
  }
`;

interface WinResultProps {
  winTitle?: string;
  winMessage?: string;
  couponCode?: string;
  onClose: () => void;
  segments: Segment[];
}

export default function WinResult({
  winTitle,
  winMessage,
  couponCode,
  onClose,
  segments,
}: WinResultProps) {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(couponCode || '');
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <>
      <ResultTitle>{winTitle}</ResultTitle>
      <div>{winMessage}</div>
      <ResultCode onClick={handleCopy}>
        {couponCode ?? segments[0].couponCode}
        <CopyButton>
          {copied ? (
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#4CAF50"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M20 6L9 17l-5-5" />
            </svg>
          ) : (
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
              <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
            </svg>
          )}
        </CopyButton>
      </ResultCode>
      <CloseButton onClick={onClose}>Close</CloseButton>
    </>
  );
}
