import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
} from 'react-hook-form';
import { Input } from 'rsuite';
import styled, { css } from 'styled-components';
import { BusinessProfile } from '.';
import Button from '../../../../shared/components/Button';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import { Loading } from '../../../../shared/types/review.types';
import Logo from './Logo';

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const Content = styled.div`
  padding: 20px;

  .title {
    font-size: 15px;
    margin-bottom: 20px;
  }
`;

const FormGroup = styled.div`
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  background-color: #fff;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid #ebebeb;
`;

const Header = styled(FlexContainer)`
  padding: 15px 20px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid rgb(235, 235, 235);
`;

const Footer = styled(FlexContainer)`
  padding: 15px 20px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  border-top: 1px solid rgb(235, 235, 235);
`;

const CustomInput = styled(Input)<{ error?: boolean }>`
  font-style: normal;
  background: #fff;
  border-radius: 5px;
  max-width: 100%;
  outline: none !important;
  margin-bottom: 5px;
  border: 1px solid #cecece;
  &:active {
    border: 1px solid #cecece;
  }

  ${({ error }) =>
    error &&
    css`
      border-color: red;
    `}
`;

const ErroMessage = styled.span`
  display: block;
  color: red;
  margin-bottom: 15px;
`;

const TextArea = styled(CustomInput)`
  width: 100%;
  height: 165px;
  padding: 11px;
  resize: none;
  margin-bottom: 0;
`;

type Props = {
  logo?: string;
  about: string;
  saving: Loading;
  companyName: string;
  onLogoChanged: () => void;
  errors: FieldErrors<BusinessProfile>;
  control: Control<BusinessProfile, any>;
  setValue: UseFormSetValue<BusinessProfile>;
};

export default function Profile({
  logo,
  about,
  saving,
  errors,
  control,
  setValue,
  companyName,
  onLogoChanged,
}: Props) {
  return (
    <Wrapper>
      <Header justifyContent="space-between">
        <span></span>

        <Button
          rounded
          type="submit"
          disabled={saving === Loading.PENDING}
          variant={saving === Loading.PENDING ? 'tertiary' : 'primary'}
        >
          {saving === Loading.PENDING ? 'Please wait...' : 'Save info'}
        </Button>
      </Header>
      <Content>
        <h4 className="title">Business profile</h4>
        <FlexContainer
          justifyContent="space-between"
          alignItems="flex-start"
          gap="20px"
        >
          <div style={{ width: '50%' }}>
            <FlexContainer
              stack
              justifyContent="flex-start"
              alignItems="flex-start"
              gap="15px"
            >
              <FormGroup>
                <Label>Name</Label>

                <Controller
                  name="businessName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomInput
                      placeholder="Apple, Inc."
                      error={Boolean(errors.businessName)}
                      {...field}
                    />
                  )}
                />
                {errors.businessName && (
                  <ErroMessage>{errors.businessName.message}</ErroMessage>
                )}
              </FormGroup>

              <FormGroup>
                <Label>Logo</Label>
                <Logo
                  logo={logo}
                  name={companyName}
                  onLogoUploaded={(value: string) => {
                    setValue('logo', value);
                    onLogoChanged();
                  }}
                  onLogoDeleted={() => {
                    setValue('logo', '');
                    onLogoChanged();
                  }}
                />
                <Controller
                  name="logo"
                  control={control}
                  render={({ field }) => <input {...field} type="hidden" />}
                />
              </FormGroup>
            </FlexContainer>
          </div>
          <div style={{ width: '50%' }}>
            <FlexContainer
              stack
              justifyContent="flex-start"
              alignItems="flex-start"
              gap="15px"
            >
              <FormGroup>
                <Label>Describe your business (150 characters max)</Label>

                <Controller
                  name="about"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <div style={{ position: 'relative' }}>
                      <TextArea
                        as="textarea"
                        error={Boolean(errors.about)}
                        placeholder="About Apple, Inc."
                        {...field}
                      />
                      <span
                        style={{ position: 'absolute', bottom: 10, right: 10 }}
                      >
                        <span
                          style={{
                            color: about.length > 150 ? 'crimson' : 'inherit',
                          }}
                        >
                          {about.length}
                        </span>{' '}
                        / 150
                      </span>
                    </div>
                  )}
                />
                {errors.about && (
                  <ErroMessage>{errors.about.message}</ErroMessage>
                )}
              </FormGroup>
            </FlexContainer>
          </div>
        </FlexContainer>
      </Content>

      {/* <Footer justifyContent="flex-end">
        <Button
          rounded
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={saving === Loading.PENDING}
          variant={saving === Loading.PENDING ? 'tertiary' : 'secondary'}
        >
          {saving === Loading.PENDING ? 'Please wait...' : 'Save info'}
        </Button>
      </Footer> */}
    </Wrapper>
  );
}
