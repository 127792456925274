import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CreateWebflowAccountPage from './create-account';
import Login from './login';

export default function Webflow() {
  const [doNext, setDoNext] = useState<
    | 'update-site-url'
    | 'set-jwt-token-in-local-storage'
    | 'create-new-account'
    | null
  >(null);

  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode<{
        doNext: string;
      }>(token);

      setDoNext(decoded.doNext as any);
    }
  }, [token]);

  if (!doNext) {
    return null;
  }

  if (doNext === 'create-new-account') {
    return <CreateWebflowAccountPage />;
  }

  if (doNext === 'set-jwt-token-in-local-storage') {
    return <Login />;
  }

  return null;
}
