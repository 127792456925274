import { useEffect, useRef, useState } from 'react';
import {
  IoBrushOutline,
  IoChatbubblesOutline,
  IoChevronBack,
  IoChevronDown,
  IoChevronUp,
  IoGiftOutline,
  IoHandRightOutline,
  IoHeartOutline,
  IoLanguageOutline,
  IoPersonOutline,
  IoShieldOutline,
} from 'react-icons/io5';
import { MdLink } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'rsuite';
import styled from 'styled-components';
import BackButton from '../../../../shared/components/BackButton';
import { SubscriptionPlan } from '../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { selectedSiteSelector } from '../../../userData/redux/userData.slice';
import useSettingsScreen from '../../hooks/useSettingsScreen';
import { reviewFormSelector } from '../../redux/review-form.slice';
import SaveButton from './SaveButton';
import Appearance from './sections/Appearance';
import BrandCustomization from './sections/BrandCustomization';
import FeedbackForm from './sections/FeedbackForm';
import FormLink from './sections/FormLink';
import GreetingScreen from './sections/GreetingScreen';
import LanguageSettings from './sections/LanguageSettings';
import ReviewRewards from './sections/ReviewRewards';
import SuccessScreen from './sections/SuccessScreen';
import UserInformation from './sections/UserInformation';

const SettingsSidebar = styled.div`
  width: 580px;
  flex-shrink: 0;
  background: white;
  border-right: 1px solid #e2e8f0;
  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
`;

const TopSection = styled.div`
  padding: 24px 24px 0;
  background: white;
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0));
    pointer-events: none;
  }
`;

const ScrollableContent = styled.div`
  padding: 24px;
  padding-bottom: 80px;
  overflow-y: auto;
  flex: 1;
  margin-top: -20px; // Overlap with the gradient for smooth transition
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
  padding-left: 0px;
  color: #1e293b;
  font-size: 16px;
  font-weight: 500;

  svg {
    font-size: 18px;
  }
`;

const Subtitle = styled.p`
  color: #64748b;
  font-size: 14px;
  margin: 0 0 24px 0;
  line-height: 1.5;
`;

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .react-colorful {
    height: 150px !important;
    width: 150px !important;
  }
`;

const AccordionItem = styled.div`
  border-bottom: 1px solid #e2e8f0;
  border-radius: 0;
  overflow: hidden;
`;

const AccordionHeader = styled.button`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 16px;
  background: white;
  border: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  color: #1e293b;
  transition: all 0.2s ease;

  &:hover {
    background: #f8fafc;
  }
`;

const AccordionContent = styled.div<{ $isOpen: boolean }>`
  padding: ${(props) => (props.$isOpen ? '16px' : '0')};
  height: ${(props) => (props.$isOpen ? 'auto' : '0')};
  opacity: ${(props) => (props.$isOpen ? '1' : '0')};
  overflow: hidden;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border-top: ${(props) => (props.$isOpen ? '1px solid #e2e8f0' : 'none')};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  svg:first-child {
    font-size: 18px;
  }
`;

const SectionIcon = styled.div<{ $color: string }>`
  display: flex;
  align-items: center;
  color: ${(props) => props.$color};
  font-size: 16px;
`;

export default function Sidebar() {
  const navigate = useNavigate();
  const [openSection, setOpenSection] = useState<string>('form-link');
  const selectedSite = useAppSelector(selectedSiteSelector);
  const { plan } = selectedSite ?? {};
  const switchPreviewScreen = useSettingsScreen();
  const { reviewFormSettings, localThirdPartyAccounts, logoPreview } =
    useAppSelector(reviewFormSelector);
  const { welcomeScreen } = reviewFormSettings ?? {};
  const { collectWithExternalSites } = welcomeScreen ?? {};
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [intendedPath, setIntendedPath] = useState('');
  const dispatch = useAppDispatch();

  // Store initial values for comparison
  const initialValuesRef = useRef({
    reviewFormSettings: JSON.stringify(reviewFormSettings),
    localThirdPartyAccounts: JSON.stringify(localThirdPartyAccounts),
    logoPreview: logoPreview,
  });

  // Function to update the initial values reference
  const updateInitialValues = () => {
    initialValuesRef.current = {
      reviewFormSettings: JSON.stringify(reviewFormSettings),
      localThirdPartyAccounts: JSON.stringify(localThirdPartyAccounts),
      logoPreview: logoPreview,
    };
  };

  // Create a custom event to listen for successful saves
  useEffect(() => {
    // Create a function to handle the save event
    const handleSaveSuccess = () => {
      updateInitialValues();
    };

    // Add event listener
    window.addEventListener('reviewFormSaved', handleSaveSuccess);

    // Clean up
    return () => {
      window.removeEventListener('reviewFormSaved', handleSaveSuccess);
    };
  }, [reviewFormSettings, localThirdPartyAccounts, logoPreview]);

  // Also update initial values on component mount
  useEffect(() => {
    updateInitialValues();
  }, []);

  // Check for unsaved changes
  const hasUnsavedChanges = () => {
    return (
      initialValuesRef.current.reviewFormSettings !==
        JSON.stringify(reviewFormSettings) ||
      initialValuesRef.current.localThirdPartyAccounts !==
        JSON.stringify(localThirdPartyAccounts) ||
      initialValuesRef.current.logoPreview !== logoPreview
    );
  };

  // Handle navigation with confirmation if needed
  const handleNavigate = (path: string) => {
    navigate(path);

    // if (hasUnsavedChanges()) {
    //   setIntendedPath(path);
    //   setShowUnsavedChangesModal(true);
    // } else {
    //   navigate(path);
    // }
  };

  // Confirm navigation and discard changes
  const confirmNavigation = () => {
    setShowUnsavedChangesModal(false);
    navigate(intendedPath);
  };

  // Cancel navigation and stay on page
  const cancelNavigation = () => {
    setShowUnsavedChangesModal(false);
  };

  let sections = [
    {
      id: 'form-link',
      title: 'Form Link',
      icon: <MdLink />,
      color: '#34d399', // emerald color
      component: <FormLink />,
    },
    {
      id: 'design',
      title: 'Appearance',
      icon: <IoBrushOutline />,
      color: '#a78bfa',
      component: <Appearance />,
    },
    {
      id: 'welcome',
      title: 'Welcome Screen',
      icon: <IoHandRightOutline />,
      color: '#60a5fa',
      component: <GreetingScreen />,
    },

    // {
    //   id: 'advanced',
    //   title: 'Custom Settings',
    //   icon: <IoSettingsOutline />,
    //   color: '#6b7280',
    //   component: <CustomSettings />,
    // },
  ];

  if (plan !== SubscriptionPlan.ADVANCED || !collectWithExternalSites) {
    sections.push(
      {
        id: 'review',
        title: 'Review',
        icon: <IoChatbubblesOutline />,
        color: '#34d399',
        component: <FeedbackForm />,
      },
      {
        id: 'user-information',
        title: 'User Information',
        icon: <IoPersonOutline />,
        color: '#9634d3',
        component: <UserInformation />,
      },
      {
        id: 'thankyou',
        title: 'Success Screen',
        icon: <IoHeartOutline />,
        color: '#f87171',
        component: <SuccessScreen />,
      },
      // {
      //   id: 'share',
      //   title: 'Share Reviews',
      //   icon: <IoShareSocialOutline />,
      //   color: '#a78bfa',
      //   component: <ShareReviews />,
      // },
      {
        id: 'rewards',
        title: 'Review Rewards',
        icon: <IoGiftOutline />,
        color: '#38bdf8',
        component: <ReviewRewards />,
      },
      {
        id: 'translation',
        title: 'Language Settings',
        icon: <IoLanguageOutline />,
        color: '#818cf8',
        component: <LanguageSettings />,
      }
    );
  }

  if (plan === SubscriptionPlan.FREE) {
    sections.push({
      id: 'branding',
      title: 'Remove ReviewsJet branding',
      icon: <IoShieldOutline />,
      color: '#94a3b8',
      component: <BrandCustomization />,
    });
  }

  return (
    <>
      <SettingsSidebar>
        <TopSection>
          <Header>
            <BackButton onClick={() => handleNavigate('/forms')}>
              <IoChevronBack />
              Your forms
            </BackButton>
          </Header>
          <Subtitle>
            Customize how your review form looks and behaves. Click the Save
            changes button when you're done.
          </Subtitle>
        </TopSection>
        <ScrollableContent>
          <AccordionContainer>
            {sections.map((section) => (
              <AccordionItem key={section.id}>
                <AccordionHeader
                  onClick={() => {
                    setOpenSection(
                      openSection === section.id ? '' : section.id
                    );
                    switchPreviewScreen(section.id);
                  }}
                >
                  <IconWrapper>
                    {openSection === section.id ? (
                      <IoChevronUp />
                    ) : (
                      <IoChevronDown />
                    )}
                    <SectionIcon $color={section.color}>
                      {section.icon}
                    </SectionIcon>
                  </IconWrapper>
                  {section.title}
                </AccordionHeader>
                <AccordionContent $isOpen={openSection === section.id}>
                  {section.component}
                </AccordionContent>
              </AccordionItem>
            ))}
          </AccordionContainer>
        </ScrollableContent>
        <SaveButton />
      </SettingsSidebar>

      {/* Unsaved Changes Modal */}
      <Modal
        open={showUnsavedChangesModal}
        onClose={cancelNavigation}
        size="xs"
      >
        <Modal.Header>
          <Modal.Title>Unsaved Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You have unsaved changes that will be lost if you navigate away from
            this page.
          </p>
          <p>Do you want to continue without saving?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={cancelNavigation} appearance="subtle">
            Stay on this page
          </Button>
          <Button onClick={confirmNavigation} appearance="primary">
            Discard changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
