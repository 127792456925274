/**
 * Returns a darker version of the input color
 * @param color - The color to darken (hex, rgb, rgba)
 * @param amount - Amount to darken by (0 to 1, default: 0.15)
 * @returns Darkened color in the same format as input
 */
export function getSegmentDarkerColor(
  color: string,
  amount: number = 0.15
): string {
  // Handle empty or invalid input
  if (!color) return color;

  // Convert hex to RGB if needed
  let r: number, g: number, b: number, a: number | undefined;

  if (color.startsWith('#')) {
    const hex = color.replace('#', '');
    const bigint = parseInt(hex, 16);
    r = (bigint >> 16) & 255;
    g = (bigint >> 8) & 255;
    b = bigint & 255;
  } else if (color.startsWith('rgb')) {
    const matches = color.match(/(\d+)/g);
    if (!matches) return color;
    [r, g, b] = matches.map(Number);
    if (matches.length === 4) {
      a = Number(matches[3]);
    }
  } else {
    return color; // Return original color if format not supported
  }

  // Darken the RGB values
  r = Math.max(0, Math.floor(r * (1 - amount)));
  g = Math.max(0, Math.floor(g * (1 - amount)));
  b = Math.max(0, Math.floor(b * (1 - amount)));

  // Convert back to original format
  if (color.startsWith('#')) {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
  } else if (typeof a !== 'undefined') {
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
}
