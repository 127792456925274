import { unwrapResult } from '@reduxjs/toolkit';
import { Divider, Input, Toggle } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../../../shared/components/Button';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import { Loading } from '../../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import FormRow from '../../../../widgetSettings/components/form-row';
import { updateWidgetsSettings } from '../../../../widgetSettings/redux/action';
import {
  settingSelector,
  siteWidgetSettingsSelector,
  updateSiteWidgetSettings,
} from '../../../../widgetSettings/redux/widgetSettings.slice';
import ReviewImage from './ReviewImage';
import ThankYouPreview from './ThankYouPreview';

/* eslint no-console: "warn" */

const Wrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const Header = styled.div`
  padding: 15px 20px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid rgb(235, 235, 235);
`;

const Content = styled.div`
  padding: 20px;
  margin-bottom: 20px;
`;

const Footer = styled(FlexContainer)`
  padding: 15px 20px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  border-top: 1px solid rgb(235, 235, 235);
`;

export type AccountInfo = {
  fullName: string;
  email: string;
};

const page = 'thankYou';

export default function ThankYou() {
  const dispatch = useAppDispatch();
  const settings = useAppSelector(siteWidgetSettingsSelector);
  const { thankYouPage } = settings ?? {};
  const { message: thankYouMessage, showImage, title } = thankYouPage ?? {};
  const { showSuccessToast } = useNotification();

  const {
    updateWidgetSettings: { saving },
  } = useAppSelector(settingSelector);

  const updateSettings = () => {
    dispatch(updateWidgetsSettings())
      .then(unwrapResult)
      .then(() => {
        showSuccessToast('Settings successfully saved');
      })
      .catch(() => {});
  };

  return (
    <Wrapper>
      <Header>
        <span>Thank you page</span>
      </Header>
      <Content>
        <FlexContainer justifyContent="space-between">
          <div style={{ width: '55%' }}>
            <FormRow alignItems="center" justifyContent="flex-start" gap="15px">
              <span>Display "Thank You" image</span>
              <Toggle
                checked={showImage}
                onChange={(checked) => {
                  dispatch(
                    updateSiteWidgetSettings({
                      ...settings,
                      thankYouPage: {
                        ...settings?.thankYouPage,
                        showImage: checked,
                      },
                    })
                  );
                }}
                checkedChildren="Yes"
                unCheckedChildren="No"
              />

              {showImage && <ReviewImage />}
            </FormRow>

            <Divider />

            <FormRow stack alignItems="flex-start">
              <span>
                Thank you title <sup>*</sup>
              </span>
              <Input
                placeholder="Thank you!"
                value={title}
                onChange={(value) => {
                  dispatch(
                    updateSiteWidgetSettings({
                      ...settings,
                      thankYouPage: {
                        ...settings?.thankYouPage,
                        title: value,
                      },
                    })
                  );
                }}
              />
            </FormRow>
            <FormRow stack alignItems="flex-start">
              <span>
                Thank you message <sup>*</sup>
              </span>
              <Input
                as="textarea"
                rows={3}
                placeholder="Thank you for sharing your rating with us. We appreciate it! 🙏"
                value={thankYouMessage}
                onChange={(value) => {
                  dispatch(
                    updateSiteWidgetSettings({
                      ...settings,
                      thankYouPage: {
                        ...settings?.thankYouPage,
                        message: value,
                      },
                    })
                  );
                }}
              />
            </FormRow>
          </div>
          <div>
            <ThankYouPreview />
          </div>
        </FlexContainer>
      </Content>
      <Footer justifyContent="flex-end">
        <Button
          rounded
          disabled={saving === Loading.PENDING}
          variant={saving === Loading.PENDING ? 'tertiary' : 'primary'}
          onClick={updateSettings}
        >
          {saving === Loading.PENDING ? 'Please wait...' : 'Save settings'}
        </Button>
      </Footer>
    </Wrapper>
  );
}
