import deepEqual from 'deep-equal';
import React, { useState } from 'react';
import { batch } from 'react-redux';
import styled from 'styled-components';
import { axiosPrivate } from '../../../../services/axiosPrivate';
import useModal from '../../../../shared/components/modal/useModal';
import { PlanRestrictionModal } from '../../../../shared/components/PlanRestrictionMessage';
import { useNotification } from '../../../../shared/hooks/useNotification';
import useUpdateLogo from '../../../../shared/hooks/useUpdateLogo';
import {
  Loading,
  SubscriptionPlan,
} from '../../../../shared/types/review.types';
import removeTrailingSlash from '../../../../shared/utils/removeTrailingSlash';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { setGet3rdPartyAccount } from '../../../socialReviews/redux/social-reviews.slice';
import { selectedSiteSelector } from '../../../userData/redux/userData.slice';
import {
  reviewFormSelector,
  updateReviewFormSettings,
} from '../../redux/review-form.slice';

/* eslint no-console: "warn" */

const StyledButton = styled.button<{ $visible?: boolean }>`
  position: fixed;
  bottom: 24px;
  left: 24px;
  width: 532px;
  padding: 12px 24px;
  background: #000;
  color: white;
  border: none;
  font-weight: 600;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 8px 16px -4px rgb(0 0 0 / 0.1), 0 4px 6px -2px rgb(0 0 0 / 0.1);
  z-index: 10;
  opacity: 1; /* Always visible */
  transform: translateY(0); /* Always positioned correctly */
  pointer-events: auto; /* Always clickable */
  border: 2px solid transparent;

  &:hover {
    background: #ffffff;
    border-color: #000000;
    color: #050505;
    transform: translateY(-1px);
    box-shadow: 0 12px 20px -6px rgb(0 0 0 / 0.1),
      0 4px 8px -4px rgb(0 0 0 / 0.1);
  }

  &:active {
    transform: translateY(0);
  }

  &:disabled {
    background: #94a3b8;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

// Function to find differences between two objects
function findDifferences(current: any, saved: any, path = ''): string[] {
  // Parse saved settings if it's a string
  const parsedSaved = typeof saved === 'string' ? JSON.parse(saved) : saved;

  if (!current || !parsedSaved) {
    return current !== parsedSaved ? [path] : [];
  }

  if (typeof current !== 'object' || typeof parsedSaved !== 'object') {
    return current !== parsedSaved ? [path] : [];
  }

  const allKeys = new Set([
    ...Object.keys(current),
    ...Object.keys(parsedSaved),
  ]) as Set<string>;
  const differences: string[] = [];

  for (const key of Array.from(allKeys)) {
    const currentPath = path ? `${path}.${key}` : key;

    if (!(key in current)) {
      differences.push(`${currentPath} (removed)`);
    } else if (!(key in parsedSaved)) {
      differences.push(`${currentPath} (added)`);
    } else if (
      typeof current[key] === 'object' &&
      current[key] !== null &&
      typeof parsedSaved[key] === 'object' &&
      parsedSaved[key] !== null
    ) {
      differences.push(
        ...findDifferences(current[key], parsedSaved[key], currentPath)
      );
    } else if (!deepEqual(current[key], parsedSaved[key])) {
      differences.push(
        `${currentPath} (changed: ${JSON.stringify(
          parsedSaved[key]
        )} → ${JSON.stringify(current[key])})`
      );
    }
  }

  return differences;
}

// Create a context to share the markThirdPartyAccountsChanged function
export const ReviewFormContext = React.createContext({
  markThirdPartyAccountsChanged: () => {},
});

export default function SaveButton() {
  const dispatch = useAppDispatch();
  const [isSaving, setIsSaving] = useState(Loading.INITIAL);
  const { reviewFormSettings, logoPreview, localThirdPartyAccounts } =
    useAppSelector(reviewFormSelector);
  const selectedSite = useAppSelector(selectedSiteSelector);
  const { plan } = selectedSite ?? {};
  const {
    showModal: showPlanRestrictionModal,
    handleCloseModal: handleClosePlanRestrictionModal,
    handleOpenModal: handleOpenPlanRestrictionModal,
  } = useModal();

  const { welcomeScreen } = reviewFormSettings ?? {};

  const { showSuccessToast, showErrorToast } = useNotification();
  const { updateLogo } = useUpdateLogo();

  const handleSave = async () => {
    if (
      !!welcomeScreen?.collectWithExternalSites &&
      plan !== SubscriptionPlan.ADVANCED
    ) {
      handleOpenPlanRestrictionModal();
      return;
    }

    // Check if using external sites but none are selected
    if (welcomeScreen?.collectWithExternalSites) {
      const hasActiveExternalSite = localThirdPartyAccounts.some(
        (account) => account.collectReviews
      );

      if (!hasActiveExternalSite) {
        showErrorToast(
          'Please select at least one external review site when using external sites to collect reviews.'
        );
        return;
      }
    }

    try {
      setIsSaving(Loading.PENDING);

      let updatedSettings = { ...reviewFormSettings };

      const {
        created_at,
        isDefault,
        siteId,
        updated_at,
        _id,
        ...cleanedSettings
      } = updatedSettings;
      updatedSettings = cleanedSettings;

      if (logoPreview && logoPreview instanceof File) {
        await updateLogo();
      }

      const response = await axiosPrivate.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/review-form/update`,
        {
          formId: _id,
          data: updatedSettings,
          localThirdPartyAccounts: !!welcomeScreen?.collectWithExternalSites
            ? localThirdPartyAccounts
            : [],
        }
      );

      const {
        data: { reviewForm, connected },
      } = response;

      batch(() => {
        dispatch(updateReviewFormSettings(reviewForm));
        dispatch(setGet3rdPartyAccount(connected));
      });

      setIsSaving(Loading.INITIAL);
      showSuccessToast('Changes saved successfully');

      // Dispatch custom event to notify that save was successful
      window.dispatchEvent(new Event('reviewFormSaved'));
    } catch (error) {
      showErrorToast('Failed to save changes');
    } finally {
      setIsSaving(Loading.INITIAL);
    }
  };

  return (
    <>
      <StyledButton
        onClick={handleSave}
        disabled={isSaving === Loading.PENDING}
      >
        {isSaving === Loading.PENDING ? 'Saving...' : 'Save Changes'}
      </StyledButton>

      <PlanRestrictionModal
        featureName="Direct External Review Collection"
        featureDescription="Allow customers to leave reviews directly on platforms like Google and Yelp through your review form, increasing your online presence and ratings across multiple sites."
        isOpen={showPlanRestrictionModal}
        closeModal={handleClosePlanRestrictionModal}
        availableOn={[SubscriptionPlan.ADVANCED]}
      />
    </>
  );
}
