import React from 'react';
import styled, { css } from 'styled-components';

export type Color =
  | 'red'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'cyan'
  | 'blue'
  | 'violet'
  | 'gray';

type Props = {
  color: Color;
  children: React.ReactNode;
};

const Wrapper = styled.div<{ $color: Color }>`
  font-size: 12px;
  border-radius: 8px;
  padding: 3px 8px;
  width: fit-content;
  ${({ $color }) => {
    if ($color === 'blue') {
      return css`
        color: #008aff;
        background-color: #e4f3ff;
      `;
    }

    if ($color === 'red') {
      return css`
        color: #ff0000;
        background-color: #ffe4e2;
      `;
    }

    if ($color === 'orange') {
      return css`
        color: #ffab44;
        background-color: #fff4e8;
      `;
    }

    if ($color === 'green') {
      return css`
        color: #10b981;
        background-color: #ecfdf5;
      `;
    }

    if ($color === 'yellow') {
      return css`
        color: #cbb800;
        background-color: #feffd9;
      `;
    }

    if ($color === 'cyan') {
      return css`
        color: #00bcd4;
        background-color: #effdff;
      `;
    }

    if ($color === 'violet') {
      return css`
        color: #8c4bff;
        background-color: #f1e9ff;
      `;
    }

    return css`
      color: #000;
      background-color: #f1f1f1;
    `;
  }}
`;

export default function Tag(props: Props) {
  const { color, children } = props;
  return <Wrapper $color={color}>{children}</Wrapper>;
}
