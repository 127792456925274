import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { MdClose } from 'react-icons/md';
import './Modal.css';

interface ModalProps {
  isOpen: boolean;
  size?: string;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, size }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isOpen]);
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={onClose} role="presentation">
      <div
        className="modal-content"
        style={{ maxWidth: size }}
        onClick={(e) => e.stopPropagation()}
        role="presentation"
      >
        <button className="modal-close" onClick={onClose}>
          <MdClose size={30} />
        </button>
        {children}
      </div>
    </div>,
    document.body
  );
};

export default Modal;
