import { IoSparklesOutline } from 'react-icons/io5';
import styled from 'styled-components';
import { useAppDispatch } from '../../store';
import { toggleUpgradeModal } from '../../store/app.slice';
import { SubscriptionPlan } from '../types/review.types';
import capitalize from '../utils/capitalize';

const Wrapper = styled.div`
  padding: 10px 16px;
  background: #f9faff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
  border: 1px solid #e0e7ff;

  &:hover {
    background: #e0e7ff;
  }
`;

const PremiumIcon = styled.div`
  color: #6366f1;
  display: flex;
  align-items: center;
  font-size: 18px;
`;

const PremiumContent = styled.div`
  flex: 1;
  color: #4338ca;
`;

interface PremiumBannerProps {
  description: string;
  availableOn: SubscriptionPlan;
}

export default function PremiumBanner({
  availableOn,
  description,
}: PremiumBannerProps) {
  const dispatch = useAppDispatch();

  return (
    <Wrapper
      onClick={() => {
        dispatch(toggleUpgradeModal(availableOn));
      }}
    >
      <PremiumIcon>
        <IoSparklesOutline />
      </PremiumIcon>
      <PremiumContent>
        {description} (available on <b>{capitalize(availableOn)}</b>)
      </PremiumContent>
    </Wrapper>
  );
}
