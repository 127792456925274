import dayjs from 'dayjs';
import millify from 'millify';
import { useCallback } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import styled from 'styled-components';
import abbreviateLastName from '../../../../../../../shared/utils/abbreviateLastName';
import getInitialAvatar from '../../../../../../../shared/utils/getInitialAvatar';
import getReviewerFullName from '../../../../../../../shared/utils/getReviewerFullName';
import { MediaWithReview } from '../../../../../../review/types';
import { SiteWidgetSettings } from '../../../../../../widgetSettings/redux/widgetSettings.slice';
import ImageElement from '../PhotosVideos/ImageElement';
import FlexContainer from './flex-container';
import LikeButton from './LikeButton';
import ReviewRating from './ReviewRating';

const Thumbnail = styled.div<{ $active: boolean }>`
  width: 60px;
  height: 60px;
  border: ${({ $active }) =>
    $active ? '1px solid #fff' : '1px solid transparent'};
  border-radius: 10px;
  cursor: pointer;
  padding: 2px;
`;

const Wrapper = styled.div<{ $fontSize: number }>`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  .left {
    position: relative;
    width: 100%;
    max-width: 600px;
    background-color: #2e2e2e;
    height: 690px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;

    .arrows-wrapper {
      position: absolute;
      width: calc(100% - 40px);
      height: 100%;
      z-index: 99;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;

      button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border: none;
        outline: none;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        border-radius: 50%;
        &:hover {
          background-color: rgba(0, 0, 0, 0.8);
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .image-container {
      flex: 1;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .thumbnails-container {
      display: flex;
      justify-content: center;
      flex-direction: row;
      width: 100%;
      height: 90px;
      align-items: center;
      padding: 0px 20px;
      gap: 5px;
      overflow: hidden;
      position: absolute;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(2px);
      z-index: 100;

      img {
        display: block;
        object-fit: cover;
        border-radius: 8px;
        cursor: pointer;
        max-width: 60px !important;
        max-height: 60px !important;
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;

        &:hover {
          opacity: 0.8;
        }
      }

      @media (max-width: 644px) {
        display: none;
      }
    }
  }

  .right {
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    font-size: ${({ $fontSize }) => $fontSize - 1}px;
    padding-top: 70px;
    padding-left: 30px;
    padding-right: 30px;

    .name {
      font-size: ${({ $fontSize }) => $fontSize}px;
      display: block;
      font-weight: bold;
    }
  }

  .rating-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;

    .date {
      color: #9e9e9e;
    }
  }

  .title {
    font-size: ${({ $fontSize }) => $fontSize + 1}px;
    margin: 10px 0;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .reply-wrapper {
    border-left: 4px solid #d0d0d0;
    padding: 10px 20px;
    margin: 20px 0;
    line-height: 22px;
    .title {
      font-size: ${({ $fontSize }) => $fontSize - 1}px;
    }
    p {
      margin-top: 5px;
      font-size: ${({ $fontSize }) => $fontSize - 1}px;
      line-height: 22px;
      color: #5e5e5e;
    }
  }

  .footer {
    /* display: flex; */
    display: none;
    justify-content: flex-end;
    align-items: center;
  }

  div.verfied-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    color: green;
    font-size: ${({ $fontSize }) => $fontSize - 3}px;
  }
`;

const Reviewer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  img {
    width: 100%;
  }
`;

const ReviewMessage = styled.div<{ $fontSize: number }>`
  font-size: ${({ $fontSize }) => $fontSize - 1}px;
  margin: 0;
  line-height: 22px;
  color: #5e5e5e;
`;

const ReviewerAvatar = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  position: relative;
`;

const ReviewerInitial = styled.div<{
  $backgroundColor: string;
  $fontSize: number;
}>`
  color: #fff;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial;
  font-size: ${({ $fontSize }) => $fontSize + 1}px;
  border-radius: 50%;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;
const ReviewerName = styled.span<{ $fontSize: number }>`
  font-size: ${({ $fontSize }) => $fontSize}px;
  font-weight: bold;
  color: #000;
`;

const ReviewContent = styled.div<{ $fontSize: number }>`
  max-height: 495px;
  overflow-y: auto;
  font-size: ${({ $fontSize }) => $fontSize}px;

  p {
    font-size: ${({ $fontSize }) => $fontSize}px;
  }

  p.title {
    font-size: ${({ $fontSize }) => $fontSize + 1}px;
  }
`;

const ReviewerPicWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
  }
`;
type Props = {
  currentIndex: number | null;
  settings: SiteWidgetSettings;
  onSelect: (selectedMediaReview: MediaWithReview, index: number) => void;
  selectedMediaReview: MediaWithReview | null;
  mediaWithReviews: MediaWithReview[];
};

export default function PhotoReviewModal({
  onSelect,
  currentIndex,
  selectedMediaReview,
  mediaWithReviews,
  settings,
}: Props) {
  const selectPreviousReview = useCallback(() => {
    if (currentIndex === null) {
      return;
    }

    if (currentIndex > 0 && currentIndex < mediaWithReviews.length) {
      const previousIndex = currentIndex - 1;
      const previous = mediaWithReviews[previousIndex];

      if (!previous) {
        return null;
      }

      onSelect(previous, previousIndex);
    }
  }, [currentIndex, mediaWithReviews, onSelect]);

  const selectNextReview = useCallback(() => {
    if (currentIndex === null) {
      return;
    }

    if (currentIndex >= 0 && currentIndex < mediaWithReviews.length - 1) {
      const nextIndex = currentIndex + 1;
      const next = mediaWithReviews[nextIndex];

      if (!next) {
        return null;
      }

      onSelect(next, nextIndex);
    }
  }, [currentIndex, mediaWithReviews, onSelect]);

  if (!selectedMediaReview) {
    return null;
  }

  const { media, review } = selectedMediaReview;
  const { title, rating, reviewer, content, reviewDate, reply, helpfulCount } =
    review;
  const { fullName } = reviewer;
  const {
    brandingSettings: { showFullName, dateFormat },
    productWidget: { starColor, showReviewDate, fontSize, textColor },
    translation: { responseFromTheOwner, verifiedReviewer },
  } = settings;

  let _reviewDate = reviewDate;

  try {
    _reviewDate = dayjs(reviewDate).format(dateFormat);

    if (_reviewDate === 'Invalid Date') {
      _reviewDate = reviewDate;
    }
  } catch {
    _reviewDate = reviewDate;
  }

  let reviewerName = '';
  if (showFullName) {
    reviewerName = abbreviateLastName(fullName);
  } else {
    const [firstName] = fullName?.split(' ') ?? [];
    reviewerName = firstName;
  }

  const reviewerFullname = getReviewerFullName(
    showFullName,
    review.reviewer,
    verifiedReviewer
  );

  const initial = getInitialAvatar(reviewerFullname);

  return (
    <>
      <Wrapper $fontSize={fontSize}>
        <div className="left">
          {mediaWithReviews.length > 1 && (
            <div className="arrows-wrapper">
              {currentIndex !== null &&
              currentIndex > 0 &&
              currentIndex < mediaWithReviews.length ? (
                <button onClick={selectPreviousReview}>
                  <FaAngleLeft size={26} />
                </button>
              ) : (
                <span />
              )}

              {currentIndex !== null &&
              currentIndex < mediaWithReviews.length - 1 ? (
                <button onClick={selectNextReview}>
                  <FaAngleRight size={26} />
                </button>
              ) : (
                <span />
              )}
            </div>
          )}
          <div className="image-container">
            <ImageElement media={media} />
          </div>
          <div className="thumbnails-container">
            {mediaWithReviews.length > 1 && (
              <div className="thumbnails-container">
                {mediaWithReviews.slice(0, 5).map((mediaWithReview, index) => (
                  <Thumbnail
                    key={mediaWithReview.media}
                    $active={currentIndex === index}
                    onClick={() => {
                      onSelect(mediaWithReview, index);
                    }}
                  >
                    <ImageElement
                      media={mediaWithReview.media}
                      showPreloader={false}
                    />
                  </Thumbnail>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="right">
          <Reviewer>
            <ReviewerAvatar>
              {review.reviewer.avatar ? (
                <ReviewerPicWrapper>
                  <ImageElement
                    media={review.reviewer.avatar}
                    showPreloader={false}
                  />
                </ReviewerPicWrapper>
              ) : (
                <ReviewerInitial
                  $backgroundColor={
                    getInitialAvatar(reviewerFullname).backgroundColor
                  }
                  $fontSize={fontSize}
                >
                  {initial.avatar}
                </ReviewerInitial>
              )}
            </ReviewerAvatar>
            <FlexContainer
              justifyContent="flex-start"
              alignItems="flex-start"
              gap="5px"
              style={{ lineHeight: '15px' }}
            >
              <ReviewerName $fontSize={fontSize}>{reviewerName}</ReviewerName>
            </FlexContainer>
          </Reviewer>

          <div className="rating-wrapper">
            <ReviewRating color={starColor} rating={rating} center={false} />
            {showReviewDate && (
              <span className="date assistant-400">{_reviewDate}</span>
            )}
          </div>
          <ReviewContent $fontSize={fontSize}>
            {title && <p className="title assistant-500">{title}</p>}
            <ReviewMessage
              className="assistant-400"
              dangerouslySetInnerHTML={{ __html: content }}
              $fontSize={fontSize}
            />
            {!!reply?.content && (
              <div className="reply-wrapper">
                <span className="title assistant-500">
                  {responseFromTheOwner}
                </span>
                <p
                  className="assistant-400"
                  dangerouslySetInnerHTML={{ __html: reply.content }}
                />
              </div>
            )}
          </ReviewContent>
          <div className="footer">
            <LikeButton count={millify(helpfulCount)} color={textColor} />
          </div>
        </div>
      </Wrapper>
    </>
  );
}
