/**
 * Extracts a human-readable error message from various error types
 * @param err - The error object from a try/catch block or API response
 * @param fallbackMessage - Optional fallback message if no specific error can be extracted
 * @returns A string containing the most specific error message available
 */
export function extractErrorMessage(
  err: unknown,
  fallbackMessage = 'An error occurred'
): string {
  // Default error message
  let errorMessage = fallbackMessage;

  // Handle standard Error objects
  if (err instanceof Error) {
    errorMessage = err.message;
  }
  // Handle object-type errors
  else if (typeof err === 'object' && err !== null) {
    // Direct message or error property
    if ('message' in err && typeof (err as any).message === 'string') {
      errorMessage = (err as any).message;
    } else if ('error' in err && typeof (err as any).error === 'string') {
      errorMessage = (err as any).error;
    }
    // Handle Axios-style error responses
    else if (
      'response' in err &&
      typeof (err as any).response === 'object' &&
      (err as any).response !== null
    ) {
      const response = (err as any).response;

      // Check for 4xx status codes - prioritize returning these errors
      const status = response.status;
      if (status && status >= 400 && status < 500) {
        // For 4xx errors, prioritize returning the error value
        if (
          'data' in response &&
          typeof response.data === 'object' &&
          response.data !== null
        ) {
          // First check for error field
          if (
            'error' in response.data &&
            typeof response.data.error === 'string'
          ) {
            return response.data.error;
          }
          // Then check for message field
          else if (
            'message' in response.data &&
            typeof response.data.message === 'string'
          ) {
            return response.data.message;
          }
          // Check for errors object with first error
          else if (
            'errors' in response.data &&
            Array.isArray(response.data.errors) &&
            response.data.errors.length > 0
          ) {
            return response.data.errors[0];
          }
          // If data itself is a string, use that
          else if (typeof response.data === 'string') {
            return response.data;
          }
        }

        // If we couldn't extract a specific error message, use a status-based message
        return `Request failed with status ${status}`;
      }

      // Extract from response.data (for non-4xx errors or as fallback)
      if (
        'data' in response &&
        typeof response.data === 'object' &&
        response.data !== null
      ) {
        if (
          'message' in response.data &&
          typeof response.data.message === 'string'
        ) {
          errorMessage = response.data.message;
        } else if (
          'error' in response.data &&
          typeof response.data.error === 'string'
        ) {
          errorMessage = response.data.error;
        }
      }
    }
  }
  // Handle string errors
  else if (typeof err === 'string') {
    errorMessage = err;
  }

  return errorMessage;
}

/**
 * Handles API errors by extracting the message and optionally displaying a toast
 * @param err - The error object from a try/catch block or API response
 * @param showToast - Function to display a toast notification (optional)
 * @param fallbackMessage - Optional fallback message if no specific error can be extracted
 * @returns The extracted error message
 */
export function handleApiError(
  err: unknown,
  showToast?: (message: string) => void,
  fallbackMessage = 'Operation failed'
): string {
  const errorMessage = extractErrorMessage(err, fallbackMessage);

  // Show toast notification if a toast function was provided
  if (showToast) {
    showToast(errorMessage);
  }

  return errorMessage;
}
