import styled from 'styled-components';
import { Order } from '../types';
import Form from './Form';
import Preview from './Preview';

const Container = styled.div`
  display: grid;
  width: 100%;
  max-width: 36rem;
  margin-left: auto;
  margin-right: auto;
  align-items: start;
  gap: 3rem;
  padding-top: 1.75rem;
  padding-bottom: 3rem;

  @media (min-width: 1024px) {
    max-width: 88rem;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const FormSection = styled.div`
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const PreviewSection = styled.div`
  position: sticky;
  top: 80px;
  padding-right: 1.5rem;
  background: white;
  border-radius: 12px;

  @media (max-width: 1024px) {
    position: static;
    width: 100%;
    height: auto;
  }
`;

interface ReviewRequestProps {
  onClose: () => void;
  orders: Order[];
  onSuccess: () => void;
}

export default function ReviewRequest({
  onClose,
  orders,
  onSuccess,
}: ReviewRequestProps) {
  return (
    <>
      <Container>
        <FormSection>
          <Form onClose={onClose} orders={orders} onSuccess={onSuccess} />
        </FormSection>
        <PreviewSection>
          <div className="email-preview-content">
            <Preview orders={orders} />
          </div>
        </PreviewSection>
      </Container>
    </>
  );
}
