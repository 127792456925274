import ExclamationCircleIcon from '@rsuite/icons/legacy/ExclamationCircle';
import PlusCircleIcon from '@rsuite/icons/legacy/PlusCircle';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Toggle } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../../../shared/components/Button';
import { Label } from '../../../../../shared/components/Label';
import PremiumBanner from '../../../../../shared/components/PremiumBanner';
import { SubscriptionPlan } from '../../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { get3rdPartyAccount } from '../../../../socialReviews/redux/actions';
import {
  IntegrationPlatform,
  socialReviewsSelector,
} from '../../../../socialReviews/redux/social-reviews.slice';
import { selectedSiteSelector } from '../../../../userData/redux/userData.slice';
import FormRow from '../../../../widgetSettings/components/form-row';
import {
  reviewFormSelector,
  setLocalThirdPartyAccounts,
  ThirdPartyAccount,
  updateReviewFormSettings,
} from '../../../redux/review-form.slice';

/* eslint no-console: "warn" */

const VideoSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #343434;
  line-height: 1.42857143;
  width: 100%;
  background: #fff;
  border: 1px solid #e5e5ea;
  border-radius: 6px;
  padding: 7px 11px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: bold;

  &:hover {
    background: #f8f9fa;
    border-color: #d1d1d6;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  svg {
    font-size: 18px;
    color: #343434;
  }
  p {
    margin: 0;
  }
`;

// Styled components
const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  margin: 16px 0;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 1px dashed #dee2e6;
`;

const EmptyStateTitle = styled.div`
  color: #6c757d;
  margin-bottom: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
`;

const EmptyStateDescription = styled.p`
  font-size: 14px;
  color: #6c757d;
  margin-bottom: 20px;
  text-align: center;
  max-width: 400px;
`;

const ConnectButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

// Define the ThirdPartyAccount type

export default function GreetingScreen() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selectedSite = useAppSelector(selectedSiteSelector);
  const { plan } = selectedSite ?? {};
  const { reviewFormSettings, localThirdPartyAccounts } =
    useAppSelector(reviewFormSelector);
  const { welcomeScreen } = reviewFormSettings ?? {};
  const { title, message, collectWithExternalSites, externalLinkPrompt } =
    welcomeScreen ?? {};

  const { get3rdPartyAccount: data } = useAppSelector(socialReviewsSelector);

  const platformDisplayNames = {
    [IntegrationPlatform.GOOGLE_MAPS_REVIEWS]: 'Google',
    [IntegrationPlatform.YELP_REVIEWS]: 'Yelp',
    [IntegrationPlatform.APPLE_REVIEWS]: 'Apple',
    [IntegrationPlatform.PRODUCTHUNT_REVIEWS]: 'ProductHunt',
    [IntegrationPlatform.TRUSTPILOT_REVIEWS]: 'Trustpilot',
    [IntegrationPlatform.CAPTERRA_REVIEWS]: 'Capterra',
    [IntegrationPlatform.TRIPADVISOR_REVIEWS]: 'Tripadvisor',
    [IntegrationPlatform.AIRBNB_REVIEWS]: 'Airbnb',
    [IntegrationPlatform.AMAZON_REVIEWS]: 'Amazon',
  };

  // Initialize local state from the Redux data
  useEffect(() => {
    if (data.data) {
      const accounts: ThirdPartyAccount[] = [];

      Object.entries(data.data).forEach(([platform, integrations]) => {
        integrations.forEach((integration) => {
          accounts.push({
            id: integration._id,
            name:
              integration.pageTitle ||
              platformDisplayNames[platform as IntegrationPlatform],
            platform: platform as IntegrationPlatform,
            pageTitle: integration.pageTitle,
            url: integration.domain || '',
            collectReviews: integration.collectReviews || false,
            pageId: integration.pageId,
            placeId: integration.placeId,
          });
        });
      });

      dispatch(setLocalThirdPartyAccounts(accounts));
    }
  }, [data.data]);

  useEffect(() => {
    dispatch(get3rdPartyAccount());
  }, []);

  return (
    <>
      <FormRow>
        <Label>Greating Page Title</Label>
        <Input
          placeholder="Enter your title"
          value={title}
          onChange={(value) =>
            dispatch(
              updateReviewFormSettings({
                welcomeScreen: {
                  ...welcomeScreen,
                  title: value,
                },
              })
            )
          }
        />
      </FormRow>

      <FormRow gap="10px" justifyContent="flex-start">
        <Toggle
          // size="lg"
          checked={collectWithExternalSites}
          // @ts-ignore
          onChange={(value) => {
            dispatch(
              updateReviewFormSettings({
                welcomeScreen: {
                  ...welcomeScreen,
                  collectWithExternalSites: value,
                },
              })
            );
          }}
          checkedChildren="Yes"
          unCheckedChildren="No"
        />
        <Label margin="0">Use External Review Sites</Label>
      </FormRow>

      {plan !== SubscriptionPlan.ADVANCED && collectWithExternalSites && (
        <PremiumBanner
          availableOn={SubscriptionPlan.ADVANCED}
          description="Allow customers to leave reviews directly on external platforms like Google, Yelp, and Trustpilot."
        />
      )}

      {collectWithExternalSites ? (
        <>
          <div style={{ marginTop: '15px' }}>
            {localThirdPartyAccounts.length > 0 ? (
              <>
                <p style={{ marginBottom: '10px' }}>Collect reviews with:</p>

                {localThirdPartyAccounts.map((account) => (
                  <FormRow
                    key={account.id}
                    gap="10px"
                    justifyContent="flex-start"
                    marginBottom="5px"
                    alignItems="flex-start"
                    marginTop="5px"
                  >
                    <Toggle
                      checked={account.collectReviews}
                      onChange={(value) => {
                        // Check if this is the last active toggle being turned off
                        const isLastActiveToggle =
                          value === false &&
                          localThirdPartyAccounts.filter(
                            (acc) => acc.id !== account.id && acc.collectReviews
                          ).length === 0;

                        // If trying to turn off the last active toggle, prevent it
                        if (isLastActiveToggle) {
                          // Could show a toast/notification here
                          return;
                        }

                        // Update the local state
                        dispatch(
                          setLocalThirdPartyAccounts(
                            localThirdPartyAccounts.map((acc) =>
                              acc.id === account.id
                                ? { ...acc, collectReviews: value }
                                : acc
                            )
                          )
                        );
                      }}
                      size="sm"
                    />
                    <div>
                      <Label margin="0">{account.name}</Label>
                      {account.collectReviews && (
                        <div
                          style={{
                            fontSize: '11px',
                            color: '#666',
                            marginTop: '2px',
                            fontStyle: 'italic',
                          }}
                        >
                          {
                            platformDisplayNames[
                              account.platform as IntegrationPlatform
                            ]
                          }
                        </div>
                      )}
                    </div>
                  </FormRow>
                ))}
              </>
            ) : (
              <>
                <EmptyStateContainer>
                  <EmptyStateTitle>
                    <ExclamationCircleIcon style={{ marginRight: '8px' }} />
                    No connected review platforms found
                  </EmptyStateTitle>
                  <EmptyStateDescription>
                    Connect your review platforms like Google, Yelp, or
                    Trustpilot to collect reviews directly on those sites.
                  </EmptyStateDescription>
                  <ConnectButton
                    rounded
                    size="small"
                    type="button"
                    onClick={() => navigate('/import-reviews')}
                  >
                    <PlusCircleIcon style={{ marginRight: '8px' }} />
                    <b>Connect Review Platforms</b>
                  </ConnectButton>
                </EmptyStateContainer>
              </>
            )}
          </div>

          {/* Only show the external link prompt when there are at least 2 active external sites */}
          {localThirdPartyAccounts.filter((account) => account.collectReviews)
            .length >= 2 && (
            <FormRow marginTop="20px">
              <Label>External sites selection prompt</Label>
              <Input
                placeholder="Select where to leave your review:"
                value={welcomeScreen?.externalLinkPrompt || ''}
                onChange={(value) =>
                  dispatch(
                    updateReviewFormSettings({
                      welcomeScreen: {
                        ...welcomeScreen,
                        externalLinkPrompt: value,
                      },
                    })
                  )
                }
              />
              <div
                style={{ fontSize: '12px', color: '#6b7280', marginTop: '4px' }}
              >
                This text appears when customers need to choose between multiple
                review platforms.
              </div>
            </FormRow>
          )}
        </>
      ) : (
        <>
          <FormRow>
            <Label>Introductory Message</Label>
            <Input
              as="textarea"
              rows={5}
              placeholder="Enter your title"
              value={message}
              onChange={(value) =>
                dispatch(
                  updateReviewFormSettings({
                    welcomeScreen: {
                      ...welcomeScreen,
                      message: value,
                    },
                  })
                )
              }
              style={{ resize: 'none' }}
            />
          </FormRow>

          {/* <FormRow>
        <Label>Welcome Video Message</Label>
        {plan === SubscriptionPlan.ADVANCED ? (
          <VideoSection>
            <IoVideocamOutline />
            <p>Record a welcome video</p>
          </VideoSection>
        ) : (
          <PremiumBanner
            availableOn={SubscriptionPlan.ADVANCED}
            description="Upgrade to add a welcome video to your greeting screen."
          />
        )}
      </FormRow> */}
        </>
      )}
    </>
  );
}
