import { FaGift } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
import { SubscriptionPlan } from '../../../../../shared/types/review.types';
import { useAppSelector } from '../../../../../store';
import { reviewFormSelector } from '../../../redux/review-form.slice';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  color: #0369a1;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    right: -35px;
    bottom: -35px;
    width: 95px;
    height: 95px;
    background-image: url('/images/spinning-wheel.png');
    background-repeat: no-repeat;
    background-size: contain;
    animation: ${spin} 50s linear infinite;
  }
`;

const GiftIcon = styled(FaGift)`
  font-size: 16px;
  flex-shrink: 0;
`;

type RewardBannerProps = {
  plan?: SubscriptionPlan;
};

export default function RewardBanner({ plan }: RewardBannerProps) {
  const { reviewFormSettings } = useAppSelector(reviewFormSelector);
  const { reward } = reviewFormSettings ?? {};
  const { rewardIncentiveText } = reward ?? {};

  // if (plan === SubscriptionPlan.FREE) {
  //   return null;
  // }

  return (
    <Wrapper>
      <GiftIcon />
      {rewardIncentiveText}
    </Wrapper>
  );
}
