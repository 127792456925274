import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z, ZodType } from 'zod';
import { axiosPrivate } from '../../../../services/axiosPrivate';
import { useNotification } from '../../../../shared/hooks/useNotification';
import { Loading } from '../../../../shared/types/review.types';
import removeTrailingSlash from '../../../../shared/utils/removeTrailingSlash';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { accountSelector } from '../../../userData/redux/userData.slice';
import { setBusinessInfo } from '../../redux/business-info.slice';
import Contact from './Contact';
import Location from './Location';
import Profile from './Profile';

/* eslint no-console: "warn" */

export type BusinessProfile = {
  businessName: string;
  about: string;
  logo?: string;
  streetAddress: string;
  city: string;
  state?: string;
  postalCode?: string;
  country: string;
  website: string;
  email: string;
  telephone: string;
  x: string;
  facebook: string;
  instagram: string;
};

const FormSchema: ZodType<BusinessProfile> = z.object({
  businessName: z.string().min(2, { message: 'Enter at least two charaters' }),
  about: z
    .string()
    .min(10, { message: 'Enter at least 10 characters' })
    .max(150, { message: 'Keep it to 150 characters maximum' }),
  logo: z.any(),
  streetAddress: z.string().min(2, { message: 'Enter at least two charaters' }),
  city: z.string().min(2, { message: 'Enter at least two charaters' }),
  state: z.string(),
  postalCode: z.string(),
  country: z.string().min(1, { message: 'Country is required' }),
  website: z.string().url('Enter a valid website URL'),
  email: z.string().email('Enter a valid email address'),
  telephone: z.string(),
  x: z.string(),
  facebook: z.string(),
  instagram: z.string(),
});

export default function Business() {
  const dispatch = useAppDispatch();
  const [saving, setSaving] = useState(Loading.INITIAL);
  const { data: businessInfo } = useAppSelector((state) => state.businessInfo);
  const { showErrorToast, showSuccessToast } = useNotification();
  const { account } = useAppSelector(accountSelector);
  const { selectedSite, ownerEmail } = account ?? {};
  const { url } = selectedSite ?? {};
  const { siteDisplayName } = selectedSite ?? {};
  const [updateLogo, setUpdateLogo] = useState('false');

  const {
    control,
    handleSubmit,
    setValue,
    watch,

    formState: { errors },
  } = useForm<BusinessProfile>({
    resolver: zodResolver(FormSchema),
    reValidateMode: 'onBlur',
    defaultValues: {
      businessName: businessInfo?.name ?? siteDisplayName ?? '',
      about: businessInfo?.about ?? '',
      logo: businessInfo?.logo ?? '',
      city: businessInfo?.city ?? '',
      state: businessInfo?.state ?? '',
      country: businessInfo?.country ?? '',
      postalCode: businessInfo?.postalCode ?? '',
      streetAddress: businessInfo?.streetAddress ?? '',
      website: businessInfo?.website ?? url ?? '',
      email: businessInfo?.email ?? ownerEmail ?? '',
      telephone: businessInfo?.telephone ?? '',
      x: businessInfo?.x ?? '',
      facebook: businessInfo?.facebook ?? '',
      instagram: businessInfo?.instagram ?? '',
    },
  });

  const [companyName, logo, about] = watch(['businessName', 'logo', 'about']);

  const onSubmit = useCallback(
    async (data: BusinessProfile) => {
      if (saving === Loading.PENDING) {
        return;
      }

      try {
        const formData = new FormData();

        formData.append('name', data.businessName);
        formData.append('about', data.about);
        formData.append('logo', data.logo ?? '');
        formData.append('updateLogo', updateLogo);
        formData.append('city', data.city);
        formData.append('state', data.state ?? '');
        formData.append('country', data.country ?? '');
        formData.append('postalCode', data.postalCode ?? '');
        formData.append('streetAddress', data.streetAddress);
        formData.append('website', data.website);
        formData.append('email', data.email);
        formData.append('telephone', data.telephone);
        formData.append('x', data.x);
        formData.append('facebook', data.facebook);
        formData.append('instagram', data.instagram);

        setSaving(Loading.PENDING);

        const {
          data: { businessInfo },
        } = await axiosPrivate.post(
          `${removeTrailingSlash(
            process.env.REACT_APP_REVIEWS_JET_API!
          )}/business-info/update`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        dispatch(setBusinessInfo(businessInfo));
        setSaving(Loading.SUCCESS);
        showSuccessToast('Business profile updated');
      } catch (error: any) {
        showErrorToast(error.message);
      }
    },
    [saving, updateLogo]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Profile
        logo={logo}
        saving={saving}
        about={about}
        companyName={companyName}
        control={control}
        errors={errors}
        setValue={setValue}
        onLogoChanged={() => setUpdateLogo('true')}
      />
      <Location errors={errors} control={control} />
      <Contact errors={errors} control={control} />
    </form>
  );
}
