import { useRef } from 'react';
import { FaRegTrashCan } from 'react-icons/fa6';
import { Button, Row, Slider } from 'rsuite';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import FormRow from '../../../../widgetSettings/components/form-row';
import {
  siteWidgetSettingsSelector,
  updateSiteWidgetSettings,
} from '../../../../widgetSettings/redux/widgetSettings.slice';

const Image = styled.img<{ $borderRadius: number }>`
  max-width: 50px;
  max-height: 50px;
  border-radius: ${({ $borderRadius }) => `${$borderRadius}%`};
  padding: 2px;
  border: 1px solid #cecece;
`;

const DeleteBtn = styled.div`
  display: block;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 100%;
  height: 26px;
  width: 26px;
  &:hover {
    color: #324fbe;
    background-color: #ebeef9;
  }
`;

const defaultThankImage =
  'https://reviews-jet.sfo3.cdn.digitaloceanspaces.com/@FlexByt/thank-you-images/default.gif';

const ReviewImage = () => {
  const ref = useRef(null);
  const dispatch = useAppDispatch();

  const settings = useAppSelector(siteWidgetSettingsSelector);

  const handleChange = (e: any) => {
    const file = URL.createObjectURL(e.target.files[0]);
    dispatch(
      updateSiteWidgetSettings({
        ...settings,
        thankYouPage: {
          ...settings?.thankYouPage,
          image: file,
        },
      })
    );
  };

  const { thankYouPage } = settings ?? {};
  const { image, imageBorderRadius = 0 } = thankYouPage ?? {};

  return (
    <>
      <FormRow
        justifyContent="flex-start"
        gap="10px"
        marginBottom="20px"
        marginTop="20px"
      >
        <Image src={image as string} alt="" $borderRadius={imageBorderRadius} />
        <Button
          size="sm"
          appearance="default"
          onClick={() => {
            (ref.current as any)?.click();
          }}
        >
          Change
        </Button>
        {image !== defaultThankImage && (
          <DeleteBtn
            role="presentation"
            onClick={() => {
              dispatch(
                updateSiteWidgetSettings({
                  ...settings,
                  thankYouPage: {
                    ...settings?.thankYouPage,
                    image: defaultThankImage,
                  },
                })
              );
            }}
          >
            <FaRegTrashCan size={16} />
          </DeleteBtn>
        )}
        <input type="file" value="" onChange={handleChange} ref={ref} hidden />
      </FormRow>

      <FormRow justifyContent="flex-start" gap="10px">
        <div style={{ width: '100%' }}>
          Border radius
          <Row style={{ maxWidth: '300px' }}>
            <Slider
              progress
              style={{ marginTop: 16 }}
              value={imageBorderRadius}
              onChange={(value) => {
                dispatch(
                  updateSiteWidgetSettings({
                    ...settings,
                    thankYouPage: {
                      ...settings?.thankYouPage,
                      imageBorderRadius: value,
                    },
                  })
                );
              }}
            />
            {/* <Col lg={4} md={5} xs={7}>
              <InputNumber
                max={100}
                min={0}
                postfix="%"
                value={imageBorderRadius}
                onChange={(value) => {
                  dispatch(
                    updateSiteWidgetSettings({
                      ...settings,
                      thankYouPage: {
                        ...settings?.thankYouPage,
                        imageBorderRadius: value,
                      },
                    })
                  );
                }}
              />
            </Col> */}
          </Row>
        </div>
      </FormRow>
    </>
  );
};

export default ReviewImage;
