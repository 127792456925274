import { BsCameraVideo } from 'react-icons/bs';
import { LuPencil } from 'react-icons/lu';
import styled, { css } from 'styled-components';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import { useAppSelector } from '../../../../../store';
import {
  CarouselWidget,
  EmbeddedWidgetSettings,
  ModalReviewsSettings,
  ProductWidgetSettings,
  siteWidgetSettingsSelector,
} from '../../../../widgetSettings/redux/widgetSettings.slice';

const Wrapper = styled.div`
  flex: 1;
  @media (max-width: 1200px) {
    padding-top: 0;
  }
`;

const FormWrapper = styled(FlexContainer)`
  margin: 0 auto;
  color: #55595f;
  border: 1px solid #ededed;
  background-color: #fff;
  border-radius: 5px;
  .preview-content {
    padding: 20px;
  }
  @media (max-width: 1200px) {
    margin: 0 !important;
    width: 100%;
  }
  h3 {
    text-align: left;
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 10px;
    color: #000;
  }
  p {
    color: #707d86;
    font-size: 15px;

    &.message {
      text-align: left;
      max-width: 400px;
      margin: auto;
      margin-bottom: 15px;
      font-size: 14px;
    }
  }
  h4 {
    width: 100%;
    font-size: 15px;
    margin-top: 15px;
    div {
      border-bottom: 3px solid #2476b3;
      width: 35px;
    }
  }
  ul {
    margin-top: 5px;
    list-style: disc;
    width: 100%;
    padding-left: 15px;

    li {
      padding: 2px 0;
      color: #707d86;
    }
  }
`;

const ButtonsWrapper = styled(FlexContainer)<{
  $settings?:
    | ProductWidgetSettings
    | ModalReviewsSettings
    | EmbeddedWidgetSettings
    | (EmbeddedWidgetSettings & {
        variant: CarouselWidget;
        autoplay: boolean;
      });
}>`
  width: 100%;
  padding-top: 20px;
  button {
    width: 100%;
    padding: 10px;
    border-radius: 6px;

    &.video {
      color: #fff;
      background-color: #31579c;
      /* ${({ $settings }) => css`
        color: ${$settings?.buttonTextColor};
        background-color: ${$settings?.buttonBackgroundColor};
      `} */
    }

    &.text {
      color: #fff;
      background: #000;
    }
  }
`;

export default function ReviewQuestionsPreview({
  settings,
}: {
  settings:
    | ProductWidgetSettings
    | ModalReviewsSettings
    | EmbeddedWidgetSettings
    | (EmbeddedWidgetSettings & {
        variant: CarouselWidget;
        autoplay: boolean;
      });
}) {
  const { reviewQuestions } = useAppSelector(siteWidgetSettingsSelector) ?? {};
  const { collectionType, message, title, questions } = reviewQuestions ?? {};

  return (
    <Wrapper>
      <FormWrapper stack gap="10px">
        <div className="preview-content">
          <h3>{title}</h3>
          <p className="message">{message}</p>
          {!!questions?.length && (
            <>
              {/* <h4>
                QUESTIONS
                <div
                  style={{
                    borderColor: settings?.buttonBackgroundColor,
                  }}
                />
              </h4> */}
              {!!questions?.length && (
                <ul>
                  {questions?.map((question, index) => (
                    <li key={index}>{question}</li>
                  ))}
                </ul>
              )}
            </>
          )}

          <ButtonsWrapper stack gap="10px" $settings={settings}>
            {collectionType === 'both' && (
              <>
                <button className="video">
                  <FlexContainer gap="10px">
                    <BsCameraVideo size={17} />
                    Record a video
                  </FlexContainer>
                </button>
                <button className="text">
                  <FlexContainer gap="10px">
                    <LuPencil size={16} />
                    Send in text
                  </FlexContainer>
                </button>
              </>
            )}
            {collectionType === 'text' && (
              <button className="text">
                <FlexContainer gap="10px">
                  <LuPencil size={16} />
                  Send in text
                </FlexContainer>
              </button>
            )}
            {collectionType === 'video' && (
              <button className="video">
                <FlexContainer gap="10px">
                  <BsCameraVideo size={17} />
                  Record a video
                </FlexContainer>
              </button>
            )}
          </ButtonsWrapper>
        </div>
      </FormWrapper>
    </Wrapper>
  );
}
