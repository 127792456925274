import { useEffect, useRef, useState } from 'react';
import { MdRefresh } from 'react-icons/md';
import { batch } from 'react-redux';
import { Input } from 'rsuite';
import styled from 'styled-components';
import { ColorPicker } from '../../../../../../shared/components/colorPicker';
import { Label } from '../../../../../../shared/components/Label';
import FlexContainer from '../../../../../../shared/components/layouts/flex-container';
import getLighterColor from '../../../../../../shared/utils/getSegmentLighterColor';
import { useAppDispatch, useAppSelector } from '../../../../../../store';
import FormRow from '../../../../../widgetSettings/components/form-row';
import {
  reviewFormSelector,
  setShowWheelLoseResult,
  setShowWheelWinResult,
  updateReviewFormSettings,
  WheelTheme,
} from '../../../../redux/review-form.slice';
/* eslint no-console: "warn" */

const WheelOptions = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
`;

const WheelOption = styled.div<{ $isSelected?: boolean }>`
  flex: 1;
  aspect-ratio: 1;
  border-radius: 8px;
  border: 1px solid
    ${(props) => (props.$isSelected ? '#7dd3fc' : 'transparent')};
  background: ${(props) => (props.$isSelected ? '#f0f9ff' : '#fff')};
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 8px;
  position: relative;

  &:hover {
    border-color: ${(props) => (props.$isSelected ? '#7dd3fc' : '#cbd5e1')};
    background: ${(props) => (props.$isSelected ? '#f0f9ff' : '#f8fafc')};
  }
`;

const WheelContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const WheelPointer = styled.div<{ $isSpinning: boolean }>`
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 16px;
  height: 30px;
  background: linear-gradient(to bottom, #c0c0c0, #808080);
  clip-path: polygon(0% 30%, 50% 0%, 100% 30%, 50% 100%);
  border-radius: 3px;
  box-shadow: inset 0 1px 2px rgba(255, 255, 255, 0.5),
    inset 0 -1px 2px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 2;
  animation: ${(props) =>
    props.$isSpinning ? 'tickerShake 0.08s linear infinite' : 'none'};

  @keyframes tickerShake {
    0% {
      transform: translateX(-50%) rotate(-4deg);
    }
    25% {
      transform: translateX(-52%) rotate(0deg);
    }
    50% {
      transform: translateX(-50%) rotate(4deg);
    }
    75% {
      transform: translateX(-48%) rotate(0deg);
    }
    100% {
      transform: translateX(-50%) rotate(-4deg);
    }
  }
`;

const defaultWheelColors = {
  [WheelTheme.BLUE]: '#3b82f6', // blue
  [WheelTheme.ORANGE]: '#f97316', // orange
  [WheelTheme.GREEN]: '#10b981', // green
  [WheelTheme.VIOLET]: '#a855f7', // purple
};

const Wheel = styled.div<{
  $variant: WheelTheme;
  $isSpinning?: boolean;
  $wheelColor: string;
  $isSelected: boolean;
}>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
  animation: ${(props) =>
    props.$isSpinning ? 'spin 3s cubic-bezier(0.3, 0, 0.2, 1)' : 'none'};

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20%;
    height: 20%;
    border-radius: 50%;
    background: radial-gradient(circle at 30% 30%, #8b4513, #654321),
      repeating-radial-gradient(
        circle at center,
        #8b4513,
        #8b4513 2px,
        #654321 2px,
        #654321 4px
      );
    background-blend-mode: overlay;
    border: 1px solid #4a3728;
    box-shadow: inset 0 2px 3px rgba(255, 255, 255, 0.2),
      inset 0 -2px 3px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.15);
    z-index: 1;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    80% {
      transform: rotate(1440deg); // 4 full rotations
    }
    90% {
      transform: rotate(1460deg); // Slight overshoot
    }
    100% {
      transform: rotate(1455deg); // Settle back
    }
  }

  ${(props) => {
    const mainColor = props.$isSelected
      ? props.$wheelColor
      : defaultWheelColors[props.$variant];
    const lighterColor = getLighterColor(mainColor);

    return `
      background: conic-gradient(
        ${mainColor} 0deg,
        ${mainColor} 30deg,
        ${lighterColor} 30deg,
        ${lighterColor} 60deg,
        ${mainColor} 60deg,
        ${mainColor} 90deg,
        ${lighterColor} 90deg,
        ${lighterColor} 120deg,
        ${mainColor} 120deg,
        ${mainColor} 150deg,
        ${lighterColor} 150deg,
        ${lighterColor} 180deg,
        ${mainColor} 180deg,
        ${mainColor} 210deg,
        ${lighterColor} 210deg,
        ${lighterColor} 240deg,
        ${mainColor} 240deg,
        ${mainColor} 270deg,
        ${lighterColor} 270deg,
        ${lighterColor} 300deg,
        ${mainColor} 300deg,
        ${mainColor} 330deg,
        ${lighterColor} 330deg,
        ${lighterColor} 360deg
      );
    `;
  }}
`;
const ResetButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  width: 40px;
  height: 40px;
  border: 1px solid #e2e8f0;
  border-radius: 50%;
  background: #fff;
  color: #64748b;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;

  &:hover {
    background: #f8fafc;
    border-color: #cbd5e1;
  }

  &:hover::after {
    content: 'Reset to default color';
    position: absolute;
    bottom: calc(100% + 8px);
    right: 0;
    background: #1e293b;
    color: white;
    padding: 6px 10px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    pointer-events: none;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  }

  &:hover::before {
    content: '';
    position: absolute;
    bottom: calc(100% + 2px);
    right: 10px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #1e293b;
  }

  svg {
    display: block;
  }
`;

interface WheelThemeProps {
  selectedWheel?: WheelTheme;
  onWheelChange: (wheel: WheelTheme) => void;
  wheelColor: string;
  onColorChange: (color: string) => void;
}

export default function WheelThemeChooser({
  selectedWheel,
  onColorChange,
}: WheelThemeProps) {
  const dispatch = useAppDispatch();
  const [spinningWheel, setSpinningWheel] = useState<string | null>(null);
  const spinTimeoutRef = useRef<NodeJS.Timeout>();

  // Set initial color when component mounts
  useEffect(() => {
    if (selectedWheel) {
      onColorChange(defaultWheelColors[selectedWheel]);
    }
  }, [selectedWheel]); // Run when selectedWheel changes

  // Clean up timeout on unmount
  useEffect(() => {
    return () => {
      if (spinTimeoutRef.current) {
        clearTimeout(spinTimeoutRef.current);
      }
    };
  }, []);

  const { reviewFormSettings } = useAppSelector(reviewFormSelector);
  const { reward } = reviewFormSettings ?? {};
  const {
    wheelColor = defaultWheelColors[WheelTheme.BLUE],
    wheelTitle,
    wheelMessage,
    winTitle,
    winMessage,
    loseTitle,
    loseMessage,
  } = reward ?? {};

  const isDefaultColor =
    selectedWheel &&
    defaultWheelColors[selectedWheel as keyof typeof defaultWheelColors] ===
      wheelColor;

  return (
    <>
      <FormRow gap="10px" justifyContent="flex-start">
        <div style={{ width: '100%' }}>
          <WheelOptions>
            <WheelOption
              $isSelected={selectedWheel === WheelTheme.BLUE}
              onClick={() => {
                batch(() => {
                  dispatch(
                    updateReviewFormSettings({
                      reward: {
                        ...reward,
                        wheelTheme: WheelTheme.BLUE,
                        wheelColor: defaultWheelColors[WheelTheme.BLUE],
                      },
                    })
                  );
                  dispatch(setShowWheelWinResult(false));
                  dispatch(setShowWheelLoseResult(false));
                });
              }}
            >
              <WheelContainer>
                <WheelPointer $isSpinning={spinningWheel === WheelTheme.BLUE} />
                <Wheel
                  $variant={WheelTheme.BLUE}
                  $isSpinning={spinningWheel === WheelTheme.BLUE}
                  $wheelColor={wheelColor}
                  $isSelected={selectedWheel === WheelTheme.BLUE}
                />
              </WheelContainer>
            </WheelOption>

            <WheelOption
              $isSelected={selectedWheel === WheelTheme.ORANGE}
              onClick={() => {
                batch(() => {
                  dispatch(
                    updateReviewFormSettings({
                      reward: {
                        ...reward,
                        wheelTheme: WheelTheme.ORANGE,
                        wheelColor: defaultWheelColors[WheelTheme.ORANGE],
                      },
                    })
                  );
                  dispatch(setShowWheelWinResult(false));
                  dispatch(setShowWheelLoseResult(false));
                });
              }}
            >
              <WheelContainer>
                <WheelPointer $isSpinning={spinningWheel === 'segments'} />
                <Wheel
                  $variant={WheelTheme.ORANGE}
                  $isSpinning={spinningWheel === WheelTheme.ORANGE}
                  $wheelColor={wheelColor}
                  $isSelected={selectedWheel === WheelTheme.ORANGE}
                />
              </WheelContainer>
            </WheelOption>

            <WheelOption
              $isSelected={selectedWheel === WheelTheme.GREEN}
              onClick={() => {
                batch(() => {
                  dispatch(
                    updateReviewFormSettings({
                      reward: {
                        ...reward,
                        wheelTheme: WheelTheme.GREEN,
                        wheelColor: defaultWheelColors[WheelTheme.GREEN],
                      },
                    })
                  );
                  dispatch(setShowWheelWinResult(false));
                  dispatch(setShowWheelLoseResult(false));
                });
              }}
            >
              <WheelContainer>
                <WheelPointer
                  $isSpinning={spinningWheel === WheelTheme.GREEN}
                />
                <Wheel
                  $variant={WheelTheme.GREEN}
                  $isSpinning={spinningWheel === WheelTheme.GREEN}
                  $wheelColor={wheelColor}
                  $isSelected={selectedWheel === WheelTheme.GREEN}
                />
              </WheelContainer>
            </WheelOption>

            <WheelOption
              $isSelected={selectedWheel === WheelTheme.VIOLET}
              onClick={() => {
                batch(() => {
                  dispatch(
                    updateReviewFormSettings({
                      reward: {
                        ...reward,
                        wheelTheme: WheelTheme.VIOLET,
                        wheelColor: defaultWheelColors[WheelTheme.VIOLET],
                      },
                    })
                  );
                  dispatch(setShowWheelWinResult(false));
                  dispatch(setShowWheelLoseResult(false));
                });
              }}
            >
              <WheelContainer>
                <WheelPointer
                  $isSpinning={spinningWheel === WheelTheme.VIOLET}
                />
                <Wheel
                  $variant={WheelTheme.VIOLET}
                  $isSpinning={spinningWheel === WheelTheme.VIOLET}
                  $wheelColor={wheelColor}
                  $isSelected={selectedWheel === WheelTheme.VIOLET}
                />
              </WheelContainer>
            </WheelOption>
          </WheelOptions>
        </div>
      </FormRow>

      <FormRow>
        <div>
          <Label>Wheel Color</Label>
          <FlexContainer
            justifyContent="space-between"
            alignItems="stretch"
            gap="15px"
          >
            <div>
              <ColorPicker
                color={wheelColor}
                isSimple={false}
                changeColor={(color) => {
                  batch(() => {
                    dispatch(
                      updateReviewFormSettings({
                        reward: {
                          ...reward,
                          wheelColor: color,
                        },
                      })
                    );
                    dispatch(setShowWheelWinResult(false));
                    dispatch(setShowWheelLoseResult(false));
                  });
                }}
              />
            </div>
            {!isDefaultColor && (
              <ResetButton
                onClick={() => {
                  batch(() => {
                    dispatch(
                      updateReviewFormSettings({
                        reward: {
                          ...reward,
                          wheelColor:
                            defaultWheelColors[
                              selectedWheel as keyof typeof defaultWheelColors
                            ],
                        },
                      })
                    );
                    dispatch(setShowWheelWinResult(false));
                    dispatch(setShowWheelLoseResult(false));
                  });
                }}
              >
                <MdRefresh size={20} />
              </ResetButton>
            )}
          </FlexContainer>
        </div>
      </FormRow>

      <FormRow justifyContent="flex-start">
        <Label>Wheel Title</Label>
        <Input
          maxLength={54}
          defaultValue={wheelTitle}
          onClick={() => {
            batch(() => {
              dispatch(setShowWheelWinResult(false));
              dispatch(setShowWheelLoseResult(false));
            });
          }}
          onChange={(value) => {
            batch(() => {
              dispatch(
                updateReviewFormSettings({
                  ...reviewFormSettings,
                  reward: { ...reward, wheelTitle: value },
                })
              );
            });
          }}
          placeholder="Win a Special Reward! 🎉"
        />
      </FormRow>

      <FormRow justifyContent="flex-start">
        <Label>Wheel Message</Label>
        <Input
          maxLength={54}
          defaultValue={wheelMessage}
          onClick={() => {
            batch(() => {
              dispatch(setShowWheelWinResult(false));
              dispatch(setShowWheelLoseResult(false));
            });
          }}
          onChange={(value) => {
            batch(() => {
              dispatch(
                updateReviewFormSettings({
                  ...reviewFormSettings,
                  reward: { ...reward, wheelMessage: value },
                })
              );
            });
          }}
          placeholder="Spin the wheel for a chance to get a discount"
        />
      </FormRow>

      <FormRow justifyContent="flex-start">
        <Label>Win Title</Label>
        <Input
          maxLength={41}
          defaultValue={winTitle}
          onClick={() => {
            batch(() => {
              dispatch(setShowWheelWinResult(true));
              dispatch(setShowWheelLoseResult(false));
            });
          }}
          onChange={(value) => {
            batch(() => {
              dispatch(
                updateReviewFormSettings({
                  ...reviewFormSettings,
                  reward: { ...reward, winTitle: value },
                })
              );
            });
          }}
          placeholder="Congratulations! 🎉"
        />
      </FormRow>

      <FormRow justifyContent="flex-start">
        <Label>Win Message</Label>
        <Input
          maxLength={147}
          defaultValue={winMessage}
          onClick={() => {
            batch(() => {
              dispatch(setShowWheelWinResult(true));
              dispatch(setShowWheelLoseResult(false));
            });
          }}
          onChange={(value) => {
            batch(() => {
              dispatch(
                updateReviewFormSettings({
                  ...reviewFormSettings,
                  reward: { ...reward, winMessage: value },
                })
              );
            });
          }}
          placeholder="Use the discount code below on your next purchase"
        />
      </FormRow>

      <FormRow justifyContent="flex-start">
        <Label>Lose Title</Label>
        <Input
          maxLength={41}
          defaultValue={loseTitle}
          onClick={() => {
            batch(() => {
              dispatch(setShowWheelWinResult(false));
              dispatch(setShowWheelLoseResult(true));
            });
          }}
          onChange={(value) => {
            batch(() => {
              dispatch(
                updateReviewFormSettings({
                  ...reviewFormSettings,
                  reward: { ...reward, loseTitle: value },
                })
              );
            });
          }}
          placeholder="No more tries left"
        />
      </FormRow>

      <FormRow justifyContent="flex-start">
        <Label>Lose Message</Label>
        <Input
          maxLength={147}
          defaultValue={loseMessage}
          onClick={() => {
            batch(() => {
              dispatch(setShowWheelWinResult(false));
              dispatch(setShowWheelLoseResult(true));
            });
          }}
          onChange={(value) => {
            batch(() => {
              dispatch(
                updateReviewFormSettings({
                  ...reviewFormSettings,
                  reward: { ...reward, loseMessage: value },
                })
              );
            });
          }}
          placeholder="Come back later for another chance to win!"
        />
      </FormRow>
    </>
  );
}
