import { useState } from 'react';
import { FaCode } from 'react-icons/fa6';
import { GiFlame } from 'react-icons/gi';
import { MdChatBubbleOutline } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Tabs } from 'rsuite';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0px 30px 20px;
  max-width: 1500px;
  margin: auto;
`;

const Title = styled.h1`
  font-size: 25px;
  margin: 0 0 24px;
  color: rgb(0, 0, 0);
  line-height: 30px;
  font-family: ubuntu;
`;

const WidgetsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 24px;
  margin-bottom: 40px;
`;

const WidgetCard = styled.div`
  border: 1px solid #eee;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
  background: white;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  }
`;

const WidgetImage = styled.div<{ image: string }>`
  width: 100%;
  aspect-ratio: 16/9;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  border-bottom: 1px solid #eee;
`;

const WidgetInfo = styled.div`
  padding: 2px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const WidgetName = styled.h3`
  font-size: 15px;
  font-weight: 500;
  color: #000000;
  margin: 0;
`;

const HotBadge = styled.span`
  background: #ff4d4f;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 11px;
  font-weight: 500;
`;

const widgets = [
  {
    id: 'slider-widget',
    title: 'Carousel slider',
    image: '/images/widgets-new/slider.png',
    url: '/widgets/slider',
    isHot: true,
    styles: {},
    type: 'widgets',
  },
  {
    id: 'marquee-widget',
    title: 'Carousel marquee',
    image: '/images/widgets-new/marquee.png',
    url: '/widgets/marquee',
    isHot: true,
    styles: {},
    type: 'widgets',
  },
  {
    id: 'masonry-widget',
    title: 'Masonry',
    image: '/images/widgets-new/masonry.png',
    url: '/widgets/masonry',
    isHot: true,
    styles: { backgroundPosition: '0px -22px' },
    type: 'widgets',
  },
  {
    id: 'grid-widget',
    title: 'Grid',
    image: '/images/widgets-new/grid.png',
    url: '/widgets/grid',
    styles: { backgroundPosition: '0px -13px' },
    type: 'widgets',
  },
  {
    id: 'list-widget',
    title: 'List',
    image: '/images/widgets-new/list.png',
    url: '/widgets/list',
    isHot: true,
    styles: { backgroundPosition: '0px 0px' },
    type: 'widgets',
  },
  {
    id: 'modal-widget',
    title: 'Floating widget',
    image: '/images/widgets-new/floating.png',
    url: '/widgets/modal-widget',
    styles: {},
    type: 'popups',
  },
];

const Header = styled.div`
  background: #0e0e0f;
  padding: 30px 20px 20px;
  margin: 0 0 20px;
  border-radius: 10px;

  .rs-nav-item {
    padding: 8px 15px !important;
    border-radius: 0;
    background-color: transparent;
  }
  .rs-nav-pills {
    background: transparent;
  }
  .rs-nav-pills .rs-nav-item {
    color: #fff;
    font-weight: 500;
    border-bottom: 2px solid transparent !important;
  }

  .rs-nav-pills .rs-nav-item-active {
    color: #fff;
    border-bottom-color: #fff !important;
  }
`;

const MainTitle = styled(Title)`
  color: #fff;
`;

const WidgetType = styled(Title)`
  font-size: 19px;
  margin: 40px 0 2px;
`;

const Description = styled.p`
  font-size: 15px;
  color: #666;
  margin-bottom: 20px;
`;

export default function Widgets() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<'widgets' | 'popups'>('widgets');

  return (
    <Wrapper>
      <Header>
        <MainTitle>Showcase Your Reviews</MainTitle>
        <Tabs
          defaultActiveKey="1"
          appearance="pills"
          activeKey={activeTab === 'widgets' ? '1' : '2'}
          onSelect={(key) => setActiveTab(key === '1' ? 'widgets' : 'popups')}
        >
          <Tabs.Tab
            eventKey="1"
            title="Widgets"
            icon={<FaCode size={20} color="#2dd4bf" />}
          ></Tabs.Tab>
          <Tabs.Tab
            eventKey="2"
            title="Popups"
            icon={<MdChatBubbleOutline size={20} color="#818cf8" />}
          ></Tabs.Tab>
        </Tabs>
      </Header>

      <WidgetType>{activeTab === 'widgets' ? 'Widgets' : 'Popups'}</WidgetType>
      <Description>
        {activeTab === 'widgets'
          ? 'Easily embed testimonials on your website—no coding required.'
          : 'Showcase your reviews in a popup when visitors click on the review tab'}
      </Description>

      <WidgetsGrid>
        {widgets
          .filter((widget) => widget.type === activeTab)
          .map((widget) => (
            <WidgetCard key={widget.id} onClick={() => navigate(widget.url)}>
              <WidgetImage image={widget.image} style={widget.styles} />
              <WidgetInfo>
                <WidgetName>{widget.title}</WidgetName>
                {widget.isHot && (
                  <GiFlame color="#ff7600" size={20} title="Trending widget" />
                )}
              </WidgetInfo>
            </WidgetCard>
          ))}
      </WidgetsGrid>
    </Wrapper>
  );
}
