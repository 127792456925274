import { Input } from 'rsuite';

import { Label } from '../../../../../shared/components/Label';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import FormRow from '../../../../widgetSettings/components/form-row';
import useSettingsScreen from '../../../hooks/useSettingsScreen';
import {
  reviewFormSelector,
  updateReviewFormSettings,
} from '../../../redux/review-form.slice';

export default function LanguageSettings() {
  const dispatch = useAppDispatch();
  const { reviewFormSettings } = useAppSelector(reviewFormSelector);
  const { translation } = reviewFormSettings ?? {};

  const {
    writeReview,
    writeYourReview,
    addPhotos,
    yourNamePlaceholder,
    emailAddressPrivacyNote,
    clickToUploadButtonLabel,
    enterReviewTitle,
    photoUploadPrompt,
    profilePictureLabel,
    skipButtonLabel,
    submitButtonLabel,
    yourEmailLabel,
    yourEmailPlaceholder,
    yourNameLabel,
    continueLabel,
  } = translation ?? {};

  // const [addPhotosText, setAddPhotosText] = useState(
  //   'Add up to 5 photos to your review (optional)'
  // );
  // const [yourName, setYourName] = useState('Your name');
  // const [yourNamePlaceholder, setYourNamePlaceholder] = useState('John Doe');
  // const [yourEmailAddress, setYourEmailAddress] =
  //   useState('Your email address');
  // const [yourEmailAddressPlaceholder, setYourEmailAddressPlaceholder] =
  //   useState('john.doe@example.com');
  // const [emailAddressPrivacyNote, setEmailAddressPrivacyNote] = useState(
  //   'Your email address will remain private and won’t be shared publicly'
  // );
  // const [profilePicture, setProfilePicture] = useState(
  //   'Profile Picture (Optional)'
  // );
  // const [submitButton, setSubmitButton] = useState('Submit');
  // const [skipButton, setSkipButton] = useState('Skip');
  // const [clickHereToUpload, setClickHereToUpload] = useState(
  //   'Click here to upload'
  // );

  const switchPreviewScreen = useSettingsScreen();

  return (
    <>
      <FormRow>
        <Label>Write a review</Label>
        <Input
          placeholder="Write a review"
          value={writeReview}
          onChange={(value) => {
            dispatch(
              updateReviewFormSettings({
                translation: {
                  ...translation,
                  writeReview: value,
                },
              })
            );
          }}
          onClick={() => switchPreviewScreen('design')}
        />
      </FormRow>

      <FormRow>
        <Label>Enter review title (Optional)</Label>
        <Input
          placeholder="Enter review title"
          value={enterReviewTitle}
          onChange={(value) => {
            dispatch(
              updateReviewFormSettings({
                translation: {
                  ...translation,
                  enterReviewTitle: value,
                },
              })
            );
          }}
          onClick={() => switchPreviewScreen('review')}
        />
      </FormRow>

      <FormRow>
        <Label>Write you review</Label>
        <Input
          placeholder="Write your review"
          value={writeYourReview}
          onChange={(value) => {
            dispatch(
              updateReviewFormSettings({
                translation: {
                  ...translation,
                  writeYourReview: value,
                },
              })
            );
          }}
        />
      </FormRow>

      <FormRow>
        <Label>Add Photos</Label>
        <Input
          placeholder="Add photos"
          value={addPhotos}
          onChange={(value) => {
            dispatch(
              updateReviewFormSettings({
                translation: {
                  ...translation,
                  addPhotos: value,
                },
              })
            );
          }}
          onClick={() => switchPreviewScreen('review')}
        />
      </FormRow>

      <FormRow>
        <Label>Continue</Label>
        <Input
          placeholder="Continue"
          value={continueLabel}
          onChange={(value) => {
            dispatch(
              updateReviewFormSettings({
                translation: {
                  ...translation,
                  continueLabel: value,
                },
              })
            );
          }}
          onClick={() => switchPreviewScreen('review')}
        />
      </FormRow>

      <FormRow>
        <Label>Your name label</Label>
        <Input
          placeholder="Your name"
          value={yourNameLabel}
          onChange={(value) => {
            dispatch(
              updateReviewFormSettings({
                translation: {
                  ...translation,
                  yourNameLabel: value,
                },
              })
            );
          }}
          onClick={() => switchPreviewScreen('user-information')}
        />
      </FormRow>

      <FormRow>
        <Label>Your name placeholder</Label>
        <Input
          placeholder="John Doe"
          value={yourNamePlaceholder}
          onChange={(value) => {
            dispatch(
              updateReviewFormSettings({
                translation: {
                  ...translation,
                  yourNamePlaceholder: value,
                },
              })
            );
          }}
          onClick={() => switchPreviewScreen('user-information')}
        />
      </FormRow>

      <FormRow>
        <Label>Your email address label</Label>
        <Input
          placeholder="Your email address"
          value={yourEmailLabel}
          onChange={(value) => {
            dispatch(
              updateReviewFormSettings({
                translation: {
                  ...translation,
                  yourEmailLabel: value,
                },
              })
            );
          }}
          onClick={() => switchPreviewScreen('user-information')}
        />
      </FormRow>

      <FormRow>
        <Label>Your email address placeholder</Label>
        <Input
          placeholder="john.doe@example.com"
          value={yourEmailPlaceholder}
          onChange={(value) => {
            dispatch(
              updateReviewFormSettings({
                translation: {
                  ...translation,
                  yourEmailPlaceholder: value,
                },
              })
            );
          }}
          onClick={() => switchPreviewScreen('user-information')}
        />
      </FormRow>

      <FormRow>
        <Label>Email address privacy note</Label>
        <Input
          placeholder="Your email address will remain private and won't be shared publicly"
          value={emailAddressPrivacyNote}
          onChange={(value) => {
            dispatch(
              updateReviewFormSettings({
                translation: {
                  ...translation,
                  emailAddressPrivacyNote: value,
                },
              })
            );
          }}
          onClick={() => switchPreviewScreen('user-information')}
        />
      </FormRow>

      <FormRow>
        <Label>Profile Picture (Optional)</Label>
        <Input
          placeholder="Profile Picture (Optional)"
          value={profilePictureLabel}
          onChange={(value) => {
            dispatch(
              updateReviewFormSettings({
                translation: {
                  ...translation,
                  profilePictureLabel: value,
                },
              })
            );
          }}
          onClick={() => switchPreviewScreen('user-information')}
        />
      </FormRow>

      <FormRow>
        <Label>Click here to upload button label</Label>
        <Input
          placeholder="Click here to upload"
          value={clickToUploadButtonLabel}
          onChange={(value) => {
            dispatch(
              updateReviewFormSettings({
                translation: {
                  ...translation,
                  clickToUploadButtonLabel: value,
                },
              })
            );
          }}
          onClick={() => switchPreviewScreen('user-information')}
        />
      </FormRow>

      <FormRow>
        <Label>Submit button label</Label>
        <Input
          placeholder="Submit"
          value={submitButtonLabel}
          onChange={(value) => {
            dispatch(
              updateReviewFormSettings({
                translation: {
                  ...translation,
                  submitButtonLabel: value,
                },
              })
            );
          }}
          onClick={() => switchPreviewScreen('user-information')}
        />
      </FormRow>
    </>
  );
}
