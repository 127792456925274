import { unwrapResult } from '@reduxjs/toolkit';
import { useState } from 'react';
import { Input, Button as RsButton } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../../shared/components/Button';
import { Label } from '../../../../shared/components/Label';
import { Title } from '../../../../shared/components/Title';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import useChangeLogo from '../../../../shared/hooks/useChangeLogo';
import useConfetti from '../../../../shared/hooks/useConfetti';
import { useNotification } from '../../../../shared/hooks/useNotification';
import { Loading } from '../../../../shared/types/review.types';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { emailsSelector } from '../../../emails/redux/emails.slice';
import { sendSingleReviewRequest } from '../../../share-form/redux /action';
import { accountSelector } from '../../../userData/redux/userData.slice';
import { formatEmailMessage, formatEmailSubject } from '../utils/common';

const StyledTitle = styled(Title)`
  font-size: 18px;
  margin-bottom: 12px;
`;

const Description = styled.p`
  font-size: 14px;
  color: #6b7280;
  line-height: 1.5;
  margin-bottom: 24px;
`;

const Footer = styled(FlexContainer)`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
`;

const ErrorMessage = styled.div`
  color: #dc2626;
  font-size: 13px;
  margin-top: 4px;
`;

const StyledButton = styled(Button)`
  background: #000;
  border: none;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px;

  &:disabled {
    background: #ccc;
  }
`;

export default function SendTestEmail({ onClose }: { onClose: () => void }) {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const { data: businessInfo } = useAppSelector((state) => state.businessInfo);
  const { logo } = businessInfo ?? {};
  const { logoUrl = '' } = useChangeLogo({ logo });
  const { data: reviewRequestData } = useAppSelector(
    (state) => state.reviewRequestData
  );
  const { account } = useAppSelector(accountSelector);
  const { ownerEmail = '' } = account ?? {};
  const {
    emailSubject = '',
    message = '',
    formButtonText,
    recipients,
    senderName = '',
    formKey = '',
    rightToLeftContent,
  } = reviewRequestData ?? {};
  const { realisticLook } = useConfetti();
  const { showSuccessToast, showErrorToast } = useNotification();

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const { sendingSingleReviewRquest } = useAppSelector(emailsSelector);

  const handleSend = () => {
    // Reset error
    setError('');

    // Validate empty
    if (!email.trim()) {
      setError('Please enter an email address');
      return;
    }

    // Validate format
    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }

    if (!recipients) {
      return;
    }

    let orderNumber = '';
    let firstName = '';
    let toName = '';
    let productName = '';
    let productImage = '';

    if (recipients[0].type === 'order') {
      orderNumber = recipients[0].orderNumber;
      firstName = recipients[0].name;
      toName = recipients[0].name;
      productName = recipients[0].product.name;
      productImage = recipients[0].product.imageUrl ?? '';
    } else {
      firstName = recipients[0].name;
      toName = recipients[0].name;
    }

    const testimonialLink = `${process.env.REACT_APP_REVIEWSJET_WEBSITE}/w/${formKey}`;

    const formattedMessage = formatEmailMessage(message, firstName);

    // If validation passes, call onSend

    const formData = new FormData();
    logo && formData.append('logo', logoUrl);
    formData.append('message', formattedMessage);
    formData.append('subject', formatEmailSubject(emailSubject, firstName));
    formData.append('replyFromName', senderName);
    formData.append('toEmail', email);
    formData.append('toName', toName);
    formData.append('testimonialLink', testimonialLink);
    formData.append('orderNumber', orderNumber);
    formData.append('productName', productName);
    formData.append('productImage', productImage);
    formData.append('isTest', 'true');

    if (formButtonText) {
      formData.append('callToAction', formButtonText);
    }

    if (rightToLeftContent) {
      formData.append('rtl', 'true');
    }

    formData.append('replyToEmail', ownerEmail);

    dispatch(sendSingleReviewRequest(formData))
      .then(unwrapResult)
      .then(() => {
        realisticLook();
        onClose();
        showSuccessToast('Test email sent successfully');
      })
      .catch((e) => {
        showErrorToast('Failed to send test email');
      });
  };

  return (
    <div>
      <StyledTitle>Send Test Email</StyledTitle>
      <Description>
        Enter your email address to preview how the review request will appear
        to your customers.
      </Description>

      <Label>Email</Label>
      <Input
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(value) => {
          setEmail(value);
          // Clear error when user types
          if (error) setError('');
        }}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}

      <Footer>
        {sendingSingleReviewRquest !== Loading.PENDING && (
          <RsButton appearance="subtle" onClick={onClose}>
            Cancel
          </RsButton>
        )}
        <StyledButton
          onClick={handleSend}
          disabled={sendingSingleReviewRquest === Loading.PENDING}
        >
          {sendingSingleReviewRquest === Loading.PENDING
            ? 'Please wait...'
            : 'Send Test Email'}
        </StyledButton>
      </Footer>
    </div>
  );
}
