import styled from 'styled-components';
import { SiteWidgetSettings } from '../../../../../../widgetSettings/redux/widgetSettings.slice';

const Wrapper = styled.div<{ $centerContent: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ $centerContent }) =>
    $centerContent ? 'center' : 'flex-start'};
  align-items: ${({ $centerContent }) =>
    $centerContent ? 'center' : 'flex-start'};
  gap: 15px;
  margin: 50px auto 20px;
  font-size: 14px;
  p {
    font-size: 14px;
  }
  button {
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 20px;
    outline: none;
    border: none;
    cursor: pointer;
  }

  span {
    display: block;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-style: dashed;
  }
`;

type Props = {
  settings: SiteWidgetSettings;
  hasQuestions: boolean;
  siteName: string;
  productId: string;
  openQuestionForm: () => void;
};

export default function EmptyState({
  settings,
  hasQuestions,
  siteName,
  productId,
  openQuestionForm,
}: Props) {
  const formLink = `https://reviewsjet.com/w/${siteName}?product=${productId}`;

  const {
    productWidget: { textColor, buttonBackgroundColor, buttonTextColor },
    translation: {
      productReviewsEmptyState,
      questionForm: { addQuestion },
      reviewForm: { addReview },
    },
  } = settings;

  return (
    <Wrapper $centerContent={!hasQuestions}>
      <p className="assistant-400" style={{ color: textColor, fontSize: 15 }}>
        {productReviewsEmptyState}
      </p>
      <button
        className="assistant-500"
        style={{
          fontSize: 15,
          color: buttonTextColor,
          backgroundColor: buttonBackgroundColor,
        }}
        onClick={() => window.open(formLink, '_blank')}
      >
        {addReview}
      </button>
      {!hasQuestions && (
        <span
          style={{ color: textColor, fontSize: 15 }}
          role="presentation"
          onClick={openQuestionForm}
          className="assistant-500"
        >
          {addQuestion}
        </span>
      )}
    </Wrapper>
  );
}
