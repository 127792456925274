import { useEffect, useState } from 'react';
import { FaCirclePlay } from 'react-icons/fa6';
import styled from 'styled-components';
import { MediaWithReview } from '../../../../../../review/types';

const Media = styled.div<{ $bgUrl: string; $isLoaded: boolean }>`
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  background-size: cover;
  background-image: ${({ $bgUrl, $isLoaded }) =>
    $isLoaded ? `url(${$bgUrl})` : 'none'};
  background-repeat: no-repeat;
  background-position: center;
  background-color: #000;
  &:hover {
    opacity: 0.7;
  }
`;

const PlayBtn = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
`;

type Props = {
  onClick: () => void;
  mediaWithReview: MediaWithReview;
};

export default function MediaElement({ mediaWithReview, onClick }: Props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const media = mediaWithReview.media;

  useEffect(() => {
    setIsLoaded(false);
    const img = new Image();
    img.src = media;
    img.onload = () => setIsLoaded(true);
  }, [media]);

  return (
    <Media
      $isLoaded={isLoaded}
      $bgUrl={mediaWithReview.media}
      role="presentation"
      onClick={onClick}
    >
      {mediaWithReview.type === 'video' && (
        <PlayBtn>
          <FaCirclePlay size={30} color="#FFF" />
        </PlayBtn>
      )}
    </Media>
  );
}

// <Media
//       $bgUrl={mediaWithReview.media}
//       role="presentation"
//       onClick={() => {
//         openMedia(mediaWithReview);
//         setCurrentIndex(index);
//       }}
//     >
//       {mediaWithReview.type === 'video' && (
//         <PlayBtn>
//           <FaCirclePlay size={30} color="#FFF" />
//         </PlayBtn>
//       )}
//     </Media>
