import { useState } from 'react';
import styled from 'styled-components';
import { axiosPrivate } from '../../../../services/axiosPrivate';
import Button from '../../../../shared/components/Button';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import { Loading } from '../../../../shared/types/review.types';
import removeTrailingSlash from '../../../../shared/utils/removeTrailingSlash';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { ReviewsJetPlatform } from '../../../product/types';
import { getSite } from '../../../userData/redux/action';
import { accountSelector } from '../../../userData/redux/userData.slice';

const Wrapper = styled.div`
  .title {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 10px;
  }

  ul {
    margin: 20px 0 25px 20px;
  }

  ul li {
    position: relative;
    margin-bottom: 10px;

    &::before {
      content: '';
      position: absolute;
      left: -20px;
      top: 3px;
      width: 1em;
      height: 1em;
      background-repeat: no-repeat;
      background-image: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M362.6 192.9L345 174.8c-.7-.8-1.8-1.2-2.8-1.2-1.1 0-2.1.4-2.8 1.2l-122 122.9-44.4-44.4c-.8-.8-1.8-1.2-2.8-1.2-1 0-2 .4-2.8 1.2l-17.8 17.8c-1.6 1.6-1.6 4.1 0 5.7l56 56c3.6 3.6 8 5.7 11.7 5.7 5.3 0 9.9-3.9 11.6-5.5h.1l133.7-134.4c1.4-1.7 1.4-4.2-.1-5.7z"></path></svg>');
    }
  }
`;

const StyledButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  color: #000;
  font-size: 14px;
`;

export default function CancelSubscriptionModal({
  onClose,
}: {
  onClose: () => void;
}) {
  const dispatch = useAppDispatch();
  const [processing, setProcessing] = useState(Loading.INITIAL);
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { platform } = selectedSite ?? {};

  const handleCancelStripeSubscription = async () => {
    try {
      setProcessing(Loading.PENDING);

      await axiosPrivate.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/stripe/cancel-subscription`
      );

      setProcessing(Loading.SUCCESS);
    } catch (error) {
      setProcessing(Loading.ERROR);
    }
  };

  const handleCancelShopifySubscription = async () => {
    try {
      setProcessing(Loading.PENDING);

      await axiosPrivate.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/shopify/cancel-subscription`
      );

      setProcessing(Loading.SUCCESS);
    } catch (error) {
      setProcessing(Loading.ERROR);
    }
  };

  const handleCancelSubscription = async () => {
    if (platform === ReviewsJetPlatform.SHOPIFY) {
      await handleCancelShopifySubscription();
    } else {
      await handleCancelStripeSubscription();
    }

    dispatch(getSite({ showPreloader: true }));
    onClose();
  };

  return (
    <Wrapper>
      <h3 className="title">Cancel Subscription?</h3>
      <p>Wait! Don’t miss out on these premium features:</p>
      <ul>
        <li>Display from 500 to unlimited text reviews </li>
        <li>Display from 2 to 50 video reviews </li>
        <li>Send from 250 to 5000 review requests per month</li>
      </ul>
      <p>Are you sure you want to cancel?</p>

      <FlexContainer
        justifyContent="flex-end"
        gap="10px"
        style={{ marginTop: 20 }}
      >
        <StyledButton onClick={onClose}>Back</StyledButton>
        <Button
          rounded
          variant="danger"
          size="small"
          onClick={handleCancelSubscription}
          disabled={processing === Loading.PENDING}
        >
          {processing === Loading.PENDING ? 'Please wait...' : 'Yes, Cancel'}
        </Button>
      </FlexContainer>
    </Wrapper>
  );
}
