import { useCallback, useState } from 'react';
import { batch } from 'react-redux';
import { getSite } from '../../features/userData/redux/action';
import { selectedSiteSelector } from '../../features/userData/redux/userData.slice';
import { axiosPrivate } from '../../services/axiosPrivate';
import { useAppDispatch, useAppSelector } from '../../store';
import { toggleUpgradeModal } from '../../store/app.slice';
import { Loading } from '../types/review.types';
import removeTrailingSlash from '../utils/removeTrailingSlash';
import { useNotification } from './useNotification';

const API_BASE_URL = removeTrailingSlash(
  process.env.REACT_APP_REVIEWS_JET_API!
);

/* eslint no-console: "warn" */

const useUpgradeLink = () => {
  const dispatch = useAppDispatch();
  const { showErrorToast, showSuccessToast } = useNotification();
  const [generatingUpgradeLink, setGeneratingUpgradeLink] = useState(
    Loading.INITIAL
  );

  // Selectors
  const selectedSite = useAppSelector(selectedSiteSelector);
  const { instanceId, siteId, stripe } = selectedSite ?? {};
  const { subscriptionId } = stripe ?? {};
  const { upgradePlanId, billingCycle } = useAppSelector((state) => state.app);

  // Error handling helper
  const handleError = (error: any) => {
    setGeneratingUpgradeLink(Loading.ERROR);
    const errorMessage = error.response?.data?.error || error.message;
    showErrorToast(errorMessage);
  };

  // Generate Wix upgrade link
  const handleGenerateWixUpgradeLink = useCallback(
    async (plan?: string | null, cycle?: string | null) => {
      try {
        setGeneratingUpgradeLink(Loading.PENDING);

        const {
          data: { checkoutUrl },
        } = await axiosPrivate.post(`${API_BASE_URL}/wix/upgrade-link`, {
          instanceId,
          productId: plan ?? upgradePlanId,
          billingCycle: cycle ?? billingCycle,
        });

        setGeneratingUpgradeLink(Loading.SUCCESS);
        window.open(checkoutUrl, '_blank');
      } catch (error: any) {
        handleError(error);
      }
    },
    [upgradePlanId, billingCycle, instanceId]
  );

  // Generate Stripe upgrade link
  const handleGenerateStripeUpgradeLink = useCallback(
    async (plan?: string | null, cycle?: string | null) => {
      try {
        setGeneratingUpgradeLink(Loading.PENDING);

        const {
          data: { confirmationUrl },
        } = await axiosPrivate.post(
          `${API_BASE_URL}/account/initiate-payment`,
          {
            plan: plan ?? upgradePlanId,
            cycle: cycle ?? billingCycle,
          }
        );

        setGeneratingUpgradeLink(Loading.SUCCESS);
        window.open(confirmationUrl, '_blank');
      } catch (error: any) {
        handleError(error);
      }
    },
    [upgradePlanId, billingCycle]
  );

  // Change Stripe subscription
  const handleChangeStripeSubscription = useCallback(
    async (plan?: string | null, cycle?: string | null) => {
      const isWixUpgradePage = window.location.href.includes('plans');

      try {
        setGeneratingUpgradeLink(Loading.PENDING);

        await axiosPrivate.post(`${API_BASE_URL}/stripe/change-subscription`, {
          plan: plan ?? upgradePlanId,
          cycle: cycle ?? billingCycle,
        });

        setGeneratingUpgradeLink(Loading.SUCCESS);

        batch(() => {
          dispatch(toggleUpgradeModal(null));
          dispatch(getSite({ showPreloader: true })).catch(() => {});
        });

        if (isWixUpgradePage) {
          showSuccessToast(
            'Subscription changed successfully. Redirecting to the dashboard...'
          );

          setTimeout(() => {
            const redirectUrl = `https://manage.wix.com/dashboard/${siteId}/app/${process.env.REACT_APP_WIX_APP_ID}`;

            window.location.href = redirectUrl;
          }, 2000);
        } else {
          showSuccessToast('Subscription changed successfully');
        }
      } catch (error: any) {
        handleError(error);
      }
    },
    [upgradePlanId, billingCycle]
  );

  // Main upgrade handler
  const handleUpgrade = useCallback(
    async (plan?: string | null) => {
      if (subscriptionId) {
        await handleChangeStripeSubscription(plan);
      } else {
        await handleGenerateStripeUpgradeLink(plan);
      }
    },
    [
      subscriptionId,
      handleChangeStripeSubscription,
      handleGenerateStripeUpgradeLink,
    ]
  );

  return {
    handleUpgrade,
    generatingUpgradeLink,
    handleGenerateWixUpgradeLink,
    handleGenerateStripeUpgradeLink,
  };
};

export default useUpgradeLink;
