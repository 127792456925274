import { useNavigate } from 'react-router-dom';
import AppModal from '../../../../shared/components/modal';
import useModal from '../../../../shared/components/modal/useModal';
import { useAppSelector } from '../../../../store';
import AddIntegrationModal from '../../../settings/components/Integrations/components/AddIntegrationModal';
import { isActiveCampaignConnectedSelector } from '../../../settings/components/Integrations/redux/integrations.slice';
import { PlatformButton } from '../MarketingPlatforms';

interface ActiveCampaignPlatformProps {
  onClose: () => void;
}

export default function ActiveCampaignPlatform({
  onClose,
}: ActiveCampaignPlatformProps) {
  const navigate = useNavigate();
  const isActiveCampaignConnected = useAppSelector(
    isActiveCampaignConnectedSelector
  );
  const { handleCloseModal, handleOpenModal, showModal } = useModal();

  return (
    <>
      <PlatformButton
        onClick={() => {
          if (isActiveCampaignConnected) {
            navigate('/share-form?source=activecampaign');
          } else {
            handleOpenModal();
          }
        }}
      >
        <img src="/images/active-campaign.png" alt="ActiveCampaign" />
        Invite ActiveCampaign contacts
      </PlatformButton>

      <AppModal
        size="sm"
        shouldOpenModal={showModal}
        onModalClose={handleCloseModal}
        body={
          <AddIntegrationModal
            onClose={handleCloseModal}
            onConnect={() => {}}
            integration="ActiveCampaign"
            logo={
              <div className="logo">
                <img
                  src="/images/active-campaign.png"
                  alt=""
                  style={{ width: 150 }}
                />
              </div>
            }
          />
        }
      />
    </>
  );
}
