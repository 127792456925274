import { useNavigate } from 'react-router-dom';
import { Button as RsButton } from 'rsuite';
import styled from 'styled-components';
import Button from '../../../../shared/components/Button';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import { Title } from '../../../../shared/components/Title';

const StyledTitle = styled(Title)`
  font-size: 18px;
  margin-bottom: 16px;
  color: #111827;
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: #4b5563;
  margin-bottom: 20px;
`;

const CustomerList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 8px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledButton = styled(Button)`
  background: #000;
  border: none;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px;

  &:disabled {
    background: #ccc;
  }
`;

const CustomerItem = styled.li`
  padding: 10px 14px;
  background: #f9fafb;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease;

  &:hover {
    background: #f3f4f6;
  }

  .name {
    font-weight: 500;
    color: #111827;
  }

  .email {
    color: #6b7280;
    margin-left: 4px;
  }
`;

const MoreCustomers = styled.li`
  color: #6b7280;
  font-size: 13px;
  padding: 10px 14px;
  list-style: none;
  font-weight: 500;
`;

const Footer = styled(FlexContainer)`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 28px;
  padding-top: 24px;
  border-top: 1px solid #e5e7eb;
`;

export default function NextStep({
  onClose,
  totalSent,
}: {
  onClose: () => void;
  totalSent: number;
}) {
  const navigate = useNavigate();
  return (
    <div>
      <StyledTitle>Next Step</StyledTitle>
      <Description>
        You email{totalSent > 1 ? 's' : ''} {totalSent > 1 ? 'have' : 'has'}{' '}
        been scheduled successfully. You can view them by clicking the{' '}
        <b>View Emails</b> button below.
      </Description>

      <Footer>
        <RsButton appearance="subtle" onClick={onClose}>
          Cancel
        </RsButton>
        <StyledButton onClick={() => navigate('/emails')}>
          View Emails
        </StyledButton>
      </Footer>
    </div>
  );
}
